import React, { useEffect, useState } from "react";
import "./addBlog.css";
import Navbar3 from "../navbar3/navbar3";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Loader from "../Loader";
import OverlayLoader from "../loader/OverlayLoader";
import { AdditionalRoles } from "../../constants/GlobalConstants";

const EditPlan = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plantype = queryParams.get("plantype");
  const [plan, setPlan] = useState();
  const [otherPlan, setOtherPlan] = useState({
    name: plan?.name || "",
    description: plan?.description || "",
    price: plan?.price || "",
    duration: plan?.duration || "",
    planType: plan?.planType || "",
  });
  const [values, setValues] = useState();
  const [limit, setLimit] = useState();
  const [quantity, setQuantity] = useState();
  const [download_cvs, setDownload] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (
      !user ||
      (user?.role !== "admin" && !AdditionalRoles.includes(user?.role))
    ) {
      window.location.href = "/";
    }

    try {
      setIsFetching(true);
      const fetchPlan = async () => {
        await axios.get(`/adminPanel/plans/registration/${id}`).then((res) => {
          setPlan(res.data?.doc);
          setValues(res.data?.doc);
          const p = res.data?.doc;
          if (p.category === "advertise jobs") {
            setQuantity(p?.advertiseJobs?.postJobs);
            setLimit(p?.advertiseJobs?.daysAllowed);
          } else {
            setQuantity(p?.searchCvs?.view_cvs);
            setDownload(p?.searchCvs?.download_cvs);
            setLimit(p?.searchCvs?.weeksAllowed);
          }
        });
      };

      const fetchOtherPlan = async () => {
        await axios.get(`/adminPanel/plans/others/${id}`).then((res) => {
          setPlan(res.data?.doc);
          setOtherPlan(res.data?.doc);
        });
      };

      if (plantype === "checkout-plan") fetchPlan();
      if (plantype === "other") fetchOtherPlan();
      setIsFetching(false);
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error);
    }
  }, [id, plantype, user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (plantype === "checkout-plan") {
        let data;
        plan?.category === "advertise jobs"
          ? (data = {
              name: values?.name,
              advertiseJobs: {
                postJobs: quantity,
                daysAllowed: limit,
              },
              price: values?.price,
              discount: values?.discount,
            })
          : (data = {
              name: values?.name,
              searchCvs: {
                view_cvs: quantity,
                download_cvs: download_cvs,
                weeksAllowed: limit,
              },
              price: values?.price,
              discount: values?.discount,
            });

        let route;
        if (user?.role === "admin")
          route = `/adminPanel/plans/registration/${id}`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/plans/registration/${id}`;

        await axios.patch(route, data);
      }
      if (plantype === "other") {
        let route;
        if (user?.role === "admin") route = `/adminPanel/plans/others/${id}`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/plans/others/${id}`;

        await axios.patch(route, otherPlan);
      }
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/adminboard";
      }, 1000);
    } catch (error) {
      // alert(error.response?.data?.message);
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const hanldeChange = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="">
        {isLoading && <OverlayLoader />}
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details to add your plan</p>
          <div className="register_form">
            <h3 className="register_form_h3_start">Plan Details</h3>

            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {plantype === "checkout-plan" && (
                  <form>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Plan Name</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={values?.name}
                          onChange={(e) => hanldeChange("name", e.target.value)}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Category</label>
                        <br />
                        <select disabled>
                          {plan?.category === "advertise jobs" ? (
                            <option value="advertise jobs" selected>
                              Advertise Jobs
                            </option>
                          ) : (
                            <option value="search cvs" selected>
                              Search CV
                            </option>
                          )}
                        </select>
                      </div>
                    </div>

                    {plan?.category === "advertise jobs" && (
                      <>
                        <div className="register_form_div">
                          <div className="register_options">
                            <label>Number of Jobs allowed daily</label>
                            <br />
                            <input
                              id="keyword"
                              className="text"
                              type="number"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target?.value)}
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="register_form_div">
                          <div className="register_options">
                            <label>Time Limit in days</label>
                            <br />
                            <input
                              id="keyword"
                              className="text"
                              type="number"
                              value={limit}
                              onChange={(e) => setLimit(e.target?.value)}
                              required
                            ></input>
                          </div>
                        </div>
                      </>
                    )}

                    {plan?.category === "search cvs" && (
                      <>
                        <div className="register_form_div">
                          <div className="register_options">
                            <label>How much cvs can be viewed</label>
                            <br />
                            <input
                              id="keyword"
                              className="text"
                              type="number"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target?.value)}
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="register_form_div">
                          <div className="register_options">
                            <label>How much cvs can be downloaded</label>
                            <br />
                            <input
                              id="keyword"
                              className="text"
                              type="number"
                              value={download_cvs}
                              onChange={(e) => setDownload(e.target?.value)}
                              required
                            ></input>
                          </div>
                        </div>

                        <div className="register_form_div">
                          <div className="register_options">
                            <label>Time limit in weeks</label>
                            <br />
                            <input
                              id="keyword"
                              className="text"
                              type="number"
                              value={limit}
                              onChange={(e) => setLimit(e.target?.value)}
                              required
                            ></input>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Price</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          value={values?.price}
                          required
                          onChange={(e) =>
                            hanldeChange("price", e.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Discount</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          value={values?.discount}
                          required
                          onChange={(e) =>
                            hanldeChange("discount", e.target.value)
                          }
                        ></input>
                      </div>
                    </div>
                  </form>
                )}

                {plantype === "other" && (
                  <form>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Plan Name</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          placeholder="name"
                          value={otherPlan.name}
                          onChange={(e) =>
                            setOtherPlan({ ...otherPlan, name: e.target.value })
                          }
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Plan Type</label>
                        <br />
                        <select
                          name=""
                          id=""
                          value={otherPlan.planType}
                          onChange={(e) =>
                            setOtherPlan({
                              ...otherPlan,
                              planType: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Please Select</option>
                          <option value="hire-recruiter">
                            Hire Recruiters Plan
                          </option>
                          <option value="premium-jobseeker">
                            Premium JobSeeker Plan
                          </option>
                          <option value="background-check">
                            Background Check Plan
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Price</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="price"
                          value={otherPlan.price}
                          onChange={(e) =>
                            setOtherPlan({
                              ...otherPlan,
                              price: e.target.value,
                            })
                          }
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Duration in Days</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="duration"
                          value={otherPlan.duration}
                          onChange={(e) =>
                            setOtherPlan({
                              ...otherPlan,
                              duration: e.target.value,
                            })
                          }
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Description</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          placeholder="description"
                          value={otherPlan.description}
                          onChange={(e) =>
                            setOtherPlan({
                              ...otherPlan,
                              description: e.target.value,
                            })
                          }
                          required
                        ></input>
                      </div>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Update Plan</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditPlan;
