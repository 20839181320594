import React from "react";
import "./payplans.css";
import Button from "../button/button";
const payplan = ({ week, curPayment, prevPayment, downloads, onclick }) => {
    return (
        <div className="payplan_maindiv animate__backInRight">
            <h1>{week} Weeks</h1>
            <p className="p1">cv database access</p>
            <p className="p2">${curPayment} +vat</p>
            <p className="p3">was $<span className="prev">{prevPayment}</span></p>
            <hr />
            <p className="p3">Unlimited CV views</p>
            <p className="p3">Instant CV alerts</p>
            <p className="p3">{downloads} CV downloads</p>
            <Button onclick={onclick} text={"Buy now"} />

        </div>
    )
}

export default payplan;