import React, { useEffect, useRef, useState } from "react";
import "./addBlog.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";
import { AdditionalRoles } from "../../constants/GlobalConstants";

const AddPost = () => {
  const { user } = useAuthContext();
  const headline = useRef();
  const category = useRef();
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState([
    {
      heading: "",
      content: "",
    },
  ]);
  const body = useRef();
  const thumbnail = useRef();
  // const [selectedImages, setSelectedImages] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/candidate/login";
      return;
    }
    if (user && user?.role === "user") {
      setError(true);
      setErrorMessage("Courses user cannot add post. Login as Candidate");
      window.location.href = "/candidate/login";
      return;
    }
  }, [user]);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    try {
      event.preventDefault();
      if (categories.length < 1) {
        setError(true);
        setErrorMessage("Please fill all the fields to  continue");
        setIsLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("headline", headline.current.value);
      categories?.forEach((value) => {
        formData.append("category", value);
      });
      formData.append("thumbnail", thumbnail.current.files[0]);
      // if (selectedImages?.length > 0) {
      //   selectedImages.forEach((file) => {
      //     formData.append("images", file);
      //   });
      // }
      formData.append("postBody", body.current.value);
      if (
        formData.get("thumbnail") === "undefined" ||
        formData.get("headline") === "" ||
        formData.get("postBody") === ""
      ) {
        setError(true);
        setErrorMessage("Please fill all the fields to  continue");
        setIsLoading(false);
        return;
      }
      if (sections.length >= 1) {
        const sectionsData = sections.filter(
          (item) => item.heading.trim() !== "" || item.content.trim() !== "",
        );
        if (sectionsData.length > 0) {
          sectionsData.forEach((section, index) => {
            formData.append(`sections[${index}][heading]`, section.heading);
            formData.append(`sections[${index}][content]`, section.content);
          });
        }
      }
      let route;
      if (user?.role === "admin") route = "/career-advices";
      if (user?.role === "jobseeker") route = "/career-advices/candidates";
      if (user?.role === "employer") route = "/career-advices/employers";
      if (user?.role === "company") route = "/career-advices/companies";
      if (AdditionalRoles.includes(user?.role))
        route = "/additionalUsers/posts";

      await axios.post(route, formData).then((res) => {
        setSuccess(true);
        setSuccessMessage("Post Created successfully!");
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/career-advice";
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const hanldeCategoryChange = (e) => {
    const { value } = e.target;
    setCategories((prev) => [...prev, value]);
  };

  // const handleImagesUpload = async (event) => {
  //   const files = event.target.files;
  //   if (files.length > 5) {
  //     setError(true);
  //     setErrorMessage("Only 5 images are allowed");
  //   }
  //   const updatedImages = [];

  //   for (let i = 0; i < files.length && i < 5; i++) {
  //     updatedImages.push(files[i]);
  //   }

  //   setSelectedImages(updatedImages);
  // };

  const categoryOptions = [
    "getting started",
    "career guides",
    "location guides",
    "cvs",
    "cv tips and templates",
    "cover letter guides",
    "interview tips",
    "how to guides",
    "work life balance",
    "career development",
    "graduates",
    "apprenticeships",
  ];

  const addSection = () => {
    if (sections.length > 0) {
      const lastSection = sections[sections.length - 1];
      if (
        lastSection.heading.trim() === "" ||
        lastSection.content.trim() === ""
      ) {
        setError(true);
        setErrorMessage("Last section must not have empty values.");
        return;
      }
      setSections((prev) => [...prev, { heading: "", content: "" }]);
    } else setSections((prev) => [...prev, { heading: "", content: "" }]);
  };

  const removeSection = (index) => {
    const newData = [...sections];
    newData.splice(index, 1);
    setSections(newData);
  };

  const handleSectionChange = (value, index, name) => {
    if (index >= 0 && index < sections.length) {
      setSections((prevSections) => {
        const updatedSections = [...prevSections]; // Create a copy of the array
        updatedSections[index][name] = value;
        return updatedSections;
      });
    }
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>
        <p>We just need a few details to add your post</p>
        <div className="register_form">
          <h3 className="register_form_h3_start">Post Headline</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Headline</label>
                <br />
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="headline"
                  ref={headline}
                  required
                ></input>
              </div>
            </div>
          </form>
        </div>
        <div className="register_form">
          {/* <h3 className="register_form_h3_start">Category</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>You can select multiple Categories</label>
                <br />
                <select name="" id="" ref={category} required>
                  <option value="Please Select ">Please Select</option>
                  <option value="$1- $10,000">$1- $10,000</option>
                  <option value="$10,001-$15,000">$10,001-$15,000</option>
                  <option value="15,001- $20,000">$15,001- $20,000</option>
                  <option value="$20,001- $25,000">$20,001- $25,000</option>
                  <option value="$25,001- $30,000">$25,001- $30,000</option>
                  <option value="$30,001- $40,000">$30,001- $40,000</option>
                  <option value="$40,001- $60,000">$40,001- $60,000</option>
                  <option value="$60,001- $80,000">$60,001- $80,000</option>
                  <option value="$80,001- $100,000">$80,001- $100,000</option>
                  <option value="$100,001- $120,000">$100,001- $120,000</option>
                  <option value="$120,001">$120,001</option>
                </select>
              </div>
            </div>
          </form> */}

          <h3 className="register_form_h3_start">Categories</h3>
          <hr />
          <div className="register_div_jobtype">
            {categoryOptions?.map((option, index) => {
              return (
                <div key={index}>
                  <input
                    type="radio"
                    id={`${option}`}
                    name=""
                    value={`${option}`}
                    ref={category}
                    onChange={hanldeCategoryChange}
                  />
                  &nbsp;&nbsp;
                  <label
                    style={{ textTransform: "capitalize" }}
                    htmlFor={`${option}`}
                  >{`${option}`}</label>
                  <br />
                </div>
              );
            })}
          </div>
        </div>

        <div className="register_form">
          <h3 className="register_form_h3_start">Post Body</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Body</label>
                <br />
                <textarea
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="Post Body"
                  ref={body}
                  required
                  maxLength={"2000"}
                ></textarea>
              </div>
            </div>
          </form>
        </div>

        <div className="register_form">
          <h3 className="register_form_h3_start">
            Upload Thumbnail for your Post
          </h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options register_options_label">
                <input
                  id="cv"
                  type="file"
                  accept="image/*"
                  ref={thumbnail}
                  required
                />
                <label>Upload thumbnail</label>
                <br />
              </div>
            </div>
            {/* <div className="register_form_div">
              <div className="register_options register_options_label">
                <input
                  id="cv"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImagesUpload}
                />
                <label>Upload Additional Images</label>
                <br />
              </div>
            </div> */}
          </form>
        </div>

        <div className="register_form">
          <h3 className="register_form_h3_start">Additional Sections</h3>

          <hr />
          <form>
            {sections?.map((item, index) => {
              return (
                <div className="register_form_div" key={index}>
                  <div className="register_options">
                    <label>Heading</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={item?.heading}
                      onChange={(e) =>
                        handleSectionChange(e.target.value, index, "heading")
                      }
                      required
                      maxLength={"300"}
                    ></input>
                  </div>
                  <div className="register_options">
                    <label>Content</label>
                    <br />
                    <textarea
                      id="keyword"
                      className="text"
                      type="text"
                      value={item?.content}
                      onChange={(e) =>
                        handleSectionChange(e.target.value, index, "content")
                      }
                      required
                      maxLength={"2000"}
                    ></textarea>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => removeSection(index)}
                  >
                    Remove Section
                  </button>
                </div>
              );
            })}
          </form>
          <hr />
          <button className="btn btn-primary mt-4" onClick={addSection}>
            Add Section
          </button>
        </div>

        <div className="button_maindiv">
          <button onClick={handleSubmit}>Add Post</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddPost;
