import React from "react";
import "./footer.css";
import cv_logo from "../../images/johan.png";
import { Link } from "react-router-dom";
import {
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTiktok,
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa";
import { motion } from "framer-motion";
import LazyImage from "../common/LazyImage";

const Footer = () => {
  return (
    <div className="footer_maindiv">
      <div className="footer_subdiv1">
        <div>
          <h4>POPULAR</h4>
          <ul>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/job-recruiting-websites">Search Jobs</Link>
            </li>

            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/search-company">Businesses</Link>
            </li>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/career-advice">Career Guidance</Link>
            </li>
          </ul>
        </div>

        <div>
          <h4>JOB SEEKERS</h4>
          <ul>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/register">Register</Link>
            </li>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/job-boards-usa">Find Jobs</Link>
            </li>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/job-alerts">Jobs Alert</Link>
            </li>
          </ul>
        </div>

        <div>
          <h4>EMPLOYERS</h4>
          <ul>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/online-recruitment">Resume/CV Database Access</Link>
            </li>

            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/job-advertisement">Advertise Jobs </Link>
            </li>
            <li style={{ fontSize: "16px", fontFamily: "system ui" }}>
              <Link to="/searchcv"> Seacrh Resume/CVs</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4 style={{ textTransform: "uppercase" }}>About US</h4>
          <ul>
            <li style={{ fontSize: "16px" }}>
              <Link to="/about-us">About Us</Link>
            </li>
            <li style={{ fontSize: "16px" }}>
              <Link to="/our-services">Our Services</Link>
            </li>
            <li style={{ fontSize: "16px" }}>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>

        <Link to="/">
          <LazyImage src={cv_logo} alt="logo" className="footer_img_div" />
        </Link>
      </div>
      
      <motion.hr
        style={{ height: "2px", background: "#ffff" }}
        className="horzinatal-line-footer"
      />
      <div className="footer_subdiv2">
        <div className="footer_terms_div">
          <ul>
            <li>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy</Link>
            </li>
          </ul>

          <p>
            © Copyright 2023 - 2027 Alphy Johnson Employment Resource Center Ltd. All rights
            reserved
          </p>
        </div>
        <div className="footer_img_div2 d-flex flex-column-reverse justify-content-end  align-items-end  flex-wrap align-items-end pt-3 foot-apndevs">
          <a
            className="nav-link"
            href="https://apnadevs.com/"
            style={{ color: "white", fontSize: "20px" }}
          >
            Website development By ApnaDevs
          </a>
          <div className="footer-icons-sections me-5">
            <div className="footer-icons">
              <FaFacebook />
            </div>
            <div className="footer-icons">
              <FaInstagram />
            </div>
            <div className="footer-icons">
              <FaYoutube />
            </div>
            <div className="footer-icons">
              <FaTwitter />
            </div>
            <div className="footer-icons">
              <FaTiktok />
            </div>
            <div className="footer-icons">
              <FaLinkedin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
