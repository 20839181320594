import React from "react";
import BrowseIndustryjob from "./BrowseIndustryjob";
import Industries from "./Industries";
import JobSearch from "./JobSearch";
import Moreindustry from "./Moreindustry";
import TrendingJobs from "./TrendingJobs";
import "./searchjob.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import Dropdown from "../homepage/Dropdown";
import { Helmet } from "react-helmet";
import LazyBackground from "../../components/common/LazyBackground";
import { IMAGES } from "../../constants/GlobalConstants";

function Searchjob() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Job Boards in the USA | Find Your Dream Job Today!</title>
        <link rel="canonical" href="https://thealphyjohnson.com/job-boards-usa" />
        <meta
          name="description"
          content="Discover the best job boards in the USA to kickstart your career. Explore & find the perfect job that matches your skills and interests. Start your job search now!"
        />
      </Helmet>
      <Navbar3 />
      {/* <Bar/> */}
      <LazyBackground src={IMAGES.homepage_img2} className="hompage_jobs search_jobs_page_header">
        <div className="homepage_search flex-wrap">
          <JobSearch isHome={false} />
        </div>
        <Dropdown />
      </LazyBackground>

      <Industries />
      <BrowseIndustryjob />
      <TrendingJobs />
      <Moreindustry />
      <Footer />
    </div>
  );
}
export default Searchjob;
