import React from "react";
import { Industries, Locations } from "../../constants/GlobalConstants";

const BottomOptions = () => {
  return (
    <div className="careeradvice_latest_jobs">
      <div>
        <h3>Jobs By Industry</h3>
        <hr />
        <div className="jobs_lists">
          <ul id="">
            {Industries.sort()
              .slice(0, 5)
              .map((item) => {
                return (
                  <li key={item} style={{ cursor: "pointer" }}>
                    <a href={`/job-recruiting-websites?industry=${encodeURIComponent(item)}`}>
                      {item}
                    </a>
                  </li>
                );
              })}
          </ul>
          <ul id="">
            {Industries.sort()
              .slice(5, 10)
              .map((item) => {
                return (
                  <li key={item} style={{ cursor: "pointer" }}>
                    <a href={`/job-recruiting-websites?industry=${encodeURIComponent(item)}`}>
                      {item}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div>
        <h3>Jobs By Location</h3>
        <hr />
        <div className="jobs_lists">
          <ul id="">
            {Locations.slice(0, 5).map((item) => {
              return (
                <li key={item} style={{ cursor: "pointer" }}>
                  <a href={`/job-recruiting-websites?location=${item}`}>Jobs in {item}</a>
                </li>
              );
            })}
          </ul>
          <ul id="">
            {Locations.slice(5, 10).map((item) => {
              return (
                <li key={item} style={{ cursor: "pointer" }}>
                  <a href={`/job-recruiting-websites?location=${item}`}>Jobs in {item}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomOptions;
