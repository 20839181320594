import React, { useEffect, useRef, useState } from "react";
import "./register.css";
import "../../components/button/button.css";
import { useLocation, useParams } from "react-router-dom";
import CvNavbar from "../../cvdatabase/cvdatabase_navbar/Cv_navbar";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Password = () => {
  const password = useRef();
  const pageLocation = useLocation();
  const queryParams = new URLSearchParams(pageLocation.search);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const role = queryParams.get("role");
    // console.log(role);
    const data = {
      password: password.current.value,
    };
    let route;
    if (role === "employer") route = `/employers/register/${id}`;
    if (role === "company") route = `/companies/register/${id}`;
    try {
      await axios.patch(route, data).then((res) => {
        const { user } = res?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Password added Successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/profile";
        }, 2000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const viewPolicy = () => {
    window.location.href = "/privacy-policy";
  };

  const viewTerms = () => {
    window.location.href = "/terms-and-conditions";
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="">
        <CvNavbar />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details about you to build your profile.</p>

          <div className="register_form">
            <h3 className="register_form_h3_start">Your Password</h3>
            <hr />
            <form>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Password</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="password"
                    ref={password}
                    required
                  ></input>
                </div>
              </div>
            </form>
            <div className="register_form_div">
              <input type="checkbox" />
              <p className="register_terms_conditions">
                By registering with Alphy Johnson's Employment Center you agree
                to our
                <span onClick={viewPolicy}> privacy policy </span> and
                <span onClick={viewTerms}> Term & Conditions</span>{" "}
              </p>
            </div>
          </div>

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Update Password</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Password;
