import React from "react";
import "./interestedJobs.css";

const InterestedJobs = ({ jobName, jobLocation, salary }) => {
  return (
    <div className="interestedjobs_maindiv">
      <h3>Title: {jobName}</h3>
      <p>At: {jobLocation}</p>
      <p>Salary: ${salary}</p>
    </div>
  );
};

export default InterestedJobs;
