import { useEffect, useRef, useState } from "react";

const LazyLoadingComponent = ({ fetchData = () => {}, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect observer once it's visible to avoid repeated triggers
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // Adjust this depending on how early you want to trigger
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      fetchData();
    }
  }, [isVisible]);

  return <div ref={containerRef}>{children}</div>;
};

export default LazyLoadingComponent;
