import React, { useState } from "react";
import "./adminPanel.css";
import {
  FaChalkboardTeacher,
  FaRegistered,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import { TbBrandBinance } from "react-icons/tb";
import { GiMultiDirections } from "react-icons/gi";
import { SiConcourse, SiTheregister } from "react-icons/si";
import { RiLuggageCartFill, RiUserSettingsFill } from "react-icons/ri";
import { MdLocalPolice, MdOutlineContactMail } from "react-icons/md";
import { ImUserPlus } from "react-icons/im";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
// import { BsPersonVcardFill } from "react-icons/bs";
import { change_show } from "../../redux/actions";
import { store } from "../../redux/store";
import { useAuthContext } from "../../context/AuthContext";

const AdminNavbar = () => {
  const [show, setShow] = useState(store.getState().NavReducer.show);
  const { user } = useAuthContext();
  store.subscribe(() => {
    setShow(store.getState().NavReducer.show);
  });

  const handleShow = (value) => {
    store.dispatch(change_show(value));
  };

  return (
    <div className="admin_navbar">
      <section className="nav_section1">
        <p className="site_logo">
          {user?.role === "admin" ? "Admin Dashboard" : "Dashboard"}
        </p>
      </section>
      <section className="navbar_options">
        <ul>
          {(user?.role === "admin" || user?.permissions.view_candidates) && (
            <li
              className={
                show?.showCandidates
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showCandidates")}
            >
              <FaUsers />
              Candidates
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_employers) && (
            <li
              className={
                show?.showEmployers
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showEmployers")}
            >
              <FaUserPlus />
              Employers
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_companies) && (
            <li
              className={
                show?.showCompanies
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showCompanies")}
            >
              <TbBrandBinance />
              Companies
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_course_users) && (
            <li
              className={
                show?.showUsers
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showUsers")}
            >
              <FaChalkboardTeacher />
              Course Users
            </li>
          )}
          {user?.role === "admin" && (
            <li
              className={
                show?.showAdditionalUsers
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showAdditionalUsers")}
            >
              <RiUserSettingsFill />
              Management
            </li>
          )}
          <li
            className={
              show?.showPosts
                ? "admin_navbar_option admin_navbar_option_active"
                : "admin_navbar_option"
            }
            onClick={() => handleShow("showPosts")}
          >
            <GiMultiDirections />
            Career Guidance
          </li>
          {(user?.role === "admin" ||
            user?.permissions.view_checkout_plans) && (
            <li
              className={
                show?.showPlans
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showPlans")}
            >
              <FaRegistered />
              Recruiter Plans
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_premium_plans) && (
            <li
              className={
                show?.showPremiumPlans
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showPremiumPlans")}
            >
              <SiTheregister />
              Premium Plans
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_hiring_orders) && (
            <li
              className={
                show?.showHiringOrders
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showHiringOrders")}
            >
              <RiLuggageCartFill />
              Hiring Orders
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_courses) && (
            <li
              className={
                show?.showCourses
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showCourses")}
            >
              <SiConcourse />
              Courses
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.view_enquiries) && (
            <li
              className={
                show?.showEnquiries
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showEnquiries")}
            >
              <MdLocalPolice />
              Enquiries
            </li>
          )}
          {(user?.role === "admin" ||
            user?.permissions.view_contact_us_queries) && (
            <li
              className={
                show?.showContactQueries
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showContactQueries")}
            >
              <MdOutlineContactMail />
              Contact Queries
            </li>
          )}
          {(user?.role === "admin" || user?.permissions.add_recruiters) && (
            <li
              className={
                show?.showAddRecruiter
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showAddRecruiter")}
            >
              <ImUserPlus />
              Add Recruiters
            </li>
          )}
          {user?.role === "admin" && (
            <li
              className={
                show?.showLogs
                  ? "admin_navbar_option admin_navbar_option_active"
                  : "admin_navbar_option"
              }
              onClick={() => handleShow("showLogs")}
            >
              <DeveloperBoardIcon />
              User Logs
            </li>
          )}
          {/* <li
            className={
              show?.showProfile
                ? "admin_navbar_option admin_navbar_option_active"
                : "admin_navbar_option"
            }
            onClick={() => handleShow("showProfile")}
          >
            <BsPersonVcardFill />
            My Profile
          </li> */}
        </ul>
      </section>
    </div>
  );
};

export default AdminNavbar;
