import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Industries } from "../../constants/GlobalConstants";

const RightOptions = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    try {
      const fetchCompanies = async () => {
        const res = await axios.get("/companies/all");
        // console.log(res?.data.data);
        setCompanies(res.data?.data);
      };

      fetchCompanies();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const hanldeSearch = (name, value) => {
    window.location.href = `/job-recruiting-websites?${[name]}=${value}`;
  };

  return (
    <div className="blog_posts_flex_div_subdiv2 d-flex gap-4 flex-column">
      <div className="careerAdvice_top_sectors">
        <h3>Browse Top Sectors</h3>
        <hr style={{ color: "#fff", backgroundColor: "#fff" }} />
        <div className="jobs_lists">
          <ul id="">
            {Industries.sort().map((item) => {
              return (
                <li
                  key={item}
                  onClick={() => hanldeSearch("industry", item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="careerAdvice_top_sectors" style={{ margin: "0" }}>
        <h2>
          Explore Job Opportunities with with Career Builder Job Search Platform
          – Alphy Johnson’s
        </h2>
        <hr />
        <div className="jobs_lists">
          <ul id="">
            {companies?.slice(0, 15)?.map((obj, index) => {
              return (
                <li
                  key={index}
                  style={{ cursor: "pointer", color: "var(--blue)" }}
                >
                  <a
                    href={`/view-profile/${obj?._id}?role=company`}
                    style={{ textDecoration: "none" }}
                  >
                    {obj?.name?.length > 20
                      ? obj?.name?.slice(0, 20) + "..."
                      : obj?.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* <Guides
              img={calculator}
              heading={"Salary Guide"}
              para={
                "Check out average salaries across the UK and find out how much you could earn!"
              }
              btn={"Salary Guide"}
            />
            <Guides
              img={calculator}
              heading={"Salary Tax Calculator"}
              para={
                "Know how much you are actually earning each month? Check out our Salary Tax Calculator."
              }
              btn={"Salary Guide"}
            />
            <Guides
              img={calculator}
              heading={"Writers Network"}
              para={
                "Know how much you are actually earning each month? Check out our Salary Tax Calculator."
              }
              btn={"Salary Guide"}
            /> */}
    </div>
  );
};

export default RightOptions;
