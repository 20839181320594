import React from "react";
import "./loader.css";

const OverlayLoader = () => {
  return (
    <div className="overlay">
      <span className="loader"></span>
    </div>
  );
};

export default OverlayLoader;
