import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Profile.css";
import { TbArrowBigUpFilled } from "react-icons/tb";
import { ImLocation } from "react-icons/im";
import { AiFillEuroCircle } from "react-icons/ai";
import logo from "../../images/logo.png";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import { useAuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ProfileLeft from "./ProfileLeft";
import ProfileInfo from "./ProfileInfo";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";
import Logout from "./Logout";
import useLocationDetails from "../../components/useLocationDetails";
import Loader from "../../components/Loader";
import { useProfileContext } from "../../context/ProfileContext";
import * as api from "../../ApiCalls";
import { ACTIONS } from "../../context/ActionTypes";

const Profile = () => {
  const { user } = useAuthContext();
  useLocationDetails();
  const { state, dispatch, fetchProfileData } = useProfileContext();
  const [upgradePlan, setUpgradePlan] = useState();
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [cv, setCV] = useState();
  const file = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchCV = useCallback(async () => {
    await axios.get("/cv/").then((res) => {
      const { cv } = res.data;
      // console.log(cv);
      if (cv?.user._id === user._id) {
        setCV(cv);
      }
    });
  }, [user]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/register";
    }
    try {
      const fetchRecommendedJobs = async () => {
        await axios.get("/jobs/recommended-jobs").then((res) => {
          // console.log(res)
          setRecommendedJobs(res.data);
        });
      };

      const fetchUpgradePlan = async () => {
        const response = await axios.get(`/adminPanel/plans/others`);
        const data = response.data?.docs;
        let planData;
        if (user?.role === "company") {
          planData = data?.filter((obj) => obj?.planType === "hire-recruiter");
        }
        if (user?.role === "jobseeker") {
          planData = data?.filter((obj) => obj?.planType === "premium-jobseeker");
        }
        setUpgradePlan(planData[0]);
      };

      const fetchData = async () => {
        setIsFetching(true);
        await fetchProfileData();
        if (user?.role === "jobseeker") {
          await fetchCV();
          await fetchRecommendedJobs();
        }

        if (user?.role === "jobseeker" || user?.role === "company") await fetchUpgradePlan();

        setIsFetching(false);
      };

      fetchData();
    } catch (error) {
      console.log(error);
      // alert(error?.response?.data?.message);
    }
  }, [user, fetchProfileData, fetchCV]);

  const buildcv = () => {
    window.location.href = "/resume-fixer";
  };

  const handleFileInputChange = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("cv", file);
    try {
      await axios.post("/cv/upload", formData).then((res) => {
        console.log(res.data?.doc);
        const user = res.data?.doc;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("CV uploaded successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleButtonClick = () => {
    file.current.click();
  };

  const downloadCv = () => {
    const fileUrl = `${user?.cv}`;
    console.log(fileUrl);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "cv.pdf";
    link.target = "_blank";
    link.click();
  };

  const editProfile = () => {
    window.location.href = "/editprofile";
  };

  const updateCv = () => {
    window.location.href = "/updatecv";
  };

  const viewCv = () => {
    window.location.href = `/viewcv/${cv?._id}`;
  };

  const deleteCv = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/cv/${cv?._id}`);
      await fetchCV();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const upgradeProfile = () => {
    if (user?.role === "company")
      window.location.href = `/checkout/${upgradePlan?._id}?type=hire-recruiter`;
    if (user?.role === "jobseeker")
      window.location.href = `/checkout/${upgradePlan?._id}?type=upgrade-jobseeker`;
  };

  const handleViewJob = (jobId) => {
    window.location.href = `/jobs/${jobId}`;
  };

  const handleApplyNow = (id) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login first to continue");
      setTimeout(() => {
        window.location.href = "/candidate/login";
      }, 1000);
      return;
    }
    window.location.href = `/applynow/${id}`;
  };

  const fetchSavedJobs = async () => {
    try {
      const res = await api.fetchSavedJobs();
      dispatch({ type: ACTIONS.fetch_start });
      dispatch({ type: ACTIONS.fetch_saved_jobs, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const handleSaveClick = async (id, action) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    try {
      setIsLoading(true);
      if (action === "save") {
        // Save the job
        await axios.patch(`/jobs/save/${id}`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res.data?.message);
        });
      }
      if (action === "unsave") {
        // Remove the saved job
        await axios.delete(`/jobs/${id}/unsave`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res?.data?.message);
        });
      }
      await fetchSavedJobs();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <Navbar3 />
      <div className="pge">
        {error && <ToastError message={errorMessage} />}
        {success && <ToastSuccess message={successMessage} />}
        <ToastContainer />
        {isLoading && <OverlayLoader />}
        <div className="searching_companies">
          <a className="link" href="/job-recruiting-websites">
            Search Unlimited Jobs from numerous companies{" "}
          </a>
          {user?.role === "jobseeker" && (
            <div className="btns">
              {!user?.cv && (
                <>
                  <button onClick={handleButtonClick}>
                    <TbArrowBigUpFilled />
                    Upload Resume or CV now to apply for jobs
                  </button>
                  <input
                    type="file"
                    ref={file}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileInputChange(e.target.files[0])}
                  />
                </>
              )}
              <button onClick={editProfile}>Enhance Profile</button>
            </div>
          )}
        </div>
        <ReactSearchBox />
        <div className="profile">
          <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
          {user?.role === "jobseeker" && (
            <div className="box">
              {user?.cv && (
                <div className="txt_1">
                  <h3>Your Uploaded Current Resume or CV</h3>
                  <div>
                    <button className="btn btn-primary" onClick={downloadCv}>
                      Download Resume or CV
                    </button>
                    <button className="btn btn-primary" onClick={handleButtonClick}>
                      Upload Resume or CV
                    </button>
                  </div>
                  <input
                    type="file"
                    ref={file}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileInputChange(e.target.files[0])}
                  />
                </div>
              )}
              <div className="txt_1">
                <h3>Resume or CV Designed from Alphy Johnson's Employment Center</h3>
                <p>Create a professional Resume or CV, and be headhunted by top employers!</p>

                {cv ? (
                  <div className="btn__2">
                    <button className="btn btn-primary" onClick={viewCv}>
                      View
                    </button>
                    <button className="btn btn-primary" onClick={updateCv}>
                      Update
                    </button>
                    <button className="btn btn-primary" onClick={deleteCv}>
                      Delete
                    </button>
                  </div>
                ) : (
                  <button className="btn btn-primary btn__2" onClick={buildcv}>
                    Design your Resume or CV
                  </button>
                )}
              </div>
            </div>
          )}

          <div className="main_box">
            <ProfileLeft />
            <div className="recommend" id="c2">
              {user?.role === "jobseeker" && (
                <>
                  <h3 className="details_heading">Recommended Jobs For You</h3>
                  {isFetching ? (
                    <div className="d-flex justify-content-center align-items-center section-padding">
                      <Loader />
                    </div>
                  ) : (
                    <div className="saved_jobs_main_div mt-4">
                      {recommendedJobs?.length > 0 ? (
                        recommendedJobs?.slice(0, 4).map((job) => (
                          <div className="b1" key={job?._id}>
                            <div className="b2">
                              <img className="pic1" src={logo} alt="Logo" loading="lazy" />
                            </div>
                            <div className="job_content_div">
                              <a href={`/jobs/${job?._id}`} className="links">
                                {job?.jobTitle}
                              </a>
                              <p className="d-flex align-items-center gap-1">
                                <AiFillEuroCircle />
                                {job?.salary}
                              </p>
                              <p className="d-flex align-items-center gap-1">
                                <ImLocation />
                                {job?.location}
                              </p>

                              <div className="btn_combo">
                                <button onClick={() => handleApplyNow(job?._id)}>Apply Now</button>{" "}
                                <button onClick={() => handleViewJob(job?._id)}>View</button>
                                {state.savedJobs.find((obj) => obj?.job?._id === job?._id) ? (
                                  <button onClick={() => handleSaveClick(job?._id, "unsave")}>
                                    Unsave
                                  </button>
                                ) : (
                                  <button onClick={() => handleSaveClick(job?._id, "save")}>
                                    Save
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center mb-2">No recommended jobs yet!!</div>
                      )}
                    </div>
                  )}
                </>
              )}
              <ProfileInfo />
            </div>
          </div>

          {user?.role === "jobseeker" && user?.status !== "featured" && (
            <div className="BOX_1">
              <div className="ads_box">
                <div className="ads">
                  <div className="d-flex flex-column align-items-center">
                    <span className="strong">BOOST YOUR PROFILE</span>
                    <p className="job_alert_tips" style={{ cursor: "pointer" }}>
                      <u>Only ${upgradePlan?.price}</u>
                    </p>
                  </div>
                  <div className="highlight">
                    <p>Upgrade to a Premium Profile and get up to 5x more CV views</p>
                  </div>
                  <div className="up-btn">
                    <button onClick={upgradeProfile} className="upgrade-btn">
                      Upgrade now
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="slider_box">
                <div className="slider">
                  <h3>Currently recruiting with AlphyJohnson</h3>
                  <Carousel />
                </div>
              </div> */}
            </div>
          )}
          {user?.role === "company" && (
            <div className="BOX_1">
              <div className="ads_box">
                <div className="ads">
                  <div className="d-flex flex-column align-items-center">
                    <span className="strong">HIRE RECRUITERS</span>
                    <p className="job_alert_tips" style={{ cursor: "pointer" }}>
                      <u>Only ${upgradePlan?.price}</u>
                    </p>
                  </div>

                  <div className="highlight">
                    <p>Checkout to Hire Recruiters and Boost your productivity</p>
                  </div>
                  <div className="up-btn">
                    <button onClick={upgradeProfile} className="upgrade-btn">
                      Upgrade now
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="slider_box">
                <div className="slider">
                  <h3>Currently recruiting with Alphy Johnson's Employment Center</h3>
                  <Carousel />
                </div>
              </div> */}
            </div>
          )}

          <Logout />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
