import React, { useEffect, useState } from "react";
import "./register.css";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";

const UserRegistration = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleRegister = async () => {
    if (user && user?.role === "user") {
      setTimeout(() => {
        window.location.href = "/dashboard/myprofile";
      }, 3000);
    }

    try {
      setIsLoading(true);
      const response = await axios.post("/users/register", {
        firstName,
        lastName,
        username,
        email,
        password,
        role: "user",
      });
      const courseUser = response.data.user;
      const { token } = response.data;
      if (token) localStorage.setItem("token", token);
      if (courseUser) localStorage.setItem("user", JSON.stringify(courseUser));
      setSuccess(true);
      setSuccessMessage("Registered Successfully");
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/dashboard/myprofile";
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const viewPolicy = () => {
    window.location.href = "/privacy-policy";
  };

  const viewTerms = () => {
    window.location.href = "/terms-and-conditions";
  };

  return (
    <div className="">
      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="register_maindiv section-padding">
        <h1>User Registration for Courses</h1>
        <h2>Create an Alphy Johnson's Employment Center courses account</h2>
        <p>Be a member of library of Unlimited courses</p>

        <div className="register_form">
          <h3 className="register_form_h3_start">Your Details</h3>
          <hr />

          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label htmlFor="firstName">First Name</label>
                <br />
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label htmlFor="lastName">Last Name</label>
                <br />
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label htmlFor="username">Username</label>
                <br />
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label htmlFor="password">Password</label>
                <br />
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </form>

          <div className="register_form_div">
            <input type="checkbox" required />
            <p className="register_terms_conditions">
              By registering with Alphy Johnson's Employment Center you agree to
              our
              <span onClick={viewPolicy}> privacy policy </span> and
              <span onClick={viewTerms}> Term & Conditions</span>{" "}
            </p>
          </div>
        </div>

        <div className="button_maindiv">
          <button onClick={handleRegister}>Register</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserRegistration;
