import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./savejob.css";
import { ImLocation } from "react-icons/im";
import { AiFillEuroCircle } from "react-icons/ai";
import logo from "../../images/logo.png";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "../profile/ProfileLeft";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Logout from "../profile/Logout";
import { useProfileContext } from "../../context/ProfileContext";
import { ACTIONS } from "../../context/ActionTypes";
import * as api from "../../ApiCalls";

function Savejob() {
  const { state, dispatch } = useProfileContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setPagePosts(state?.savedJobs?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.savedJobs?.length / postsPerPage));
  }, [state?.savedJobs, postsPerPage]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchSavedJobs = async () => {
    try {
      dispatch({ type: ACTIONS.fetch_start });
      const res = await api.fetchSavedJobs();
      setPagePosts(res?.slice(0, postsPerPage));
      setTotalPages(Math.ceil(res?.length / postsPerPage));

      dispatch({ type: ACTIONS.fetch_saved_jobs, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const handleSaveClick = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/jobs/${id}/unsave`).then((res) => {
        setSuccess(true);
        setSuccessMessage(res?.data?.message);
      });

      await fetchSavedJobs();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  // const handleViewJob = (id) => {
  //   window.location.href = `/jobs/${id}`;
  // };

  const handleApplyJob = (id) => {
    window.location.href = `/applynow/${id}`;
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state.savedJobs?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <>
      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          {state.isFetching ? (
            <div className="recommend " id="c2">
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="recommend " id="c2">
              <h3 className="details_heading">Saved Jobs</h3>
              {state.savedJobs?.length === 0 ? (
                <>
                  <h2>You have no saved jobs!</h2>
                  <p>Search Unlimited jobs from Various companies.</p>
                </>
              ) : (
                <>
                  <div className="saved_jobs_main_div mt-4">
                    {pagePosts?.map((saved) => (
                      <div className="b1" key={saved._id}>
                        <div className="b2">
                          <img className="pic1" src={logo} alt="Logo" loading="lazy" />
                        </div>
                        <div className="job_content_div">
                          <a href={`/jobs/${saved?.job?._id}`} className="links">
                            {saved?.job?.jobTitle}
                          </a>
                          <p className="d-flex align-items-center gap-1">
                            <AiFillEuroCircle />
                            {saved?.job?.salary}
                          </p>
                          <p className="d-flex align-items-center gap-1">
                            <ImLocation />
                            {saved?.job?.location}
                          </p>
                          <div className="btn_combo">
                            <button onClick={() => handleApplyJob(saved?.job?._id)}>
                              Apply Now
                            </button>
                            <button onClick={() => handleSaveClick(saved?.job?._id)}>Unsave</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {pagePosts?.length >= 1 && (
                    <div>
                      <div className="pagination">
                        <div className="btn btn-primary" onClick={() => paginate("prev")}>
                          <FaLessThan />
                          <FaLessThan />
                        </div>
                        <div
                          className={
                            currentPage === 1
                              ? "btn btn-primary active_page_btn"
                              : "btn btn-primary"
                          }
                          onClick={() => paginate(1)}
                        >
                          1
                        </div>
                        {currentPage !== 1 && (
                          <div
                            className="btn btn-primary active_page_btn"
                            onClick={() => paginate(currentPage)}
                          >
                            {currentPage}
                          </div>
                        )}
                        {totalPages >= 2 && (
                          <>
                            <div className="btn btn-primary">...</div>
                            <div className="btn btn-primary" onClick={() => paginate(totalPages)}>
                              {totalPages}
                            </div>
                          </>
                        )}
                        <div className="btn btn-primary" onClick={() => paginate("next")}>
                          <FaGreaterThan />
                          <FaGreaterThan />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
}

export default Savejob;
