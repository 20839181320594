import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./posts.css";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "./ProfileLeft";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";
import Logout from "./Logout";
import { useProfileContext } from "../../context/ProfileContext";
import { ACTIONS } from "../../context/ActionTypes";
import * as api from "../../ApiCalls";

const PostedJobs = () => {
  const { state, dispatch } = useProfileContext();
  const { user } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPagePosts(state?.jobs?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state?.jobs?.length / postsPerPage));
  }, [state?.jobs, postsPerPage]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchJobs = async () => {
    try {
      dispatch({ type: ACTIONS.fetch_start });
      const res = await api.fetchJobs(user?.role);

      setPagePosts(res?.slice(0, postsPerPage));
      setTotalPages(Math.ceil(res?.length / postsPerPage));

      dispatch({ type: ACTIONS.fetch_jobs, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.jobs?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  const handleEditJob = (id) => {
    window.location.href = `/editjob/${id}`;
  };

  const handleDeleteJob = async (id) => {
    try {
      setIsLoading(true);
      let route;
      switch (user?.role) {
        case "employer":
          route = `/employers/jobs/${id}`;
          break;
        case "company":
          route = `/companies/jobs/${id}`;
          break;
        default:
          return;
      }
      await axios.delete(route);
      setSuccess(true);
      setSuccessMessage("Job closed Successfully.");
      await fetchJobs();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const viewJob = (id) => {
    window.location.replace(`/jobs/${id}`);
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          <div className="recommend" id="c2">
            <h3 className="details_heading">Posted Jobs</h3>
            {state?.isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {pagePosts?.length < 1 ? (
                  <div className="container d-flex justify-content-center align-items-center section-padding">
                    You have not Posted any job yet!!
                  </div>
                ) : (
                  <div className="posted_jobs_div">
                    {pagePosts?.map((job) => (
                      <div key={job?._id} className="job-block">
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => viewJob(job?._id)}
                        >
                          {job?.jobTitle}
                        </h3>
                        <div className="job-details">
                          {/* {job?.creator?.role === "employer" && (
                    <p>Posted just now by {job?.creator?.employerId?.name}</p>
                  )}
                  {job?.creator?.role === "company" && (
                    <p>Posted just now by {job?.creator?.companyId?.name}</p>
                  )} */}
                          {/* <a href={`/jobs/${job?._id}`}>Quick apply</a> */}
                          <p>
                            <b>Posted At: </b>
                            {formatDate(job?.postedDate)}
                          </p>
                          <p>
                            <b>Salary: </b>
                            {job?.salary}
                          </p>
                          <p>
                            <b>Location: </b>
                            {job?.location}
                          </p>
                          <p>
                            <b>Job Type: </b>
                            {job?.jobType}
                          </p>
                          <p>{job?.description.substring(0, 100) + "..."}</p>
                        </div>
                        <button
                          className="cv_build_btn"
                          onClick={() => handleEditJob(job._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="cv_build_btn"
                          onClick={() => handleDeleteJob(job._id)}
                        >
                          Close
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {pagePosts?.length >= 1 && (
              <div>
                <div className="pagination">
                  <div
                    className="btn btn-primary"
                    onClick={() => paginate("prev")}
                  >
                    <FaLessThan />
                    <FaLessThan />
                  </div>
                  <div
                    className={
                      currentPage === 1
                        ? "btn btn-primary active_page_btn"
                        : "btn btn-primary"
                    }
                    onClick={() => paginate(1)}
                  >
                    1
                  </div>
                  {currentPage !== 1 && (
                    <div
                      className="btn btn-primary active_page_btn"
                      onClick={() => paginate(currentPage)}
                    >
                      {currentPage}
                    </div>
                  )}
                  {totalPages >= 2 && (
                    <>
                      <div className="btn btn-primary">...</div>
                      <div
                        className="btn btn-primary"
                        onClick={() => paginate(totalPages)}
                      >
                        {totalPages}
                      </div>
                    </>
                  )}
                  <div
                    className="btn btn-primary"
                    onClick={() => paginate("next")}
                  >
                    <FaGreaterThan />
                    <FaGreaterThan />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
};

export default PostedJobs;
