import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";

function Moreindustry() {
  const sectorsList = [
    "Administration",
    "Managerial",
    "Finance",
    "Banking",
    "Multilingual",
    "Public sector",
    "Seasonal",
    "Charity",
    "Ecommerce",
    "Legal",
    "Media",
    "Procurement",
    "Teaching",
    "Science",
    "Security and Emergency",
    "Hardware",
  ];

  sectorsList.sort();

  return (
    <>
      <section id="Trendingjob" className="moreindustry_div_left">
        <div className="container">
          <h2 className="trendingjob-head fs-4">ADDITIONAL SECTORS</h2>
          <div className="Trendingjob-row1">
            <div>
              <ul className="col-4-Trendingjoblinks">
                {sectorsList.slice(0, 4)?.map((obj, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`/job-recruiting-websites?keyword=${encodeURIComponent(
                          obj,
                        )}`}
                      >
                        {obj}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div>
              <ul className="col-4-Trendingjoblinks">
                {sectorsList.slice(4, 8)?.map((obj, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`/job-recruiting-websites?keyword=${encodeURIComponent(
                          obj,
                        )}`}
                      >
                        {obj}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div>
              <ul className="col-4-Trendingjoblinks">
                {sectorsList.slice(8, 12)?.map((obj, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`/job-recruiting-websites?keyword=${encodeURIComponent(
                          obj,
                        )}`}
                      >
                        {obj}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div>
              <ul className="col-4-Trendingjoblinks">
                {sectorsList.slice(12, 16)?.map((obj, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={`/job-recruiting-websites?keyword=${encodeURIComponent(
                          obj,
                        )}`}
                      >
                        {obj}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="browser-section">
        <div className="jobsearch_maindiv">
          <div className="Moreindustry-row">
            <div className="searchjobs_right_div">
              <h2 className="header-heading">
                Tips for navigating job listings
              </h2>

              <p>
                <FaChevronCircleRight color="var(--btn-color)" size={27} />
                &ensp; You can find a list of our main employment industries and
                sectors that we use to categorise each of the job vacancies
                listed on{" "}
                <a href="/job-boards-usa">https://www.thealphyjohnson.com</a>
              </p>
              <p>
                <FaChevronCircleRight color="var(--btn-color)" size={27} />
                &ensp; Using the industry title links above, you can go directly
                to the search results pages that will include all roles relevant
                to your chosen industry.
              </p>
              <p>
                <FaChevronCircleRight color="var(--btn-color)" size={27} />
                &ensp; You can also go to our browse jobs listing pages by
                clicking the view all job titles links. This will take you to a
                page that lists each of the main job titles that we have for
                each industry allowing you to further refine your search.
              </p>
              <p>
                <FaChevronCircleRight color="var(--btn-color)" size={27} />
                &ensp; You can also search in a chosen location via{" "}
                <a href="/job-recruiting-websites">this</a> link that will take
                you to a page listing your chosen industry by Global locations
                allowing you to refine your search.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Moreindustry;
