import React from "react";
import "./homepage.css";
import { FaHandshake } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../constants/GlobalConstants";
import LazyBackground from "../../components/common/LazyBackground";

const Services = ({ IsHome = true }) => {
  const navigate = useNavigate();

  return (
    <div className="services_main_div">
      <h2 className="services_heading">Our Services</h2>
      <p className="services_below_line mt-3">
        At Alphy Johnson, we provide a range of services designed to make your job search or hiring
        process smooth and efficient.
      </p>
      <div className="services_first_line">
        <LazyBackground src={IMAGES.screening} className="services_first_line_left">
          <h3 className="service_line_content heading-font">Resume Reviews</h3>

          <p>
            Want your resume to stand out? Let our experts help. We match candidates with relevant
            part-time and full-time jobs in USA and beyond.
          </p>
        </LazyBackground>

        <div className="line_connector_left color-blue"></div>

        <div className="services_first_line_center">
          <div className="first_line_inner_div">
            <FaHandshake className="services_line_icon" />
          </div>
        </div>

        <div className="line_connector_right color-blue"></div>

        <LazyBackground src={IMAGES.interviews} className="services_first_line_right">
          <h3 className="service_line_content heading-font">Interview Help</h3>

          <p>
            We offer support for employers to set up and conduct interviews, whether remote or
            in-person.
          </p>
        </LazyBackground>
      </div>

      <div className="services_second_line">
        <LazyBackground
          src={IMAGES.background_check}
          className="services_second_line_div background_check_div color-blue text-white"
        >
          <h3 className="service_line_content heading-font">Background Checks</h3>

          <p>
            For recruiters, we offer a full background check service, ensuring you find the best fit
            for your business.
          </p>
        </LazyBackground>

        <div className="line_connector_left color-blue"></div>
        <div className="line_connector_vertical color-blue"></div>
        <div className="line_connector_right color-blue"></div>

        <LazyBackground
          src={IMAGES.assistance}
          className="services_second_line_div assistance_div color-yellow text-white"
        >
          <h3 className="service_line_content heading-font"> Hiring Made Easy</h3>

          <p>
            Need to fill a local or remote role? Post your job opening with a detailed description,
            and we’ll connect you to the right candidates.
          </p>
        </LazyBackground>
      </div>

      <div className="services_third_line">
        <LazyBackground
          src={IMAGES.employment}
          className="services_second_line_div employment_div color-pink text-white"
        >
          <h3 className="service_line_content heading-font">Job Openings</h3>

          <p>
            From tech jobs to healthcare, we keep a wide range of current job listings across
            Florida and beyond.
          </p>
        </LazyBackground>
      </div>

      {IsHome ? (
        <button className="btn btn-primary mt-4" onClick={() => navigate("/our-services")}>
          Read More
        </button>
      ) : null}
    </div>
  );
};

export default Services;
