import React from "react";
// import Navbar3 from "../../components/navbar3/navbar3";
import AdminNavbar from "./adminNavbar";
// import Footer from "../../components/footer/footer";
import AdminTopBar from "./adminTopBar";
import AdminPanel from "./adminPanel";
// import { store } from "../../redux/store";

const adminPanel2 = () => {
  return (
    <div>
      <AdminNavbar />
      <div className="admin_navbar_div">
        <AdminTopBar />
        <AdminPanel />
      </div>
    </div>
  );
};

export default adminPanel2;
