import React, { useEffect, useRef, useState } from "react";
import "../form2/form2.css";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import Navbar3 from "../../components/navbar3/navbar3";
import { ToastSuccess, ToastError } from "../alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";

function AdminLogin() {
  const email = useRef();
  const password = useRef();
  const formRef = useRef(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post("/admin/login", {
        email: email.current.value,
        password: password.current.value,
      });
      const { user, token } = response?.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      setSuccess(true);
      setSuccessMessage(response.data?.message);
      formRef.current.reset();
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/adminboard";
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="rec_session text-center mt-4">
        <div className="rec-session-left">
          <h1>Admin login</h1>
          <p>Please enter your email address to get started.</p>
          <div className="rec-session-form">
            <form ref={formRef} onSubmit={handleLogin}>
              <div className="rec-email_input">
                <label className="lable-startsession">Email Address</label>
                <br />
                <input
                  type="email"
                  name="rec-email"
                  required="required"
                  ref={email}
                />
              </div>
              <div className="rec-email_input">
                <label className="lable-startsession">Password</label>
                <br />
                <input
                  type="password"
                  name="rec-password"
                  required="required"
                  ref={password}
                />
              </div>
              <div className="rec-login">
                <button className="btn-primary" type="submit">
                  submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AdminLogin;
