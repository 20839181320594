import { ADMIN_PANEL_ACTIONS } from "./ActionTypes";

const ProfileReducer = (state, action) => {
  switch (action.type) {
    case ADMIN_PANEL_ACTIONS.fetch_start:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case ADMIN_PANEL_ACTIONS.fetch_candidates:
      return {
        ...state,
        candidates: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_employers:
      return {
        ...state,
        employers: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_companies:
      return {
        ...state,
        companies: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_course_users:
      return {
        ...state,
        courseUsers: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_management_users:
      return {
        ...state,
        managementUsers: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_posts:
      return {
        ...state,
        posts: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_checkout_plans:
      return {
        ...state,
        checkoutPlans: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_premium_plans:
      return {
        ...state,
        premiumPlans: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_hiring_orders:
      return {
        ...state,
        hiringOrders: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_courses:
      return {
        ...state,
        courses: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_enquiries:
      return {
        ...state,
        enquiries: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_contact_us_queries:
      return {
        ...state,
        contactUsQueries: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_user_logs:
      return {
        ...state,
        userLogs: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_configurations:
      return {
        ...state,
        configurations: action.payload,
      };

    case ADMIN_PANEL_ACTIONS.fetch_success:
      return {
        ...state,
        isFetching: false,
        error: false,
      };

    case ADMIN_PANEL_ACTIONS.fetch_failure:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
