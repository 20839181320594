import React, { useEffect, useState } from "react";
import Blogposts from "../../components/blogPosts/blogPosts";
import "./posts.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "./ProfileLeft";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import Logout from "./Logout";
import { useProfileContext } from "../../context/ProfileContext";
import * as api from "../../ApiCalls";
import { useAuthContext } from "../../context/AuthContext";
import { ACTIONS } from "../../context/ActionTypes";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";

const Posts = () => {
  const { user } = useAuthContext();
  const { state, dispatch } = useProfileContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setPagePosts(state?.posts?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.posts?.length / postsPerPage));
  }, [state?.posts, postsPerPage]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchPosts = async () => {
    try {
      dispatch({ type: ACTIONS.fetch_start });
      const res = await api.fetchPosts(user?.role);
      setPagePosts(res?.slice(0, postsPerPage));
      setTotalPages(Math.ceil(res?.length / postsPerPage));
      dispatch({ type: ACTIONS.fetch_posts, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const handleDeletePost = async (id) => {
    try {
      setIsLoading(true);
      await api.deletePost(id, user?.role);
      setSuccess(true);
      setSuccessMessage("Post deleted Successfully.");
      await fetchPosts();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.posts?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  const visitPost = (id) => {
    window.location.replace(`/career-advice/${id}`);
  };

  return (
    <>
      <Navbar3 />
      <ReactSearchBox />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading ? <OverlayLoader /> : null}
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />

          <div className="recommend" id="c2">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <h3 className="details_heading" style={{ margin: "0" }}>
                Created Posts
              </h3>
              <a className="btn btn-primary" href="/addpost">
                Add Post
              </a>
            </div>
            {state?.isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <div className="container_posts">
                {pagePosts?.length === 0 && (
                  <div className="d-flex justify-content-center align-items-center section-padding text-center">
                    <h3>You have not created Any Post Yet!!</h3>
                  </div>
                )}
                <div>
                  <div className="careeradvice_separate_blogposts">
                    {pagePosts?.slice(0, 3).map((post) => {
                      return (
                        <Blogposts
                          key={post?._id}
                          onclick={() => visitPost(post._id)}
                          img={post?.thumbnail}
                          heading={
                            post?.headline?.length > 28
                              ? `${post?.headline.slice(0, 25)}...`
                              : post?.headline
                          }
                          author={post?.author}
                          date={formatDate(post?.date)}
                          isEdit={true}
                          isDel={true}
                          onDelClick={() => handleDeletePost(post?._id)}
                          id={post._id}
                        />
                      );
                    })}
                  </div>
                </div>

                <div>
                  <div className="careeradvice_separate_blogposts">
                    {pagePosts?.slice(3, 6).map((post) => {
                      return (
                        <Blogposts
                          key={post?._id}
                          onclick={() => visitPost(post._id)}
                          img={post?.thumbnail}
                          heading={
                            post?.headline?.length > 28
                              ? `${post?.headline.slice(0, 25)}...`
                              : post?.headline
                          }
                          author={post?.author}
                          date={formatDate(post?.date)}
                          isEdit={true}
                          isDel={true}
                          onDelClick={() => handleDeletePost(post?._id)}
                          id={post._id}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {pagePosts?.length > 0 && (
              <div className="pagination">
                <div
                  className="btn btn-primary"
                  onClick={() => paginate("prev")}
                >
                  <FaLessThan />
                  <FaLessThan />
                </div>
                <div
                  className={
                    currentPage === 1
                      ? "btn btn-primary active_page_btn"
                      : "btn btn-primary"
                  }
                  onClick={() => paginate(1)}
                >
                  1
                </div>
                {currentPage !== 1 && (
                  <div
                    className="btn btn-primary active_page_btn"
                    onClick={() => paginate(currentPage)}
                  >
                    {currentPage}
                  </div>
                )}
                {totalPages >= 2 && (
                  <>
                    <div className="btn btn-primary">...</div>
                    <div
                      className="btn btn-primary"
                      onClick={() => paginate(totalPages)}
                    >
                      {totalPages}
                    </div>
                  </>
                )}
                <div
                  className="btn btn-primary"
                  onClick={() => paginate("next")}
                >
                  <FaGreaterThan />
                  <FaGreaterThan />
                </div>
              </div>
            )}
          </div>
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
};

export default Posts;
