import React from "react";

function Header_cvdatabase() {
  return (
    <div
      className="container-fluid d-flex justify-content-between align-items-center pt-2 pb-2 "
      style={{}}
    >
      <div className="ms-4 header_cv_left">
        <p>
          {" "}
          <span className="fs-6 fw-bold" style={{ fontFamily: "sans-serif" }}>
            Excellent
          </span>
          <span>
            <i
              className="bx bxs-star-half ms-1 text-white "
              style={{ width: "19px", background: "#00b67a" }}
            ></i>
            <i
              className="bx bxs-star-half ms-1 text-white "
              style={{ width: "19px", background: "#00b67a" }}
            ></i>
            <i
              className="bx bxs-star-half ms-1 text-white "
              style={{ width: "19px", background: "#00b67a" }}
            ></i>
            <i
              className="bx bxs-star-half ms-1 text-white "
              style={{ width: "19px", background: "#00b67a" }}
            ></i>
            <i
              className="bx bxs-star-half ms-1 text-white "
              style={{ width: "19px", background: "#00b67a" }}
            ></i>
          </span>{" "}
          <span className="ps-2">
            <i className="bx bxs-star" style={{ color: "#00b67a" }}></i>{" "}
            <span style={{ fontSize: "12px", fontFamily: "serif" }}>
              Employment Center
            </span>
          </span>{" "}
        </p>
      </div>
      <div className="right header_cv_right header_cv_database">
        <p style={{ fontWeight: "bold" }}>+1 (855) 580-0685</p>
        <a
          className="btn btn-primary"
          aria-current="page"
          href="/enquirenow"
          style={{
            padding: "7px 20px",
            fontSize: "14px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          Enquire
        </a>
      </div>
    </div>
  );
}

export default Header_cvdatabase;
