import React, { useEffect, useState } from "react";
import Bulidmycv from "./Bulidmycv";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";

function Blog() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllPosts();
  }, []);

  const fetchAllPosts = async () => {
    try {
      await axios.get("/career-advices").then((res) => {
        const post1 = filterPosts(res?.data?.docs, "cvs");
        post1?.reverse();
        console.log(post1);
        setPosts(post1);
      });
    } catch (error) {
      console.log(error?.response);
    }
  };

  const filterPosts = (postsArray, category) => {
    const filtered = postsArray.filter((post) => {
      return post?.category?.includes(category);
    });
    return filtered;
  };

  return (
    <div>
      <div
        className="container-fluid mt-4 main-blog"
        style={{ background: "var(--blue)", color: "#fff" }}
      >
        <h3 className="text-center pt-5">Need Help with Job Applications? We've Got You Covered</h3>
        <p className="text-center blog_para">
          Navigating the job market can be tough, but we’re here to help. With so many professionals
          looking for{" "}
          <a
            href="https://www.cnbc.com/2023/01/13/96percent-of-workers-are-looking-for-a-new-job-in-2023.html"
            target="_blank"
            rel="noreferrer"
          >
            new roles in 2023
          </a>
          , it’s the perfect time to update your resume. Our resume builder makes it simple, and
          with our tips and resume assistance for free, you'll be ready to apply with confidence.
          Whether you're applying for{" "}
          <a href={`/job-recruiting-websites?location=USA`} target="_blank" rel="noreferrer">
            jobs in the USA
          </a>{" "}
          or globally, our tools will give your resume the professional edge it needs.
        </p>
        <div className="container mt-5 flex-wrap">
          <div className="blog cv_builder_blog_center">
            {posts[0] && (
              <div
                className="cv_builder_blog_div m-0 p-0 "
                onClick={() => navigate(`/career-advice/${posts[0]?._id}`)}
              >
                <img
                  src={posts[0]?.thumbnail}
                  className="cv-designer-blog-pic mb-3 "
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  crossOrigin="anonymous"
                  alt={posts[0]?.headline}
                  loading="lazy"
                />
                <a href={`/career-advice/${posts[0]?._id}`} className="nav-link p-0 m-0">
                  {posts[0]?.headline?.length > 48
                    ? `${posts[0]?.headline?.slice(0, 44)}...`
                    : `${posts[0]?.headline}`}
                </a>
              </div>
            )}
            {posts[1] && (
              <div
                className="cv_builder_blog_div"
                onClick={() => navigate(`/career-advice/${posts[1]?._id}`)}
              >
                <img
                  src={posts[1]?.thumbnail}
                  className="cv-designer-blog-pic mb-3"
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  alt={posts[1]?.headline}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
                <a href={`/career-advice/${posts[1]?._id}`} className="nav-link p-0 m-0">
                  {posts[1]?.headline?.length > 48
                    ? `${posts[1]?.headline?.slice(0, 44)}...`
                    : `${posts[1]?.headline}`}
                </a>
              </div>
            )}
            {posts[2] && (
              <div
                className="cv_builder_blog_div"
                onClick={() => navigate(`/career-advice/${posts[2]?._id}`)}
              >
                <img
                  src={posts[2]?.thumbnail}
                  className="cv-designer-blog-pic mb-3 "
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  alt={posts[2]?.headline}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
                <a href={`/career-advice/${posts[2]?._id}`} className="nav-link p-0 m-0">
                  {posts[2]?.headline?.length > 48
                    ? `${posts[2]?.headline?.slice(0, 44)}...`
                    : `${posts[2]?.headline}`}
                </a>
              </div>
            )}
          </div>
          <div className="blog cv_builder_blog_center mt-5">
            {posts[3] && (
              <div
                className="cv_builder_blog_div m-0 p-0"
                onClick={() => navigate(`/career-advice/${posts[3]?._id}`)}
              >
                <img
                  src={posts[3]?.thumbnail}
                  className="cv-designer-blog-pic mb-3 "
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  alt={posts[3]?.headline}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
                <a href={`/career-advice/${posts[3]?._id}`} className="nav-link p-0 m-0 ">
                  {posts[3]?.headline?.length > 48
                    ? `${posts[3]?.headline?.slice(0, 44)}...`
                    : `${posts[3]?.headline}`}
                </a>
              </div>
            )}
            {posts[4] && (
              <div
                className="cv_builder_blog_div"
                onClick={() => navigate(`/career-advice/${posts[4]?._id}`)}
              >
                <img
                  src={posts[4]?.thumbnail}
                  className="cv-designer-blog-pic mb-3 "
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  alt={posts[4]?.headline}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
                <a href={`/career-advice/${posts[4]?._id}`} className="nav-link p-0 m-0">
                  {posts[4]?.headline?.length > 48
                    ? `${posts[4]?.headline?.slice(0, 44)}...`
                    : `${posts[4]?.headline}`}
                </a>
              </div>
            )}
            {posts[5] && (
              <div
                className="cv_builder_blog_div"
                onClick={() => navigate(`/career-advice/${posts[5]?._id}`)}
              >
                <img
                  src={posts[5]?.thumbnail}
                  className="cv-designer-blog-pic mb-3 "
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "7px",
                  }}
                  alt={posts[5]?.headline}
                  loading="lazy"
                  crossOrigin="anonymous"
                />
                <a href={`/career-advice/${posts[5]?._id}`} className="nav-link p-0 m-0">
                  {posts[5]?.headline?.length > 48
                    ? `${posts[5]?.headline?.slice(0, 44)}...`
                    : `${posts[5]?.headline}`}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <Bulidmycv />
    </div>
  );
}

export default Blog;
