import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./cvbuilder.css";
import { Pagination } from "swiper";
import { useAuthContext } from "../../context/AuthContext";
import { ToastError } from "../../components/alert/toastAlert";

function Cv_create() {
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setError(false);
  }, [error]);

  const hanldeJobseekerClicks = (action) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      if (action === "build") window.location.href = "/buildcv";
      if (action === "assist") window.location.href = "/assistcv";
    }
  };

  return (
    <div className="container-fluid position-static">
      {error && <ToastError message={errorMessage} />}
      <h1 className="text-center fs-3 pt-4">Frequently Asked Questions about CV Designer [FAQs]</h1>
      <div className="cv-designer-btns-div">
        <button className=" fw-bold cv-designer-btn" onClick={() => hanldeJobseekerClicks("build")}>
          Design my Resume/CV
        </button>
        <button
          className=" fw-bold cv-designer-btn"
          onClick={() => hanldeJobseekerClicks("assist")}
        >
          DESIGN FOR ME
        </button>
      </div>
      <div className="container-fluid mt-4">
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper cv-designer-slider"
        >
          <SwiperSlide className=" cv-designer-slider-items">
            {" "}
            <div className="container-fluid  text-start faqs-slider">
              <p className="fw-bold p-0 m-0">Is the Resume or CV Designer Free to Use?</p>
              <p>
                Yes, our resume builder is entirely free to use. Whether you're updating your
                current resume or creating a new one, you can do so without any cost. Simply follow
                the steps to get started.
              </p>

              <p className="fw-bold p-0 m-0">Can I Create My Resume or CV on a Smartphone?</p>
              <p className="p-0 m-0">
                Absolutely! Our CV builder works across multiple devices, including phones, tablets,
                laptops, and desktops. You can create or update your resume using your smartphone,
                making the process super convenient wherever you are.
              </p>

              <p className="fw-bold">What is the Delivery Method for My Resume or CV?</p>
              <p className="">
                Once you’ve completed your resume build, we’ll ask for your email, and your
                finalized document will be sent to you as a PDF file within minutes. Easy and
                efficient!
              </p>

              <p className="fw-bold p-0 ">In What Format Will I Receive My Resume?</p>
              <p>
                Your completed resume or CV will be emailed to you in a professional PDF format,
                ready for you to submit to potential employers or upload to job sites.
              </p>

              <p className="fw-bold">How Can I Make Changes to My Resume or CV?</p>
              <p className="p-0 m-0">
                After your resume build is complete, you’ll receive login credentials for your CV
                global hub account, where you can access and modify your resume anytime. Simply log
                in and make updates whenever needed!
              </p>

              <small>
                <b>NOTE:</b> If you already have an existing Resume or CVGlobalHub account, your
                newly created Resume or CV will be added to it, allowing you to edit and make
                changes to it whenever necessary.
              </small>
            </div>
          </SwiperSlide>
          <SwiperSlide className="cv-designer-slider-items">
            {" "}
            <div className="container-fluid  text-start faqs-slider">
              <p className="fw-bold p-0 m-0">Is It Possible to Delete My Resume or CV?</p>
              <p>
                Yes, if you no longer need your resume or want to remove it for any reason, you can
                easily log in to your{" "}
                <a href="https://cvglobalhub.thealphyjohnson.com" target="_blank" rel="noreferrer">
                  CV global hub
                </a>{" "}
                account and delete it.
              </p>

              <p className="fw-bold p-0 m-0">How Long Should My Personal Summary Be?</p>
              <p>
                Keep your personal summary concise, usually around four to five lines or 180-200
                words. Focus on clarity and brevity to make the best first impression.
              </p>

              <p className="fw-bold p-0 m-0">
                Do I Need a Title for My Personal Profile on My Resume?
              </p>
              <p className="p-0 m-0">
                You don’t need to label your personal profile explicitly. Simply place your
                statement under your name and contact details to keep things clean and professional.
              </p>

              <p className="fw-bold">How Should I Write a Personal Profile for My Resume?</p>
              <p className="">
                Start with a clear, strong statement that highlights your skills, experience, and
                what you’re looking to achieve. For example: "A results-driven marketing
                professional with 5 years of experience, passionate about creating impactful
                campaigns."
              </p>

              <p className="fw-bold p-0 ">What is a Personal Profile?</p>
              <p>
                A personal profile (or summary) is a short section at the top of your resume that
                introduces who you are and what you offer. It’s your chance to showcase your
                strengths and grab the reader’s attention early on.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="mt-4 text-center">
          <small>
            For more comprehensive guidance, Please Visit Our{" "}
            <a href="/career-advice/category/cvs" target="_blank" rel="noreferrer">
              Career Guidance Center
            </a>
          </small>
        </div>
      </div>
    </div>
  );
}

export default Cv_create;
