import React from "react";
import "./mission.css";
function Mission() {
  return (
    <div className="mission-container">
      <div className="container-bg">
        <div className="mission-container-content">
          <h1 className="header-heading job_alert_tips text-center">
            Mission Statement
          </h1>
          <div className="mission-container-content-left job_board2">
            <h2>Our Mission</h2>
            <p>
              Our mission at <a href="/">Alphy Johnson's Employment Center</a>{" "}
              is to facilitate speedy and efficient connections between
              companies and the most suitable candidates. As a{" "}
              <span className="mission_bold_text">
                Global Employment Center
              </span>
              , we have established ourselves as industry experts and offer a
              range of acclaimed recruitment products.
            </p>
            <p>
              We strive at <a href="/">Alphy Johnson's Employment Center</a> to
              simplify the task of connecting companies with the most suitable
              candidates. As the{" "}
              <span className="mission_bold_text">global job board</span>, we
              are recognized for our industry knowledge and offer a
              comprehensive selection of{" "}
              <a href="/online-recruitment">recruitment products</a> that have garnered
              accolades.
            </p>
          </div>
          <div className="mission-container-content-right">
            <h2>Employers & recruiters</h2>
            <p>
              Our Employment Resource Center offers employers and recruitment
              agencies a{" "}
              <span className="mission_bold_text">user-friendly platform</span>{" "}
              to search for <b>permanent</b>, <b>graduate</b>, <b>contract</b>,
              and <b>freelance candidates</b>.
              <a href="/online-recruitment">Click Here!</a>
            </p>
            <h2>Job seekers</h2>
            <p>
              At our site, candidates can register their CV or RESUMES to be
              scouted by top employers throughout the globe, while also gaining
              access to a broad range of{" "}
              <span className="mission_bold_text">186,348</span> job openings in
              various industries.<a href="/job-recruiting-websites">Click Here!</a>
            </p>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>

        <div className="mission-container-content mt-4">
          <h1 className="header-heading job_alert_tips text-center">
            Our Values
          </h1>
          <div className="mission-container-content-left job_board2">
            <h2>INTEGRITY</h2>
            <p>
              We adhere unwaveringly to the most elevated principles of ethical
              behavior. We communicate with unwavering <b>honesty</b> and{" "}
              <b>courage</b>, leaving no room for deception. Our actions align
              precisely with our words, demonstrating{" "}
              <b>unwavering consistency</b>.
            </p>
            <h2>RESPECT</h2>
            <p>
              We uphold a commitment to treating others with <b>fairness</b> and{" "}
              <b>courtesy</b>. We deeply cherish diverse ideas, <b>opinions</b>,
              and experiences, recognizing their immense value. Through
              compassion and humility, we seek to understand and empathize with
              the challenges faced by those we serve.
            </p>
            <h2>RESPONSIBILITY</h2>
            <p>
              We exhibit an unwavering positive <b>spirit</b> and{" "}
              <b>determination</b>
              to conquer challenges. We prioritize creating a safe and secure
              workplace while upholding the highest standards of quality in
              everything we undertake. As responsible global citizens, we
              actively care for our community and the environment, embracing our
              role in making a positive impact on the world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
