import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { Colors } from "../../constants/GlobalConstants";
import axios from "../../axios";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { ToastError, ToastSuccess } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.blue,
    },
  },
});

const SignIn = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setIsLoading(true);
      const res = await axios.post(
        "/additionalUsers/login",
        {
          email: data.get("email"),
          password: data.get("password"),
        },
        { withCredentials: true }
      );
      setSuccess(true);
      setSuccessMessage(res.data?.message);
      window.location.href = `${res.data?.url}`;
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <Navbar3 />
      <div>
        {isLoading ? <OverlayLoader /> : null}
        {error && <ToastError message={errorMessage} />}
        {success && <ToastSuccess message={successMessage} />}
        <ToastContainer />
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "var(--blue)" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h4" fontWeight={"bold"}>
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  color="primary"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={isVisible ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  color="primary"
                />
                <div className="text-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => setIsVisible(!isVisible)}
                        sx={{
                          color: "var(--blue)",
                          "&.Mui-checked": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    }
                  />
                </div>
                <Button
                  className="btn-primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontSize: 18, fontWeight: "bold" }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs className="text-center">
                    <Link href="#" variant="body2" className="link">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;
