import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Navbar3 from "../navbar3/navbar3";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";

const CourseSignin = () => {
  const { user } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (user && user?.role === "user") {
      setError(true);
      setErrorMessage("You are already logged in");
      setTimeout(() => {
        window.location.href = "/dashboard/myprofile";
      }, 3000);
    }
  }, [user]);

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/users/login",
        {
          email,
          password,
        },
        { withCredentials: true }
      );

      const { user, token } = response.data;
      localStorage.setItem("token", token);
      if (user) localStorage.setItem("user", JSON.stringify(user));
      setSuccess(true);
      setSuccessMessage("Logged in Successfully");
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/dashboard";
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>
        <div className="register_form">
          <h3 className="register_form_h3_start">Courses Sign In</h3>
          <hr />

          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Email</label>
                <br />
                <input
                  id="keyword"
                  className="text"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className="register_form_div">
              <div className="register_options">
                <label>Password</label>
                <br />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className="register_form_div">
              <div className="register_options">
                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={handleTogglePasswordVisibility}
                />
              </div>
            </div>
          </form>
        </div>
        {/* <span onClick={() => setShowPopup(true)}>Courses Sign In</span> */}

        {/* {showPopup && ( */}
        <div className="button_maindiv">
          <button onClick={handleSignIn}>Sign In</button>
        </div>

        <div>
          <a href="/reset-password" style={{ cursor: "pointer" }}>
            Forgot Password?
          </a>
        </div>

        <div>
          <span>Don't have an account? - </span>
          <a href="/courses/register">
            <b>Create Account</b>
          </a>
        </div>

        {/* <button
            onClick={handleGoogleLogin}
            style={{
              backgroundColor: "#4285F4",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            Google Redirect Login
          </button>
          <button
            onClick={handleFacebookLogin}
            style={{
              backgroundColor: "#3B5998",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Facebook Redirect Login
          </button> */}
      </div>
      {/* )} */}
      <Footer />
    </>
  );
};

export default CourseSignin;
