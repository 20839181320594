import React, { useContext, useEffect, useRef, useState } from "react";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { BsLayoutTextSidebar } from "react-icons/bs";
import { GoDeviceDesktop } from "react-icons/go";
import { FaDropbox } from "react-icons/fa";
import { IoIosCreate, IoLogoXbox } from "react-icons/io";
import { BsPencilFill } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { FcMultipleDevices } from "react-icons/fc";
import { MdEditNotifications, MdOutlineSaveAs, MdPostAdd, MdRequestQuote } from "react-icons/md";
import { useAuthContext } from "../../context/AuthContext";
import { Link, Outlet } from "react-router-dom";
import axios from "../../axios";
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { AiFillCaretDown } from "react-icons/ai";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { ProfileContext } from "../../context/ProfileContext";

const ProfileLeft = () => {
  const { user } = useAuthContext();
  const { state } = useContext(ProfileContext);
  const profilePhoto = useRef();
  const [skills, setSkills] = useState(user?.skills);
  const [jobTitle, setJobTitle] = useState(user?.jobTitle);
  const file = useRef();
  const [openPicker] = useDrivePicker();
  const APP_KEY = "8s9ofo9kobnk6gd";
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [coverLetter, setCoverLetter] = useState({
    title: "",
    content: "",
  });
  const [isOpen, setIsOpen] = useState({
    upload_cv: false,
    edit_skills: false,
    edit_job_title: false,
    add_cover_letter: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (isOpen.upload_cv) {
      setIsOpen((prev) => ({
        ...prev,
        edit_skills: false,
        edit_job_title: false,
        add_cover_letter: false,
      }));
    }
  }, [isOpen.upload_cv]);

  useEffect(() => {
    if (isOpen.edit_skills) {
      setIsOpen((prev) => ({
        ...prev,
        upload_cv: false,
        edit_job_title: false,
        add_cover_letter: false,
      }));
    }
  }, [isOpen.edit_skills]);

  useEffect(() => {
    if (isOpen.edit_job_title) {
      setIsOpen((prev) => ({
        ...prev,
        edit_skills: false,
        upload_cv: false,
        add_cover_letter: false,
      }));
    }
  }, [isOpen.edit_job_title]);

  useEffect(() => {
    if (isOpen.add_cover_letter) {
      setIsOpen((prev) => ({
        ...prev,
        edit_skills: false,
        edit_job_title: false,
        upload_cv: false,
      }));
    }
  }, [isOpen.add_cover_letter]);

  // const fetchJobAlerts = async () => {
  //   const response = await axios.get("/jobs/job-alerts");
  //   setJobAlerts(response.data);
  //   // console.log(response.data);
  // };

  function handleSuccess(files) {
    uploadFile(files[0].thumbnailLink);
  }

  const handleAddInfo = async (name) => {
    let fieldName, value;
    switch (name) {
      case "skills":
        fieldName = "skills";
        const array = skills?.split(",");
        value = array;
        break;
      case "jobTitle":
        fieldName = "jobTitle";
        value = jobTitle;
        break;
      default:
        setError(true);
        setErrorMessage("Cannot modify this field!!");
        break;
    }
    try {
      setIsLoading(true);
      await axios.patch("/candidates/me", { [fieldName]: value }).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage(fieldName + " updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const editProfilePhoto = async (image) => {
    let route;
    switch (user?.role) {
      case "jobseeker":
        route = "/candidates/me";
        break;
      case "employer":
        route = "/employers/me";
        break;
      case "company":
        route = "/companies/me";
        break;
      default:
        break;
    }
    const formData = new FormData();
    formData.append("profilePhoto", image);
    try {
      setIsLoading(true);
      await axios.patch(route, formData).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Profile photo updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const addCoverLetter = async () => {
    try {
      setIsLoading(true);
      await axios
        .post("/coverLetters", {
          coverLetterName: coverLetter.title,
          coverLetterContent: coverLetter.content,
        })
        .then((res) => {
          // console.log(res.data);
          setSuccess(true);
          setSuccessMessage("Cover Letter added..");
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
          }, 1500);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId: "358593215396-35kdfg28jc3i2ejd530b8jlvj64g096o.apps.googleusercontent.com",
      developerKey: "AIzaSyBjuDmlkZl_hqq1ijkbmww3bs5VJ2D582c",
      viewId: "DOCUMENTS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        console.log(data?.docs);
        if (data?.docs?.length > 0) {
          const fileUrl = data?.docs[0].embedUrl;
          uploadFile(fileUrl);
        }
      },
    });
  };

  const uploadFile = async (fileUrl) => {
    console.log(fileUrl);
    const data = {
      file: fileUrl,
    };
    try {
      setIsLoading(true);
      await axios.post("/cv/upload/drive", data).then((res) => {
        console.log(res.data?.doc);
        const user = res.data?.doc;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("CV uploaded successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleFileInputChange = async (file) => {
    const formData = new FormData();
    formData.append("cv", file);
    try {
      setIsLoading(true);
      await axios.post("/cv/upload", formData).then((res) => {
        console.log(res.data?.doc);
        const user = res.data?.doc;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("CV uploaded successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleButtonClick = () => {
    file.current.click();
  };

  const createJob = () => {
    window.location.href = "/createjob";
  };

  const toggle2FA = async () => {
    let route;
    switch (user?.role) {
      case "jobseeker":
        route = "/candidates/2fa/toggle";
        break;
      case "employer":
        route = "/employers/2fa/toggle";
        break;
      case "company":
        route = "/companies/2fa/toggle";
        break;
      default:
        break;
    }
    try {
      setIsLoading(true);
      await axios.patch(route).then((res) => {
        const user = res.data?.user;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage(res?.data?.message);
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      {/* <ToastContainer /> */}
      {isLoading && <OverlayLoader />}
      <div className="profile_box" id="c1">
        <div className="profile_icon">
          <Link to="/profile">
            <img
              src={user?.profilePhoto}
              alt={user?.profilePhoto}
              crossOrigin="anonymous"
              loading="lazy"
            />
          </Link>
          <Link onClick={() => profilePhoto.current.click()} className="edit_icon">
            <BsPencilFill />
          </Link>
          <input
            type="file"
            ref={profilePhoto}
            style={{ display: "none" }}
            onChange={(e) => editProfilePhoto(e.target.files[0])}
          />
        </div>
        <div className="credentials_1">
          <h3>{user?.name ? user?.name : user?.firstName + " " + user?.lastName}</h3>
          {user?.role === "jobseeker" && user?.status === "featured" && (
            <h4 style={{ textDecoration: "underline", color: "blue" }}>
              <b>
                <span style={{ color: "red" }}>F</span>
                <span style={{ color: "green" }}>e</span>
                <span style={{ color: "yellow" }}>a</span>
                <span style={{ color: "brown" }}>t</span>
                <span style={{ color: "blue" }}>u</span>
                <span style={{ color: "aqua" }}>r</span>
                <span style={{ color: "grey" }}>e</span>
                <span style={{ color: "red" }}>d</span>
              </b>
            </h4>
          )}
          {user?.jobTitle}
          <div className="links">
            <a href="/editprofile">Edit Profile</a>
          </div>
          {(user?.role === "employer" || user?.role === "company") &&
            user?.checkout?.packageName === "search cvs" && (
              <div className="links">
                <a href="/searchcv">Search CVs</a>
              </div>
            )}
          {(user?.role === "employer" || user?.role === "company") &&
            user?.checkout?.packageName === "advertise jobs" && (
              <div className="links">
                <a href="/job-advertisement">Advertise Jobs</a>
              </div>
            )}
        </div>
        <div className="btn-group">
          {state.isFetching ? (
            <div className="d-flex justify-content-center section-padding">
              <Loader />
            </div>
          ) : (
            <>
              {user?.role === "jobseeker" && (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/savejob" className="btn_link">
                      <MdOutlineSaveAs />
                      <strong>Saved Jobs:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{state.savedJobs?.length}</strong>
                  </p>{" "}
                </div>
              )}
              <div className="profile_links_btn job_alert-tips">
                <p>
                  <Link to="/profile/createdposts" className="btn_link">
                    <MdPostAdd />
                    <strong>Created Posts:</strong>
                  </Link>
                </p>
                <p className="btn_link">
                  <strong>{state.posts?.length}</strong>
                </p>
              </div>
              {user?.role === "employer" || user?.role === "company" ? (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/postedjobs" className="btn_link">
                      <IoIosCreate />
                      <strong>Posted Jobs:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{state.jobs?.length}</strong>
                  </p>
                </div>
              ) : (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/jobalert1" className="btn_link">
                      <MdEditNotifications />
                      <strong>Job Alerts:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{state.jobAlerts?.length}</strong>
                  </p>
                </div>
              )}
              {/* {user?.role === "jobseeker" && (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/profileveiw" className="btn_link">
                      <BsFillPersonVcardFill />
                      <strong>Profile Views:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{user?.views}</strong>
                  </p>
                </div>
              )} */}
              {(user?.role === "employer" || user?.role === "company") &&
                user?.checkout?.packageName === "advertise jobs" && (
                  <div className="profile_links_btn">
                    <p>
                      <Link to="/profile/jobsApplications" className="btn_link">
                        <MdRequestQuote />
                        <strong>Applications for Jobs:</strong>
                      </Link>
                    </p>
                    <p className="btn_link">
                      <strong>{state.jobs?.length}</strong>
                    </p>
                  </div>
                )}
              {user?.role === "jobseeker" && (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/appliedJobs" className="btn_link">
                      <MdRequestQuote />
                      <strong>My Applications:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{state.applications?.length}</strong>
                  </p>
                </div>
              )}
              {user?.role === "company" && (
                <div className="profile_links_btn">
                  <p>
                    <Link to="/profile/team" className="btn_link">
                      <RiTeamFill />
                      <strong>Recruiters Team:</strong>
                    </Link>
                  </p>
                  <p className="btn_link">
                    <strong>{state.team?.length}</strong>
                  </p>
                </div>
              )}
              <div className="profile_links_btn">
                <p>
                  <Link to="/profile/devices" className="btn_link">
                    <FcMultipleDevices />
                    <strong>Connected Devices:</strong>
                  </Link>
                </p>
                <p className="btn_link">
                  <strong>{state.devices?.length}</strong>
                </p>
              </div>
              <Outlet />
            </>
          )}
          <br></br>
          {user?.role === "jobseeker" && (
            <div className="credentials">
              <h4>Complete your profile</h4>
              <p>A completed profile is more likely to be viewed by a recruiter.</p>
            </div>
          )}
          {(user?.role === "employer" || user?.role === "company") && (
            <div className="credentials">
              <h4>Complete your profile</h4>
              <p>A completed profile is more likely to be viewed by a recruiter.</p>
            </div>
          )}
          <div className="profile_buttons_div">
            {user?.role === "jobseeker" && (
              <>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={() => setIsOpen({ ...isOpen, upload_cv: !isOpen.upload_cv })}
                  >
                    <span>
                      <BsLayoutTextSidebar />
                      &nbsp;Upload CV&emsp;
                    </span>
                    <AiFillCaretDown />
                  </button>
                  {isOpen.upload_cv && (
                    <div className="dropdown-content">
                      <p style={{ cursor: "pointer" }} onClick={handleButtonClick}>
                        <GoDeviceDesktop />
                        &nbsp;Device
                      </p>
                      <input
                        type="file"
                        ref={file}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileInputChange(e.target.files[0])}
                      />
                      {/* <a style={{ cursor: "pointer" }} onClick={handleFilePick}>
                      <FaDropbox />
                      Dropbox
                    </a> */}
                      <p style={{ cursor: "pointer" }} onClick={handleOpenPicker}>
                        <IoLogoXbox />
                        &nbsp;Google Drive{" "}
                      </p>
                      <DropboxChooser
                        appKey={APP_KEY}
                        success={handleSuccess}
                        cancel={() => console.log("closed")}
                        multiselect={false}
                        extensions={[".pdf", ".doc", ".docx"]}
                      >
                        <p style={{ cursor: "pointer" }}>
                          <FaDropbox />
                          &nbsp;Dropbox
                        </p>
                      </DropboxChooser>
                      <p className="txt1">
                        We can accept the following file types: .doc, .docx, .rtf, .txt, .odt, .pdf
                        and .wps
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
            {(user?.role === "employer" || user?.role === "company") &&
              user?.checkout?.packageName === "advertise jobs" && (
                <>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={createJob}>
                      <span>
                        <BsLayoutTextSidebar />
                        &nbsp;Create Job&emsp;
                      </span>
                    </button>
                  </div>
                </>
              )}
            {(user?.role === "employer" || user?.role === "company") &&
              user?.checkout?.packageName === "search cvs" && (
                <>
                  <div className="dropdown">
                    <button className="dropbtn">
                      <span>
                        <a href="/searchcv">
                          <BsLayoutTextSidebar />
                        </a>
                        &nbsp;Search CVs
                      </span>
                    </button>
                  </div>
                </>
              )}
            {user?.role === "jobseeker" && (
              <>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={() => setIsOpen({ ...isOpen, edit_skills: !isOpen.edit_skills })}
                  >
                    <span>
                      <BsPencilFill />
                      &nbsp;Main Skills
                    </span>
                    <AiFillCaretDown />
                  </button>
                  {isOpen.edit_skills && (
                    <div className="dropdown-content">
                      <p className="fs-6 text-center">Enter up to 10 skills, separated by commas</p>
                      <textarea
                        className="form-control"
                        type="text"
                        value={skills}
                        required
                        maxLength={300}
                        onChange={(e) => setSkills(e.target.value)}
                      ></textarea>
                      <button className="btn btn-primary" onClick={() => handleAddInfo("skills")}>
                        Update Skills
                      </button>
                    </div>
                  )}
                </div>
                {/* <br></br> */}
              </>
            )}

            {user?.role === "jobseeker" && (
              <>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        edit_job_title: !isOpen.edit_job_title,
                      })
                    }
                  >
                    <span>
                      <GoMail />
                      &nbsp;Desired Job Title
                    </span>
                    <AiFillCaretDown />
                  </button>
                  {isOpen.edit_job_title && (
                    <div className="dropdown-content">
                      <p className="fs-6 text-center">Enter preferred Job Title</p>
                      <input
                        type="text"
                        className="form-control"
                        value={jobTitle}
                        required
                        onChange={(e) => setJobTitle(e.target.value)}
                      ></input>
                      <button className="btn btn-primary" onClick={() => handleAddInfo("jobTitle")}>
                        Update Job Title
                      </button>
                    </div>
                  )}
                </div>
                {/* <br></br> */}
              </>
            )}

            {user?.role === "jobseeker" && (
              <>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        add_cover_letter: !isOpen.add_cover_letter,
                      })
                    }
                  >
                    <span>
                      <MdRequestQuote />
                      &nbsp;Cover Letter
                    </span>
                    <AiFillCaretDown />
                  </button>
                  {isOpen.add_cover_letter && (
                    <div className="dropdown-content">
                      <p className="fs-6 text-center">Enter Cover Letter Title</p>
                      <input
                        type="text"
                        className="form-control"
                        value={coverLetter.title}
                        placeholder="Title"
                        onChange={(e) =>
                          setCoverLetter({
                            ...coverLetter,
                            title: e.target.value,
                          })
                        }
                        required
                      />
                      <p className="fs-6 text-center">Enter Content</p>
                      <textarea
                        className="form-control"
                        rows={3}
                        type="text"
                        value={coverLetter.content}
                        placeholder="Content"
                        onChange={(e) =>
                          setCoverLetter({
                            ...coverLetter,
                            content: e.target.value,
                          })
                        }
                        required
                        maxLength={300}
                      ></textarea>
                      <button className="btn btn-primary" onClick={addCoverLetter}>
                        Add Cover Letter
                      </button>
                    </div>
                  )}
                </div>
                {/* <br></br> */}
              </>
            )}

            <div className="dropdown">
              <button className="dropbtn">
                2 FA
                <span
                  className="d-flex align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={toggle2FA}
                >
                  Off
                  {user?.is2FAOn ? (
                    <FaToggleOn size="26" color="var(--btncolorHover)" />
                  ) : (
                    <FaToggleOff size="26" color="var(--btncolorHover)" />
                  )}
                  On
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileLeft;
