import React, { useEffect, useState } from "react";
import { ToastError, ToastSuccess } from "../../../components/alert/toastAlert";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import axios from "../../../axios";

const Settings = () => {
  const { state, fetchConfigurations } = useAdminPanelContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    taxType: "",
    tax: 0,
  });

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  React.useLayoutEffect(() => {
    setData((p) => ({
      ...p,
      taxType: state?.configurations?.configurations?.tax?.taxType,
      tax: state?.configurations?.configurations?.tax?.value,
    }));
  }, [state?.configurations?.configurations?.tax]);

  const handleChange = (e) => {
    setData((p) => ({
      ...p,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const updateConfig = async () => {
    try {
      setIsLoading(true);
      const res = await axios.patch("/adminPanel/configurations", {
        tax: {
          value: data.tax,
          taxType: data.taxType,
        },
      });

      if (res.status === 200) {
        setSuccess(true);
        setSuccessMessage("Configurations updated successfully.");
        await fetchConfigurations();
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="messages">
        <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>Settings</h3>

        <table>
          <thead>
            <tr>
              <th>Tax</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  placeholder="Enter tax"
                  name="tax"
                  value={data.tax}
                  onChange={handleChange}
                  type="number"
                />
              </td>
              <td>
                <select
                  style={{
                    height: "35px",
                    width: "100%",
                  }}
                  value={data.taxType}
                  name="taxType"
                  onChange={handleChange}
                >
                  <option>percent</option>
                  <option>price</option>
                </select>
              </td>
              <td>
                <button onClick={updateConfig}>Update</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Settings;
