import React from "react";

const TopHeader = () => {
  return (
    <div className="services_top_header">
      <section className="services_content_section">
        <h1 className="services-heading heading-font">
          Welcome to <span className="homepage_title_alt">Alphy Johnson's</span> Employment{" "}
          <span className="homepage_title_alt">Resource</span> Center
        </h1>
        <p className="services_para">
          At <a href="/">Alphy Johnson's Employment Resource Center</a>, we specialize in connecting
          job seekers with top employers. As part of the Alphy Johnson Data Solutions family, we
          provide a global platform that supports both individuals searching for jobs and companies
          seeking talented candidates. Our site attracts thousands of unique visitors every month,
          making us a trusted resource for anyone looking to advance their career or find the right
          hire. Whether you're here to explore new opportunities or recruit the best talent, we
          prioritize your success.
        </p>
      </section>
    </div>
  );
};

export default TopHeader;
