import React, { useState } from "react";
import {
  FaPlus,
  FaMinus,
  FaCertificate,
  FaLuggageCart,
  FaPowerOff,
} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { BsCollectionPlayFill, BsPersonVcardFill } from "react-icons/bs";
import { RiSave2Fill } from "react-icons/ri";
import { VscLayersActive } from "react-icons/vsc";
import { AiFillCheckCircle, AiFillAccountBook } from "react-icons/ai";
import { Link } from "react-router-dom";

const Drawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`drawer ${isOpen ? "open" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="drawer-handle"
          onClick={toggleDrawer}
          style={{ color: "#fff", cursor: "pointer" }}
        >
          {isOpen ? <FaMinus /> : <FaPlus />}
          <Link to="/career-counselling-courses">
            <FaPowerOff color="#fff" />
          </Link>
        </div>
        <div className="drawer-body">
          {isOpen && (
            <>
              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-3.9rem", marginRight: "0.5rem" }}>
                  <MdDashboard color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard">Dashboard</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-3.5rem", marginRight: "0.5rem" }}>
                  <BsCollectionPlayFill color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/mycourses">My Courses</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-2rem", marginRight: "0.5rem" }}>
                  <RiSave2Fill color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/saved">Saved Courses</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-2.2rem", marginRight: "0.5rem" }}>
                  <VscLayersActive color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/active-courses">Active Courses</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-0.7rem" }}>
                  <AiFillCheckCircle color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/completed-courses">Completed Courses</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-2.2rem", marginRight: "0.5rem" }}>
                  <FaCertificate color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/certificates">My Certificates</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-2.6rem", marginRight: "0.5rem" }}>
                  <AiFillAccountBook color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/specialoffer">Special Offers</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-4.5rem", marginRight: "0.5rem" }}>
                  <FaLuggageCart color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/myorders">My Orders</a>
              </div>

              <div className="drawer-content" style={{ cursor: "pointer" }}>
                <p style={{ marginLeft: "-4.5rem", marginRight: "0.5rem" }}>
                  <BsPersonVcardFill color="var(--blue)" size={25} />
                </p>
                <a href="/dashboard/myprofile">My Profile</a>
              </div>
              {/* <p>Privacy & Security</p>
              <p>Terms of Use</p> */}
            </>
          )}
          {!isOpen && (
            <>
              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard">
                  <p>
                    <MdDashboard color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/mycourses">
                  <p>
                    <BsCollectionPlayFill color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/saved">
                  <p>
                    <RiSave2Fill color="var(--blue)" size={28} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/active-courses">
                  <p>
                    <VscLayersActive color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/completed-courses">
                  <p>
                    <AiFillCheckCircle color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/certificates">
                  <p>
                    <FaCertificate color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/specialoffer">
                  <p>
                    <AiFillAccountBook color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/myorders">
                  <p>
                    <FaLuggageCart color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>

              <div className="drawer-content1" style={{ cursor: "pointer" }}>
                <Link to="/dashboard/myprofile">
                  <p>
                    <BsPersonVcardFill color="var(--blue)" size={25} />
                  </p>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Drawer;
