import axios from "./axios";

export const fetchAllCandidates = async () => {
  const res = await axios.get("/additionalUsers/candidates");
  return res?.data?.docs;
};

export const deleteCandidate = async (id) => {
  const res = await axios.delete(`/additionalUsers/candidates/${id}`);
  return res?.data;
};

export const fetchAllEmployers = async () => {
  const res = await axios.get("/additionalUsers/employers");
  return res?.data?.docs;
};

export const deleteEmployer = async (id) => {
  const res = await axios.delete(`/additionalUsers/employers/${id}`);
  return res?.data;
};

export const fetchAllCompanies = async () => {
  const res = await axios.get("/additionalUsers/companies");
  return res?.data?.docs;
};

export const deleteCompany = async (id) => {
  const res = await axios.delete(`/additionalUsers/companies/${id}`);
  return res?.data;
};

export const fetchCourseUsers = async () => {
  const res = await axios.get("/additionalUsers/courseUsers");
  const userArray = res.data?.docs;
  const newArray = userArray?.filter((user) => user?.role === "user");
  return newArray;
};

export const deleteCourseUser = async (id) => {
  const res = await axios.delete(`/additionalUsers/courseUsers/${id}`);
  return res?.data;
};

export const fetchAllPosts = async () => {
  const res = await axios.get("/career-advices");
  return res?.data?.docs;
};

export const deletePost = async (id) => {
  const res = await axios.delete(`/additionalUsers/posts/${id}`);
  return res?.data;
};

export const fetchAllPlans = async () => {
  const res = await axios.get("/additionalUsers/plans/registration");
  return res?.data?.docs;
};

export const deleteCheckoutPlan = async (id) => {
  const res = await axios.delete(`/additionalUsers/plans/registration/${id}`);
  return res?.data;
};

export const fetchPremiumPlans = async () => {
  const res = await axios.get("/additionalUsers/plans/others");
  return res?.data?.docs;
};

export const deletePremiumPlan = async (id) => {
  const res = await axios.delete(`/additionalUsers/plans/others/${id}`);
  return res?.data;
};

export const fetchHiringOrders = async () => {
  const res = await axios.get("/additionalUsers/hiring/orders");
  return res?.data?.docs;
};

export const deleteHiringOrder = async (id) => {
  const res = await axios.delete(`/additionalUsers/hiring/orders/${id}`);
  return res?.data;
};

export const fetchAllCourses = async () => {
  const res = await axios.get("/additionalUsers/courses");
  return res?.data?.data;
};

export const deleteCourse = async (id) => {
  const res = await axios.delete(`/additionalUsers/courses/${id}`);
  return res?.data;
};

export const fetchEnquiries = async () => {
  const res = await axios.get("/additionalUsers/enquiries");
  return res?.data?.docs;
};

export const deleteEnquiry = async (id) => {
  const res = await axios.delete(`/additionalUsers/enquiries/${id}`);
  return res?.data;
};

export const fetchContactQueries = async () => {
  const res = await axios.get("/additionalUsers/contact/queries");
  return res?.data?.docs;
};

export const deleteQuery = async (id) => {
  const res = await axios.delete(`/additionalUsers/contact/queries/${id}`);
  return res.data;
};
