import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./allcourses.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";

function AllCourses() {
  const [courses, setCourses] = useState([]);
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const categoryOptions = [
    "Business and Entrepreneurship",
    "Technology and Programming",
    "Arts and Creativity",
    "Language Learning",
    "Personal Development",
    "Health and Wellness",
    "Science and Engineering",
    "Social Sciences and Humanities",
    "Beauty Tips",
    "Staff Training",
  ];

  useEffect(() => {
    setIsFetching(true);
    const params = new URLSearchParams(location.search);
    console.log(params);
    const handleQuery = async () => {
      try {
        const response = await axios.get(`/courses/search-filter?${params}`);
        const data = response.data?.courses;
        const newData = data.filter((obj) => obj?.courseMetadata?.isApprovedByAdmin === 1);
        setCourses(newData);
        const pages = Math.ceil(newData?.length / postsPerPage);
        setTotalPages(pages);
        setPagePosts(newData?.slice(0, postsPerPage));
        setTimeout(() => {
          setIsFetching(false);
        }, 1500);
      } catch (error) {
        console.error(error);
      }
    };

    const title = params.get("title");
    const categoryParam = params.get("category");
    if (title) {
      setSearchQuery(title);
    } else if (categoryParam) {
      setCategory(decodeURI(categoryParam));
    }
    handleQuery();
  }, [location.search, postsPerPage]);

  const handleClick = () => {
    const params = new URLSearchParams(location.search);
    params.set("title", searchQuery);
    params.set("category", category);
    params.set("duration", duration);
    params.set("price", price);
    navigate(`?${params.toString()}`);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = courses?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <div className="cont">
      <Navbar3 />
      <div className="text">
        <h1 className="page-title">All Courses</h1>
        <div className="input-group">
          <input
            type="text"
            placeholder="Search by course name"
            aria-label="Search"
            aria-describedby="basic-addon2"
            name="search"
            value={searchQuery}
            onChange={handleChange}
            className="form-control text-left"
          />
          <div className="input-group-append">
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => handleClick()}
            >
              <i className="bx bx-search-alt-2 fs-4 courses_search_icon"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="main">
        <div className="filters">
          <label htmlFor="category">Category:</label>
          <select
            className="custom-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categoryOptions.sort().map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>

          <label htmlFor="duration">Max Duration:</label>
          <select
            id="duration"
            className="custom-select"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <option value="">Select Duration</option>
            <option value="30">Less than 30 Hours</option>
            <option value="100">Less than 100 Hours</option>
            <option value="300">Less than 300 Hours</option>
            <option value="400">Less than 400 Hours</option>
            <option value="">Any</option>
          </select>

          <label htmlFor="price">Price:</label>
          <select
            id="price"
            className="custom-select"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          >
            <option value="">Select Price</option>
            <option value="50">50$</option>
            <option value="100">100$</option>
            <option value="200">200$</option>
            <option value="300">300$</option>
            <option value="">400$+</option>
          </select>

          <div className="search-button">
            <button type="submit" className="btn btn-primary" onClick={handleClick}>
              Search
            </button>
          </div>
        </div>

        {isFetching ? (
          <div
            className="container d-flex justify-content-center align-items-center"
            style={{ minHeight: "70px", margin: "2rem auto" }}
          >
            <Loader />
          </div>
        ) : (
          <div className="course-list">
            {pagePosts?.length > 0 ? (
              <>
                <div className="courses_row">
                  {pagePosts?.slice(0, 3)?.map((course) => {
                    return (
                      <div
                        key={course._id}
                        className="course-card"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/courses/${course._id}`)}
                      >
                        <img
                          src={course?.courseMetadata?.coverImage}
                          alt={course.courseMetadata.title}
                          crossOrigin="anonymous"
                          loading="lazy"
                        />
                        <div className="text-container">
                          {course.courseMetadata && (
                            <>
                              <h1 className="course-title">
                                {course.courseMetadata.title?.length > 20
                                  ? course.courseMetadata.title.slice(0, 20) + "..."
                                  : course.courseMetadata.title}
                              </h1>
                              <h3 className="course-category">
                                {course.courseMetadata?.userId?.firstName +
                                  " " +
                                  course.courseMetadata?.userId?.lastName}
                              </h3>

                              <div className="course-below-line">
                                <h3 className="course-price">${course.courseMetadata.price}</h3>
                                <p>{course.courseMetadata.duration + " Hours"}</p>
                              </div>
                              <p className="course-description">
                                {course.courseMetadata.description?.length > 50
                                  ? course.courseMetadata.description.slice(0, 50) + "..."
                                  : course.courseMetadata.description}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="courses_row">
                  {pagePosts?.slice(3, 6)?.map((course) => {
                    return (
                      <div
                        key={course._id}
                        className="course-card"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/courses/${course._id}`)}
                      >
                        <img
                          src={course?.courseMetadata?.coverImage}
                          alt={course.courseMetadata.title}
                          crossOrigin="anonymous"
                          loading="lazy"
                        />
                        <div className="text-container">
                          {course.courseMetadata && (
                            <>
                              <h1 className="course-title">
                                {course.courseMetadata.title?.length > 20
                                  ? course.courseMetadata.title.slice(0, 20) + "..."
                                  : course.courseMetadata.title}
                              </h1>
                              <h3 className="course-category">
                                {course.courseMetadata?.userId?.firstName +
                                  " " +
                                  course.courseMetadata?.userId?.lastName}
                              </h3>

                              <div className="course-below-line">
                                <h3 className="course-price">${course.courseMetadata.price}</h3>
                                <p>{course.courseMetadata.duration + " Hours"}</p>
                              </div>
                              <p className="course-description">
                                {course.courseMetadata.description?.length > 50
                                  ? course.courseMetadata.description.slice(0, 50) + "..."
                                  : course.courseMetadata.description}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="courses_row">
                  {pagePosts?.slice(6, 9)?.map((course) => {
                    return (
                      <div
                        key={course._id}
                        className="course-card"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/courses/${course._id}`)}
                      >
                        <img
                          src={course?.courseMetadata?.coverImage}
                          alt={course.courseMetadata.title}
                          crossOrigin="anonymous"
                          loading="lazy"
                        />
                        <div className="text-container">
                          {course.courseMetadata && (
                            <>
                              <h1 className="course-title">
                                {course.courseMetadata.title?.length > 20
                                  ? course.courseMetadata.title.slice(0, 20) + "..."
                                  : course.courseMetadata.title}
                              </h1>
                              <h3 className="course-category">
                                {course.courseMetadata?.userId?.firstName +
                                  " " +
                                  course.courseMetadata?.userId?.lastName}
                              </h3>

                              <div className="course-below-line">
                                <h3 className="course-price">${course.courseMetadata.price}</h3>
                                <p>{course.courseMetadata.duration + " Hours"}</p>
                              </div>
                              <p className="course-description">
                                {course.courseMetadata.description?.length > 50
                                  ? course.courseMetadata.description.slice(0, 50) + "..."
                                  : course.courseMetadata.description}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div
                className="container d-flex justify-content-center align-items-center"
                style={{ minHeight: "70px", margin: "2rem auto" }}
              >
                No Course Found!!
              </div>
            )}
          </div>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={currentPage === 1 ? "btn btn-primary active_page_btn" : "btn btn-primary"}
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div className="btn btn-primary" onClick={() => paginate(totalPages)}>
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default AllCourses;
