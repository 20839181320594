import React from "react";
import { Link } from "react-router-dom";

function TrendingJobs() {
  const handleLinkClick = async (industryName) => {
    try {
      const encoded = encodeURIComponent(industryName);
      const queryParams = new URLSearchParams({
        keyword: encoded,
      });
      window.location.href = `/job-recruiting-websites?${queryParams}`;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section id="Trendingjob">
      <div className="container">
        <h2 className="trendingjob-head fs-4">HIGH DEMAND OCCUPATIONS</h2>
        <div className="Trendingjob-row1">
          <div>
            <ul className="col-4-Trendingjoblinks">
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("Document Controller")}
                >
                  Document Controller Jobs
                </Link>
              </li>
              <li></li>
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("Electrical Improver")}
                >
                  Electrical Improver Jobs
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("General Labourer")}
                >
                  General Labourer Jobs
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <ul className="col-4-Trendingjoblinks">
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("Document Controller")}
                >
                  General Labourer Jobs
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => handleLinkClick("Painter")}>
                  Painter Jobs
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => handleLinkClick("Steel Fixer")}>
                  Steel Fixer Jobs
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <ul className="col-4-Trendingjoblinks">
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("Electrical Improver")}
                >
                  Electrical Improver Jobs
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => handleLinkClick("Plumber")}>
                  Plumber Jobs
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => handleLinkClick("Telehandler")}>
                  Telehandler Jobs
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <ul className="col-4-Trendingjoblinks">
              <li>
                <Link to="#" onClick={() => handleLinkClick("Engineer")}>
                  Setting Out Engineer Jobs
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick("Traffic Marshall")}
                >
                  Traffic Marshall Jobs
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => handleLinkClick(">General Labourer")}
                >
                  General Labourer Jobs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrendingJobs;
