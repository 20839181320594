import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination } from "swiper";
import "./homepage.css";
import Board from "../../components/board/board";
import Button from "../../components/button/button";
import Interestedjobs from "../../components/interestedJobs/interestedJobs";
import Navbar3 from "../../components/navbar3/navbar3";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { RiInformationLine } from "react-icons/ri";
// import Slider from "./Slider";
import JobSearch from "../searchjobs/JobSearch";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Services from "./Services";
import Loader from "../../components/Loader";
import Dropdown from "./Dropdown";
import { Helmet } from "react-helmet";
import {
  IMAGES,
  localBusinessSchema,
  organizationSchema,
  websiteSchema,
} from "../../constants/GlobalConstants";
import LazyImage from "../../components/common/LazyImage";
import LazyBackground from "../../components/common/LazyBackground";
import LazyLoadingComponent from "../../components/common/LazyLoadingComponent";

const Homepage = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setError(false);
  }, [error]);

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchRecommendedJobs = async () => {
        await axios.get("/jobs/recommended-jobs").then((res) => {
          // console.log(res?.data?.recommendedJobs);
          setJobs(res?.data);
        });
      };

      const fetchJobs = async () => {
        await axios.get("/jobs").then((res) => {
          // console.log(res?.data);
          setJobs(res?.data);
        });
      };

      const fetchData = async () => {
        if (user?.role === "jobseeker") await fetchRecommendedJobs();
        else await fetchJobs();
        setTimeout(() => {
          setIsFetching(false);
        }, 500);
      };

      fetchData();
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      }
      setIsFetching(false);
      console.log(error);
      // alert(error?.response?.data?.message);
    }
  }, [user?.role]);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(localBusinessSchema)}</script>

        <link rel="canonical" href="https://thealphyjohnson.com/" />
        <title>Alphy Johnson – Find Jobs in USA | Best Job Search Engine</title>
        <meta
          name="description"
          content=" Searching for jobs in USA? Alphy Johnson connects job seekers with full-time, parttime, and remote opportunities, while recruiters can post jobs easily."
        />
      </Helmet>

      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      <LazyBackground src={IMAGES.homepage_img2} className="hompage_jobs section-padding">
        <div className="hompage_jobs_subdiv1">
          <div style={{ marginBottom: "20px" }}>
            <LazyImage
              src={IMAGES.cv_logo}
              alt="logo"
              className="home_logo"
              // initial={{ scaleY: 1 }} // Initial scale value for zooming in
              // animate={{ scaleY: 0 }} // Target scale value for zooming out
              // transition={{
              //   duration: 7,
              //   repeat: Infinity,
              //   repeatType: "reverse",
              //   ease: "easeInOut",
              // }}
              loading="lazy"
            />

            <motion.h2
              className="homepage_banner_h2 logo_title"
              initial={{}}
              animate={{ scale: 0.6 }}
              transition={{
                duration: 7, // Increase the duration for a slower animation
                repeat: RiInformationLine,
                repeatType: "reverse", // Reverse the animation after each repeat
                ease: "easeInOut", // Use linear easing for a smoother transition
              }}
            >
              Employment <span className="homepage_title_alt">Resource</span> Centre
            </motion.h2>
          </div>

          <div>
            <motion.h2
              className="homepage_banner_h2"
              initial={{}}
              animate={{ scale: 0.6 }}
              transition={{
                duration: 7, // Increase the duration for a slower animation
                repeat: RiInformationLine,
                repeatType: "reverse", // Reverse the animation after each repeat
                ease: "easeInOut", // Use linear easing for a smoother transition
              }}
            >
              Discover an <span className="homepage_title_alt">employment</span> opportunity that{" "}
              <span className="homepage_title_alt">suits</span> you
            </motion.h2>

            <p className="text-center simple-font">Unlimited Jobs from various companies</p>
          </div>
        </div>

        <div className="homepage_search flex-wrap">
          <JobSearch />
        </div>

        <div className="mb-2">
          <Dropdown />
        </div>
      </LazyBackground>

      <div className="interested_jobs_maindiv section-padding">
        <p className="text-center fs-8">
          If you're looking to switch careers or land your next job, Alphy Johnson is a great
          resource, whether you’re in Miami, Orlando, or anywhere else in USA. We offer a range of
          options from nearby{" "}
          <a
            href={`/job-recruiting-websites?${new URLSearchParams({
              jobType: encodeURIComponent("part time"),
            })}`}
          >
            part-time jobs
          </a>{" "}
          to remote work that allows flexibility. For companies looking to hire, it’s quick and easy
          to post job openings and connect with candidates. Whether you’re recruiting in tech,
          healthcare, or another industry, Alphy Johnson makes the process smoother for both job
          seekers and employers.
        </p>
        <p>
          <b style={{ fontSize: "20px" }}>Recommended Jobs For You</b>
        </p>

        {isFetching ? (
          <div className="container d-flex justify-content-center mt-4">
            <Loader />
          </div>
        ) : (
          <>
            {jobs?.length > 0 ? (
              <div className="interested_jobs">
                <Swiper
                  slidesPerView={5}
                  spaceBetween={10}
                  freeMode={true}
                  modules={[FreeMode, Pagination]}
                  className="mySwiper home-slider-secion1"
                  autoplay={{
                    delay: 1000, // Change slide every 1 second (1000 milliseconds)
                    disableOnInteraction: false, // Allow user interaction with the slider
                  }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {jobs?.slice(0, 4)?.map((job, index) => {
                    return (
                      <SwiperSlide key={index} className="home-slider-items">
                        {" "}
                        <Link
                          to={`/jobs/${job?._id}`}
                          style={{
                            textDecoration: "none",
                            width: "95%",
                            height: "95%",
                          }}
                        >
                          <Interestedjobs
                            jobName={job?.jobTitle}
                            jobLocation={job?.location}
                            salary={job?.salary}
                          />
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            ) : (
              <div className="container d-flex justify-content-center">
                <p>No Recommended Jobs Yet!!</p>
              </div>
            )}
          </>
        )}
      </div>

      <LazyLoadingComponent>
        <Services />
      </LazyLoadingComponent>

      <div className="job_board section-padding">
        <div className="">
          <h2>Make Hiring Simple with Alphy Johnson</h2>

          <p className="mb-4">
            Are you hiring in Florida or looking for remote candidates? Alphy Johnson is here to
            help. As one of the most reliable platforms for job postings, our site makes it easy to
            list your job openings. With simple job posting tools and a wide reach, you can fill
            roles in IT, healthcare, or any other field. Post detailed job descriptions, sit back,
            and let the applications roll in.
          </p>
        </div>

        <div className="job_board2">
          <h2>Welcome to Alphy Johnson – Florida’s Hub for Jobs and Hiring</h2>

          <p>
            At Alphy Johnson, our mission is to make{" "}
            <a href="/job-recruiting-websites" target="_blank">
              job hunting
            </a>{" "}
            and recruitment simple. Whether you're a job seeker looking for part-time, full-time, or
            remote roles, or an employer seeking top talent, we’ve got everything you need in one
            place. With job listings in industries like IT and healthcare, we make the hiring
            process quick and effective.
            <br></br>
            Ready to find your next opportunity or hire the right candidate? Alphy Johnson is one of
            USA’s best platforms for finding the job or employee you need.
          </p>
        </div>

        <div className="home_content_div">
          <h2 className="home_content_heading">Find the Perfect Job for You</h2>
          <p className="home_content_main_para">
            Ready to land your dream job? Our <a href="/career-advice">career builder platform</a>{" "}
            is packed with resources to guide you through every stage of your job search. Explore
            our collection of articles from industry experts, get tips and{" "}
            <a href="/career-advice/category/getting started" target="_blank">
              how-to guides
            </a>
            , and access{" "}
            <a href="/career-advice/category/cvs" target="_blank">
              CV templates
            </a>{" "}
            designed to help you stand out. We’re here to support your career growth with the tools
            and knowledge you need to succeed.
          </p>
          <div className="home_content_columns_div">
            <div className="home_content_column job_board2">
              <h2 className="home_content_heading">
                Kick start Your Career with Alphy Johnson's Job Board
              </h2>

              <p>
                Ready to dive into your job search? You've come to the right place. Whether you're
                fresh out of college or exploring a new career path, our in-depth guides offer
                valuable advice and career insights. We’ll help you craft a standout{" "}
                <a href="/buildcv">resume or CV</a> and navigate your job hunt effectively. From
                re-entering the workforce after a break to transitioning into a new field, we’re
                here to support you every step of the way.
              </p>
            </div>

            <div className="home_content_column job_board2">
              <h2 className="home_content_heading">Expert Career Guidance at Your Fingertips</h2>

              <p>
                Excited to start your job search in the USA. or beyond? Our platform is perfect for
                both newcomers and those considering a career change. Our{" "}
                <a href="/career-advice">comprehensive guides</a> provide essential tips and advice
                tailored to various career stages. Whether you’re creating a compelling CV or
                looking to restart your career after a break, we’re here to help you make the most
                of your <a href="/job-recruiting-websites">job search</a> and career opportunities.
              </p>
            </div>
          </div>
        </div>

        <div className="job_board_maindiv">
          <Board
            img={IMAGES.nature8}
            heading={"jobs alert"}
            para={
              "Notifications directly to your inbox for the latest jobs openings by numerous Companies."
            }
            buttonText={"Create Alerts"}
            onclick={() => navigate("/job-alerts")}
          />

          <Board
            img={IMAGES.nature9}
            heading={" Businesses"}
            para={"A large number of registered Companies, Employers can be searched and visited."}
            buttonText={"Goto Companies Search"}
            onclick={() => navigate("/search-company")}
          />
          <Board
            img={IMAGES.nature10}
            heading={"Career Guidance"}
            para={
              "Our Career Guidance center provides you with the advices from experts related to all your queries."
            }
            buttonText={"Goto Career Guidance"}
            onclick={() => navigate("/career-advice")}
          />
        </div>
      </div>

      <div className="registration_maindiv section-margin">
        <div className="registration_subdiv">
          <h2>Register your Profile</h2>
          <p className="rigster-your-cv">
            Looking for jobs? Register your resume on Alphy Johnson, and top employers will find
            you. Whether you’re seeking part-time, full-time, or remote positions, we connect you to
            job opportunities in fields like IT, healthcare, and more. Find your next career move
            with us.
          </p>
        </div>
        <div className="registraion_button">
          <a href="/register">
            <Button text={"Register now"} />
          </a>
        </div>
        <div className="owlimg_div">
          <div className="cat">
            <div className="ear ear--left"></div>
            <div className="ear ear--right"></div>
            <div className="face">
              <div className="eye eye--left">
                <div className="eye-pupil"></div>
              </div>
              <div className="eye eye--right">
                <div className="eye-pupil"></div>
              </div>
              <div className="muzzle"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="recruiting mb-3">
        <div className="recruit_heading">
          <h2>Hiring?</h2>
          <p>
            <span>Post jobs</span> on Alphy Johnson's Employment Resource Center for many users or{" "}
            <span>Search CV's</span> of the Candidates/Job Seekers.
          </p>
        </div>
        <div className="recruitng_btn">
          <a href="/job-advertisement">
            <Button text={"Start Hiring Now"} />
          </a>
        </div>
        <div>
          <img src={IMAGES.advertise} alt="advertise" loading="lazy" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
