import React, { useEffect, useState } from "react";
import "./companyz.css";
import Navbar3 from "../../components/navbar3/navbar3";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import Footer from "../../components/footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { FaPhone, FaLandmark, FaLink, FaLessThan, FaGreaterThan } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import Loader from "../../components/Loader";

function SearchEmployers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alphabet, setAlphabet] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const Letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const name = queryParams.get("name");
      const letter = queryParams.get("alphabet");
      if (name) {
        setSearchQuery(name);
      }
      if (letter) {
        setAlphabet(letter);
      }

      const fetchData = async () => {
        setIsFetching(true);
        const response = await axios.get(`/jobs/searchEmployers?${queryParams}`);
        setData(response.data.employers);
        const newPosts = response.data.employers;
        // console.log(newPosts);
        setPagePosts(newPosts?.slice(0, postsPerPage));
        const pages = Math.ceil(newPosts?.length / postsPerPage);
        setTotalPages(pages);
        setTimeout(() => {
          setIsFetching(false);
        }, 500);
      };

      fetchData();
    } catch (error) {
      console.error("Error fetching data");
    }
  }, [location.search, postsPerPage]);

  const handleAlphabetClick = (letter) => {
    navigate(`/company/employer?alphabet=${letter}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/company/employer?name=${searchQuery}`);
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = data?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <div>
      <Navbar3 />
      <ReactSearchBox />

      <div className="container-fluid header1">
        <div className="container d-flex justify-content-between cvlib_search_job_header">
          <div>
            <p className="fs-3 fw-bold">
              Companies {alphabet ? ` Starting with "${alphabet}"` : ` matching "${searchQuery}"`}
            </p>
            <span>
              Displaying {pagePosts?.length === 0 ? 0 : (currentPage - 1) * postsPerPage + 1}-
              {currentPage === totalPages
                ? data?.length
                : pagePosts?.length === 0
                ? pagePosts?.length
                : postsPerPage * currentPage}{" "}
              of {data?.length} Companies
            </span>
          </div>
          <div className="sort_job_div">
            <p className="option-title">Sort by:</p>
            <select
              className="form-select form-select-md opton2"
              aria-label=".form-select-lg example"
            >
              <option value={""}>Date</option>
            </select>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container employers_search_div">
          <div className="employers_left_div">
            <h5 className="card-title">
              <b>Search Companies</b>
            </h5>
            <div>
              <form onSubmit={handleSubmit} className="search_employers_form mt-1">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  id="inputPassword2"
                  placeholder="Company Name"
                />
                <button type="submit" className="btn btn-primary search_employers_btn">
                  Search Companies <AiOutlineSearch />
                </button>
              </form>
            </div>
            <h5 className="card-title">
              <b>Companies A-Z</b>
            </h5>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              {Letters?.slice(0, 6).map((item) => {
                return (
                  <span
                    className="search_alphabets"
                    key={item}
                    onClick={() => handleAlphabetClick(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              {Letters?.slice(6, 12).map((item) => {
                return (
                  <span
                    className="search_alphabets"
                    key={item}
                    onClick={() => handleAlphabetClick(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              {Letters?.slice(12, 18).map((item) => {
                return (
                  <span
                    className="search_alphabets"
                    key={item}
                    onClick={() => handleAlphabetClick(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              {Letters?.slice(18, 24).map((item) => {
                return (
                  <span
                    className="search_alphabets"
                    key={item}
                    onClick={() => handleAlphabetClick(item)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          </div>

          {isFetching ? (
            <div className="employers_right_div section-padding" style={{ minHeight: "80px" }}>
              <div className="container d-flex justify-content-center">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="employers_right_div">
              {pagePosts?.length === 0 && (
                <div className="container fs-8 fw-bold section-padding d-flex justify-content-center">
                  No data Exists!!
                </div>
              )}
              {pagePosts?.map((item) => {
                return (
                  <div className="container d-flex" key={item?._id}>
                    <div className="card job_card_left">
                      <div className="card-body">
                        <h5
                          className="card-title text-danger fw-bolder fs-4 "
                          style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`/view-profile/${item?._id}?role=${item?.role}`)}
                        >
                          {item?.name?.split(" ")[0]}&ensp;
                          <span className="text-primary" style={{ textTransform: "capitalize" }}>
                            {item?.name?.split(" ")?.slice(1)?.join(" ")}
                          </span>
                          <span style={{ fontSize: "17px" }}>
                            {" "}
                            &ensp;{"( "}
                            {item?.postedJobs?.length}
                            {" live jobs )"}
                          </span>
                        </h5>
                        <small>
                          {item?.creator?.role === "employer"
                            ? item?.creator?.employerId?.name
                            : item?.creator?.companyId?.name}{" "}
                          {item?.isFeatured ? (
                            <>
                              <span>
                                <i className="bx bxs-star fw-bold text-primary"></i>
                              </span>{" "}
                              Featured job
                            </>
                          ) : (
                            ""
                          )}
                        </small>
                        {item?.phone && (
                          <p className="p-0 m-0">
                            <FaPhone color="#5eb5f2" size={22} />
                            &emsp;
                            <span className="fw-bold">{item?.phone}</span>
                          </p>
                        )}
                        {item?.linkedInUrl && (
                          <p className="p-0 m-0">
                            <FaLink color="#5eb5f2" size={22} />
                            &emsp;
                            <span className="fw-bold">£{item?.linkedInUrl}</span>
                          </p>
                        )}

                        <p className="p-0 mt-1">
                          <FaLandmark color="#5eb5f2" size={22} />
                          &emsp;
                          <span className="fw-bold">{item?.address}</span>
                        </p>
                      </div>
                      <button
                        className="cv_build_btn btnapply"
                        onClick={() => navigate(`/view-profile/${item?._id}?role=${item?.role}`)}
                      >
                        View Profile
                      </button>
                    </div>
                    <div className="card job_card_right">
                      <div className="card-body">
                        {/* <h5 className="card-title"></h5> */}
                        <div
                          className="card-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/view-profile/${item?._id}?role=${item?.role}`)}
                        >
                          <div className="text-center">
                            {item?.creator?.role === "employer" ? (
                              <img
                                className="img-fluid"
                                src={item?.profilePhoto}
                                alt={item?.name}
                                crossOrigin="anonymous"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  marginBottom: "1rem",
                                }}
                                loading="lazy"
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                src={item?.profilePhoto}
                                alt={item?.name}
                                crossOrigin="anonymous"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  marginBottom: "1rem",
                                }}
                                loading="lazy"
                              />
                            )}
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {item?.name}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={currentPage === 1 ? "btn btn-primary active_page_btn" : "btn btn-primary"}
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages > 2 && (
              <>
                <div className="btn btn-primary" onClick={() => paginate()}>
                  ...
                </div>
                <div className="btn btn-primary" onClick={() => paginate(totalPages)}>
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default SearchEmployers;
