import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constants/GlobalConstants";
import LazyImage from "../../components/common/LazyImage";

function BrowseIndustryjob() {
  const handleLinkClick = (industry) => {
    const encodedIndustry = encodeURIComponent(industry);
    const queryParams = new URLSearchParams({
      keyword: encodedIndustry,
    });
    window.location.href = `/job-recruiting-websites?${queryParams}`;
  };

  return (
    <section id="BrowseIndustryjob">
      <div className="browseIndustryDivFlex" style={{ marginBottom: "30px" }}>
        <div className="container">
          <h2
            className="BrowseIndustryjob-head text-center fw-bold"
            style={{
              marginBottom: "30px",
              fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
              fontSize: "24px",
              textTransform: "uppercase",
            }}
          >
            EXPLORE JOB POSITIONS WITHIN VARIOUS INDUSTRIES
          </h2>
        </div>
        <div className="BrowseIndustryjob-row">
          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.civil}
              alt="civil engineer"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("civil engineer")}>
                civil engineer
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.manager}
              alt="bank manager"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("bank manager")}>
                bank manager
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage src={IMAGES.img3} alt="designer" className="col-5-BrowseIndustryjobimg" />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("designer")}>
                designer
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.manager}
              alt="social media manager"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("social media manager")}>
                social media manager
              </Link>
            </h3>
          </div>

          {/* Add more industries here */}
          <div className="col-5-BrowseIndustryjob">
            <LazyImage src={IMAGES.prof} alt="professor" className="col-5-BrowseIndustryjobimg" />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("professor")}>
                professor
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage src={IMAGES.doc} alt="doctor" className="col-5-BrowseIndustryjobimg" />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("doctor")}>
                doctor
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.img2}
              alt="software engineer"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("software engineer")}>
                software engineer
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.img10}
              alt="manufacturer"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("manufacturer")}>
                manufacturer
              </Link>
            </h3>
          </div>

          <div className="col-5-BrowseIndustryjob">
            <LazyImage
              src={IMAGES.img11}
              alt="sales officer"
              className="col-5-BrowseIndustryjobimg"
            />
            <h3 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
              <Link to="#" onClick={() => handleLinkClick("sales officer")}>
                sales officer
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrowseIndustryjob;
