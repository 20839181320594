import React from "react";
import "./Terms.css";
import Footer from "../../components/footer/footer";
import Navbar3 from "../../components/navbar3/navbar3";
import { Helmet } from "react-helmet";

const Brand = () => {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/terms-and-conditions" />

        <title>Terms and Conditions | Understand Our Service Policies</title>

        <meta
          name="description"
          content="Read our terms & conditions to learn about the policies that govern your use of our services. Stay informed on your rights when engaging with our platform."
        />
      </Helmet>

      <Navbar3 />
      <div>
        <br></br>
        {/* <div className="line"></div> */}
        <br></br>
      </div>
      <div className="border terms_border_div">
        <div className="border-1">
          <div className="heading__style">
            <h1>Terms & Conditions</h1>
            <div className="line"></div>
          </div>
          <div className="terms_nav_line">
            <div className="links__1">
              <a className="links" href="#General">
                General Terms
              </a>
            </div>
            <div className="links__1">
              <a className="links" href="#Additional">
                Supplementary Terms for Candidates
              </a>
            </div>
            <div className="links__1">
              <a className="links" href="#Recruiters">
                Supplementary Terms for Recruiters{" "}
              </a>
            </div>
          </div>
          <div></div>

          <div className="text">
            <p>
              Alphy Johnson’s Employment Resource Center owns and operates this website, and it is
              accessible to you under the terms and conditions outlined below. Your use of the
              website and its available services indicates your acceptance of these terms and
              conditions, as well as any supplementary terms and conditions that are specifically
              applicable to services and information offered by third parties. 
            </p>
          </div>
          <div className="text">
            <p className="text__1">
              {" "}
              IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS & CONDITIONS, PLEASE DO NOT REGISTER OR
              USE THIS WEBSITE.
            </p>
            <div className="bold_text">
              ' Alphy Johnson’s Employment Resource,' ‘cvglobalhub.com’, ’resumeglobalhub.com’,
              'we,' 'us,' and 'our'
            </div>
            Refers to Alphy Johnson's Employment Center Data and Technology Solutions LLC, which is
            located at our Head Quarters : 6731 Frontier Dr. #1095 Springfield, VA 22150 Unites
            States. 
            <div className="bold_text">'Candidate'; 'You' and 'Your' </div>
            means any person registering with us or purchasing any of our services to assist with
            your job search.
            <div className="bold_text">'Recruiter'; 'You' and 'Your' </div> means any person,
            company, organisation or firm which purchases services from us to assist your
            recruitment needs.
          </div>

          <section id="General" className="terms_section">
            <div>
              <ol>
                <li className="headings"> General Terms</li>
              </ol>
              <div className="fonts">
                {/* <ol className="text"> */}
                <p>
                  1. Alphy Johnson's Employment Center grants access to its site to Candidates and
                  Recruiters under the conditions outlined in these terms and conditions. Any
                  individual or entity using the service for purposes other than those expressly
                  stated in these terms and conditions will be promptly prohibited from using the
                  site. 
                </p>
                <p>
                  2. While we make every effort to keep the site and its services available
                  uninterrupted, we cannot be held responsible for any negative consequences
                  resulting from any interruptions or delays, regardless of the cause. Alphy
                  Johnson, its directors, or employees are not liable for any loss incurred from
                  your use or inability to use the Website or its services, to the extent permitted
                  by law. We reserve the right to modify the design and features of the site at any
                  time. 
                </p>
                <p>
                  3. It is important for Candidates and Recruiters to note that Alphy Johnson’s
                  Employment Resource Center does not act as a mediator or supplier of Candidates to
                  Recruiters (or vice versa). It is advisable for Candidates to ensure that they are
                  suitable for the advertised role and for Recruiters to verify a work-seeker's
                  suitability for the role by following the procedures outlined in the Fair Labor
                  Standards Act, Conduct of Employment Agencies and International Labor Standard
                  2023. 
                </p>
                <p>
                  4. By accessing and using the Website or Services, you acknowledge and agree that
                  you shall not engage in any activities that involve the transmission or reception
                  of material that is deemed obscene, threatening, offensive, defamatory, abusive,
                  untrue, or in breach of confidence. Moreover, you shall not transmit or receive
                  any material that infringes any intellectual property rights (including
                  copyright), violates any applicable law or regulation, contains any virus, or
                  places excessive demands on the bandwidth of The Alphy Johnson's Employment Center
                  or any third-party computer system. Any transmission of material that may harm or
                  impair the computer systems of Alphy Johnson's Employment Center or any
                  third-party computer system is strictly prohibited.    Alphy Johnson’s Employment
                  Resource cannot guarantee that its website and services will function without
                  errors, nor that they are free from viruses or other harmful elements. Except to
                  the extent permitted by law, The Alphy Johnson's Employment Center accepts no
                  liability for any such issues. 
                </p>
                <p>
                  5. Except as required by law, no guarantees, warranties or promises of any kind
                  are provided regarding the Website, Services or their contents (including comments
                  or views). The information and data available on the Website, Services, and any
                  directory or listings provided, are intended for guidance only. The Alphy
                  Johnson's Employment Center makes no representations or warranties about the
                  accuracy, completeness, or suitability for any purpose of the information and
                  graphics on the site, and there may be technical inaccuracies or errors. Your use
                  of such information and data is at your own risk. Alphy Johnson's Employment
                  Center, its directors, employees, or other representatives are not liable for any
                  loss resulting from your use of the Website or Services to the fullest extent
                  permitted by law. 
                </p>
                <p>
                  6. Despite what has been stated before, the exclusions and limitations in these
                  terms are not meant to restrict any rights that you, as a consumer, may have under
                  local law or other statutory rights that cannot be excluded. Also, it does not aim
                  to exclude or limit Alphy Johnson's Employment Center's liability to you for death
                  or personal injury resulting from our negligence or the negligence of our
                  employees or agents. 
                </p>
                <p>
                  7. We provide links to third-party websites solely for your convenience, and these
                  links do not constitute an endorsement by Alphy Johnson's Employment Center of the
                  content on those websites. We do not accept any responsibility for the content of
                  these third-party sites, nor do we make any representations regarding the accuracy
                  of materials on them. If you choose to access linked third-party websites, you do
                  so at your own risk. Additionally, we cannot guarantee the legality of material
                  presented on the Internet and we will not restrict access to any material unless
                  we are notified that it is illegal. Please note that we are not responsible for
                  the content of job postings provided by third parties on our website, but we
                  reserve the right to remove any postings or materials that we believe may be
                  illegal or inappropriate. 
                </p>
                <p>
                  8. You consent to protect, compensate, and not hold Alphy Johnson, its affiliates,
                  and their officers, directors, employees, and agents responsible for any
                  allegations, actions, or demands, including reasonable legal and accounting fees,
                  that may arise from
                  <ol type="i">
                    <li>any material You provide to any Alphy Johnson's Employment Center Site</li>
                    <li>Your use of any Alphy Johnson's Employment Center Content</li>
                    <li>
                      Your violation of these Terms. If any claim, lawsuit, or proceeding is filed
                      against Alphy Johnson's Employment Center, they will inform You promptly. 
                    </li>
                  </ol>
                </p>
                <p>
                  9. While you use any of Alphy Johnson's Employment Center's websites, these terms
                  will be valid and enforced. If you violate these terms or if Alphy Johnson's
                  Employment Center cannot confirm or verify any information you provide in your
                  registration, Alphy Johnson's Employment Center has the right to remove your user
                  content, terminate your registration, or prevent you from accessing any Alphy
                  Johnson's Employment Center site or service without incurring any liability. Even
                  after you stop using Alphy Johnson's Employment Center's websites, certain
                  provisions of these terms will still apply. Alphy Johnson's Employment Center has
                  the right to terminate, suspend, or restrict your access to their website or
                  services at any time for any reason without incurring any liability. 
                </p>
                <p>
                  10. If you are requested to fill out a registration form, you must ensure that all
                  personal information provided is truthful, precise, and complete. You also consent
                  to informing us of any changes to your registration that are relevant. 
                </p>
                <p>
                  11. Alphy Johnson's Employment Center will collect and process your personal data
                  in line with their privacy policy. Your agreement to the terms and conditions of
                  this Agreement signifies your acceptance of these terms as outlined in the privacy
                  policy. 
                </p>
                <p>
                  12. You are the sole party entitled to this Agreement, and you are not permitted
                  to assign, transfer, sublet, lease or delegate any of your rights and
                  responsibilities without the prior written approval of Alphy Johnson's Employment
                  Center. 
                </p>
                <p>
                  13. Alphy Johnson's Employment Center Ltd has the authority to transfer or assign
                  its obligations and rights under this Agreement to any third party or to any
                  companies in the same group as Alphy Johnson's Employment Center Ltd. If such a
                  transfer or assignment takes place, you will be informed through an email or by a
                  notification displayed on the website.
                </p>
                <p>
                  14. If either party fails or neglects to exercise or enforce any conferred right,
                  it will not be considered a waiver of that right. Such a failure or neglect will
                  not prevent the party from exercising or enforcing that right or any other right
                  at a later time.
                </p>
                <p>
                  15. If any portion or provision of this Agreement is deemed unenforceable,
                  unlawful or invalid, that portion or provision will be removed from the remaining
                  terms, conditions, and provisions. The remaining terms, conditions, and provisions
                  will continue to be in effect and enforceable to the maximum extent permitted by
                  law. 
                </p>
                <p>
                  16. Although Alphy Johnson's Employment Center reserves the right to make changes
                  to this Agreement, including the services provided by Alphy Johnson, this
                  Agreement represents the entire agreement between the parties regarding its
                  subject matter. Changes to this Agreement will be communicated to you via email or
                  by posting them on the website's logged-in candidate or recruiter home pages. If
                  you continue to use the Website and/or Services after two weeks from the date of
                  notification or posting, whichever comes later, you will be deemed to have
                  accepted the changes. 
                </p>
                <p>
                  17. All correspondence between us shall be by email or first-class post. You must
                  notify us of any change to your address. Notices sent to you will be deemed
                  received two business days after dispatch to the last address you provided.
                  Notices sent to us must be sent to our registered office. 
                </p>
                <p>18. For training purposes, kindly be advised that all calls may be recorded. </p>
                <p>
                  19. The laws of the United States will govern this Agreement, and you agree to
                  submit exclusively to the jurisdiction of the US courts.
                </p>
              </div>
            </div>
          </section>

          <section id="Additional" className="terms_section">
            <div>
              <ol start={2}>
                <li className="headings"> Supplementary Terms for Candidates</li>
              </ol>
              <div className="fonts">
                <p>
                  1. You acknowledge and agree that you bear complete responsibility for the format,
                  substance, and precision of any curriculum vitae or related content that you
                  upload to the Alphy Johnson's Employment Center Sites. Please note that recruiters
                  may reach out to you directly by email, mail, or phone, depending on the contact
                  information you have provided. 
                </p>
                <p>
                  2. Alphy Johnson's Employment Center retains the right to provide you with
                  third-party services and products that align with your preferences as indicated
                  during registration or afterwards, subject to your consent to receive such offers.
                  Such offers may originate from Alphy Johnson's Employment Center or third parties.
                  Please refer to our Privacy Policy for additional information. 
                </p>
                <p>
                  3. If the Recruiter contacts the Candidate directly, without the involvement of
                  Alphy Johnson's Employment Center, the Candidate will be held responsible. 
                </p>
                <p>
                  4. Vetted Candidate Profile: 
                  <ol type="a">
                    <li>
                      Please note that subscribing to an Vetted profile does not guarantee job
                      placement. It is solely intended to increase your visibility to recruiters and
                      employers and enable you to apply for jobs more quickly via email. 
                    </li>
                    <li>
                      Your credit/debit card information is not saved by us. Payments are one-time
                      only and are not recurring. 
                    </li>
                    <li>
                      Just to be clear, buying the Vetted Candidate profile is not mandatory, and
                      after creating your online CV or RESUMES, you can explore and apply for job
                      opportunities on our website for free. 
                    </li>
                    <li>
                      At Alphy Johnson's Employment Center, we do not engage in discrimination
                      against any user who is registered on or utilizes our website. We make every
                      effort to ensure that our job listings and searches do not discriminate based
                      on age, gender, race, or disabilities. However, the search tools on our site
                      do not have the ability to filter out candidates with disabilities. It is the
                      responsibility of users to comply with anti-discrimination laws. 
                    </li>
                    <li>
                      Our services and the information offered on the site by us and our third-party
                      providers are intended to facilitate the job search and recruitment process.
                      However, neither we nor our third-party suppliers can guarantee their
                      appropriateness or probability of success in any given scenario. It is
                      advisable to seek independent verification before relying on the information
                      provided on the site in circumstances that could result in loss or damage.
                    </li>
                    <li>
                      Our responsibility, as well as that of our third-party suppliers, for any loss
                      or damage incurred by you due to your use of this site, is limited to your
                      actual direct damages. This excludes any potential loss of future earnings,
                      profits, prospects, or any speculative loss, except in cases of fraud. This
                      exclusion does not extend to death or personal injury caused by our
                      negligence, as mandated by law. 
                    </li>
                    <li>
                      Please be aware that you do not possess any ownership rights to your account,
                      and if you terminate your account or it is terminated by us, all your account
                      information, including CV or RESUMES, profiles, cover letters, saved jobs, and
                      questionnaires, will be designated for deletion and removed from our databases
                      within a reasonable timeframe. However, third parties may still have saved
                      copies of your information. 
                    </li>
                    <li>
                      Please note that Alphy Johnson's Employment Center reserves the right to
                      terminate your account and remove all your information in the event of
                      prolonged inactivity or a violation of our terms by you.
                    </li>
                    <li>
                      Upon registering your CV or RESUMES on Alphy Johnson's Employment Center, you
                      consent to the storage and presentation of your CV or RESUMES and profile
                      information to recruiters on any other job board directly owned by Alphy
                      Johnson's Employment Center LLC, including resumeglobalhub and cvglobalhub. 
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </section>

          <section id="Recruiters" className="terms_section">
            <div>
              <ol start={3}>
                <li className="headings">Supplementary Terms for Recruiters</li>
              </ol>
              <div className="fonts">
                <p>
                  1. Upon submission of your information through our online form, email, telephone
                  agreement, or signed service agreement, you acknowledge acceptance of these terms
                  and conditions and will be granted access to our database (subject to payment) in
                  accordance with these terms. You also confirm that all information you provide to
                  us is truthful, accurate, and complete, and agree to notify us promptly of any
                  changes via email, telephone, or post. Please note that we reserve the right to
                  terminate this agreement if any of the information provided by you becomes
                  outdated or inaccurate or if you violate these Terms & Conditions.
                </p>
                <p>
                  2. We strive to offer you a dependable service, but we cannot assure the accuracy
                  or genuineness of any cvs/resumes stored in our database. You understand and agree
                  that this matter is beyond our control, and we are unable to provide refunds in
                  the event that Candidates submit inaccurate information. 
                </p>
                <p>
                  3. You recognize and agree that third parties hold all rights to the information
                  they provide, and such information should not be used in any way that violates
                  their proprietary interests. Additionally, you commit to not sharing any
                  information provided by candidates with anyone who is not directly involved in the
                  recruitment process of the relevant individual(s). 
                </p>
                <p>
                  4. You agree and acknowledge that you will always adhere to the provisions of the
                  prevailing data protection laws, and you will not engage in any activity or
                  omission that could result in Alphy Johnson's Employment Center violating the
                  current data protection legislation. 
                </p>
                <p>
                  5. You recognize and agree that you are serving as a data controller concerning
                  any personal data obtained in the provision of Alphy Johnson's Employment Center's
                  services, as per the existing data protection legislation. It is your
                  responsibility to ensure compliance with your duties as a data controller and to
                  verify the legal bases for processing any personal data. 
                </p>
                <p>
                  6. Alphy Johnson's Employment Center recognizes that it will perform as a data
                  controller under the current data protection laws and that it will fulfill its
                  legal responsibilities while offering its services. 
                </p>
                <p>
                  7. Your personal information that can be used to identify you will be stored in
                  compliance with our Data Protection Registration. You are required to have a
                  legitimate reason for transferring any personal data to us. By providing us with
                  your personal details, you agree to our use of this information for contacting you
                  regarding service improvements, payment requests, and account status discussions.
                  Moreover, you confirm that you do not consider any of the aforementioned actions
                  as a violation of your rights under the Telecommunications (Data Protection and
                  Privacy) Regulations 2023. 
                </p>
                <p>
                  8. Regarding the usage of the Google Maps API, you recognize and agree that the
                  utilization of Alphy Johnson's Employment Center obliges you to comply with 
                  <a className="google_link" href="https://policies.google.com/terms?hl=en">
                    Google's Terms of Service
                  </a>
                  .
                </p>
                <p>
                  9. Without prior written permission from Alphy Johnson's Employment Center,
                  recruitment agencies/flat fee companies are not permitted to resell Alphy
                  Johnson's Employment Center's services to other companies or agencies for
                  recruitment purposes on the site. If such reselling occurs, your account will be
                  canceled without a refund. Permitted resellers are also subject to the following
                  conditions:
                  <ol type="a">
                    <li>
                      It is prohibited to resell Alphy Johnson's Employment Center's services to
                      direct clients or to prevent companies from using our service.
                    </li>
                    <li>
                      Unless you buy client-branded advertisements, all job postings must be under
                      your own brand.
                    </li>
                    <li>
                      Client names cannot be mentioned in the advertisement unless client-branded
                      adverts have been bought. 
                    </li>
                    <li>
                      We reserve the right to decline to publish any client-branded advertisements
                      if we have a direct relationship with the client or are in discussions to
                      establish one.
                    </li>
                  </ol>
                </p>
                <p>
                  10. It is strictly prohibited to download or leech our cvs/resumes database,
                  either in whole or in part, for any reason other than recruitment for a current
                  job vacancy. Violation of this policy will result in immediate cancellation of
                  your account without a refund. Additionally, downloading via any software other
                  than a browser is not allowed and will lead to immediate cancellation of your
                  account without a refund. We may also take legal action against you. Alphy
                  Johnson's database is designed for searching and selecting suitable candidates for
                  a specific job vacancy, and therefore, cvs/resumes must be previewed before
                  downloading to ensure their relevance. Bulk downloading of cvs/resumes is not
                  permitted. 
                </p>
                <p>
                  11. It is strictly prohibited to contact candidates through email or any other
                  means for any purpose other than recruitment. 
                </p>
                <p>12. You are required to refrain from overloading our servers. </p>
                <p>
                  13. For fixed users, you agree that only those individuals will have access to the
                  account. Unauthorized attempts by other individuals to access the account or
                  access from different locations may result in immediate termination of your
                  account without a refund. 
                </p>
                <p>
                  14. You are required to use the Alphy Johnson's Employment Center CV or RESUMES
                  Database as outlined in these Terms and any contracts with Alphy Johnson's
                  Employment Center. Usage of the Alphy Johnson's Employment Center CV or RESUMES
                  Database must comply with all relevant privacy and data protection laws. You agree
                  not to disclose any data from the Alphy Johnson CV or RESUMES Database to any
                  third party, unless you are an authorized recruitment agency, staffing agency,
                  advertising, or other agency, or using the CV or RESUMES explicitly for employment
                  purposes. 
                </p>
                <p>
                  15. You agree to treat individuals who respond to your posted advertisement fairly
                  and professionally, and you will not engage in any actions that may harm the
                  reputation of Alphy Johnson. You agree to indemnify Alphy Johnson's Employment
                  Center against any claims brought by individuals against Alphy Johnson's
                  Employment Center as a result of your breach of this obligation or any other terms
                  and conditions. 
                </p>
                <p>
                  16. We cannot assure you of any response to your advertisement or guarantee that
                  the responses received will be from suitable candidates for the job advertised.
                  You are solely responsible for carrying out all necessary checks and procedures to
                  ensure that the candidates are qualified for the job advertised and possess the
                  necessary personal characteristics. 
                </p>
                <p>
                  17. The purpose of our rules regarding the content and format of jobs posted on
                  Alphy Johnson's Employment Center is to ensure clear and informative presentation
                  of results for users who search the site or database. By using our service, you
                  agree that we may remove, at our discretion and without liability to you, any
                  advertisement that violates these rules. Please note that the rules may be updated
                  periodically, and we advise you to review them regularly. 
                  <ol type="a">
                    <li>
                      Unless it is a franchise opportunity, the job advertised must be located in
                      the town specified in the posting, and this should be accurately reflected in
                      the description. 
                    </li>
                    <li>
                      The job title should accurately describe the position being advertised and
                      should not contain any irrelevant information such as location, salary, or
                      company name. It should also not be a promotional slogan such as "Great
                      Opportunity" or "Money To Be Made!" 
                    </li>
                    <li>
                      Job descriptions should not include any email addresses or URLs. If you do
                      include them, we will remove them automatically. 
                    </li>
                    <li>Job adverts should not contain premium rate phone numbers. </li>
                    <li>
                      When describing the role, the job description should mention the necessary
                      skills and provide details about the position. The contact names must not be
                      included in the job description. If a recruitment agency is posting the job on
                      behalf of a client, they may only mention the client's name once within the
                      job description, unless they have purchased client-branded adverts. 
                    </li>
                    <li>
                      If the job position provides training for a fee, the job listing must
                      explicitly mention it and be tagged with our 'learn to earn' banner. However,
                      the training courses should be advertised separately in our training section. 
                      g. If the job position offers training at a cost, it is essential to mention
                      it explicitly in the job vacancy description. In this case, the vacancy should
                      be marked with our 'learn to earn' banner. However, the training courses
                      should be advertised separately in our training section and not within the job
                      vacancy description. 
                    </li>
                    <li>
                      Any advertisements that discriminate (or have the appearance of
                      discriminating) based on sex, race, age, or disability are prohibited by law
                      and may result in legal action against both the advertiser and the publisher.
                      Alphy Johnson's Employment Center accepts ads only if the advertiser confirms
                      that any requirements or qualifications that may appear to discriminate follow
                      the relevant legislation's available exemptions. Despite this confirmation, if
                      we suspect that an advertisement may be discriminatory, we may, at our
                      discretion, either modify the ad or remove it from Alphy Johnson without any
                      obligation to refund any payments made or due in connection with the posting,
                      and we will notify you accordingly. 
                    </li>
                    <li>
                      The job posting must provide an accurate and honest description of the role
                      without misleading the Candidate. The posting should include enough
                      information for the Candidate to understand the nature of the role and its
                      basic requirements. If the job requires working in multiple locations, this
                      should be explicitly stated. If the job involves door-to-door sales, this
                      should also be mentioned. Similarly, if the job is commission-only, this must
                      be clearly stated in the posting. 
                    </li>
                  </ol>
                </p>
                <p>
                  18. To ensure the safety of our candidates from fraudulent job activities, we
                  strictly prohibit recruiters from soliciting any form of payment during the
                  recruitment process. Any reported incidents will be thoroughly investigated and
                  may result in the cancellation of your account without any refund. 
                </p>
                <p>
                  19. Your job listing(s) has the potential to be shared and displayed on our
                  network of partner sites. If you wish to exclude your job listing(s) from selected
                  or all partner sites, please contact your Client Response Coordinator. However,
                  please note that we cannot guarantee the following:  
                  <ol type="a">
                    <li>Your job posting(s) will appear on our entire network </li>
                    <li>The job posting(s) will appear on specific partner sites </li>
                    <li>The ranking/positioning of your job(s) on any of our partner sites </li>
                  </ol>
                </p>
                <p>
                  20. Job credits should be utilized within the fixed term, and no extension of time
                  will be given for job credits that have not been posted or are posted partially
                  during the fixed term. For instance, if you purchase a single job credit for a
                  one-month fixed term and it is posted on the 21st day, the job will expire on the
                  last day of the one-month fixed term. 
                </p>
                <p>
                  21. All information provided by us is confidential and you are responsible for
                  keeping your login credentials secure. You must not share your username and/or
                  password with anyone outside of your company. If such a disclosure occurs, we
                  reserve the right to terminate the agreement immediately and seek compensation.
                  Your password is for your use only, and it must be kept confidential and secure.
                  If we have reason to believe that your password is being used by an unauthorized
                  person or that you are breaching our terms and conditions, we may deny access to
                  your password. If you suspect or become aware that your password is or may be
                  known to anyone outside of your organization, you agree to notify us immediately.
                  Upon notification of such an event, we will deactivate the compromised password
                  and issue a new password via an agreed-upon method such as telephone, email, or
                  post. 
                </p>
                <p>
                  22. Unless caused by our negligence or deliberate misconduct, we are not
                  responsible for any losses, damages, or claims arising directly or indirectly from
                  the unauthorized acquisition of any passwords that we have provided to you. Our
                  liability to you for any services provided, except in cases of death or personal
                  injury caused by our negligence, is limited to the total amount paid to us for
                  such services during the Contract Term, or $10,000, whichever is greater.  
                </p>
                <p>
                  23. Alphy Johnson's Employment Center serves as a representative and assumes no
                  responsibility for the content of job advertisements posted by various
                  employers/agencies. If you have any concerns regarding a job advertisement that is
                  not your own, please address them directly and not through Alphy Johnson's
                  Employment Center. 
                </p>
                <p>
                  Payment Terms:
                  <ol type="a">
                    <li>
                      Prior to the commencement of the service, you agree to pay the full balance
                      (in your local currency) of your account unless a different payment structure
                      is agreed upon in writing. Payment can be made by check, credit/debit card, or
                      bank transfer. You acknowledge and agree that if you or the person responsible
                      for payment fails to make the payment, we may immediately terminate your
                      account and cancel this agreement. We may also seek payment through legal
                      action in a court of law. 
                    </li>
                    <li>
                      By using our service, you agree to the payment structure that has been agreed
                      upon with your account manager, and you understand that you will be charged
                      accordingly. All prices are listed in pounds sterling. 
                    </li>
                    <li>
                      By accepting this agreement, you acknowledge that we reserve the right to
                      modify the rates mentioned above. We will notify you of such changes at least
                      14 days prior to the effective date, however, the modified rates will only be
                      applicable after the completion of your Fixed Term. If you do not wish to
                      proceed with the agreement with the revised rates, you may terminate the
                      agreement at the end of your Fixed Term. 
                    </li>
                    <li>
                      We offer the option of paying for our services in monthly instalments through
                      Direct Debit. These Direct Debits will be collected in advance of service
                      usage by Debit Finance, and you will receive written notification of the
                      amount debited and the Fixed Term. In the event of a missed payment, you must
                      settle the outstanding amount immediately using BACS or credit card to avoid
                      suspension of your account and services. If two consecutive payments are
                      missed, your account will be cancelled, and all future Direct Debits will be
                      terminated. The remaining outstanding amount for the remainder of the Fixed
                      Term will become due immediately, and Alphy Johnson's Employment Center
                      reserves the right to pursue legal action to recover the outstanding balance. 
                    </li>
                    <li>
                      Please note that the prices mentioned do not include VAT. You are required to
                      make payments for all invoices as per the terms mentioned in your Service
                      Agreement. If there is a delay in payment, we reserve the right to suspend the
                      provision of services. Additionally, interest will be charged on overdue
                      payments at a rate of 3% above National Westminster Bank plc's base rate at
                      the relevant time. 
                    </li>
                  </ol>
                </p>
                <p>
                  24. We are unable to offer refunds if the Candidate does not meet the desired age,
                  sex, race, or nationality criteria as this would be in contradiction with our
                  equal opportunities policy and goes against common decency. 
                </p>
                <p>
                  25. This agreement shall remain in effect until terminated by either party,
                  provided that such termination is in compliance with the notice requirements set
                  forth in this section.
                  <ol type="a">
                    <li>
                      We reserve the right to terminate this agreement immediately and payment will
                      become due immediately of all outstanding amounts. Alphy Johnson's Employment
                      Center will have no further obligation to you, upon the occurrence of any of
                      the following events:
                      <ol type="i">
                        <li>In the event of your failure to pay any amount due to us.</li>
                        <li>
                          If you, being a limited company, convene a meeting of creditors or become
                          the subject of proceedings under the Insolvency Act of 1986, or are unable
                          to pay your debts within the meaning of section 123 of the Insolvency Act
                          of 1986, or if you cease to trade or go into liquidation, then we reserve
                          the right to terminate this agreement immediately.
                        </li>
                        <li>
                          If you, being an individual, are declared bankrupt or enter any
                          arrangement or compromise with your creditors, or if you cease to trade,
                          we reserve the right to terminate this agreement. 
                        </li>
                        <li>
                          If the Recruiter is found to be in material breach of these terms and
                          conditions and has failed to remedy such breach within ten days of
                          receiving notice that specifies the breach and requires its remedy, then
                          this agreement may be terminated. 
                        </li>
                      </ol>
                    </li>
                    <li>
                      The Recruiter has the option to terminate the provision of Services by
                      providing written notice to us: 
                      <ol type="i">
                        <li>
                          The termination shall take effect upon the expiration of the Fixed Term. 
                        </li>
                        <li>
                          If we are found to be in material breach of these terms and conditions and
                          fail to remedy such breach within ten days of receiving notice specifying
                          the breach and requiring its remedy, or: 
                        </li>
                        <li>If we become insolvent, cease to trade, or go into liquidation, </li>
                        <li>
                          If the Customer terminates the agreement before the end of the Fixed Term,
                          we reserve the right, at our discretion, to withdraw any discounts that
                          were agreed upon for the entire Fixed Term. 
                        </li>
                      </ol>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </section>

          <div className="heading__style">
            <h1>Terms & Conditions</h1>
          </div>
          <div className="terms_nav_line">
            <div className="links__1">
              <a className="links" href="#General">
                General Terms
              </a>
            </div>
            <div className="links__1">
              <a className="links" href="#Additional">
                Supplementary Terms for Candidates
              </a>
            </div>
            <div className="links__1">
              <a className="links" href="#Recruiters">
                Supplementary Terms for Recruiters{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Brand;
