import React, { useEffect, useRef, useState } from "react";
import "./register.css";
import "../../components/button/button.css";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import Navbar3 from "../../components/navbar3/navbar3";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Details = () => {
  const [role, setRole] = useState("employer");
  const name = useRef();
  const email = useRef();
  const companyName = useRef();
  const address = useRef();
  const postcode = useRef();
  const town = useRef();
  const country = useRef();
  const [phone, setPhone] = useState();
  const [isValid, setIsValid] = useState(false);
  const buisnessType = useRef();
  const buisnessSize = useRef();
  const [countryNames, setNames] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    try {
      const fetchCountries = async () => {
        await axios.get("https://restcountries.com/v3.1/all").then((res) => {
          const array = res.data;
          const namesArray = [];
          array.forEach((country) => {
            namesArray.push(country?.name?.common);
          });
          namesArray.sort();
          setNames(namesArray);
        });
      };

      fetchCountries();
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, []);

  const handleSubmit = async () => {
    if (!isValid) {
      setError(true);
      setErrorMessage("Please enter a valid phone number to continue");
      return;
    }
    const data = {
      name: name.current.value,
      email: email.current.value,
      companyName: companyName?.current?.value,
      address: address.current.value,
      postcode: postcode.current.value,
      town: town.current.value,
      country: country.current.value,
      phone: phone,
      buisnessType: buisnessType.current.value,
      buisnessSize: buisnessSize.current.value,
    };
    Object.entries(data).forEach(([key, value]) => {
      if (value === "" || value === "Please Select ") {
        setError(true);
      }
    });
    if (error) {
      setErrorMessage(`Please fill all the fields to continue`);
      return;
    }
    // console.log(data);
    setIsLoading(true);
    let route;
    if (role === "employer") route = `/employers/checkout/${id}`;
    if (role === "company") route = `/companies/checkout/${id}`;
    try {
      await axios.post(route, data, { withCredentials: true }).then((res) => {
        setSuccess(true);
        setSuccessMessage("Success");
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = `${res?.data?.session?.url}`;
        }, 3000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const phoneRegex = /^[0-9]{11}$/;
    const isValidPhoneNumber = phoneRegex.test(inputValue);
    setPhone(inputValue);
    setIsValid(isValidPhoneNumber);
  };

  const viewPolicy = () => {
    window.location.href = "/privacy-policy";
  };

  const viewTerms = () => {
    window.location.href = "/terms-and-conditions";
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="">
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details about you to build your profile.</p>

          <div className="register_form">
            <h3 className="register_form_h3_start">Your Role</h3>
            <hr />
            <form>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Role</label>
                  <br />
                  <select
                    name=""
                    id=""
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                  >
                    <option value="Please Select ">Please Select</option>
                    <option value="employer">Employer</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Your Details</h3>
            <hr />

            <form>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Name</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    ref={name}
                    required
                  ></input>
                </div>
              </div>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Email</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="email"
                    ref={email}
                    required
                  ></input>
                </div>
              </div>
            </form>
          </div>
          <div className="register_form">
            <h3 className="register_form_h3_start">Billing Details</h3>

            <hr />
            <form>
              {role === "employer" && (
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Company Name</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      ref={companyName}
                      required
                    ></input>
                  </div>
                </div>
              )}

              <div className="register_form_div">
                <div className="register_options">
                  <label>Address</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    ref={address}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Postcode</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="email"
                    ref={postcode}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Town</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    ref={town}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Country</label>
                  <br />
                  <select name="" id="" ref={country} required>
                    <option value="Please Select ">Please Select</option>
                    {countryNames.map((name) => {
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Telephone</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    value={phone}
                    onChange={handlePhoneNumberChange}
                    required
                  ></input>
                  {isValid ? (
                    <p style={{ color: "green" }}>Valid phone number</p>
                  ) : (
                    <p style={{ color: "red" }}>Invalid phone number</p>
                  )}
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Buisness Type</label>
                  <br />
                  <select name="" id="" ref={buisnessType} required>
                    <option value="Please Select ">Please Select</option>
                    <option value="employer agency">Employer Agency</option>
                    <option value="direct employer">Direct Employer</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Buisness Size</label>
                  <br />
                  <select name="" id="" ref={buisnessSize} required>
                    <option value="Please Select ">Please Select</option>
                    <option value="1-100">1-100</option>
                    <option value="100-500">100-500</option>
                    <option value="500+">500+</option>
                  </select>
                </div>
              </div>
            </form>

            <div className="register_form_div">
              <input type="checkbox" />
              <p className="register_terms_conditions">
                By registering with Alphy Johnson's Employment Center you agree
                to our
                <span onClick={viewPolicy}> privacy policy </span> and
                <span onClick={viewTerms}> Term & Conditions</span>{" "}
              </p>
            </div>
          </div>
          <div className="button_maindiv">
            <button onClick={handleSubmit}>Checkout Securely</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Details;
