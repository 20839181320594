import React from "react";
import * as api from "../ApiCalls";
import { useAuthContext } from "../context/AuthContext";

const useLocationDetails = () => {
  const { user } = useAuthContext();

  React.useEffect(() => {
    // Get Location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          addDevice(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const addDevice = async (latitude, longitude) => {
    try {
      await api.addDevice(user?.role, {
        userAgent: navigator.userAgent,
        location: {
          lat: latitude,
          long: longitude,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {};
};

export default useLocationDetails;
