import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import cv_logo from "../../images/johan.png";
import "./cv_navbar.css";
import { useAuthContext } from "../../context/AuthContext";
import LazyImage from "../../components/common/LazyImage";

function Cv_navbar() {
  const { user } = useAuthContext();
  const [isColapsed, setIsColapsed] = useState(false);

  return (
    <div className={isColapsed ? "cv_navbar_collapsed" : ""}>
      <nav className="navbar navbar-expand-lg navbar-light cvdatabase_navbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <LazyImage className="img-fluid cvdatabase_logo" src={cv_logo} alt="cv logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setIsColapsed(!isColapsed)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav cv_navbar_collapse">
              <li className="nav-item">
                <Link
                  className="nav-link  fw-bold text-white"
                  aria-current="page"
                  to="/online-recruitment"
                  style={{ fontSize: "14px", fontFamily: "sans-serif" }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link  fw-bold text-white"
                  aria-current="page"
                  to="/job-advertisement"
                  style={{ fontSize: "14px", fontFamily: "sans-serif" }}
                >
                  Advertise Jobs
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link fw-bold text-white"
                  to="/searchcv"
                  style={{ fontSize: "14px", fontFamily: "sans-serif" }}
                >
                  Search Resume/CVs
                </Link>
              </li>

              <div className="navbar-nav Navbar_finds_jobs ">
                <li className="nav-item">
                  <Link
                    className="nav-link  fw-bold text-white"
                    aria-current="page"
                    to="/job-recruiting-websites"
                    style={{ fontSize: "12px", fontFamily: "sans-serif" }}
                  >
                    Find Jobs
                  </Link>
                </li>
                {!user && (
                  <li className="nav-item">
                    <Link
                      className="nav-link  fw-bold text-white"
                      aria-current="page"
                      to="/login"
                      style={{ fontSize: "12px", fontFamily: "sans-serif" }}
                    >
                      Login
                    </Link>
                    {/* <i className="bx bx-search-alt-2"></i> */}
                  </li>
                )}
                <Outlet />
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Cv_navbar;
