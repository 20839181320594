import React from "react";
import CvNavbar from "./cvdatabase_navbar/Cv_navbar";
import HeaderCvDatabase from "./header_cvdatabase/Header_cvdatabase";
import MainCvDatabase from "./main_cvdatabase/Main_cvdatabase";
import Footer from "../components/footer/footer";
import { Helmet } from "react-helmet";

function Cvdatabase() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/online-recruitment" />
      </Helmet>

      <CvNavbar />
      <HeaderCvDatabase />
      <MainCvDatabase />
      <Footer />
    </div>
  );
}

export default Cvdatabase;
