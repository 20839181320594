import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "./ProfileLeft";
import Footer from "../../components/footer/footer";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Logout from "./Logout";
import { useProfileContext } from "../../context/ProfileContext";

function TeamMembers() {
  const { user } = useAuthContext();
  const { state } = useProfileContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  if (!user || user?.role !== "company") {
    window.location.href = "/register";
  }

  useEffect(() => {
    setPagePosts(state?.team?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state?.team?.length / postsPerPage));
  }, [state?.team, postsPerPage]);

  const viewProfile = (id) => {
    window.location.href = `/view-profile/${id}?role=employer`;
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state.team?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <>
      <Navbar3 />
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          <div className="recommend" id="c2">
            {user?.role === "company" && (
              <>
                {state?.team?.length === 0 ? (
                  <div className="application_main_div">
                    <div className="application d-flex flex-column justify-content-center align-items-center">
                      <p>You have not any team Member yet.</p>
                      <p>Search and Hire Recruiters Now.</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <h3 className="details_heading">Team Members</h3>
                    <div className="application_main_div">
                      {pagePosts?.map((application) => (
                        <div className="application" key={application?._id}>
                          <div className="application_para-1">
                            <div>
                              <h3 className="job-title">{application?.name}</h3>
                              <button
                                className="btn btn-primary"
                                onClick={() => viewProfile(application?._id)}
                              >
                                View Profile
                              </button>
                            </div>
                            <p>
                              <b>CompanyName: </b> {application?.companyName}
                            </p>
                          </div>
                          <div className="application_para-1">
                            <p>
                              <b>Phone: </b> {application?.phone}
                            </p>
                            <p>
                              <b>Email: </b> {application?.email}
                            </p>
                          </div>
                          {application?.linkedInUrl && (
                            <p>
                              <b>LinkedIn Profile: </b>{" "}
                              <a
                                href={`${application?.linkedInUrl}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Visit LinkedIn Profile
                              </a>
                            </p>
                          )}

                          <p>
                            <b>Address: </b> {application?.address}
                          </p>
                          <p>
                            <b>Country: </b> {application?.country}
                          </p>
                        </div>
                      ))}
                    </div>
                    {pagePosts?.length >= 1 && (
                      <div>
                        <div className="pagination">
                          <div
                            className="btn btn-primary"
                            onClick={() => paginate("prev")}
                          >
                            <FaLessThan />
                            <FaLessThan />
                          </div>
                          <div
                            className={
                              currentPage === 1
                                ? "btn btn-primary active_page_btn"
                                : "btn btn-primary"
                            }
                            onClick={() => paginate(1)}
                          >
                            1
                          </div>
                          {currentPage !== 1 && (
                            <div
                              className="btn btn-primary active_page_btn"
                              onClick={() => paginate(currentPage)}
                            >
                              {currentPage}
                            </div>
                          )}
                          {totalPages >= 2 && (
                            <>
                              <div className="btn btn-primary">...</div>
                              <div
                                className="btn btn-primary"
                                onClick={() => paginate(totalPages)}
                              >
                                {totalPages}
                              </div>
                            </>
                          )}
                          <div
                            className="btn btn-primary"
                            onClick={() => paginate("next")}
                          >
                            <FaGreaterThan />
                            <FaGreaterThan />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <Logout />
      </div>
      <Footer />
    </>
  );
}

export default TeamMembers;
