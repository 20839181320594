import React, { useState, useEffect } from "react";
import "./perks.css";
import LazyImage from "../common/LazyImage";

const Perks = ({ img, heading, p, onclick }) => {
  const [scrollDirection, setScrollDirection] = useState("down");
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > previousScrollPosition) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      previousScrollPosition = currentScrollPosition;
    };

    let previousScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      onClick={onclick}
      className={`animate__animated ${
        scrollDirection === "down" ? "animate__backInLeft" : "animate__backInRight"
      }`}
    >
      <div className="perks_maindiv hover-effect">
        <LazyImage src={img} alt={heading} />

        <h2>{heading}</h2>
        <p>{p}</p>
      </div>
    </div>
  );
};

export default Perks;
