import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../../components/alert/toastAlert";

const UserLogs = () => {
  const { state, fetchAllLogs } = useAdminPanelContext();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    setData(state?.userLogs);
    setPagePosts(state?.userLogs?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.userLogs?.length / postsPerPage));
  }, [state?.userLogs, postsPerPage]);

  const deleteLog = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/adminPanel/logs/${id}`);
      setSuccess(true);
      setSuccessMessage("Log deleted successfully!");
      await fetchAllLogs();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = data?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  const handleFilterChange = (id) => {
    if (id === "") {
      setData(state?.userLogs);
      setPagePosts(state?.userLogs?.slice(0, postsPerPage));
      setTotalPages(Math.ceil(state.userLogs?.length / postsPerPage));
      return;
    }
    const newData = state?.userLogs.filter((obj) => obj?.user?._id === id);
    setData(newData);
    setPagePosts(newData?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(newData?.length / postsPerPage));
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="messages">
        <div>
          <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
            User Logs
          </h3>
          <select
            className="form-control"
            style={{
              float: "right",
              maxWidth: "30%",
              width: "fit-content",
              margin: "0 5px 10px",
            }}
            onChange={(e) => handleFilterChange(e.target.value)}
          >
            <option value={""}>Select User</option>
            {state?.managementUsers.map((item) => {
              return (
                <option key={item?._id} value={item?._id}>
                  {item?.firstName + " " + item?.lastName}
                </option>
              );
            })}
          </select>
        </div>

        {pagePosts?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Method</th>
                <th>Route</th>
                <th>IP</th>
                <th>Device Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pagePosts?.map((item, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                  <td>{item?.user?.firstName + " " + item?.user?.lastName}</td>
                  <td>{item?.method}</td>
                  <td>{item?.route}</td>
                  <td>{item?.ip}</td>
                  <td>{item?.userAgent}</td>
                  <td>
                    <button onClick={() => deleteLog(item?._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No User Logs yet!!</p>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserLogs;
