import React from "react";
import "./button.css";

const button = ({onclick, text}) => {
     
    return (
        <div className="button_maindiv">
            <button onClick={onclick}>{text}</button>
        </div>
    )
}

export default button;