import React, { useState, useEffect } from "react";
import axios from "../../axios";
// import header_pic from "../../images/header.png";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

function Header() {
  const { user } = useAuthContext();
  const [formData, setFormData] = useState({
    keywords: "",
    location: "",
    radius: "",
  });
  const [jobAlerts, setJobAlerts] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    const fetchJobAlerts = async () => {
      try {
        const response = await axios.get("/jobs/job-alerts");
        setJobAlerts(response.data);
        // console.log(response.data);
        // console.log(jobAlerts);
      } catch (error) {
        console.log("An error occurred while fetching job alerts:", error);
      }
    };

    if (user && user?.role === "jobseeker") fetchJobAlerts();
  }, [user]);

  const handleSubmit = async () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    try {
      setIsLoading(true);
      await axios.post("/jobs/job-alerts", formData);
      setSuccess(true);
      setSuccessMessage("Job Alert created");
      setTimeout(() => {
        setIsLoading(false);
        window.location.reload();
      }, 1500);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const hanldeJobseekerClicks = () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      window.location.href = "/profile/jobalert1";
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="container-fluid header">
        <div className="container d-flex justify-content-between align-items-center header1 flex-wrap">
          <div className="w-50 jobalert_left_div">
            <h1 className="text-white fs-1">
              Stay Updated With Latest Job Alerts!
            </h1>
            <p className="text-white fs-5">
              Subscribe to Our Job Alerts Today - Stay Informed on the Latest
              Opportunities Tailored Just for You. Let Our Service Connect You
              with Perfect Jobs Matching Your Skills, Aspirations, and Career
              Goals.
            </p>
            <p className="text-white">
              Are you already registered?{" "}
              <span
                onClick={hanldeJobseekerClicks}
                className="text-white"
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Take a look at your Job Alerts.
              </span>
            </p>
          </div>
          <div
            className="right-header bg-light container"
            style={{
              marginTop: "50px",
              height: "484px",
            }}
          >
            <div className="container mt-3">
              <p className="fw-bold fs-4 text-center">Create Job Alert</p>
              <div className="mb-3">
                <label htmlFor="keywords" className="form-label">
                  Keywords/Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  // id="keywords"
                  name="keywords"
                  placeholder="keywords"
                  value={formData.keywords}
                  onChange={(e) =>
                    setFormData({ ...formData, keywords: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="location" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  name="location"
                  placeholder="location"
                  value={formData.location}
                  onChange={(e) =>
                    setFormData({ ...formData, location: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label for="pet-select">Within</label>

                <select
                  id="pet-select"
                  className="form-control"
                  value={formData.radius}
                  onChange={(e) =>
                    setFormData({ ...formData, radius: e.target.value })
                  }
                >
                  <option value="">Select Miles</option>
                  <option value="4">4 Miles</option>
                  <option value="6">6 Miles</option>
                  <option value="8">8 Miles</option>
                  <option value="11">11 Miles</option>
                  <option value="12">12 Miles</option>
                  <option value="23">23 Miles</option>
                </select>
              </div>
              <div className="text-center">
                <input type="checkbox" required />
              </div>
              <p className="pt-3">
                By creating a job alert with Alphy Johnson's Employment Center
                you agree to our <a href="/privacy-policy">Privacy Policy </a> and{" "}
                <a href="/terms-and-conditions">Terms & Conditions</a>
              </p>

              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary fw-bold "
                  onClick={handleSubmit}
                >
                  Create Job Alert
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {jobAlerts && (
        <div className="container mt-4">
          <h3>Job Alert Details</h3>
          <p>Keywords: {jobAlerts.keywords}</p>
          <p>Location: {jobAlerts.location}</p>
          <p>Email: {jobAlerts.email}</p>
        </div>
      )} */}
      {user?.role === "jobseeker" && (
        <div className="container d-flex flex-column justify-content-center align-items-center">
          {jobAlerts.length === 0 ? (
            <p>No job alerts found</p>
          ) : (
            <div className="job-alerts">
              <h3>Job Alert Details</h3>
              {jobAlerts.map((jobsAlert) => (
                <div className="job-alert" key={jobsAlert._id}>
                  <p>Keywords: {jobsAlert.keywords.join(", ")}</p>
                  <p>Location: {jobsAlert.location}</p>
                  <p>Radius: {jobsAlert.radius} Miles</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
