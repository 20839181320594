import React, { useEffect, useState } from "react";
// import "./register.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { Industries } from "../../constants/GlobalConstants";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const BuildCV = () => {
  const { user } = useAuthContext();
  const [countryNames, setNames] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    companyName: user?.companyName || null,
    name: user?.name || null,
    website: user?.website || "",
    phone: user?.phone || null,
    firstName: user?.firstName || null,
    lastName: user?.lastName || null,
    jobTitle: user?.jobTitle || null,
    address: user?.address || null,
    email: user?.email || null,
    postcode: user?.postcode || null,
    town: user?.town || null,
    country: user?.country || null,
    buisnessType: user?.buisnessType || null,
    buisnessSize: user?.buisnessSize || null,
    linkedInUrl: user?.linkedInUrl || null,
    details: {
      contactNo: user?.details?.contactNo || null,
      expectedSalary: user?.details?.expectedSalary || null,
      jobType: user?.details?.jobType || null,
    },
    preferences: {
      industries: user?.preferences?.industries || null,
      Availability: user?.preferences?.Availability || null,
      travelDistance: user?.preferences?.travelDistance || null,
      relocate: user?.preferences?.relocate || null,
      drivingLiscence: user?.preferences?.drivingLiscence || null,
    },
    languages: user?.languages || [""],
    skills: user?.skills || [""],
  });

  const jobtypes = ["Permanent", "Contract", "Temporary", "Part Time"];

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/register";
    }

    try {
      const fetchCountries = async () => {
        await axios.get("https://restcountries.com/v3.1/all").then((res) => {
          const array = res.data;
          const namesArray = [];
          array.forEach((country) => {
            namesArray.push(country?.name?.common);
          });
          namesArray.sort();
          setNames(namesArray);
        });
      };

      fetchCountries();
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, [user]);

  const handleSubmit = async () => {
    // console.log(userDetails);
    let route;
    const role = user?.role;
    switch (role) {
      case "jobseeker":
        route = "/candidates/me";
        break;
      case "employer":
        route = "/employers/me";
        break;
      case "company":
        route = "/companies/me";
        break;
      case "user":
        route = "/users/me";
        break;
      default:
        alert("error");
        break;
    }
    // console.log(route);
    try {
      setIsLoading(true);
      delete userDetails.email;
      await axios.patch(route, userDetails).then((res) => {
        console.log(res.data?.data);
        const newUser = res.data?.data;
        localStorage.setItem("user", JSON.stringify(newUser));
        setSuccess(true);
        setSuccessMessage("User Updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/profile";
        }, 2000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleInputChange = (name, value) => {
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (index, event, arrayName) => {
    const newValues = { ...userDetails };
    const { value } = event.target;
    newValues[arrayName][index] = value;
    // console.log(newValues);
    setUserDetails(newValues);
  };

  const handleAddInput = (arrayName) => {
    const newValues = { ...userDetails };
    newValues[arrayName].push("");
    // console.log(newValues);
    setUserDetails(newValues);
  };

  const handleRemoveInput = (index, arrayName) => {
    const newValues = { ...userDetails };
    newValues[arrayName]?.splice(index, 1);
    // console.log(newValues);
    setUserDetails(newValues);
  };

  const removeSectionValue = (index, section, field) => {
    const newValues = { ...userDetails };
    newValues[section][field]?.splice(index, 1);
    // console.log(newValues);
    setUserDetails(newValues);
  };

  const handleSectionChange = (event, section, field) => {
    const newCvDetails = { ...userDetails };
    const { value } = event.target;
    if (section === "details" || section === "preferences") {
      if (field === "jobType") {
        if (event.target.checked) {
          newCvDetails[section][field].push(value);
        } else {
          const updatedJobTypes = userDetails.details.jobType.filter(
            (jobType) => jobType !== value
          );
          newCvDetails[section][field] = updatedJobTypes;
        }
      } else if (field === "industries") {
        if (userDetails?.preferences?.industries.length >= 5) {
          alert("Only 5 industries can be selected");
          return;
        }
        if (value && !userDetails?.preferences?.industries.includes(value)) {
          newCvDetails[section][field].push(value);
        }
      } else newCvDetails[section][field] = value;
    }
    setUserDetails(newCvDetails);
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="">
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details about you to build your cv.</p>

          <div className="register_form">
            <h3 className="register_form_h3_start">Personal Details</h3>
            <hr />
            <form>
              {user?.role === "employer" && (
                <>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Company Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.companyName}
                        onChange={(e) =>
                          handleInputChange("companyName", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>LinkedIn Url</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.linkedInUrl}
                        onChange={(e) =>
                          handleInputChange("linkedInUrl", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>
                </>
              )}

              {(user?.role === "employer" || user?.role === "company") && (
                <>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.name}
                        onChange={(e) =>
                          handleInputChange("name", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Phone</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.phone}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>
                </>
              )}

              {user?.role === "jobseeker" && (
                <>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>First Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.firstName}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Last Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.lastName}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Job Title</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.jobTitle}
                        onChange={(e) =>
                          handleInputChange("jobTitle", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>
                </>
              )}

              <div className="register_form_div">
                <div className="register_options">
                  <label>Email</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="email"
                    value={userDetails?.email}
                    disabled
                  ></input>
                </div>
              </div>

              {user?.role === "company" && (
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Website Url</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={userDetails?.website}
                      onChange={(e) =>
                        handleInputChange("website", e.target.value)
                      }
                    ></input>
                  </div>
                </div>
              )}
            </form>
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Billing Details</h3>
            <hr />
            <form>
              {(user?.role === "employer" || user?.role === "company") && (
                <>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Address</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.address}
                        onChange={(e) =>
                          handleInputChange("address", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Town</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        value={userDetails?.town}
                        onChange={(e) =>
                          handleInputChange("town", e.target.value)
                        }
                        required
                      ></input>
                    </div>
                  </div>
                </>
              )}

              <div className="register_form_div">
                <div className="register_options">
                  <label>Postcode</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    value={userDetails?.postcode}
                    onChange={(e) =>
                      handleInputChange("postcode", e.target.value)
                    }
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Country</label>
                  <br />
                  <select
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                    name=""
                    id=""
                    value={userDetails?.country}
                    required
                  >
                    <option value="Please Select ">Please Select</option>
                    {countryNames.map((name) => {
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </form>
          </div>

          {user?.role !== "jobseeker" && (
            <div className="register_form">
              <h3 className="register_form_h3_start">Buisness Details</h3>
              <hr />

              <form>
                {user?.role === "employer" && (
                  <>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Buisness Type</label>
                        <br />
                        <select
                          onChange={(e) =>
                            handleInputChange("buisnessType", e.target.value)
                          }
                          name=""
                          id=""
                          value={userDetails?.buisnessType}
                          required
                        >
                          <option value="Please Select ">Please Select</option>
                          <option value="employer agency">
                            Employer Agency
                          </option>
                          <option value="direct employer">
                            Direct Employer
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {user?.role === "company" && (
                  <>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Buisness Type</label>
                        <br />
                        <select
                          onChange={(e) =>
                            handleInputChange("buisnessType", e.target.value)
                          }
                          name=""
                          id=""
                          value={userDetails?.buisnessType}
                          required
                          disabled
                        >
                          <option value="Please Select ">Please Select</option>
                          <option value="employer agency">
                            Employer Agency
                          </option>
                          <option value="direct employer">
                            Direct Employer
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {(user?.role === "employer" || user?.role === "company") && (
                  <>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Buisness Size</label>
                        <br />
                        <select
                          name=""
                          id=""
                          value={userDetails?.buisnessSize}
                          onChange={(e) =>
                            handleInputChange("buisnessSize", e.target.value)
                          }
                          required
                        >
                          <option value="Please Select ">Please Select</option>
                          <option value="1-100">1-100</option>
                          <option value="100-500">100-500</option>
                          <option value="500+">500+</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          )}

          {user?.role === "jobseeker" && (
            <div className="register_form">
              <h3 className="register_form_h3_start">Preferences</h3>
              <hr />

              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Contact No</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={userDetails?.details?.contactNo}
                      onChange={(e) =>
                        handleSectionChange(e, "details", "contactNo")
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Expected Salary</label>
                    <br />
                    <select
                      onChange={(e) =>
                        handleSectionChange(e, "details", "expectedSalary")
                      }
                      name=""
                      id=""
                      value={userDetails?.details?.expectedSalary}
                      required
                    >
                      <option value="Please Select ">Please Select</option>
                      <option value="1$-100$">1$ - 100$</option>
                      <option value="100$-200$">100$ - 200$</option>
                      <option value="200$-300$">200$ - 300$</option>
                    </select>
                  </div>
                </div>

                <div className="register_div_jobtype">
                  <p>Job Types</p>
                  {jobtypes.map((type) => {
                    return (
                      <label key={type} htmlFor={type}>
                        <input
                          type="checkbox"
                          id={type}
                          name=""
                          value={type}
                          checked={userDetails?.details?.jobType?.includes(
                            type
                          )}
                          onChange={(e) =>
                            handleSectionChange(e, "details", "jobType")
                          }
                        />
                        {type}
                      </label>
                    );
                  })}
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Availability</label>
                    <br />
                    <select
                      name=""
                      id=""
                      value={userDetails?.preferences?.Availability}
                      onChange={(e) =>
                        handleSectionChange(e, "preferences", "Availability")
                      }
                      required
                    >
                      <option value="Please Select ">Please Select</option>
                      <option value="immediately">Immediately</option>
                      <option value="1 week">1 week</option>
                      <option value="2 weeks">2 weeks</option>
                      <option value="3 weeks">3 weeks</option>
                      <option value="1 month">1 month</option>
                      <option value="2 months">2 months</option>
                      <option value="3 months+">3 months+</option>
                    </select>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Travel Distance</label>
                    <br />
                    <select
                      name=""
                      id=""
                      value={userDetails?.preferences?.travelDistance}
                      onChange={(e) =>
                        handleSectionChange(e, "preferences", "travelDistance")
                      }
                      required
                    >
                      <option value="Please Select ">Please Select</option>
                      <option value="immediately">Immediately</option>
                      <option value="10 miles">10 miles</option>
                      <option value="20 miles">20 miles</option>
                      <option value="30 miles">30 miles</option>
                      <option value="50 miles">50 miles</option>
                      <option value="100 miles">100 miles</option>
                      <option value="200 miles">200 miles</option>
                      <option value="anywhere">Anywhere</option>
                    </select>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Preferred Industries</label>
                    <br />
                    <select
                      value=""
                      onChange={(e) =>
                        handleSectionChange(e, "preferences", "industries")
                      }
                    >
                      <option value="" disabled hidden>
                        Select a value
                      </option>
                      {Industries.sort().map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <div className="selected_ind">
                      Selected Industries:
                      {userDetails.preferences?.industries.map(
                        (value, index) => (
                          <>
                            <p key={index}>{value}</p>
                            <button
                              className="cv_build_btn"
                              onClick={() =>
                                removeSectionValue(
                                  index,
                                  "preferences",
                                  "industries"
                                )
                              }
                            >
                              Remove
                            </button>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Willing to Relocate?</label>
                    <br />
                    <select
                      name=""
                      id=""
                      value={userDetails?.preferences?.relocate}
                      onChange={(e) =>
                        handleSectionChange(e, "preferences", "relocate")
                      }
                      required
                    >
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                  </div>
                  <div className="register_options">
                    <label>Driving Licence?</label>
                    <br />
                    <select
                      name=""
                      id=""
                      value={userDetails?.preferences?.drivingLiscence}
                      onChange={(e) =>
                        handleSectionChange(e, "preferences", "drivingLiscence")
                      }
                      required
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          )}

          {user?.role === "jobseeker" && (
            <div className="register_form">
              <h3 className="register_form_h3_start">Skills and Languages</h3>

              <hr />
              <form>
                <div className="register_form_div">
                  {userDetails?.skills?.map((item, index) => {
                    return (
                      <div key={index} className="register_options">
                        <label>Skill</label>
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={item}
                          required
                          onChange={(e) => handleChange(index, e, "skills")}
                        />
                        <button
                          className="cv_build_btn"
                          onClick={() => handleRemoveInput(index, "skills")}
                        >
                          Remove Skill
                        </button>
                      </div>
                    );
                  })}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddInput("skills")}
                  >
                    Add Skill
                  </button>
                </div>

                <div className="register_form_div">
                  {userDetails?.languages?.map((item, index) => {
                    return (
                      <div key={index} className="register_options">
                        <label>Language</label>
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={item}
                          required
                          onChange={(e) => handleChange(index, e, "languages")}
                        />
                        <button
                          className="cv_build_btn"
                          onClick={() => handleRemoveInput(index, "languages")}
                        >
                          Remove Language
                        </button>
                      </div>
                    );
                  })}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddInput("languages")}
                  >
                    Add Language
                  </button>
                </div>
              </form>
            </div>
          )}

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Update Profile</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BuildCV;
