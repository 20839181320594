import React, { useState, useEffect, useCallback } from "react";
import axios from "../../axios";
// import "./applyNow.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

function ApplyNow() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [cv, setCV] = useState();
  const [job, setJob] = useState();
  const [phone, setPhone] = useState(null);
  const [coverLetters, setCoverLetters] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(null); // Added selectedLetter state
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchJobDetails = useCallback(async () => {
    const response = await axios.get(`/jobs/${id}`);
    setJob(response.data);
    // console.log(response.data);
  }, [id]);

  useEffect(() => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login first to continue");
      setTimeout(() => {
        window.location.href = "/candidate/login";
      }, 2000);
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      setTimeout(() => {
        window.location.href = "/candidate/login";
      }, 2000);
      return;
    }
    try {
      const fetchData = async () => {
        setIsFetching(true);
        await fetchJobDetails();
        await fetchCoverLetters();
        setIsFetching(false);
      };

      fetchData();
    } catch (error) {
      console.log(error.response);
      setIsFetching(false);
    }
  }, [user, fetchJobDetails]);

  const fetchCoverLetters = async () => {
    const response = await axios.get("/coverLetters/view");
    setCoverLetters(response.data.docs);
  };

  const handleCoverLetterChange = (event) => {
    const selectedCoverLetter = coverLetters.find(
      (letter) => letter._id === event.target.value
    );
    // console.log(selectedCoverLetter);
    setSelectedLetter(selectedCoverLetter);
  };

  const submitApplication = async () => {
    if (!cv || !phone || !selectedLetter) {
      setError(true);
      setErrorMessage("Please fill all the fields to apply for job");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("cv", cv);
    formData.append("phone", phone);
    formData.append("coverLetter", selectedLetter?._id);
    try {
      await axios.post(`/jobs/apply/${job?._id}`, formData);
      setSuccess(true);
      setSuccessMessage("Application submitted successfully!");
      setTimeout(() => {
        setIsLoading(false);
        navigate(-1);
      }, 2000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div>
        <section>
          <div className="container update_password_container">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-9">
                <h3 className="mb-4 text-center">Apply Now</h3>

                {isFetching ? (
                  <div className="container d-flex justify-content-center align-items-center section-padding">
                    <Loader />
                  </div>
                ) : (
                  <div
                    className="card text-white"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "var(--blue)",
                    }}
                  >
                    <div className="card-body">
                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Job Title:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="jobTitle"
                            value={job?.jobTitle}
                            placeholder="Job Title"
                            disabled
                            required
                          />
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Job Location:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="jobTitle"
                            value={job?.location}
                            placeholder="Job location"
                            disabled
                            required
                          />
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Upload CV:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <input
                            className="form-control form-control-lg"
                            type="file"
                            name="cv"
                            onChange={(e) => setCV(e.target.files[0])}
                            required
                          />
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Phone:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="phone"
                            required
                          />
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Your Cover Letters:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <select
                            className="form-control form-control-lg"
                            name="coverLetter"
                            onChange={handleCoverLetterChange}
                          >
                            <option value="">Select a cover letter</option>
                            {coverLetters.map((letter) => (
                              <option key={letter._id} value={letter?._id}>
                                {letter.coverLetterName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="row align-items-center py-3 input-field">
                        <div className="col-md-3 input_field_label">
                          <h6 className="mb-3">Selected Cover Letter:</h6>
                        </div>
                        <div className="col-md-9 pe-5">
                          <textarea
                            className="form-control form-control-lg"
                            name="coverLetterContent"
                            value={selectedLetter?.coverLetterContent}
                            disabled
                          />
                        </div>
                      </div>

                      <hr className="mx-n3" />

                      <div className="px-5 py-4 text-center">
                        <button
                          type="submit"
                          className="btn-lg"
                          onClick={submitApplication}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ApplyNow;
