import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import { Industries } from "../../constants/GlobalConstants";

function JobSearch({ isHome = true }) {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [miles, setMiles] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [salaryType] = useState("");
  const [jobType, setJobType] = useState("");
  const [industry, setIndustry] = useState("");
  const [isMore, setIsMore] = useState(true);

  const handleSearch = async () => {
    try {
      const encodedKeyword = encodeURIComponent(keyword);
      const encodedLocation = encodeURIComponent(location);
      const encodedMiles = encodeURIComponent(miles);
      const encodedMinSalary = encodeURIComponent(minSalary);
      const encodedMaxSalary = encodeURIComponent(maxSalary);
      const encodedSalaryType = encodeURIComponent(salaryType);
      const encodedJobType = encodeURIComponent(jobType);
      const encodedIndustry = encodeURIComponent(industry);

      const queryParams = new URLSearchParams({
        keyword: encodedKeyword,
        location: encodedLocation,
        miles: encodedMiles,
        minSalary: encodedMinSalary,
        maxSalary: encodedMaxSalary,
        salaryType: encodedSalaryType,
        jobType: encodedJobType,
        industry: encodedIndustry,
      });
      navigate(`/job-recruiting-websites?${queryParams}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <motion.section
      id="jobsearch"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 3 }}
    >
      <div className="container-fluid">
        <div className="jobsrarchtitle  text-capitalize">
          {isHome ? (
            <h1 className="search_job_bar_heading">
              Discover Your <span className="homepage_title_alt">Next Career</span> with USA’s
              Leading & <span className="homepage_title_alt">Best Job Search</span> Engine
            </h1>
          ) : (
            <h1 className="search_job_bar_heading">
              Explore <span className="homepage_title_alt">Employment</span> opportunity with{" "}
              <span className="homepage_title_alt">Alphy</span> Johnson
            </h1>
          )}
          <Button onclick={handleSearch} text={"See All Jobs"} />
          {/* <button
            className="btn btn-info"
            style={{ color: "white", marginRight: "3rem" }}
            onclick={() => navigate("/recruiter")}
          >
            See All Jobs
          </button> */}
        </div>
        <div
          className={
            isMore ? "homepage_search_toggle flex-wrap" : "homepage_search_not_toggle flex-wrap"
          }
        >
          <input
            className="home-input-serach-keywords"
            type="text"
            placeholder="Keywords"
            value={keyword}
            name="keywords"
            onChange={(e) => setKeyword(e.target.value)}
          />
          <input
            className="home-input-serach-location-toggle"
            type="text"
            placeholder="Location"
            value={location}
            name="location"
            onChange={(e) => setLocation(e.target.value)}
          />

          {isMore && (
            <>
              <select
                className="homepage_options-miles-toggle search_bar_hide"
                name="miles"
                id="miles"
                value={miles}
                onChange={(e) => setMiles(e.target.value)}
              >
                <option value="">Select Miles</option>
                <option value="4">4 Miles</option>
                <option value="6">6 Miles</option>
                <option value="8">8 Miles</option>
                <option value="11">11 Miles</option>
                <option value="12">12 Miles</option>
                <option value="23">23 Miles</option>
              </select>
              <select
                className="homepage_options-others search_bar_hide"
                name="industry"
                id="industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="">Industry</option>
                {Industries.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              <input
                type="text"
                className="homepage_options-others search_bar_hide"
                placeholder="Min Sal"
                name="min-salary"
                value={minSalary}
                onChange={(e) => setMinSalary(e.target.value)}
              />
              <input
                type="text"
                className="homepage_options-others search_bar_hide"
                placeholder="Max Sal"
                name="max-sal"
                value={maxSalary}
                onChange={(e) => setMaxSalary(e.target.value)}
              />

              <select
                className="homepage_options-others search_bar_hide"
                name="jobType"
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
              >
                <option value="">Job Types</option>
                <option value="permanant">Permanant</option>
                <option value="contract">Contract</option>
                <option value="temporary">Temporary</option>
                <option value="part time">Part Time</option>
                <option value="full time">Full Time</option>
                <option value="internship">Internship</option>
              </select>
            </>
          )}

          <button className="btn-search-jobs-finds" onClick={handleSearch}>
            Find jobs
          </button>
        </div>
        <div
          className={
            isMore
              ? "homepage_more_btn search_bar_hide"
              : "homepage_more_btn_not_toggle search_bar_hide"
          }
        >
          <p onClick={() => setIsMore(!isMore)}>
            {isMore ? "See Fewer Options" : "See More Options"}
          </p>
        </div>
      </div>
    </motion.section>
  );
}

export default JobSearch;
