import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import { MdOutlinePersonPin, MdWork } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import { GiLetterBomb } from "react-icons/gi";

const ProfileInfo = () => {
  const { user } = useAuthContext();
  const [coverLetters, setCoverLetters] = useState([]);

  useEffect(() => {
    try {
      if (user?.role === "jobseeker") {
        fetchCoverLetters();
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.role]);

  const fetchCoverLetters = async () => {
    await axios.get("/coverLetters/view").then((res) => {
      setCoverLetters(res.data?.docs);
    });
  };

  const editProfile = () => {
    window.location.href = "/editprofile";
  };

  const editCoverLetters = () => {
    window.location.href = "/coverletters";
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      {user?.role === "jobseeker" && (
        <div className="details">
          <div className="profile_details_header">
            <p className="profile_details_heading">
              <MdOutlinePersonPin /> Personal Details
            </p>
            <p className="edit_link" onClick={editProfile}>
              Edit
            </p>
          </div>
          <div className="profile_details_main_div">
            {/* <div className="details_left_div"> */}
            <div className="details_item">
              <p>First name: </p>
              <p>{user?.firstName}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Last Name: </p>
              <p>{user?.lastName}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Country: </p>
              <p>{user?.country}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Desired Job Title: </p>
              <p>{user?.jobTitle}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Post Code: </p>
              <p>{user?.postcode}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Phone: </p>
              <p>{user?.details?.contactNo}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Email Address: </p>
              <p>{user?.email}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>status: </p>
              <p>{user?.status}</p>
            </div>
            <div className="details_item_row"></div>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>
      )}

      {(user?.role === "employer" || user?.role === "company") && (
        <div className="details">
          <div className="profile_details_header">
            <p className="profile_details_heading">
              <MdOutlinePersonPin />
              Personal Details
            </p>
            <p className="edit_link" onClick={editProfile}>
              Edit
            </p>
          </div>
          <div className="profile_details_main_div">
            {user?.role === "employer" && (
              <>
                <div className="details_item">
                  <p>Company Name: </p>
                  <p>{user?.companyName}</p>
                </div>
                <div className="details_item_row"></div>
              </>
            )}
            <div className="details_item">
              <p>Name: </p>
              <p>{user?.name}</p>
            </div>
            <div className="details_item_row"></div>
            {user?.role === "company" && (
              <>
                <div className="details_item">
                  <p>Website Url: </p>
                  <p>
                    <a target="_blank" href={user?.website} rel="noreferrer">
                      {user?.website}
                    </a>
                  </p>
                </div>
                <div className="details_item_row"></div>
              </>
            )}
            <div className="details_item">
              <p>Phone: </p>
              <p>{user?.phone}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Email Address: </p>
              <p>{user?.email}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Address: </p>
              <p>{user?.address}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Post Code: </p>
              <p>{user?.postcode}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Town: </p>
              <p>{user?.town}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Country: </p>
              <p>{user?.country}</p>
            </div>
            <div className="details_item_row"></div>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>
      )}

      {(user?.role === "employer" || user?.role === "company") && (
        <div className="details">
          <div className="profile_details_header">
            <p className="profile_details_heading">
              <MdWork /> Buisness Details
            </p>
            <p className="edit_link" onClick={editProfile}>
              Edit
            </p>
          </div>
          <div className="profile_details_main_div">
            <div className="details_item">
              <p>Buisness Type: </p>
              <p>{user?.buisnessType}</p>
            </div>
            <div className="details_item_row"></div>

            <div className="details_item">
              <p>Buisness Size: </p>
              <p>{user?.buisnessSize}</p>
            </div>
            <div className="details_item_row"></div>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>
      )}

      {(user?.role === "employer" || user?.role === "company") && (
        <div className="details">
          <div className="profile_details_header">
            <p className="profile_details_heading">
              <MdWork /> Checkout Details
            </p>
            {/* <p onClick={editProfile}>Edit</p> */}
          </div>
          <div className="profile_details_main_div">
            <div className="details_item">
              <p>Package Name: </p>
              <p>{user?.checkout?.packageName}</p>
            </div>
            <div className="details_item_row"></div>
            {user?.checkout?.packageName === "search cvs" ? (
              <>
                <div className="details_item">
                  <p>View CV's: </p>
                  <p>{user?.checkout?.searchCvs?.view_cvs}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>Number of Weeks: </p>
                  <p>{user?.checkout?.searchCvs?.daysAllowed}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>Start Date: </p>
                  <p>{formatDate(user?.checkout?.searchCvs?.start)}</p>
                </div>
                <div className="details_item_row"></div>
              </>
            ) : (
              <>
                <div className="details_item">
                  <p>Post Jobs: </p>
                  <p>{user?.checkout?.advertiseJobs?.postJobs}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>Number of Days: </p>
                  <p>{user?.checkout?.advertiseJobs?.daysAllowed}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>Start Date: </p>
                  <p>{formatDate(user?.checkout?.advertiseJobs?.start)}</p>
                </div>
                <div className="details_item_row"></div>
              </>
            )}

            <div className="details_item">
              <p>Consumer ID: </p>
              <p>{user?.checkout?.customerId}</p>
            </div>
            <div className="details_item_row"></div>
            {user?.checkout?.packageName === "search cvs" ? (
              <>
                <div className="details_item">
                  <p>Downlaod CV's: </p>
                  <p>{user?.checkout?.searchCvs?.download_cvs}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>Downlaoded CV's: </p>
                  <p>{user?.checkout?.searchCvs?.downloaded_cvs || 0}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>End Date: </p>
                  <p>{formatDate(user?.checkout?.searchCvs?.end)}</p>
                </div>
              </>
            ) : (
              <>
                <div className="details_item">
                  <p>Posted Jobs: </p>
                  <p>{user?.checkout?.advertiseJobs?.consumedJobs}</p>
                </div>
                <div className="details_item_row"></div>
                <div className="details_item">
                  <p>End Date: </p>
                  <p>{formatDate(user?.checkout?.advertiseJobs?.end)}</p>
                </div>
              </>
            )}
            <div className="details_item_row"></div>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>
      )}

      {user?.role === "jobseeker" && (
        <div className="details">
          <div className="profile_details_header">
            <p className="profile_details_heading">
              <MdWork /> Job Details
            </p>
            <p className="edit_link" onClick={editProfile}>
              Edit
            </p>
          </div>
          <div className="profile_details_main_div">
            <div className="details_item">
              <p>Job Types: </p>
              <p>
                {user?.details?.jobType?.map((job) => {
                  return `${job}  `;
                })}
              </p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Expected Salary: </p>
              <p>{user?.details?.expectedSalary}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Current/Previous Job: </p>
              <p>{user?.jobTitle}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Willing to Relocate: </p>
              <p>{user?.preferences?.relocate ? "Yes" : "No"}</p>
            </div>
            <div className="details_item_row"></div>

            <div className="details_item">
              <p>Travel: </p>
              <p>{user?.preferences?.travelDistance}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Notice Period: </p>
              <p>{user?.preferences?.Availability}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Driving Liscence: </p>
              <p>{user?.preferences?.drivingLiscence ? "Yes" : "No"}</p>
            </div>
            <div className="details_item_row"></div>
            <div className="details_item">
              <p>Industries: </p>
              <p>
                {user?.preferences?.industries?.map((job) => {
                  return `${job}  `;
                })}
              </p>
            </div>
            <div className="details_item_row"></div>

            <span className="top_border profile_items_border"></span>
            <span className="right_border profile_items_border"></span>
            <span className="bottom_border profile_items_border"></span>
            <span className="left_border profile_items_border"></span>
          </div>
        </div>
      )}

      {user?.role === "jobseeker" && (
        <>
          <div className="details">
            <div className="profile_details_header">
              <p className="profile_details_heading">
                <MdLanguage /> Skills and Languages
              </p>
              <p className="edit_link" onClick={editProfile}>
                Edit
              </p>
            </div>
            <div className="profile_details_main_div">
              <div className="details_item">
                <p>Skills: </p>
                <p>
                  {user?.skills?.map((skill) => {
                    return `${skill}  `;
                  })}
                </p>
              </div>
              <div className="details_item_row"></div>

              <div className="details_item">
                <p>Languages: </p>
                <p>
                  {user?.languages?.map((skill) => {
                    return `${skill}  `;
                  })}
                </p>
              </div>
              <div className="details_item_row"></div>

              <span className="top_border profile_items_border"></span>
              <span className="right_border profile_items_border"></span>
              <span className="bottom_border profile_items_border"></span>
              <span className="left_border profile_items_border"></span>
            </div>
          </div>

          <div className="details">
            <div className="profile_details_header">
              <p className="profile_details_heading">
                <GiLetterBomb /> Cover Letters
              </p>
              <p className="edit_link" onClick={editCoverLetters}>
                Edit
              </p>
            </div>
            <div className="profile_details_main_div">
              {coverLetters?.map((item) => {
                return (
                  <div key={item?._id}>
                    <div className="details_item">
                      <p>Title: </p>
                      <p>{item?.coverLetterName}</p>
                    </div>
                    <div className="details_item">
                      <p>Content: </p>
                      <p>{item?.coverLetterContent}</p>
                    </div>
                    <div className="details_item_row"></div>
                  </div>
                );
              })}

              <span className="top_border profile_items_border"></span>
              <span className="right_border profile_items_border"></span>
              <span className="bottom_border profile_items_border"></span>
              <span className="left_border profile_items_border"></span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileInfo;
