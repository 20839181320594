import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import "./adminPanel.css";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import {
  FaUsers,
  FaChalkboardTeacher,
  FaRegistered,
  FaUserPlus,
} from "react-icons/fa";
import { change_show } from "../../redux/actions";
import { store } from "../../redux/store";
import { TbBrandBinance } from "react-icons/tb";
import { GiMultiDirections } from "react-icons/gi";
import { SiConcourse, SiTheregister } from "react-icons/si";
import { RiLuggageCartFill, RiUserSettingsFill } from "react-icons/ri";
import { MdLocalPolice, MdOutlineContactMail } from "react-icons/md";
import { ImUserPlus } from "react-icons/im";
import { AiFillMacCommand, AiFillSetting } from "react-icons/ai";
import { useAdminPanelContext } from "../../context/AdminPanelContext";
import Candidates from "./pages/Candidates";
import Employers from "./pages/Employers";
import Companies from "./pages/Companies";
import CourseUsers from "./pages/CourseUsers";
import ManagementUsers from "./pages/ManagementUsers";
import Posts from "./pages/Posts";
import CheckoutPlans from "./pages/CheckoutPlans";
import PremiumPlans from "./pages/PremiumPlans";
import HiringOrders from "./pages/HiringOrders";
import Courses from "./pages/Courses";
import Enquiries from "./pages/Enquiries";
import ContactUsQueries from "./pages/ContactUsQueries";
import AddRecruiters from "./pages/AddRecruiters";
import UserLogs from "./pages/UserLogs";
import { AdditionalRoles } from "../../constants/GlobalConstants";
import Settings from "./pages/Settings";

function AdminPanel() {
  const { user } = useAuthContext();
  const { state } = useAdminPanelContext();
  const [show, setShow] = useState(store.getState().NavReducer.show);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentState, setCurrentState] = useState(store.getState());

  useEffect(() => {
    if (user?.role !== "admin" && !AdditionalRoles.includes(user?.role)) {
      setError(true);
      setErrorMessage("Only admin can access this page");
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    }
  }, [user]);

  useEffect(() => {
    setError(false);
  }, [error]);

  store.subscribe(() => {
    setShow(store.getState().NavReducer.show);
    setCurrentState(store.getState());
  });

  const handleShow = (value) => {
    store.dispatch(change_show(value));
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      <div
        className={
          currentState.NavReducer.lightMode
            ? "adminDashboard light-mode"
            : "adminDashboard dark-mode"
        }
      >
        <div className="stats-items">
          {(user?.role === "admin" || user?.permissions?.view_candidates) && (
            <div
              onClick={() => handleShow("showCandidates")}
              className="stats-item"
              id={show?.showCandidates ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
            >
              <FaUsers className="stats-item-icon" />
              <p>{state?.candidates?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.view_employers) && (
            <div
              className="stats-item"
              id={show?.showEmployers ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showEmployers")}
            >
              <FaUserPlus className="stats-item-icon" />
              <p>{state?.employers?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.view_companies) && (
            <div
              className="stats-item"
              id={show?.showCompanies ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showCompanies")}
            >
              <TbBrandBinance className="stats-item-icon" />
              <p>{state?.companies?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.view_course_users) && (
            <div
              className="stats-item"
              id={show?.showUsers ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showUsers")}
            >
              <FaChalkboardTeacher className="stats-item-icon" />
              <p>{state?.courseUsers?.length}</p>
            </div>
          )}

          {user?.role === "admin" && (
            <div
              className="stats-item"
              id={show?.showAdditionalUsers ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showAdditionalUsers")}
            >
              <RiUserSettingsFill className="stats-item-icon" />
              <p>{state?.managementUsers?.length}</p>
            </div>
          )}

          <div
            className="stats-item"
            id={show?.showPosts ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
            onClick={() => handleShow("showPosts")}
          >
            <GiMultiDirections className="stats-item-icon" />
            <p>{state?.posts?.length}</p>
          </div>

          {(user?.role === "admin" ||
            user?.permissions?.view_checkout_plans) && (
            <div
              className="stats-item"
              id={show?.showPlans ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showPlans")}
            >
              <FaRegistered className="stats-item-icon" />
              <p>{state?.checkoutPlans?.length}</p>
            </div>
          )}

          {(user?.role === "admin" ||
            user?.permissions?.view_premium_plans) && (
            <div
              className="stats-item"
              id={show?.showPremiumPlans ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showPremiumPlans")}
            >
              <SiTheregister className="stats-item-icon" />
              <p>{state?.premiumPlans?.length}</p>
            </div>
          )}

          {(user?.role === "admin" ||
            user?.permissions?.view_hiring_orders) && (
            <div
              className="stats-item"
              id={show?.showHiringOrders ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showHiringOrders")}
            >
              <RiLuggageCartFill className="stats-item-icon" />
              <p>{state?.hiringOrders?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.view_courses) && (
            <div
              className="stats-item"
              id={show?.showCourses ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showCourses")}
            >
              <SiConcourse className="stats-item-icon" />
              <p>{state?.courses?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.view_enquiries) && (
            <div
              className="stats-item"
              id={show?.showEnquiries ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showEnquiries")}
            >
              <MdLocalPolice className="stats-item-icon" />
              <p>{state?.enquiries?.length}</p>
            </div>
          )}

          {(user?.role === "admin" ||
            user?.permissions?.view_contact_us_queries) && (
            <div
              className="stats-item"
              id={show?.showContactQueries ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showContactQueries")}
            >
              <MdOutlineContactMail className="stats-item-icon" />
              <p>{state?.contactUsQueries?.length}</p>
            </div>
          )}

          {(user?.role === "admin" || user?.permissions?.add_recruiters) && (
            <div
              className="stats-item"
              id={show?.showAddRecruiter ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showAddRecruiter")}
            >
              <ImUserPlus className="stats-item-icon" />
              <p>{state?.hiringOrders?.length}</p>
            </div>
          )}

          {user?.role === "admin" && (
            <div
              className="stats-item"
              id={show?.showLogs ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showLogs")}
            >
              <AiFillMacCommand className="stats-item-icon" />
              <p>{state?.userLogs?.length}</p>
            </div>
          )}

          {user?.role === "admin" && (
            <div
              className="stats-item"
              id={show?.showSettings ? "active-stats-item" : ""}
              style={{ cursor: "pointer" }}
              onClick={() => handleShow("showSettings")}
            >
              <AiFillSetting className="stats-item-icon" />
            </div>
          )}
        </div>

        {show?.showCandidates ? <Candidates /> : null}
        {show?.showEmployers ? <Employers /> : null}
        {show?.showCompanies ? <Companies /> : null}
        {show?.showUsers ? <CourseUsers /> : null}
        {show?.showAdditionalUsers ? <ManagementUsers /> : null}
        {show?.showPosts ? <Posts /> : null}
        {show?.showPlans ? <CheckoutPlans /> : null}
        {show?.showCourses ? <Courses /> : null}
        {show?.showPremiumPlans ? <PremiumPlans /> : null}
        {show?.showHiringOrders ? <HiringOrders /> : null}
        {show?.showAddRecruiter ? <AddRecruiters /> : null}
        {show?.showEnquiries ? <Enquiries /> : null}
        {show?.showContactQueries ? <ContactUsQueries /> : null}
        {show?.showLogs ? <UserLogs /> : null}
        {show?.showSettings ? <Settings /> : null}
      </div>
    </>
  );
}

export default AdminPanel;
