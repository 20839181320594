import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./container/homepage/homepage";
import AdminPanel2 from "./container/adminPanel/adminPanel2";
import SearchCV from "./container/searchCV/searchCV";
import Advertisejob from "./container/advertiseJobs/advertiseJobs";

import CareerAdvice from "./container/careerAdvice/careerAdvice";
import ViewPost from "./container/careerAdvice/careerPost";
import AddPost from "./components/blogs/addBlog";
import EditPost from "./components/blogs/editPost";
import CategoryPosts from "./container/careerAdvice/categoryPosts";
import Terms from "./components/terms/Terms";
import Privacy from "./components/privacy/Privacy";

import Register from "./container/register/register";
import Register2 from "./container/register/register2";
import Register3 from "./container/register/register3";
import About from "./container/about/About";
import Home from "./components/Home";
import AdminLogin from "./components/login2/adminLogin";
import Searchjob from "./container/searchjobs/searchjob";
import CvbuilderPage from "./container/cvbuilder/cvbuilder";
import Jobalert from "./container/jobalert/jobalert";
import JobAlert1 from "./container/job_alerts/JobAlert";

import Savejob from "./container/save_job/Savejob";
import AppliedJobs from "./container/applications/AppliedJobs";
import MyApplication from "./container/applications/MyApplication";
import Profile from "./container/profile/Profile";
import AllCompanies from "./container/companyA-Z/Company_A_z";
import Dashboard from "./container/dashboard/dashboard";
import SpecialOffers from "./container/dashboard/offer";
import Cvdatabase from "./cvdatabase/Cvdatabase";
import CourseDetails from "./components/courses/CourseDetails";
import JobDetails from "./container/jobs/jobsdetails";
import MyCourses from "./container/dashboard/mycourses";
import Saved from "./container/dashboard/saved";
import SearchEmployers from "./container/companyA-Z/SearchEmployers";

import ViewProfile from "./container/profile/ViewProfile";
import EditProfile from "./container/profile/EditProfile";
import Posts from "./container/profile/Posts";
import PostedJobs from "./container/profile/postedJobs";
import Devices from "./container/profile/Devices";
import TeamMembers from "./container/profile/TeamMembers";
import { ResetPassword, Verify2FA } from "./components/courses/Resetpassword-2fa";

import AddJob from "./container/jobs/addJob";
import EditJob from "./container/jobs/editJob";
import Interview from "./container/applications/Interview";

import AddPlan from "./components/plans/addPlan";
import EditPlan from "./components/plans/editPlan";

import Courses from "./container/courses/Courses";
import CreateCourse from "./container/courses/addCourse";
import UpdateCourse from "./container/courses/updateCourse";
import AllCourses from "./container/courses/allcourses";
import NotFound from "./components/pageNotFound/notfound";
import ActiveCoursesPage from "./container/dashboard/activecourses";
import Order from "./container/dashboard/order";
import UserProfile from "./container/dashboard/myprofile";
import CourseSignin from "./components/courses/cousesSignin";
import UserRegistration from "./components/courses/userRegistration";
import CompletedCourses from "./container/dashboard/completedCourses";
import Certificates from "./container/dashboard/certificates";

import Checkout from "./container/recruiter/recruiterCheckout";
import Details from "./container/recruiter/details";
import Password from "./container/recruiter/password";

import BuildCV from "./container/cvbuilder/BuildCV";
import UpdateCV from "./container/cvbuilder/UpdateCV";
import ViewCV from "./container/cvbuilder/ViewCV";
import AssistCV from "./container/cvbuilder/AssistCV";
import UpdatePassword from "./container/profile/UpdatePassword";
import ApplyNow from "./container/applications/applyNow";
import CoverLetters from "./container/profile/CoverLetters";
import Enquiry from "./cvdatabase/Enquiry";

import Services from "./container/services/services";
import { useAuthContext } from "./context/AuthContext";
import ContactUs from "./container/contact/ContactUs";
import SignIn from "./container/forms/loginForm";
import { AdditionalRoles } from "./constants/GlobalConstants";
import Login from "./components/login/Login";

function App() {
  const { user } = useAuthContext();

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users/login" element={<SignIn />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/2/:id" element={<Register2 />} />
        <Route path="/register/3/:id" element={<Register3 />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/our-services" element={<Services />} />

        <Route path="/career-advice" element={<CareerAdvice />} />
        <Route path="/career-advice/:postId" element={<ViewPost />} />
        <Route path="/career-advice/category/:category" element={<CategoryPosts />} />
        <Route path="/addpost" element={<AddPost />} />
        <Route path="/editpost/:id" element={<EditPost />} />

        <Route path="/resume-fixer" element={<CvbuilderPage />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/job-recruiting-websites" element={<Home />} />
        <Route path="/search-company" element={<AllCompanies />} />
        <Route path="/company/employer" element={<SearchEmployers />} />

        <Route path="/career-counselling-courses" element={<Courses />} />
        <Route path="/courses/create" element={<CreateCourse />} />
        <Route path="/courses/update/:id" element={<UpdateCourse />} />
        <Route path="/allcourses" element={<AllCourses />} />
        <Route path="/courses/login" element={<CourseSignin />} />
        <Route path="/courses/register" element={<UserRegistration />} />

        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="/dashboard/mycourses" element={<MyCourses />} />
        <Route path="/dashboard/saved" element={<Saved />} />
        <Route path="/dashboard/active-courses" element={<ActiveCoursesPage />} />
        <Route path="/dashboard/completed-courses" element={<CompletedCourses />} />
        <Route path="/dashboard/certificates" element={<Certificates />} />
        <Route path="/dashboard/specialoffer" element={<SpecialOffers />} />
        <Route path="/dashboard/myprofile" element={<UserProfile />} />
        <Route path="/dashboard/myorders" element={<Order />} />

        <Route path="/courses/:id" element={<CourseDetails />} />

        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/:token" element={<UpdatePassword />} />
        <Route path="/view-profile/:id" element={<ViewProfile />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/coverletters" element={<CoverLetters />} />
        <Route path="/verify" element={<Verify2FA />} />

        {user &&
          (user?.role === "jobseeker" || user?.role === "employer" || user?.role === "company") && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/savejob" element={<Savejob />} />
              <Route path="/profile/jobalert1" element={<JobAlert1 />} />
              <Route path="/profile/appliedJobs" element={<AppliedJobs />} />
              <Route path="/profile/jobsApplications" element={<MyApplication />} />
              <Route path="/profile/createdposts" element={<Posts />} />
              <Route path="/profile/postedjobs" element={<PostedJobs />} />
              <Route path="/profile/team" element={<TeamMembers />} />
              <Route path="/profile/devices" element={<Devices />} />
            </>
          )}

        <Route path="/*" element={<NotFound />} />
        <Route path="/online-recruitment" element={<Cvdatabase />} />
        <Route path="/job-advertisement" element={<Advertisejob />} />
        <Route path="/searchcv" element={<SearchCV />} />
        <Route path="/enquirenow" element={<Enquiry />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/password/:id" element={<Password />} />

        {(user?.role === "admin" || AdditionalRoles.includes(user?.role)) && (
          <Route path="/adminboard" element={<AdminPanel2 />} />
        )}

        <Route path="/addplan/:plantype" element={<AddPlan />} />
        <Route path="/editplan/:id" element={<EditPlan />} />

        <Route path="/buildcv" element={<BuildCV />} />
        <Route path="/assistcv" element={<AssistCV />} />
        <Route path="/viewcv/:id" element={<ViewCV />} />
        <Route path="/updatecv" element={<UpdateCV />} />

        <Route path="/job-alerts" element={<Jobalert />} />
        <Route path="/jobs/:id" element={<JobDetails />} />
        <Route path="/job-boards-usa" element={<Searchjob />} />
        <Route path="/createjob" element={<AddJob />} />
        <Route path="/editjob/:id" element={<EditJob />} />
        <Route path="/applynow/:id" element={<ApplyNow />} />
        <Route path="/interview/:appId" element={<Interview />} />

        {/* Redirect from "/example" to "/home" with a 301 status */}
        <Route
          path="/searchjob"
          element={<Navigate to="/job-boards-usa" replace state={{ status: 301 }} />}
        />

        <Route
          path="/careeradvice"
          element={<Navigate to="/career-advice" replace state={{ status: 301 }} />}
        />

        <Route
          path="/careeradvice/:postId"
          element={<Navigate to="/career-advice/:postId" replace state={{ status: 301 }} />}
        />

        <Route
          path="/careeradvice/category/:category"
          element={
            <Navigate to="/career-advice/category/:category" replace state={{ status: 301 }} />
          }
        />

        <Route
          path="/cvbuilder"
          element={<Navigate to="/resume-fixer" replace state={{ status: 301 }} />}
        />

        <Route
          path="/recruiter"
          element={<Navigate to="/job-recruiting-websites" replace state={{ status: 301 }} />}
        />

        <Route
          path="/company"
          element={<Navigate to="/search-company" replace state={{ status: 301 }} />}
        />

        <Route
          path="/courses"
          element={<Navigate to="/career-counselling-courses" replace state={{ status: 301 }} />}
        />

        <Route
          path="/resume-builder-online"
          element={<Navigate to="/resume-fixer" replace state={{ status: 301 }} />}
        />

        <Route
          path="/jobalert"
          element={<Navigate to="/job-alerts" replace state={{ status: 301 }} />}
        />

        <Route
          path="/candidate/login"
          element={<Navigate to="/login" replace state={{ status: 301 }} />}
        />

        <Route
          path="/cvdatabase"
          element={<Navigate to="/online-recruitment" replace state={{ status: 301 }} />}
        />

        <Route
          path="/advertisejobs"
          element={<Navigate to="/job-advertisement" replace state={{ status: 301 }} />}
        />

        <Route
          path="/about"
          element={<Navigate to="/about-us" replace state={{ status: 301 }} />}
        />

        <Route
          path="/services"
          element={<Navigate to="/our-services" replace state={{ status: 301 }} />}
        />

        <Route
          path="/contactUs"
          element={<Navigate to="/contact-us" replace state={{ status: 301 }} />}
        />

        <Route
          path="/terms"
          element={<Navigate to="/terms-and-conditions" replace state={{ status: 301 }} />}
        />

        <Route
          path="/privacy"
          element={<Navigate to="/privacy-policy" replace state={{ status: 301 }} />}
        />
      </Routes>
    </div>
  );
}

export default App;
