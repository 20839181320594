import React from "react";
import { CSSTransition } from "react-transition-group";
import "./notfound.css";
import Footer from "../../components/footer/footer";
import Navbar3 from "../navbar3/navbar3";
const NotFound = () => {
  return (
    <>
      <Navbar3 />
      <div className="not-found">
        <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
          <h1 className="text-center header-heading text-dark">Page Not Found!!</h1>
        </CSSTransition>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
