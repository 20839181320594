import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

const CoverLetters = () => {
  const { user } = useAuthContext();
  const [coverLetters, setCoverLetters] = useState([]);
  const [coverLetter, setCoverLetter] = useState({
    coverLetterName: "",
    coverLetterContent: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
    try {
      fetchCoverLetters();
    } catch (error) {
      console.log(error);
    }
  }, [user, navigate]);

  const fetchCoverLetters = async () => {
    setIsFetching(true);
    await axios.get("/coverLetters/view").then((res) => {
      setCoverLetters(res.data?.docs);
    });
    setTimeout(() => {
      setIsFetching(false);
    }, 500);
  };

  const handleCoverLetterChange = async (id, action) => {
    try {
      setIsLoading(true);
      if (action === "delete") {
        await axios.delete(`/coverLetters/${id}`).then((res) => {
          setSuccess(true);
          setSuccessMessage("Cover Letter deleted successfully");
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
          }, 1000);
        });
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const addCoverLetter = async () => {
    try {
      if (
        coverLetter.coverLetterName.trim() === "" ||
        coverLetter.coverLetterContent.trim() === ""
      ) {
        setError(true);
        setErrorMessage("Please fill the fields to continue!!");
        return;
      }
      setIsLoading(true);
      await axios.post("/coverLetters", coverLetter).then((res) => {
        setSuccess(true);
        setSuccessMessage("Cover Letter added..");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>

        <div className="register_form">
          <h3 className="register_form_h3_start">Cover Letters</h3>
          <hr />
          {isFetching ? (
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <Loader />
            </div>
          ) : (
            <>
              {coverLetters?.length > 0 ? (
                <form>
                  {coverLetters?.map((item, index) => {
                    return (
                      <div key={item?._id} className="register_form_div">
                        <div className="register_options">
                          <label>{index + 1}.</label>
                          <input
                            id="keyword"
                            className="text"
                            type="text"
                            value={item?.coverLetterName}
                            disabled
                          />
                        </div>
                        <div className="register_options">
                          <input
                            id="keyword"
                            className="text"
                            type="text"
                            value={item?.coverLetterContent}
                            disabled
                          />
                        </div>

                        {/* <button
                    type="button"
                    className="btn btn-primary"
                    style={{ margin: "1rem" }}
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Edit
                  </button>
                  {isEdit && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ margin: "1rem" }}
                      onClick={() => handleCoverLetterChange(item?._id, "edit")}
                    >
                      Update
                    </button>
                  )} */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ margin: "1rem" }}
                          onClick={() =>
                            handleCoverLetterChange(item?._id, "delete")
                          }
                        >
                          Remove Cover Letter
                        </button>
                      </div>
                    );
                  })}
                </form>
              ) : (
                <div className="container d-flex justify-content-center align-items-center">
                  No Cover Letter Added yet!!
                </div>
              )}
            </>
          )}
        </div>

        <div className="register_form">
          <h3 className="register_form_h3_start">Add Cover Letter</h3>
          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Title: </label>
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  value={coverLetter.coverLetterName}
                  onChange={(e) =>
                    setCoverLetter({
                      ...coverLetter,
                      coverLetterName: e.target.value,
                    })
                  }
                />
                <label>Content: </label>
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  value={coverLetter.coverLetterContent}
                  onChange={(e) =>
                    setCoverLetter({
                      ...coverLetter,
                      coverLetterContent: e.target.value,
                    })
                  }
                />

                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  style={{ margin: "1rem auto" }}
                  onClick={addCoverLetter}
                >
                  Add Cover Letter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CoverLetters;
