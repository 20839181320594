import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Industries } from "../constants/GlobalConstants";

function Searchjob() {
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [miles, setMiles] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [jobType, setJobType] = useState("");
  const [industry, setIndustry] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(pageLocation.search);
    const param1 = queryParams.get("keyword");
    const param2 = queryParams.get("location");
    const param3 = queryParams.get("miles");
    const param4 = queryParams.get("minSalary");
    const param5 = queryParams.get("maxSalary");
    const param6 = queryParams.get("jobType");
    const param7 = queryParams.get("industry");
    if (param1) setKeyword(decodeURIComponent(param1));
    if (param2) setLocation(decodeURIComponent(param2));
    if (param3) setMiles(decodeURIComponent(param3));
    if (param4) setMinSalary(decodeURIComponent(param4));
    if (param5) setMaxSalary(decodeURIComponent(param5));
    if (param6) setJobType(decodeURIComponent(param6));
    if (param7) setIndustry(decodeURIComponent(param7));
    // console.log(queryParams);
  }, [pageLocation.search]);

  const handleSearch = async () => {
    try {
      const encodedKeyword = encodeURIComponent(keyword);
      const encodedLocation = encodeURIComponent(location);
      const encodedMiles = encodeURIComponent(miles);
      const encodedMinSalary = encodeURIComponent(minSalary);
      const encodedMaxSalary = encodeURIComponent(maxSalary);
      const encodedJobType = encodeURIComponent(jobType);
      const encodedIndustry = encodeURIComponent(industry);

      const queryParams = new URLSearchParams({
        keyword: encodedKeyword,
        location: encodedLocation,
        miles: encodedMiles,
        minSalary: encodedMinSalary,
        maxSalary: encodedMaxSalary,
        jobType: encodedJobType,
        industry: encodedIndustry,
      });
      navigate(`/job-recruiting-websites?${queryParams}`);
      // window.location.href = `/recruiter?${queryParams}`;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReset = () => {
    setKeyword("");
    setLocation("");
    setMiles("");
    setMinSalary("");
    setMaxSalary("");
    setJobType("");
    setIndustry("");
  };

  return (
    <div className="container-fluid mt-2 search_job_main_div">
      <div className="container-fluid">
        {/* <div className="search_job_content_div">
          <p>
            Welcome to Alphy Johnson Employment Resource Center. At Resume
            global hub or CV global Hub, we have categorized our job vacancies
            into various employment industries and sectors for your convenience.
            By clicking on the industry title links, you can access search
            result pages containing relevant roles within your chosen industry.
          </p>
        </div> */}
        <div className="container search_job_subdiv1">
          <div className="left">
            <a href="/job-boards-usa" className="search_links_a">
              Search Jobs
            </a>
            <i className="bx bx-chevron-right text-white"></i>
            <a href="/search-company" className="search_links_a">
              Buisnesses
            </a>
          </div>
          <div className="right">
            <a href="/job-boards-usa" className="search_links_a">
              Search jobs
            </a>
            <span> from Unlimited </span>
            <a href="/search-company" className="search_links_a">
              Companies
            </a>
          </div>
        </div>
      </div>
      {/* start from */}
      <div className="container-fluid mt-3">
        <div className="container d-flex from-info">
          <div className="input-group mb-3 py-2">
            <button
              className="btn btn-outline-secondary fw-bold"
              type="button"
              id="button-addon1"
            >
              Keywords
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="e.g Node.js"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className="input-group mb-3 py-2">
            <button
              className="btn btn-outline-secondary fw-bold"
              type="button"
              id="button-addon1"
            >
              Locations
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="e.g USA"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* End start */}
      <div className="container-fluid mt-3">
        <div className="container d-flex hidenoption cv-lib-recruiter-div-select">
          <select
            className="form-select form-select-md mb-3 "
            aria-label=".form-select-lg example"
            value={miles}
            onChange={(e) => setMiles(e.target.value)}
          >
            <option value="">Distance: Miles</option>
            <option value="4">4 Miles</option>
            <option value="6">6 Miles</option>
            <option value="8">8 Miles</option>
            <option value="11">11 Miles</option>
            <option value="12">12 Miles</option>
            <option value="23">23 Miles</option>
          </select>
          <div className="input-group search_job_input">
            <input
              name="min"
              type="number"
              className="form-control"
              placeholder="min salary"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              value={minSalary}
              onChange={(e) => setMinSalary(e.target.value)}
            />
          </div>
          <div className="input-group search_job_input">
            <input
              name="max"
              type="number"
              className="form-control"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              placeholder="max salary"
              value={maxSalary}
              onChange={(e) => setMaxSalary(e.target.value)}
            />
          </div>
          <select
            className="form-select form-select-md mb-3"
            aria-label=".form-select-lg example"
            value={jobType}
            onChange={(e) => setJobType(e.target.value)}
          >
            <option value="">Job Type</option>
            <option value="permanent">Permanent</option>
            <option value="contract">Contract</option>
            <option value="temporary">Temporary</option>
            <option value="part time">Part Time</option>
            <option value="full time">Full Time</option>
            <option value="internship">Internship</option>
          </select>
          <select
            className="form-select form-select-md mb-3"
            aria-label=".form-select-lg example"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="">Industry</option>
            {Industries.sort().map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="container d-flex justify-content-center mt-3">
        <button className="save-btn" onClick={handleSearch}>
          Search
        </button>
        <button
          className="btn btn-danger"
          onClick={handleReset}
          style={{ marginLeft: "1rem" }}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default Searchjob;
