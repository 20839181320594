import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { ToastError } from "../../components/alert/toastAlert";

function Bulidmycv() {
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setError(false);
  }, [error]);

  const hanldeJobseekerClicks = (action) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      if (user && user?.role === "jobseeker") {
        if (action === "build") window.location.href = "/buildcv";
        if (action === "assist") window.location.href = "/assistcv";
      }
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      <div className="container-fluid mt-5 my-cv ">
        <div className="container flex-wrap buildmycv_div">
          <h2 className="text-center text-white pt-5">
            DESIGN YOUR RESUME/CV AND START APPLYING FOR
          </h2>
          <h2 className="text-center  text-white">Numerous Jobs Today!</h2>
          <button
            className=" fw-bold cv-designer-btn"
            onClick={() => hanldeJobseekerClicks("build")}
          >
            Design my Resume/CV
          </button>
          <h2 className="text-center text-white mt-4">
            Create your complimentary CV now
          </h2>
          <p className="text-white">
            Effortlessly input your information to generate and download your
            complimentary Resume or cv within minutes.{" "}
          </p>
          <button
            className=" fw-bold cv-designer-btn"
            onClick={() => hanldeJobseekerClicks("assist")}
          >
            Design for Me
          </button>
        </div>
      </div>
    </>
  );
}

export default Bulidmycv;
