import React, { useEffect, useRef, useState } from "react";
import axios from "../../axios";
import { BsPencilFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Drawer from "./drawer";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

function UserProfile() {
  const { user } = useAuthContext();
  const profilePhoto = useRef();
  const [oldVisible, setOldVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    username: user?.username || "",
    address: user?.address || "",
    oldPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user && user?.role === "user") {
      setError(true);
      setErrorMessage("Please login as courses user to continue!!");
      setTimeout(() => {
        window.location.href = "/courses/login";
      }, 4000);
      return;
    }
    // console.log(user);
    // fetchUserProfile();
  }, [user]);

  const updateProfile = async () => {
    try {
      setIsLoading(true);
      await axios.patch("/users/me", userDetails).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Profile updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const editProfilePhoto = async (image) => {
    const formData = new FormData();
    formData.append("profilePhoto", image);
    try {
      setIsLoading(true);
      await axios.patch("/users/me", formData).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Profile photo updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleUpdatePassword = async () => {
    try {
      setIsLoading(true);
      await axios
        .patch("/users/update/password", {
          oldPassword: userDetails.oldPassword,
          newPassword: userDetails.newPassword,
        })
        .then((res) => {
          // console.log(res.data?.user);
          setSuccess(true);
          setSuccessMessage(res.data?.message);
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
          }, 1000);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const toggleOldVisibility = () => {
    setOldVisible(!oldVisible);
  };

  const toggleNewVisibility = () => {
    setNewVisible(!newVisible);
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Drawer />
      <div className="dashboard">
        <div
          className="details_main_div"
          // style={{ backgroundColor: "var(--blue)", color: "#fff" }}
        >
          <div className="container rounded">
            <div className="row">
              <div className="col-lg-3 border-right">
                <div className="d-flex flex-column align-items-center text-center p-3">
                  <img
                    className="rounded-circle mt-5"
                    width="150px"
                    alt={user?.profilePhoto}
                    src={user?.profilePhoto}
                    crossOrigin="anonymous"
                    loading="lazy"
                  />
                  <BsPencilFill
                    onClick={() => profilePhoto.current.click()}
                    style={{ cursor: "pointer" }}
                    size={25}
                    color="#1e8fff"
                  />
                  <input
                    type="file"
                    ref={profilePhoto}
                    style={{ display: "none" }}
                    onChange={(e) => editProfilePhoto(e.target.files[0])}
                  />
                  <span className="fw-bold">
                    {user?.firstName + " " + user?.lastName}
                  </span>
                  <span className="text-black-50">{user?.email}</span>
                </div>
              </div>
              <div className="col-lg-5 border-right profile_input_div">
                <div className="p-3 py-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right courses_dashboard_heading job_alert_tips">
                      Profile Settings
                    </h4>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="first name"
                        value={userDetails?.firstName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={userDetails?.lastName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="User Name"
                        value={userDetails?.username}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            username: e.target.value,
                          })
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      className="btn btn-primary profile-button"
                      type="button"
                      onClick={updateProfile}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 profile_input_div">
                <div className="p-3 py-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right courses_dashboard_heading job_alert_tips">
                      Update Password
                    </h4>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">Current Password</label>
                      <div
                        className="input-group"
                        style={{ margin: "0", width: "unset" }}
                      >
                        <input
                          type={oldVisible ? "text" : "password"}
                          className="form-control"
                          style={{ borderRadius: ".25rem" }}
                          value={userDetails.oldPassword}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              oldPassword: e.target.value,
                            })
                          }
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                          }}
                          onClick={toggleOldVisibility}
                        >
                          {oldVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">New Password</label>
                      <div
                        className="input-group"
                        style={{ margin: "0", width: "unset" }}
                      >
                        <input
                          type={newVisible ? "text" : "password"}
                          className="form-control"
                          style={{ borderRadius: ".25rem" }}
                          value={userDetails.newPassword}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              newPassword: e.target.value,
                            })
                          }
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                          }}
                          onClick={toggleNewVisibility}
                        >
                          {newVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      className="btn btn-primary profile-button"
                      type="button"
                      onClick={handleUpdatePassword}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
