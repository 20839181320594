import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { store } from "../../redux/store";
import * as actions from "../../redux/actions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Container,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { PersonAdd, Close } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Colors } from "../../constants/GlobalConstants";
import {
  PermissionsList,
  AdditionalRoles,
} from "../../constants/GlobalConstants";
import axios from "../../axios";
import { ToastError, ToastSuccess } from "../alert/toastAlert";
import { useAdminPanelContext } from "../../context/AdminPanelContext";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.blue,
    },
  },
});

const AddUserModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { fetchAdditionalUsers } = useAdminPanelContext();
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState(PermissionsList);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  store.subscribe(() => {
    setIsOpen(store.getState().NavReducer.isModalOpen);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const res = await axios.post("/adminPanel/additional/user", {
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        email: data.get("email"),
        role,
        permissions,
      });

      setSuccess(true);
      setSuccessMessage(res.data?.message);
      await fetchAdditionalUsers();
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      return;
    }
    store.dispatch(actions.change_modal());
    event.currentTarget.reset();
    setRole("");
    setPermissions(PermissionsList);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isSmallScreen ? "90%" : "60%",
    height: "80vh",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <div>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Container component="main" maxWidth="xs">
            <ThemeProvider theme={defaultTheme}>
              <Box sx={style}>
                <Close
                  style={closeIconStyle}
                  onClick={() => store.dispatch(actions.change_modal())}
                />
                <Avatar
                  sx={{ m: 1, bgcolor: "var(--blue)" }}
                  id="transition-modal-description"
                >
                  <PersonAdd />
                </Avatar>
                <Typography
                  className="text-center"
                  component="h1"
                  variant="h4"
                  fontWeight={"bold"}
                  id="transition-modal-title"
                >
                  Add Additional User
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="role_label">Role</InputLabel>
                        <Select
                          sx={{ textTransform: "capitalize" }}
                          labelId="role_label"
                          value={role}
                          label="Role"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          {AdditionalRoles.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel component="legend">
                          Permissions List:
                        </FormLabel>
                        <FormGroup>
                          {Object.keys(permissions).map((key) => {
                            return (
                              <FormControlLabel
                                key={key}
                                sx={{ textTransform: "capitalize" }}
                                control={
                                  <Checkbox
                                    checked={permissions[key] ? true : false}
                                    onChange={(e) =>
                                      setPermissions({
                                        ...permissions,
                                        [key]: e.target.checked,
                                      })
                                    }
                                  />
                                }
                                label={key.split("_").join(" ")}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    className="btn-primary"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontSize: "20px", fontWeight: "bold" }}
                  >
                    Add User
                  </Button>
                </Box>
              </Box>
            </ThemeProvider>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddUserModal;

const closeIconStyle = {
  cursor: "pointer",
  position: "fixed",
  right: "0",
  top: "0",
  fontSize: "35px",
};
