import React, { useState, useEffect } from "react";
import "./posts.css";
import LazyImage from "../common/LazyImage";

const Posts = ({ icon, heading, para }) => {
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > previousScrollPosition) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      previousScrollPosition = currentScrollPosition;
    };

    let previousScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`animate__animated ${
        scrollDirection === "down" ? "animate__backInLeft" : "animate__backInRight"
      }`}
    >
      <div className="posts_maindiv">
        <LazyImage className="post_sub_div_img" src={icon} alt={heading} />

        <div className="posts_subdiv2">
          <h1>{heading}</h1>
          <p>{para}</p>
        </div>
      </div>
    </div>
  );
};

export default Posts;
