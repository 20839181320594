import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import { useAuthContext } from "../context/AuthContext";
import { ToastSuccess, ToastError } from "./alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "./Loader";
import OverlayLoader from "./loader/OverlayLoader";
import RightOptions from "../components/careerAdvice/RightOptions";
import BottomOptions from "../components/careerAdvice/BottomOptions";
import LazyImage from "./common/LazyImage";

function Mainheader() {
  const { user } = useAuthContext();
  const [jobs, setJobs] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchJobs = useCallback(async () => {
    const query = new URLSearchParams(location.search);
    await axios
      .get(`/jobs/search`, {
        params: {
          keyword: decodeURIComponent(query.get("keyword") || ""),
          location: decodeURIComponent(query.get("location") || ""),
          miles: decodeURIComponent(query.get("miles") || ""),
          minSalary: decodeURIComponent(query.get("minSalary") || ""),
          maxSalary: decodeURIComponent(query.get("maxSalary") || ""),
          jobType: decodeURIComponent(query.get("jobType") || ""),
          industry: decodeURIComponent(query.get("industry") || ""),
        },
      })
      .then((res) => {
        // console.log(res.data?.data);
        setJobs(res.data?.data);
        const newPosts = res.data?.data;
        setPagePosts(newPosts?.slice(0, postsPerPage));
        const pages = Math.ceil(newPosts?.length / postsPerPage);
        setTotalPages(pages);
      });
  }, [postsPerPage, location.search]);

  useEffect(() => {
    setIsFetching(true);
    try {
      const fetchData = async () => {
        if (user && user?.role === "jobseeker") {
          await fetchApplications();
          await fetchSavedJobs();
        }

        await fetchJobs();
        setTimeout(() => {
          setIsFetching(false);
        }, 500);
      };

      fetchData();
    } catch (error) {
      console.error("Error fetching saved jobs:", error);
    }
  }, [location.search, user, fetchJobs]);

  const fetchApplications = async () => {
    await axios.get(`/candidates/get/applied-jobs`).then((res) => {
      setApplications(res.data?.appliedJobs);
    });
  };

  const fetchSavedJobs = async () => {
    const response = await axios.get("/jobs/saved");
    const savedJobsData = response.data;
    setSavedJobs(savedJobsData);
    // console.log(savedJobsData);
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleSaveClick = async (id, action) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    try {
      setIsLoading(true);
      if (action === "save") {
        // Save the job
        await axios.patch(`/jobs/save/${id}`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res.data?.message);
        });
      }
      if (action === "unsave") {
        // Remove the saved job
        await axios.delete(`/jobs/${id}/unsave`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res?.data?.message);
        });
      }
      await fetchSavedJobs();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const hanldeJobseekerClicks = (url) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      window.location.href = url;
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = jobs?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <h1 className="industries-head fs-3 text-center fw-bold mt-4">
        Top Job Recruiting Websites: Your Gateway to Career Opportunities
      </h1>
      <div className="container-fluid mt-3 mb-3">
        <div className="container d-flex justify-content-between cvlib_search_job_header">
          <div className="">
            <p className="fs-4 fw-bold">Searching Jobs</p>
            <span>
              Displaying {pagePosts?.length === 0 ? 0 : (currentPage - 1) * postsPerPage + 1}-
              {currentPage === totalPages
                ? jobs?.length
                : pagePosts?.length === 0
                ? pagePosts?.length
                : postsPerPage * currentPage}{" "}
              of {jobs?.length} jobs
            </span>
          </div>
          <div className="sort_job_div">
            <p className="option-title">Sort by:</p>
            <select
              className="form-select form-select-md mb-3 opton2"
              aria-label=".form-select-lg example"
            >
              <option value={""}>Date</option>
            </select>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container d-flex justify-content-between">
          {isFetching ? (
            <div
              className="container d-flex justify-content-center text-center"
              style={{ minHeight: "200px", marginTop: "3rem" }}
            >
              <Loader />
            </div>
          ) : (
            <div className="jobs_cards_div">
              {pagePosts?.length === 0 && (
                <div
                  className="container d-flex justify-content-center"
                  style={{ minHeight: "200px", marginTop: "1rem" }}
                >
                  No Job found with these filters
                </div>
              )}
              {pagePosts?.map((item) => {
                return (
                  <div className="container d-flex " key={item?._id}>
                    <div className="card job_card_left">
                      <div className="card-body">
                        <h5
                          className="card-title text-danger fw-bolder fs-4 "
                          style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`/jobs/${item?._id}`)}
                        >
                          {item?.jobTitle?.split(" ")[0]}&ensp;
                          <span className="text-primary" style={{ textTransform: "capitalize" }}>
                            {item?.jobTitle?.split(" ")?.slice(1)?.join(" ")}
                          </span>
                        </h5>
                        <small>
                          Posted At {formatDate(item?.postedDate)} by{" "}
                          {item?.creator?.role === "employer"
                            ? item?.creator?.employerId?.name
                            : item?.creator?.companyId?.name}{" "}
                          {item?.isFeatured ? (
                            <>
                              <span>
                                <i className="bx bxs-star fw-bold text-primary"></i>
                              </span>{" "}
                              Featured job
                            </>
                          ) : (
                            ""
                          )}
                        </small>
                        <p className="p-0 m-0">
                          Salary: <span className="fw-bold">${item?.salary}</span>
                        </p>
                        <p>
                          Location: <span className="fw-bold">{item?.location}</span>
                        </p>
                        <p>
                          Job Type:{" "}
                          <span className="fw-bold" style={{ textTransform: "capitalize" }}>
                            {item?.jobType}
                          </span>
                        </p>

                        <p className="card-text">
                          <b>About Job: </b>
                          {item?.description?.length > 100 ? (
                            <>
                              {item?.description.slice(0, 100) + " ..."}
                              <a href={`/jobs/${item?._id}`} className="btn btn-cart">
                                <small>more</small>
                              </a>
                            </>
                          ) : (
                            item?.description
                          )}
                        </p>
                        {applications?.find((obj) => obj?.job?._id === item?._id) ? (
                          <p style={{ color: "blue" }}>Application Submitted..</p>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => hanldeJobseekerClicks(`/applynow/${item?._id}`)}
                          >
                            Apply Now
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="card job_card_right">
                      <div className="text-center">
                        {item?.creator?.role === "employer" ? (
                          <LazyImage
                            className="img-fluid"
                            src={item?.creator?.employerId?.profilePhoto}
                            alt="user profile"
                            crossOrigin="anonymous"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              marginBottom: "1rem",
                            }}
                          />
                        ) : (
                          <LazyImage
                            className="img-fluid"
                            src={item?.creator?.companyId?.profilePhoto}
                            alt="user profile"
                            crossOrigin="anonymous"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              marginBottom: "1rem",
                            }}
                          />
                        )}
                      </div>
                      <div className="card-text text-center">
                        <span style={{ fontWeight: "bold", textAlign: "center" }}>
                          {item?.creator?.role === "employer"
                            ? item?.creator?.employerId?.name
                            : item?.creator?.companyId?.name}{" "}
                        </span>
                        <div className="mt-1">
                          <span className="p-0">See more :</span>
                          {item?.creator?.role === "employer" ? (
                            <a
                              href={`/view-profile/${item?.creator?.employerId?._id}?role=employer`}
                              className="nav-link p-0"
                            >
                              Recruitment jobs
                            </a>
                          ) : (
                            <a
                              href={`/view-profile/${item?.creator?.companyId?._id}?role=company`}
                              className="nav-link p-0"
                            >
                              Recruitment jobs
                            </a>
                          )}
                        </div>
                      </div>
                      {savedJobs.find((obj) => obj?.job?._id === item?._id) ? (
                        <button
                          onClick={() => handleSaveClick(item?._id, "unsave")}
                          className="btn btn-primary"
                        >
                          Unsave
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSaveClick(item?._id, "save")}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="right jobrelated cv-lib-jobs">
            <RightOptions />
          </div>
        </div>

        {pagePosts?.length >= 1 && (
          <div className="container-fluid mt-4">
            <div className="pagination">
              <div className="btn btn-primary" onClick={() => paginate("prev")}>
                <FaLessThan />
                <FaLessThan />
              </div>
              <div
                className={
                  currentPage === 1 ? "btn btn-primary active_page_btn" : "btn btn-primary"
                }
                onClick={() => paginate(1)}
              >
                1
              </div>
              {currentPage !== 1 && (
                <div
                  className="btn btn-primary active_page_btn"
                  onClick={() => paginate(currentPage)}
                >
                  {currentPage}
                </div>
              )}
              {totalPages >= 2 && (
                <>
                  <div className="btn btn-primary">...</div>
                  <div className="btn btn-primary" onClick={() => paginate(totalPages)}>
                    {totalPages}
                  </div>
                </>
              )}
              <div className="btn btn-primary" onClick={() => paginate("next")}>
                <FaGreaterThan />
                <FaGreaterThan />
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 mb-4">
          <BottomOptions />
        </div>
      </div>
    </>
  );
}

export default Mainheader;
