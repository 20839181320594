import React, { useEffect, useRef } from "react";
import "./blogPosts.css";
import LazyImage from "../common/LazyImage";

const Blogposts = ({ onclick, img, heading, date, author, isEdit, isDel, onDelClick, id }) => {
  const animatedHeadingRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = animatedHeadingRef?.current;
      const elementPosition = element?.getBoundingClientRect()?.top;
      const windowHeight = window?.innerHeight;

      if (elementPosition < windowHeight * 0.8) {
        element?.classList?.add("animate__backInLeft");
      } else {
        element?.classList?.remove("animate__backInLeft");
      }
    };

    window?.addEventListener("scroll", handleScroll);
    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={animatedHeadingRef} className="blogposts_maindiv animate__backInLeft ">
      <div className="d-flex justify-content-center">
        <LazyImage
          alt={heading}
          className="blog_post_img"
          src={img}
          crossOrigin="anonymous"
          onClick={onclick}
        />
      </div>

      <div>
        <p className="heading" onClick={onclick}>
          {heading}
        </p>
        <p>
          {author?.employerId
            ? author?.employerId?.name
            : author?.companyId
            ? author?.companyId?.name
            : author?.jobSeekerId
            ? `${author?.jobSeekerId?.firstName} ${author?.jobSeekerId?.lastName}`
            : author?.userId
            ? `${author?.userId?.firstName} ${author?.userId?.lastName}`
            : "Content Team"}
        </p>
        <p className="date">{date}</p>
        {isEdit ? <a href={`/editpost/${id}`}>Edit Post</a> : null}
        {isDel ? (
          <span className="delete_button" onClick={onDelClick}>
            Delete
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Blogposts;
