import React, { useEffect, useRef, useState } from "react";
import "./register.css";
import "../../components/button/button.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import { ToastError, ToastSuccess } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Navbar3 from "../../components/navbar3/navbar3";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Register2 = () => {
  const navigate = useNavigate();
  const phone = useRef();
  const salary = useRef();
  const jobType = useRef();
  const cv = useRef();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("contactNo", phone.current.value);
    formData.append("salary", salary.current.value);
    formData.append("jobType", jobType.current.value);
    formData.append("cv", cv.current.files[0]);
    try {
      await axios.patch(`/candidates/register/2/${id}`);
      setSuccess(true);
      setSuccessMessage("Success!");
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/register/3/${id}`);
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const viewPolicy = () => {
    window.location.href = "/privacy-policy";
  };

  const viewTerms = () => {
    window.location.href = "/terms-and-conditions";
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>
        <p>We just need a few details about you to build your profile.</p>
        <div className="register_form">
          <h3 className="register_form_h3_start">Personal details</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Contact Number</label>
                <br />
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="Contact Number"
                  ref={phone}
                  required
                ></input>
              </div>
            </div>
          </form>
        </div>
        <div className="register_form">
          <h3 className="register_form_h3_start">Employment details</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Realistic Expected Salary</label>
                <br />
                <select name="" id="" ref={salary} required>
                  <option value="Please Select ">Please Select</option>
                  <option value="$1- $10,000">$1- $10,000</option>
                  <option value="$10,001-$15,000">$10,001-$15,000</option>
                  <option value="15,001- $20,000">$15,001- $20,000</option>
                  <option value="$20,001- $25,000">$20,001- $25,000</option>
                  <option value="$25,001- $30,000">$25,001- $30,000</option>
                  <option value="$30,001- $40,000">$30,001- $40,000</option>
                  <option value="$40,001- $60,000">$40,001- $60,000</option>
                  <option value="$60,001- $80,000">$60,001- $80,000</option>
                  <option value="$80,001- $100,000">$80,001- $100,000</option>
                  <option value="$100,001- $120,000">$100,001- $120,000</option>
                  <option value="$120,001">$120,001</option>
                </select>
              </div>
            </div>
          </form>

          <div className="register_div_jobtype">
            <p>Job Type</p>
            <input
              type="radio"
              id="Permanent"
              name=""
              value="Permanent"
              ref={jobType}
            />
            <label htmlFor="Permanent">Permanent</label>
            <br />
            <input
              type="radio"
              id="Contract"
              name=""
              value="Contract"
              ref={jobType}
            />
            <label htmlFor="Contract">Contract</label>
            <br />
            <input
              type="radio"
              id="Temporary"
              name=""
              value="Temporary"
              ref={jobType}
            />
            <label htmlFor="Temporary">Temporary</label>
            <br />
            <input
              type="radio"
              id="Part Time"
              name=""
              value="Part Time"
              ref={jobType}
            />
            <label htmlFor="Part Time">Part Time</label>
          </div>
        </div>
        <div className="register_form">
          <h3 className="register_form_h3_start">Upload Your CV</h3>

          <hr />
          <form>
            <div className="register_form_div">
              <div className="register_options register_options_label">
                <input id="cv" type="file" ref={cv} required />
                <label>Upload your cv</label>
              </div>
            </div>
          </form>
        </div>

        <div>
          <input id="keyword" type="checkbox" required></input>
          <p className="register_terms_conditions">
            By registering with Alphy Johnson's Employment Center you agree to
            our
            <span onClick={viewPolicy}> privacy policy </span> and
            <span onClick={viewTerms}> Term & Conditions</span>{" "}
          </p>
        </div>
        <div className="button_maindiv">
          <button onClick={handleSubmit}>Complete Registration</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register2;
