import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useAuthContext } from "./AuthContext";
import ProfileReducer from "./ProfileReducer";
import * as api from "../ApiCalls";
import { ACTIONS, INITIAL_STATE } from "./ActionTypes";

export const ProfileContext = createContext(INITIAL_STATE);

export const ProfileContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProfileReducer, INITIAL_STATE);
  const { user } = useAuthContext();

  const fetchJobs = useCallback(async () => {
    try {
      const res = await api.fetchJobs(user?.role);
      dispatch({ type: ACTIONS.fetch_jobs, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchApplications = async () => {
    try {
      const res = await api.fetchApplications();
      dispatch({ type: ACTIONS.fetch_applications, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const fetchPosts = useCallback(async () => {
    try {
      const res = await api.fetchPosts(user?.role);
      dispatch({ type: ACTIONS.fetch_posts, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchSavedJobs = async () => {
    try {
      const res = await api.fetchSavedJobs();
      dispatch({ type: ACTIONS.fetch_saved_jobs, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const fetchTeam = async () => {
    try {
      const res = await api.fetchTeam();
      dispatch({ type: ACTIONS.fetch_team, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const fetchDevices = useCallback(async () => {
    try {
      const res = await api.fetchDevices(user?.role);
      dispatch({ type: ACTIONS.fetch_devices, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchJobAlerts = async () => {
    try {
      const res = await api.fetchJobAlerts();
      dispatch({ type: ACTIONS.fetch_job_alerts, payload: res });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const fetchProfileData = useCallback(async () => {
    try {
      dispatch({ type: ACTIONS.fetch_start });
      if (user?.role === "employer" || user?.role === "company") {
        await fetchJobs();
      }
      if (user?.role === "jobseeker") {
        await fetchApplications();
        await fetchSavedJobs();
        await fetchJobAlerts();
      }
      if (user?.role === "company") {
        await fetchTeam();
      }
      if (
        user?.role === "jobseeker" ||
        user?.role === "employer" ||
        user?.role === "company"
      ) {
        await fetchPosts();
        await fetchDevices();
      }
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTIONS.fetch_failure });
    }
  }, [user?.role, fetchJobs, fetchPosts, fetchDevices]);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  return (
    <ProfileContext.Provider value={{ state, dispatch, fetchProfileData }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
