import Header from "../../components/header1/Header";
import Mission from "../../components/mission/Mission";
import Workingcv from "../../components/working/Workingcv";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/about-us" />

        <title>About Us | Learn More About Our Mission and Team</title>

        <meta
          name="description"
          content="Discover who we are & what drives us. Learn about our mission, values, & team dedicated to helping you achieve the career goals. Get to know us better today!"
        />
      </Helmet>

      <Navbar3 />
      <Header />
      <Mission />
      <Workingcv />
      <Footer />
    </>
  );
}

export default About;
