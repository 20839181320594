import { ACTIONS } from "./actionTypes";

export const change_show = (name) => {
  return {
    type: ACTIONS.CHANGE_SHOW,
    payload: {
      name,
    },
  };
};

export const change_mode = () => {
  return {
    type: ACTIONS.CHANGE_MODE,
  };
};

export const change_modal = () => {
  return {
    type: ACTIONS.change_modal,
  };
};

export const change_update_modal = () => {
  return {
    type: ACTIONS.change_update_modal,
  };
};

export const change_update_profile_modal = () => {
  return {
    type: ACTIONS.change_update_profile_modal,
  };
};
