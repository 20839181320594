import { useState, useEffect, useRef } from "react";

const LazyBackground = ({ src, className, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      },
    );

    observer.observe(containerRef.current);
    const refValue = containerRef.current;

    return () => {
      if (refValue) observer.disconnect();
    };
  }, []);

  // Styles for the gradient background
  const gradientBackground = `linear-gradient(90deg, rgba(29, 29, 29, 0.52) 50%, rgba(65, 65, 65, 0.568) 100%)`;

  const combinedStyle = {
    backgroundImage: isLoaded
      ? `${gradientBackground}, url(${src})`
      : gradientBackground,
    transition: "background-image 0.5s ease-in-out",
  };

  return (
    <div ref={containerRef} className={className} style={combinedStyle}>
      {children}
    </div>
  );
};

export default LazyBackground;
