import React, { useCallback, useEffect, useState } from "react";
import { FaClock, FaDollarSign, FaHeart, FaOptinMonster, FaUser } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import "./CourseDetails.css";
import Navbar3 from "../navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../Loader";
import OverlayLoader from "../loader/OverlayLoader";
import LazyImage from "../common/LazyImage";

const CourseDetails = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const courseId = id;
  const [course, setCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [activeCourses, setActiveCourses] = useState([]);
  const [isCourseSaved, setIsCourseSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchSavedCourses = useCallback(async () => {
    const response = await axios.get(`/users/courses/saved`);
    // console.log(response.data?.courses);
    const arr = response.data?.courses;
    const isFound = arr.find((obj) => obj?._id === id);
    setIsCourseSaved(isFound);
  }, [id]);

  useEffect(() => {
    try {
      const fetchCourseDetails = async () => {
        const response = await axios.get(`/courses/${courseId}`);
        // console.log(response.data.data.courseMetadata);
        setCourse(response.data.data.courseMetadata);
      };
      const fetchAllCourses = async () => {
        const res = await axios.get(`/courses/approved-courses`);
        // console.log(res.data.data);
        const newData = res.data.data;
        setCourses(newData?.reverse());
      };

      const fetchData = async () => {
        setIsFetching(true);
        if (user && user?.role === "user") {
          await fetchSavedCourses();
          await fetchActiveCourses();
        }
        await fetchCourseDetails();
        await fetchAllCourses();

        setIsFetching(false);
      };

      fetchData();
    } catch (error) {
      console.log(error?.response);
      setIsFetching(false);
    }
  }, [courseId, user, fetchSavedCourses]);

  const fetchActiveCourses = async () => {
    const response = await axios.get(`/users/courses/active`);
    // console.log(response.data?.courses);
    const arr = response.data?.courses;
    setActiveCourses(arr);
  };

  const viewCourse = (id) => {
    window.location.href = `/courses/${id}`;
  };

  const handleSaveClick = async () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login to continue");
      return;
    }
    if (user && user?.role !== "user") {
      setError(true);
      setErrorMessage("Please login as a course user to continue");
      return;
    }
    try {
      setIsLoading(true);
      await axios
        .post(`/users/courses/save`, {
          courseId: id,
        })
        .then((res) => {
          setSuccess(true);
          setSuccessMessage("Course saved successfully");
          fetchSavedCourses();
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const unSaveCourse = async () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login to continue");
      return;
    }
    if (user && user?.role !== "user") {
      setError(true);
      setErrorMessage("Please login as a course user to continue");
      return;
    }
    try {
      setIsLoading(true);
      await axios
        .post(`/users/courses/unsave`, {
          courseId: id,
        })
        .then((res) => {
          setSuccess(true);
          setSuccessMessage("Course removed from saved successfully");
          fetchSavedCourses();
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const buyCourse = async (id) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login to continue");
      return;
    }
    if (user && user?.role !== "user") {
      setError(true);
      setErrorMessage("Please login as a course user to continue");
      return;
    } else {
      window.location.href = `/checkout/${id}?type=buy-course`;
    }
  };

  const watchCourse = async (id) => {
    if (user && (user?.role === "user" || user?.role === "admin"))
      window.location.href = `/dashboard/active-courses`;
    else {
      setError(true);
      setErrorMessage("Please login as a course user to continue");
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="container1">
        <div className="container">
          {isFetching ? (
            <div className="course_loading_div">
              <Loader />
            </div>
          ) : (
            <>
              {course ? (
                <>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="course_header_div">
                        <h1 className="course_title">{course?.title}</h1>
                        <p className="course_description">
                          {course?.description.length > 300
                            ? course?.description.slice(0, 300) + "..."
                            : course?.description}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <LazyImage
                        className="course_thumbnail"
                        src={course?.coverImage}
                        alt={course?.title}
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>

                  <div className="row course_meta_main_div">
                    <div className="course_meta_div">
                      <FaClock />
                      <p>
                        {"  "}
                        {course?.duration + " Hours"}
                      </p>
                    </div>
                    <div className="course_meta_div">
                      <FaOptinMonster />
                      <p>{course?.category}</p>
                      <p style={{ cursor: "pointer" }}>
                        {isCourseSaved ? (
                          <FaHeart
                            title="Remove"
                            onClick={unSaveCourse}
                            color="rgb(255, 101, 101)"
                          />
                        ) : (
                          <FaHeart title="Add" onClick={handleSaveClick} color="grey" />
                        )}
                      </p>
                    </div>
                    <div className="course_meta_div">
                      <FaUser />
                      <p>Self-paced</p>
                    </div>
                    <div className="course_meta_div">
                      <FaDollarSign />
                      <p>{course?.price}</p>
                      {course?.userId?._id !== user?._id &&
                        !activeCourses?.find((obj) => obj?._id === courseId) && (
                          <button
                            className="course_buy_button cv_build_btn"
                            onClick={() => buyCourse(courseId)}
                          >
                            Buy Course
                          </button>
                        )}
                      {activeCourses?.find((obj) => obj?._id === courseId) && (
                        <button
                          className="course_buy_button cv_build_btn"
                          onClick={() => watchCourse(courseId)}
                        >
                          Watch
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 course_desc_row">
                      <h1 className="course_title">About this course</h1>
                      <p className="course_description">{course?.description}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="container d-flex justify-content-center align-items-center">
                  Network Error!!
                </div>
              )}
            </>
          )}

          <div className="row recommended_row">
            <h1
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
                textDecoration: "underline",
                marginBottom: "1rem",
              }}
            >
              <b>Recommended Courses</b>
            </h1>
            {isFetching ? (
              <div className="course_loading_div">
                <Loader />
              </div>
            ) : (
              <>
                {courses?.length > 0 ? (
                  <>
                    {courses?.slice(0, 10)?.map((course) => {
                      return (
                        <div
                          className="course_row"
                          key={course?._id}
                          onClick={() => viewCourse(course?._id)}
                        >
                          <LazyImage
                            className="course_cover_img"
                            src={course?.courseMetadata?.coverImage}
                            alt={course?.courseMetadata?.title}
                            crossOrigin="anonymous"
                          />
                          <div className="course_metadata">
                            <p>
                              <b>{course?.courseMetadata?.title}</b>
                            </p>
                            <div className="metadata_subdiv">
                              <p className="course_price">{course?.courseMetadata?.price + " $"}</p>
                              <p>
                                <b>{course?.courseMetadata?.category}</b>
                              </p>
                            </div>
                            <p className="course_hours">
                              {course?.courseMetadata?.duration + " Hours"}
                            </p>
                            <p>{course?.courseMetadata?.type + " Series"}</p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data Exists!!
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetails;
