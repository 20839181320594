import React, { useState, useEffect } from "react";
import "./companyz.css";
import { Link, useNavigate } from "react-router-dom";
import blog from "../../images/4.jpg";
import blog2 from "../../images/companyhome.jpg";
import owl from "../../images/owl.png";
import Navbar3 from "../../components/navbar3/navbar3";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { useAuthContext } from "../../context/AuthContext";
import { IMAGES, Industries } from "../../constants/GlobalConstants";
import { AiOutlineSearch } from "react-icons/ai";
import { Helmet } from "react-helmet";

function AllCompanies() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const Letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  Industries.sort();

  useEffect(() => {
    setError(false);
  }, [error]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleAlphabetClick = (letter) => {
    window.location.href = `/company/employer?alphabet=${letter}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/company/employer?name=${searchQuery}`);
  };

  const handleSearch = (name, value) => {
    navigate(`/job-recruiting-websites?${[name]}=${encodeURIComponent(value)}`);
  };

  const hanldeJobseekerClicks = () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      window.location.href = "/buildcv";
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Companies | Find Top Employers and Opportunities</title>

        <link rel="canonical" href="https://thealphyjohnson.com/search-company" />
        <meta
          name="description"
          content="Use our search tool to find top companies & explore job opportunities. Get detailed insights about potential employers & find the best fit for your career goals."
        />
      </Helmet>
      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      <div className="container-fluid main_company_A-z  ">
        <div className="container pt-5">
          <motion.h1
            className="text-center header-heading"
            initial={{ opacity: 0 }}
            animate={{ opacity: isVisible ? 1 : 0.5 }}
            transition={{ duration: 4 }}
          >
            Search Companies by Industry & Location in the USA
          </motion.h1>

          <p className="text-center">
            Looking for a job in the USA? Our platform offers a wide range of opportunities from
            leading companies in various sectors like Healthcare, Finance, Engineering, and more.
            With an easy-to-use search function, you can filter companies based on location,
            industry, or job role. Don't miss out on the latest job openings—register today to
            receive tailored job alerts directly to your inbox!
          </p>
          <div className="container-fluid mt-5 main_search_employers_section">
            <div className="container d-flex justify-content-around company-a-z_links_both mt-4">
              <Link className="nav-link text-white fs-8 fw-bold heading-font">
                SEARCH <span className="homepage_title_alt">COMPANIES</span>
              </Link>
              {/* <Link className="nav-link text-white company-a-z_links">
                Search Recruitment Agencies
              </Link> */}
            </div>
            <div className="container-fluid">
              <form onSubmit={handleSubmit} className="company_form mt-4">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control company_a-z_input"
                  id="inputPassword2"
                  placeholder="Company Name"
                />
                <button type="submit" className="mb-3 company_a-z_button">
                  Search Companies <AiOutlineSearch />
                </button>
              </form>
            </div>

            {/* links */}
            <div className="container-fluid d-flex justify-content-around aligns-items-center mt-3 company_a-z_multifl_links_first_all">
              {/* <Link
                className="company_a-z_multifl_links_first"
                onClick={() => handleAlphabetClick("0-9")}
              >
                0-9
              </Link> */}
              {Letters?.map((item) => {
                return (
                  <Link
                    key={item}
                    className="company_a-z_multifl_links"
                    onClick={() => handleAlphabetClick(item)}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <div className="container-fluid d-flex justify-content-around company_a-z_cards">
          <motion.div
            className="card"
            style={{ width: "17rem" }}
            whileHover={{ scale: 1.05 }}
            // whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <img
              src={IMAGES.img1}
              className="card-img-top companyaz-pic"
              alt="all companies"
              loading="lazy"
            />
            <div className="card-body company_page_card_body">
              <h5 className="card-title">Need a new Resume?</h5>
              <p className="card-text">
                Create a professional Resume or CV and start applying for jobs today with our free
                Resume or CV Designer.
              </p>
              <button className="btn btn-primary" onClick={hanldeJobseekerClicks}>
                Design My Resume or CV
              </button>
            </div>
          </motion.div>
          <motion.div
            className="card"
            style={{ width: "17rem" }}
            whileHover={{ scale: 1.05 }}
            // whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <img
              src={owl}
              className="card-img-top companyaz-pic"
              alt="register cv"
              loading="lazy"
            />
            <div className="card-body company_page_card_body">
              <h5 className="card-title">Register your CV</h5>
              <p className="card-text">
                Upload your CV today to be headhunted by the World's top companies.
              </p>
              <a href="/register" className="btn btn-primary">
                Register Now
              </a>
            </div>
          </motion.div>
          <motion.div
            className="card"
            style={{ width: "17rem" }}
            whileHover={{ scale: 1.05 }}
            // whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <img src={blog} className="card-img-top companyaz-pic" alt="blog" loading="lazy" />
            <div className="card-body company_page_card_body">
              <h5 className="card-title">Career Guidance</h5>
              <p className="card-text">
                Career tips, including salary guides, tax calculators and interview advice, helping
                you get your next job.
              </p>
              <a href="/career-advice" className="btn btn-primary">
                View career Guidance
              </a>
            </div>
          </motion.div>
          <motion.div
            className="card"
            style={{ width: "17rem" }}
            whileHover={{ scale: 1.05 }}
            // whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <img src={blog2} className="card-img-top companyaz-pic" alt="blog 2" loading="lazy" />
            <div className="card-body company_page_card_body">
              <h5 className="card-title">Companies Searching</h5>
              <p className="card-text">
                Follow your favourite companies and receive instant email alerts when they post new
                jobs.
              </p>
              <a href="/search-company" className="btn btn-primary">
                Search companies
              </a>
            </div>
          </motion.div>
        </div>
      </div>

      <div
        className="container-fluid mt-5 d-flex flex-wrap"
        style={{ backgroundColor: "var(--blue)" }}
      >
        <div className="container flex-wrap buildmycv_div pb-5">
          <h2 className="home_content_heading text-center text-white pt-5">
            Here's how our platform can assist you in finding job opportunities within specific
            companies:
          </h2>
          <div className="home_content_columns_div">
            <div className="home_content_column job_board2">
              <h3 className="home_content_heading">Extensive Company Database</h3>
              <p>
                Our platform offers a comprehensive directory featuring a wide range of companies
                and recruitment agencies across the USA. Whether you're looking at established
                corporations or niche, industry-specific firms, you'll find a diverse selection of
                companies offering job opportunities in various sectors.
              </p>

              <h3 className="home_content_heading mt-4">Targeted Job Search</h3>
              <p>
                Easily focus your job search by company name with our powerful search tool. Narrow
                down your search to organizations that match your career aspirations and goals. Our
                targeted search feature allows you to discover jobs at companies that align with
                your interests and values, making your job hunt more efficient and personalized.
              </p>
            </div>

            <div className="home_content_column job_board2">
              <h3 className="home_content_heading">In-Depth Company Insights</h3>
              <p>
                Go beyond job listings and get to know the companies you’re interested in. Our
                business directory provides detailed company profiles where you can explore their
                mission, vision, values, and organizational culture. This deeper insight helps you
                make informed decisions, ensuring the company you choose aligns with your
                professional preferences and long-term goals.
              </p>

              <h3 className="home_content_heading mt-4">Diverse Industries and Sectors</h3>
              <p>
                Our platform connects you to a broad range of industries. Whether you’re eyeing
                opportunities in tech, finance, healthcare, or the creative fields, you’ll find
                companies across all major sectors. With such a variety of options, you’re more
                likely to discover a job that suits your skills and passions, increasing your
                chances of landing the right role.
              </p>
            </div>
          </div>

          <p className="text-center text-white mt-5">
            By using our <a href="/job-recruiting-websites">job search platform</a>, you can easily
            explore job opportunities with top companies and recruitment agencies across the USA.
            With access to a vast database, advanced search options, and detailed company profiles,
            you’ll discover exciting career paths that align with your goals. Start your search
            today and take the first step toward unlocking new opportunities for professional growth
            and success.
          </p>
        </div>
      </div>

      <div className="container company_a-z_overall_links " style={{ marginTop: "5rem" }}>
        <div className="container company_a-z_overall_links_title mt-4">
          <h2 className="text-center text-center fs-3"> INDUSTRIES</h2>
        </div>
        <div className="container-fluid d-flex justify-content-around mt-4 mb-4 industries_links">
          <ul>
            {Industries?.slice(0, 3)?.map((item) => {
              return (
                <li
                  key={item}
                  style={{
                    listStyle: "none",
                    color: "#4488d5",
                    fontSize: "15px",
                    paddingTop: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSearch("industry", item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
          <ul>
            {Industries?.slice(3, 6)?.map((item) => {
              return (
                <li
                  key={item}
                  style={{
                    listStyle: "none",
                    color: "#4488d5",
                    fontSize: "15px",
                    paddingTop: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSearch("industry", item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
          <ul>
            {Industries?.slice(6, 9)?.map((item) => {
              return (
                <li
                  key={item}
                  style={{
                    listStyle: "none",
                    color: "#4488d5",
                    fontSize: "15px",
                    paddingTop: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSearch("industry", item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
          <ul>
            {Industries?.slice(9, 12)?.map((item) => {
              return (
                <li
                  key={item}
                  style={{
                    listStyle: "none",
                    color: "#4488d5",
                    fontSize: "15px",
                    paddingTop: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSearch("industry", item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default AllCompanies;
