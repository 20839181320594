import React, { useRef } from "react";
import Button from "../../components/button/button";

const SearchBar = () => {
  const location = useRef();
  const keyword = useRef();

  const findJobs = () => {
    window.location.href = `/job-recruiting-websites?location=${location.current.value}&keyword=${keyword.current.value}`;
  };

  return (
    <div className="search_jobs section-padding">
      <form>
        <label htmlFor="keyword">Keywords:</label>
        <input
          id="keyword"
          className="text"
          ref={keyword}
          type="text"
          placeholder="eg: Sales Executive"
        ></input>
        <label htmlFor="location">Location:</label>
        <input
          id="location"
          className="text"
          ref={location}
          type="text"
          placeholder="eg: postcode or town"
        ></input>
        <br />
      </form>
      <div className="search_jobs_btn">
        <Button onclick={findJobs} text={"Find jobs"} />
      </div>
    </div>
  );
};

export default SearchBar;
