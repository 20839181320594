import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useAuthContext } from "./AuthContext";
import AdminPanelReducer from "./AdminPanelReducer";
import * as api from "../ApiCalls";
import * as apiCalls from "../ManagementApiCalls";
import { ADMIN_PANEL_ACTIONS, INITIAL_ADMIN_PANEL_STATE } from "./ActionTypes";
import { AdditionalRoles } from "../constants/GlobalConstants";

export const AdminPanelContext = createContext(INITIAL_ADMIN_PANEL_STATE);

export const AdminPanelContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    AdminPanelReducer,
    INITIAL_ADMIN_PANEL_STATE,
  );
  const { user } = useAuthContext();

  const fetchAllCandidates = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllCandidates();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchAllCandidates();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_candidates, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAllEmployers = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllEmployers();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchAllEmployers();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_employers, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAllCompanies = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllCompanies();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchAllCompanies();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_companies, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAllUsers = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllUsers();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchCourseUsers();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_course_users, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAllPosts = async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      const res = await api.fetchAllPosts();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_posts, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  };

  const fetchAllPlans = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllPlans();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchAllPlans();
      dispatch({
        type: ADMIN_PANEL_ACTIONS.fetch_checkout_plans,
        payload: res,
      });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAllCourses = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchAllCourses();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchAllCourses();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_courses, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchPremiumPlans = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchPremiumPlans();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchPremiumPlans();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_premium_plans, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchHiringOrders = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchHiringOrders();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchHiringOrders();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_hiring_orders, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchEnquiries = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchEnquiries();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchEnquiries();
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_enquiries, payload: res });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchContactQueries = useCallback(async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      let res;
      if (user?.role === "admin") res = await api.fetchContactQueries();
      if (AdditionalRoles.includes(user?.role))
        res = await apiCalls.fetchContactQueries();
      dispatch({
        type: ADMIN_PANEL_ACTIONS.fetch_contact_us_queries,
        payload: res,
      });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  }, [user?.role]);

  const fetchAdditionalUsers = async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      const res = await api.fetchAllAdditionalUsers();
      dispatch({
        type: ADMIN_PANEL_ACTIONS.fetch_management_users,
        payload: res,
      });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  };

  const fetchConfigurations = async () => {
    try {
      const res = await api.fetchConfigurations();
      dispatch({
        type: ADMIN_PANEL_ACTIONS.fetch_configurations,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllLogs = async () => {
    try {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });
      const res = await api.fetchAllLogs();
      dispatch({
        type: ADMIN_PANEL_ACTIONS.fetch_user_logs,
        payload: res,
      });
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
    }
  };

  useEffect(() => {
    const fetchAdminPanelData = async () => {
      try {
        dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_start });

        if (user?.role === "admin") {
          await fetchAllCandidates();
          await fetchAllEmployers();
          await fetchAllCompanies();
          await fetchAllUsers();
          await fetchAllPlans();
          await fetchAllCourses();
          await fetchPremiumPlans();
          await fetchHiringOrders();
          await fetchEnquiries();
          await fetchContactQueries();
          await fetchAdditionalUsers();
          await fetchAllLogs();
          fetchConfigurations();
        } else if (AdditionalRoles.includes(user?.role)) {
          if (user?.permissions.view_candidates) await fetchAllCandidates();
          if (user?.permissions.view_employers) await fetchAllEmployers();
          if (user?.permissions.view_companies) await fetchAllCompanies();
          if (user?.permissions.view_course_users) await fetchAllUsers();
          if (user?.permissions.view_checkout_plans) await fetchAllPlans();
          if (user?.permissions.view_premium_plans) await fetchPremiumPlans();
          if (user?.permissions.view_hiring_orders) await fetchHiringOrders();
          if (user?.permissions.view_courses) await fetchAllCourses();
          if (user?.permissions.view_enquiries) await fetchEnquiries();
          if (user?.permissions.view_contact_us_queries)
            await fetchContactQueries();
        }
        await fetchAllPosts();

        dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_success });
      } catch (error) {
        console.log(error);
        dispatch({ type: ADMIN_PANEL_ACTIONS.fetch_failure });
      }
    };

    fetchAdminPanelData();
  }, [
    user,
    fetchAllCandidates,
    fetchAllEmployers,
    fetchAllCompanies,
    fetchAllCourses,
    fetchAllPlans,
    fetchAllUsers,
    fetchContactQueries,
    fetchEnquiries,
    fetchHiringOrders,
    fetchPremiumPlans,
  ]);

  return (
    <AdminPanelContext.Provider
      value={{
        state,
        dispatch,
        fetchAllCandidates,
        fetchAllEmployers,
        fetchAllCompanies,
        fetchAllUsers,
        fetchAllPosts,
        fetchAllPlans,
        fetchAllCourses,
        fetchPremiumPlans,
        fetchHiringOrders,
        fetchEnquiries,
        fetchContactQueries,
        fetchAdditionalUsers,
        fetchAllLogs,
        fetchConfigurations,
      }}
    >
      {children}
    </AdminPanelContext.Provider>
  );
};

export const useAdminPanelContext = () => {
  return useContext(AdminPanelContext);
};
