import React from "react";
import "./formright.css";
import { useNavigate } from "react-router-dom";

function Formright() {
  const navigate = useNavigate();

  return (
    <div className="rec_session_right">
      <h2>Are you a new customer?</h2>
      <p>
        Join the numerous companies and recruitment agencies who trust the leading global job board.
      </p>
      <ul>
        <li>Access a vast pool of Resumes CVs to find the perfect candidates.</li>
        <li>Advertise job openings across an unparalleled network of sites.</li>
        <li>Experience award-winning customer service for a seamless hiring process.</li>
      </ul>

      <div className="rec-login-enquire">
        <button className="form_btn btn-primary" onClick={() => navigate("/enquirenow")}>
          Enquire now
        </button>
      </div>
    </div>
  );
}

export default Formright;
