import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

const UpdateCourse = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const courseId = id;
  const [modules, setModules] = useState([{ name: "" }]);
  const [currentModules, setCurrentModules] = useState([]);
  const [selectedModule, setSelectedModules] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [currentVideos, setCurrentVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    title: "",
    coverImage: null,
    price: "",
    category: "",
    type: "",
    duration: "",
    description: "",
    certificate: null,
  });
  const [show, setShow] = useState({
    showEdit: true,
    showAddModules: false,
    showAddVideos: false,
  });

  const categoryOptions = [
    "Business and Entrepreneurship",
    "Technology and Programming",
    "Arts and Creativity",
    "Language Learning",
    "Personal Development",
    "Health and Wellness",
    "Science and Engineering",
    "Social Sciences and Humanities",
    "Beauty Tips",
    "Staff Training",
  ];

  useEffect(() => {
    setError(false);
    setSuccess(false);
    setErrorMessage("");
    setSuccessMessage("");
  }, [error, success]);

  useEffect(() => {
    if (
      !user &&
      (user?.role !== "user" || user?.role !== "admin") &&
      !user?.courses.includes(id)
    ) {
      setError(true);
      setErrorMessage("You are not allowed to update course!!");
      navigate(-1);
      return;
    }
  }, [id, user, navigate]);

  useEffect(() => {
    setIsFetching(true);
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`/courses/${id}`);
        const meta = response.data.data.courseMetadata;
        setCourseData({
          title: meta?.title,
          price: meta?.price,
          category: meta?.category,
          type: meta?.type,
          duration: meta?.duration,
          description: meta?.description,
        });
        console.log(response.data.data);
        setCurrentModules(response.data.data?.modules);
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    fetchCourseDetails();
    setTimeout(() => {
      setIsFetching(false);
    }, 1500);
  }, [id, currentVideos]);

  const handleInputChange = (e) => {
    setCourseData({ ...courseData, [e.target.name]: e.target.value });
  };

  const handleFileInputChange = (e) => {
    setCourseData({ ...courseData, [e.target.name]: e.target.files[0] });
  };

  const handleUpdateCourse = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("courseMetadata[title]", courseData.title);
      formData.append("courseMetadata[price]", courseData.price);
      formData.append("courseMetadata[category]", courseData.category);
      formData.append("courseMetadata[type]", courseData.type);
      formData.append("courseMetadata[duration]", courseData.duration);
      formData.append("courseMetadata[description]", courseData.description);
      if (courseData.coverImage)
        formData.append("coverImage", courseData.coverImage);
      if (courseData.certificate)
        formData.append("certificate", courseData.certificate);

      await axios.patch(`/courses/${id}`, formData).then((res) => {
        setSuccess(true);
        setSuccessMessage("Course updated successfully");
        setIsLoading(false);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleShow = (name) => {
    const newShow = { ...show };
    const updatedObj = Object.keys(newShow).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    updatedObj[name] = true;
    setShow(updatedObj);
  };

  const handleModuleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedModules = [...modules];
    updatedModules[index] = { ...updatedModules[index], [name]: value };
    setModules(updatedModules);
  };

  const handleAddModule = () => {
    setModules([...modules, { name: "" }]);
  };

  const handleCancel = () => {
    setModules([{ name: "" }]);
  };

  const handleAddModules = async () => {
    const modulesData = {
      modules: modules.filter(
        (module) => module.name !== "" && module.duration !== ""
      ),
    };

    try {
      setIsLoading(true);
      await axios
        .patch(`/courses/modules/${courseId}`, modulesData)
        .then((res) => {
          setSuccess(true);
          setSuccessMessage("Module added successfully");
          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
          setCurrentModules(res?.data?.modules?.modules);
          handleCancel();
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  function isObjectEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const handleAddVideo = () => {
    if (isObjectEmpty(selectedModule)) {
      setError(true);
      setErrorMessage("Please select a module first to add video!!");
      return;
    }
    setVideos([...videos, { title: "", videoFile: null }]);
  };

  const handleVideoCancel = () => {
    setVideos([]);
  };

  const handleVideoChange = (e, index) => {
    const { name, value } = e.target;
    const updatedVideos = [...videos];
    updatedVideos[index] = { ...updatedVideos[index], [name]: value };
    setVideos(updatedVideos);
  };

  const handleVideoFileChange = (e, index) => {
    const file = e.target.files[0];
    const updatedVideos = [...videos];
    updatedVideos[index] = { ...updatedVideos[index], videoFile: file };
    setVideos(updatedVideos);
  };

  const handleAddVideos = async () => {
    const videosData = videos.filter(
      (module) => module.title !== "" && module.videoFile !== null
    );
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("moduleId", selectedModule?._id);

      videosData.forEach((video) => {
        formData.append(`title`, video.title);
        formData.append(`video`, video.videoFile);
      });

      await axios.patch(`/courses/videos/${courseId}`, formData).then((res) => {
        setSuccess(true);
        setSuccessMessage(res?.data?.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
        setCurrentVideos(res?.data?.module?.videos);
        handleVideoCancel();
        return;
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleSelectModule = (id) => {
    const mod = currentModules.find((obj) => obj?._id === id);
    setSelectedModules(mod);
    setCurrentVideos(mod?.videos);
  };

  const handleDeleteModule = async (id) => {
    try {
      setIsLoading(true);
      await axios
        .patch(`/courses/remove-modules/${courseId}/modules/${id}`)
        .then((res) => {
          setSuccess(true);
          setSuccessMessage("Module removed successfully");
          setTimeout(() => {
            setIsLoading(false);
          }, 1500);

          setCurrentModules(res?.data?.modules);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleDeleteVideo = async (id) => {
    try {
      setIsLoading(true);
      await axios
        .patch(`/courses/remove-video/${courseId}/videos/${id}`, {
          moduleId: selectedModule?._id,
        })
        .then((res) => {
          setSuccess(true);
          setSuccessMessage("Video removed successfully");
          setTimeout(() => {
            setIsLoading(false);
          }, 1500);
          const newVideos = currentVideos.reduce((acc, obj) => {
            if (obj._id !== id) {
              acc.push(obj);
            }
            return acc;
          }, []);
          setCurrentVideos(newVideos);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <div className="stats-items">
          <div
            onClick={() => handleShow("showEdit")}
            className="stats-item"
            id={show.showEdit ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Update Course</h3>
          </div>
          <div
            onClick={() => handleShow("showAddModules")}
            className="stats-item"
            id={show.showAddModules ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Add Modules</h3>
          </div>
          <div
            onClick={() => handleShow("showAddVideos")}
            className="stats-item"
            id={show.showAddVideos ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Add Videos</h3>
          </div>
        </div>

        {show.showEdit && (
          <>
            <h2>Welcome to Alphy Johnson's Employment Center</h2>
            <p>We just need a few details to create your Course.</p>

            <div className="register_form">
              <h2>Update Course</h2>
              <hr />

              {isFetching ? (
                <div className="container d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                <form>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Title</label>
                      <br />
                      <input
                        type="text"
                        name="title"
                        value={courseData.title}
                        onChange={handleInputChange}
                        placeholder="Course Title"
                      />
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Price</label>
                      <br />
                      <input
                        type="number"
                        name="price"
                        value={courseData.price}
                        onChange={handleInputChange}
                        placeholder="Price"
                      />
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Category</label>
                      <br />
                      <select
                        name="category"
                        value={courseData.category}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="Please Select ">Please Select</option>
                        {categoryOptions.sort().map((name) => {
                          return (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Type</label>
                      <br />
                      <select
                        name="type"
                        value={courseData.type}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="Please Select ">Please Select</option>
                        <option value="video">Video</option>
                        <option value="article">Article</option>
                      </select>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Duration in Hours</label>
                      <br />
                      <input
                        type="number"
                        name="duration"
                        value={courseData.duration}
                        onChange={handleInputChange}
                        placeholder="Duration"
                      />
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Course Description</label>
                      <br />
                      <textarea
                        rows={4}
                        style={{
                          resize: "none",
                          outline: "none",
                          fontSize: "16px",
                        }}
                        type="text"
                        name="description"
                        value={courseData.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                      />
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Cover Image</label>
                      <br />
                      <input
                        type="file"
                        accept="image/*"
                        name="coverImage"
                        onChange={handleFileInputChange}
                      />
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Certificate File</label>
                      <br />
                      <input
                        type="file"
                        name="certificate"
                        onChange={handleFileInputChange}
                        placeholder="certificate"
                      />
                    </div>
                  </div>
                </form>
              )}
              <div className="button_maindiv">
                <button onClick={handleUpdateCourse}>Submit</button>
              </div>
            </div>
          </>
        )}
        {show.showAddModules && (
          <div className="register_form">
            <h2>Current Modules</h2>
            <hr />

            {currentModules.map((module) => (
              <div className="register_form_div" key={module?._id}>
                <div className="register_options">
                  <label>Module Name: </label>
                  <br />
                  <input type="text" name="name" value={module?.name} />
                </div>
                <button
                  className="cv_build_btn"
                  onClick={() => handleDeleteModule(module?._id)}
                >
                  Delete Module
                </button>
              </div>
            ))}

            <br />
            <h2>New Modules</h2>
            <hr />

            {modules.map((module, index) => (
              <>
                <div className="register_form_div" key={index}>
                  <div className="register_options">
                    <label htmlFor={`moduleName${index}`}>Module Name: </label>
                    <br />
                    <input
                      type="text"
                      id={`moduleName${index}`}
                      name="name"
                      value={module.name}
                      onChange={(e) => handleModuleChange(e, index)}
                    />
                  </div>
                </div>
              </>
            ))}

            <button className="cv_build_btn" onClick={handleAddModule}>
              Add Module
            </button>
            <button className="cv_build_btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="cv_build_btn" onClick={handleAddModules}>
              Submit
            </button>
          </div>
        )}

        {show.showAddVideos && (
          <div className="register_form">
            <h2>Add Videos</h2>
            <hr />
            <div className="register_form_div">
              <div className="register_options">
                <label>Select Module</label>
                <br />
                <select
                  name=""
                  id=""
                  onChange={(e) => handleSelectModule(e.target.value)}
                  required
                >
                  <option value="Please Select ">Please Select</option>
                  {currentModules?.map((item) => {
                    return (
                      <option key={item?._id} value={item?._id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <br />
            {selectedModule && (
              <div className="register_form_div">
                <div className="register_options">
                  <label>Current Videos</label>
                  <br />
                  {currentVideos?.map((item) => {
                    return (
                      <div>
                        <p>{item?.title}</p>
                        <a
                          href={item?.diskVideos}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Lecture
                        </a>
                        <button
                          className="cv_build_btn"
                          onClick={() => handleDeleteVideo(item?._id)}
                        >
                          Delete Video
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {videos.map((video, index) => (
              <div key={index}>
                <label>New Videos</label>
                <br />
                <div className="register_form_div">
                  <div className="register_options">
                    <label htmlFor={`videoTitle${index}`}>Video Title: </label>
                    <input
                      type="text"
                      id={`videoTitle${index}`}
                      name="title"
                      value={video.title}
                      onChange={(e) => handleVideoChange(e, index)}
                    />
                  </div>
                </div>
                <div className="register_form_div">
                  <div className="register_options">
                    <label htmlFor={`videoFile${index}`}>Video File: </label>
                    <input
                      type="file"
                      id={`videoFile${index}`}
                      name="videoFile"
                      accept="video/*"
                      onChange={(e) => handleVideoFileChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            ))}

            <button className="cv_build_btn" onClick={handleAddVideo}>
              Add Video
            </button>
            <button className="cv_build_btn" onClick={handleAddVideos}>
              Submit
            </button>
            <button className="cv_build_btn" onClick={handleVideoCancel}>
              Cancel
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UpdateCourse;
