import React, { useEffect, useRef, useState } from "react";
import "./addBlog.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";
import { useAuthContext } from "../../context/AuthContext";
import { AdditionalRoles } from "../../constants/GlobalConstants";

const AddPlan = () => {
  const { user } = useAuthContext();
  const name = useRef();
  const [category, setCategory] = useState("");
  const quantity = useRef();
  const price = useRef();
  const limit = useRef();
  const download_cvs = useRef();
  const discount = useRef();
  const [otherPlan, setOtherPlan] = useState({
    name: "",
    description: "",
    price: "",
    duration: "",
    planType: "",
  });
  const { plantype } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user || user?.role !== "admin") {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      let res;
      if (plantype === "checkout-plan") {
        let data;
        category === "advertise jobs"
          ? (data = {
              name: name.current.value,
              category: category,
              advertiseJobs: {
                postJobs: quantity.current.value,
                daysAllowed: limit.current.value,
              },
              price: price.current.value,
              discount: discount.current.value,
            })
          : (data = {
              name: name.current.value,
              category: category,
              searchCvs: {
                view_cvs: quantity.current.value,
                download_cvs: download_cvs.current.value,
                weeksAllowed: limit.current.value,
              },
              price: price.current.value,
              discount: discount.current.value,
            });

        let route;
        if (user?.role === "admin") route = `/adminPanel/plans/registration`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/plans/registration`;

        res = await axios.post(route, data);
      }
      if (plantype === "other") {
        let route;
        if (user?.role === "admin") route = `/adminPanel/plans/others`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/plans/others`;

        res = await axios.post(route, otherPlan);
      }
      if (res.status === 201) {
        setSuccess(true);
        setSuccessMessage("Plan added successfully");
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/adminboard";
        }, 1500);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error.response?.data?.message);
      console.log(error);
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <div className="">
        <Navbar3 />
        {error && <ToastError message={errorMessage} />}
        {success && <ToastSuccess message={successMessage} />}
        <ToastContainer />
        {isLoading && <OverlayLoader />}
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details to add your plan</p>
          <div className="register_form">
            <h3 className="register_form_h3_start">Plan Details</h3>

            <hr />
            {plantype === "checkout-plan" && (
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Plan Name</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      placeholder="abc"
                      ref={name}
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Category</label>
                    <br />
                    <select
                      name=""
                      id=""
                      onChange={(e) => setCategory(e.target.value)}
                      required
                    >
                      <option value="">Please Select</option>
                      <option value="advertise jobs">Advertise Jobs</option>
                      <option value="search cvs">Search CV</option>
                    </select>
                  </div>
                </div>

                {category === "advertise jobs" && (
                  <>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Number of Jobs allowed daily</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="0"
                          ref={quantity}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Time Limit in days</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="0"
                          ref={limit}
                          required
                        ></input>
                      </div>
                    </div>
                  </>
                )}

                {category === "search cvs" && (
                  <>
                    <div className="register_form_div">
                      <div className="register_options">
                        <label>How much cvs can be viewed</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="0"
                          ref={quantity}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>How much cvs can be downloaded</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="0"
                          ref={download_cvs}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="register_form_div">
                      <div className="register_options">
                        <label>Time limit in weeks</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="number"
                          placeholder="0"
                          ref={limit}
                          required
                        ></input>
                      </div>
                    </div>
                  </>
                )}

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Price</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="number"
                      placeholder="0"
                      ref={price}
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Discount</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="number"
                      placeholder="0"
                      ref={discount}
                      required
                    ></input>
                  </div>
                </div>
              </form>
            )}
            {plantype === "other" && (
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Plan Name</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      placeholder="name"
                      value={otherPlan.name}
                      onChange={(e) =>
                        setOtherPlan({ ...otherPlan, name: e.target.value })
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Plan Type</label>
                    <br />
                    <select
                      name=""
                      id=""
                      value={otherPlan.planType}
                      onChange={(e) =>
                        setOtherPlan({ ...otherPlan, planType: e.target.value })
                      }
                      required
                    >
                      <option value="">Please Select</option>
                      <option value="hire-recruiter">
                        Hire Recruiters Plan
                      </option>
                      <option value="premium-jobseeker">
                        Premium JobSeeker Plan
                      </option>
                      <option value="background-check">
                        Background Check Plan
                      </option>
                    </select>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Price</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="number"
                      placeholder="price"
                      value={otherPlan.price}
                      onChange={(e) =>
                        setOtherPlan({ ...otherPlan, price: e.target.value })
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Duration in Days</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="number"
                      placeholder="duration"
                      value={otherPlan.duration}
                      onChange={(e) =>
                        setOtherPlan({ ...otherPlan, duration: e.target.value })
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Description</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      placeholder="description"
                      value={otherPlan.description}
                      onChange={(e) =>
                        setOtherPlan({
                          ...otherPlan,
                          description: e.target.value,
                        })
                      }
                      required
                    ></input>
                  </div>
                </div>
              </form>
            )}
          </div>

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Add Plan</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddPlan;
