import React, { useEffect, useState } from "react";
import "./navbar3.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import { ToastSuccess, ToastError } from "../alert/toastAlert";
import OverlayLoader from "../loader/OverlayLoader";
import { AdditionalRoles, IMAGES } from "../../constants/GlobalConstants";
import LazyImage from "../common/LazyImage";

const Navbar3 = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      localStorage.clear();
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await axios.post("/logout");
      setSuccess(true);
      setSuccessMessage("Logged out Successfully.");
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/";
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="navbar3_maindiv">
      {isLoading && <OverlayLoader />}
      <motion.div
        className="navbar3_subdiv1"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 5 }}
      >
        {error && <ToastError message={errorMessage} />}
        {success && <ToastSuccess message={successMessage} />}
        <div className="navbar3_img_link_div">
          <div className="navbar3_links_div">
            <ul>
              <li className="logo">
                <Link to="/">
                  <LazyImage src={IMAGES.cv_logo} alt="logo" />
                </Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/job-boards-usa">Jobs</Link>
              </li>
              <li>
                <Link to="/job-recruiting-websites">Searching Jobs</Link>
              </li>
              {user?.role !== "employer" && user?.role !== "company" && (
                <li>
                  <Link to="/resume-fixer">Resume/CV Designer</Link>
                </li>
              )}
              {(user?.role === "employer" || user?.role === "company" || user?.role === "admin") &&
                user?.checkout?.packageName === "search cvs" && (
                  <li>
                    <Link to="/searchcv">Search Resume/CVs</Link>
                  </li>
                )}
              {(user?.role === "employer" || user?.role === "company" || user?.role === "admin") &&
                user?.checkout?.packageName === "advertise jobs" && (
                  <li>
                    <Link to="/job-advertisement">Advertise Jobs</Link>
                  </li>
                )}
              <li>
                <Link to="/career-counselling-courses">Courses</Link>
              </li>
              {user?.role !== "employer" && user?.role !== "company" && (
                <li>
                  <Link to="/job-alerts">Job Alerts</Link>
                </li>
              )}
              <li>
                <Link to="/career-advice">Career Guidance</Link>
              </li>
              <li>
                <Link to="/search-company">Businesses</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar3_subdiv2">
          {user ? (
            <ul>
              {user?.role === "user" ? (
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
              ) : null}
              {(user?.role === "jobseeker" ||
                user?.role === "employer" ||
                user?.role === "company") && (
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
              )}
              {user?.role === "admin" && (
                <li>
                  <Link to="/adminboard">Admin Panel</Link>
                </li>
              )}
              {AdditionalRoles.includes(user?.role) && (
                <li>
                  <Link to="/adminboard">Dashboard</Link>
                </li>
              )}
              <li onClick={handleLogout}>
                <Link>Logout</Link>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
              <li>
                <Link to="/online-recruitment">Recruiting?</Link>
              </li>
            </ul>
          )}
        </div>
      </motion.div>
      <div className="Navbar-toggle-icon">
        {toggleMenu ? (
          <RiCloseLine
            style={{ marginLeft: "7px" }}
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            style={{ marginLeft: "7px" }}
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        <span className="fw-bold">Menu</span>
        {toggleMenu && (
          <motion.div
            className="navbar2_subdiv1  Navbar-toggle-design "
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ul>
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1 }}>
                <Link to="/">Home</Link>
              </motion.li>
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1 }}>
                <Link to="/job-boards-usa">Jobs</Link>
              </motion.li>
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1 }}>
                <Link to="/job-recruiting-websites">Searching Jobs</Link>
              </motion.li>
              {user?.role !== "employer" && user?.role !== "company" && (
                <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.2 }}>
                  <Link to="/resume-fixer">Resume/CV Designer</Link>
                </motion.li>
              )}
              {(user?.role === "employer" || user?.role === "company" || user?.role === "admin") &&
                user?.checkout?.packageName === "search cvs" && (
                  <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.3 }}>
                    <Link to="/searchcv">Search Resume/CVs</Link>
                  </motion.li>
                )}
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.3 }}>
                <Link to="/career-counselling-courses">Courses</Link>
              </motion.li>
              {user?.role !== "employer" && user?.role !== "company" && (
                <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.4 }}>
                  <Link to="/job-alerts">Job Alerts</Link>
                </motion.li>
              )}
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.5 }}>
                <Link to="/career-advice">Career Guidance</Link>
              </motion.li>
              <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.6 }}>
                <Link to="/search-company">Businesses</Link>
              </motion.li>
            </ul>
            <div className="navbar3_subdiv2 navbar3_mobile_design">
              {user ? (
                <ul>
                  {user?.role === "user" && (
                    <motion.li
                      initial={{ x: 100 }}
                      animate={{ x: 0 }}
                      transition={{ duration: 1.9 }}
                    >
                      <Link to="/dashboard">Dashboard</Link>
                    </motion.li>
                  )}

                  {user?.role === "admin" && (
                    <motion.li
                      initial={{ x: 100 }}
                      animate={{ x: 0 }}
                      transition={{ duration: 1.9 }}
                    >
                      <Link to="/adminboard">Admin Panel</Link>
                    </motion.li>
                  )}

                  {AdditionalRoles.includes(user?.role) && (
                    <motion.li
                      initial={{ x: 100 }}
                      animate={{ x: 0 }}
                      transition={{ duration: 1.9 }}
                    >
                      <Link to="/adminboard">Dashboard</Link>
                    </motion.li>
                  )}

                  {(user?.role === "jobseeker" ||
                    user?.role === "employer" ||
                    user?.role === "company") && (
                    <motion.li
                      initial={{ x: 100 }}
                      animate={{ x: 0 }}
                      transition={{ duration: 1.9 }}
                    >
                      <Link to="/profile">Profile</Link>
                    </motion.li>
                  )}

                  <motion.li
                    initial={{ x: 100 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 1.9 }}
                    onClick={handleLogout}
                  >
                    <Link>Logout</Link>
                  </motion.li>
                </ul>
              ) : (
                <ul>
                  <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.7 }}>
                    <Link to="/login">Login</Link>
                  </motion.li>
                  <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.8 }}>
                    <Link to="/register">Register</Link>
                  </motion.li>
                  <motion.li initial={{ x: 100 }} animate={{ x: 0 }} transition={{ duration: 1.9 }}>
                    <Link to="/online-recruitment">Recruiting?</Link>
                  </motion.li>
                </ul>
              )}
            </div>
          </motion.div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default Navbar3;
