import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const CreateCourse = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    title: "",
    coverImage: null,
    price: "",
    category: "",
    type: "",
    duration: "",
    description: "",
    certificate: null,
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const categoryOptions = [
    "Business and Entrepreneurship",
    "Technology and Programming",
    "Arts and Creativity",
    "Language Learning",
    "Personal Development",
    "Health and Wellness",
    "Science and Engineering",
    "Social Sciences and Humanities",
    "Beauty Tips",
    "Staff Training",
  ];

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user && (user?.role !== "user" || user?.role !== "admin")) {
      setError(true);
      setErrorMessage("You are not allowed to create course!!");
      setTimeout(() => {
        navigate(-1);
      }, 4000);
    }
  }, [user, navigate]);

  const handleInputChange = (e) => {
    setCourseData({ ...courseData, [e.target.name]: e.target.value });
  };

  const handleFileInputChange = (e) => {
    setCourseData({ ...courseData, [e.target.name]: e.target.files[0] });
  };

  const handleCreateCourse = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("courseMetadata[title]", courseData.title);
      formData.append("courseMetadata[price]", courseData.price);
      formData.append("courseMetadata[category]", courseData.category);
      formData.append("courseMetadata[type]", courseData.type);
      formData.append("courseMetadata[duration]", courseData.duration);
      formData.append("courseMetadata[description]", courseData.description);
      formData.append("coverImage", courseData.coverImage);
      formData.append("certificate", courseData.certificate);
      console.log(formData);

      await axios.post("/courses", formData).then((res) => {
        setSuccess(true);
        setSuccessMessage("Course created successfully");
        setTimeout(() => {
          setIsLoading(false);
          navigate("/dashboard");
        }, 1000);
        setCourseData({
          title: "",
          coverImage: null,
          price: "",
          category: "",
          type: "",
          duration: "",
          description: "",
          certificate: null,
        });
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>
        <p>We just need a few details to create your Course.</p>

        <div className="register_form">
          <h2>Create Course</h2>
          <hr />

          <form>
            <div className="register_form_div">
              <div className="register_options">
                <label>Course Title</label>
                <br />
                <input
                  type="text"
                  name="title"
                  value={courseData.title}
                  onChange={handleInputChange}
                  placeholder="Course Title"
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>{"Course Price ($)"}</label>
                <br />
                <input
                  type="number"
                  name="price"
                  value={courseData.price}
                  onChange={handleInputChange}
                  placeholder="Price"
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Course Category</label>
                <br />
                <select
                  name="category"
                  value={courseData.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Please Select ">Please Select</option>
                  {categoryOptions.sort().map((name) => {
                    return (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Course Type</label>
                <br />
                <select
                  name="type"
                  value={courseData.type}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Please Select ">Please Select</option>
                  <option value="video">Video</option>
                  <option value="article">Article</option>
                </select>
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Course Duration in Hours</label>
                <br />
                <input
                  type="number"
                  name="duration"
                  value={courseData.duration}
                  onChange={handleInputChange}
                  placeholder="Duration"
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Course Description</label>
                <br />
                <textarea
                  rows={4}
                  style={{ resize: "none", outline: "none", fontSize: "16px" }}
                  type="text"
                  name="description"
                  value={courseData.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Cover Image</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  name="coverImage"
                  onChange={handleFileInputChange}
                />
              </div>
            </div>

            <div className="register_form_div">
              <div className="register_options">
                <label>Certificate File</label>
                <br />
                <input
                  type="file"
                  name="certificate"
                  onChange={handleFileInputChange}
                  placeholder="certificate"
                />
              </div>
            </div>
          </form>
          <div className="button_maindiv">
            <button onClick={handleCreateCourse}>Submit</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateCourse;
