import React from "react";
import "./jobalert.css";
import Cvbulider from "./Cvbulider";
import Header from "./Header";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Alerts | Stay Updated on New Job Opportunities</title>

        <link rel="canonical" href="https://thealphyjohnson.com/job-alerts" />
        <meta
          name="description"
          content="Sign Up for job alerts to get the latest job openings directly in your inbox. Stay informed about new opportunities & never miss a chance to advance your career."
        />
      </Helmet>
      <Navbar3 />
      <Header />
      <Cvbulider />
      <Footer />
    </>
  );
}

export default App;
