import React from "react";
import "./cvdatabase.css";

const cvdatabase = ({ heading, para }) => {
  return (
    <div
      className="container-fluid cv_database_below_div"
      style={{ backgroundColor: "var(--blue)", color: "#fff" }}
    >
      <h3>{heading}</h3>
      <p>{para}</p>
    </div>
  );
};

export default cvdatabase;
