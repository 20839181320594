import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../../components/alert/toastAlert";
import { useAuthContext } from "../../../context/AuthContext";
import { AdditionalRoles } from "../../../constants/GlobalConstants";

const AddRecruiters = () => {
  const { state, fetchHiringOrders } = useAdminPanelContext();
  const { user } = useAuthContext();
  const [employers, setEmployers] = useState([]);
  const [selectedRecs, setSelectedRecs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    setPagePosts(state?.hiringOrders?.slice(0, postsPerPage));
    setEmployers(state?.employers);
    setTotalPages(Math.ceil(state.hiringOrders?.length / postsPerPage));
  }, [state?.hiringOrders, state?.employers, postsPerPage]);

  const handleTeam = async (compId, action, index) => {
    try {
      setIsLoading(true);
      let res;
      if (action === "add") {
        let route;
        if (user?.role === "admin")
          route = `/adminPanel/add-team-member/${selectedRecs[index]?._id}`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/add-team-member/${selectedRecs[index]?._id}`;

        res = await axios.post(route, {
          companyId: compId,
        });
        setSuccess(true);
        setSuccessMessage(res.data?.message);
      }

      if (action === "remove") {
        let route;
        if (user?.role === "admin")
          route = `/adminPanel/remove-team-member/${selectedRecs[index]?._id}`;
        if (AdditionalRoles.includes(user?.role))
          route = `/additionalUsers/remove-team-member/${selectedRecs[index]?._id}`;

        res = await axios.patch(route, {
          companyId: compId,
        });
        setSuccess(true);
        setSuccessMessage(res.data?.message);
      }
      if (res.status === 200) {
        await fetchHiringOrders();
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleRecChange = (e, index) => {
    const id = e?.target?.value;
    const obj = employers.find((emp) => emp._id === id);

    setSelectedRecs((prevSelectedRecs) => {
      const newSelectedRecs = [...prevSelectedRecs];
      newSelectedRecs[index] = obj;
      return newSelectedRecs;
    });
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.hiringOrders?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setPagePosts(current);
    }
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="messages">
        <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
          Add Team Members
        </h3>
        {pagePosts?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Recruiters Required</th>
                <th>Recruiters Added</th>
                <th>Select Recruiter to Add</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pagePosts?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.companyId?.name}</td>
                  <td>{item?.numberOfRecruiters}</td>
                  <td>{item?.companyId?.teamMembers?.length}</td>
                  <td>
                    <select
                      style={{
                        height: "35px",
                        width: "100%",
                      }}
                      onChange={(e) => handleRecChange(e, index)}
                    >
                      <option>Select a Recruiter to Add</option>
                      {employers?.map((employer) => {
                        return (
                          <option key={employer?._id} value={employer?._id}>
                            {employer?.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td>
                    {selectedRecs[index] && (
                      <>
                        <button
                          onClick={() =>
                            handleTeam(item?.companyId?._id, "add", index)
                          }
                        >
                          Add
                        </button>
                        <button
                          onClick={() =>
                            handleTeam(item?.companyId?._id, "remove", index)
                          }
                        >
                          Remove
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No Data yet to show Here!!</p>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddRecruiters;
