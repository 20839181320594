import React from "react";
import img1 from "../../images/1.jpg";
import img2 from "../../images/2.jpg";
import img3 from "../../images/3.jpg";
import img4 from "../../images/4.jpg";
import img5 from "../../images/5.jpg";
import img6 from "../../images/6.jpg";
import img7 from "../../images/7.jpg";
import img8 from "../../images/8.jpg";
import img10 from "../../images/10.jpg";
import img11 from "../../images/11.jpg";
import img12 from "../../images/12.jpg";
import LazyImage from "../../components/common/LazyImage";
import { IMAGES } from "../../constants/GlobalConstants";

function Industries() {
  return (
    <section id="popularindustries">
      <div className="">
        <div className="container">
          <h2 className="industries-head text-center fw-bold">
            Discover Trending Sectors for Your Job Search in the USA
          </h2>
        </div>
        <div className="popular-industriesrow">
          <div className="col-4-industries">
            <LazyImage src={img1} alt="best job boards for remote work" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Administrative & Clerical",
                )}`}
              >
                Administrative & Clerical
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img2} alt="Banking & Finance" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Banking & Finance",
                )}`}
              >
                Banking & Finance
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img3} alt="Construction" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Construction",
                )}`}
              >
                Construction
              </a>
            </h3>
          </div>
        </div>
        <div className="popular-industriesrow">
          <div className="col-4-industries">
            <LazyImage src={img4} alt="Customer Service" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Customer Service",
                )}`}
              >
                Customer Service
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img5} alt="Education" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Education",
                )}`}
              >
                Education
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img6} alt="Engineering" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Engineering",
                )}`}
              >
                Engineering
              </a>
            </h3>
          </div>
        </div>
        <div className="popular-industriesrow">
          <div className="col-4-industries">
            <LazyImage src={img7} alt="Hospitality & Tourism" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Hospitality & Tourism",
                )}`}
              >
                Hospitality & Tourism
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img8} alt="Healthcare" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Healthcare",
                )}`}
              >
                Healthcare
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={IMAGES.img1} alt="Manufacturing & Production" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Manufacturing & Production",
                )}`}
              >
                Manufacturing & Production
              </a>
            </h3>
            {/* <p>
              <a href="#" onClick={() => handleLinkClick("Retail")}>
                All Retail Job Titles
              </a>
            </p>
            <p className="bottomofcol">
              <a href="#" onClick={() => handleLinkClick("Retail-location")}>
                By location
              </a>
            </p> */}
          </div>
        </div>
        <div className="popular-industriesrow">
          <div className="col-4-industries">
            <LazyImage src={img10} alt="Marketing & Advertising" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Marketing & Advertising",
                )}`}
              >
                Marketing & Advertising
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img11} alt="Sales" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Sales",
                )}`}
              >
                Sales
              </a>
            </h3>
          </div>

          <div className="col-4-industries">
            <LazyImage src={img12} alt="Technology" />
            <h3 className="pic-head">
              <a
                href={`/job-recruiting-websites?industry=${encodeURIComponent(
                  "Technology",
                )}`}
              >
                Technology
              </a>
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Industries;
