import React, { useCallback, useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import axios from "../../axios";
import "./dashboard.css";
import Drawer from "./drawer";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

const DashboardPage = () => {
  const { user } = useAuthContext();
  const [courses, setCourses] = useState([]);
  const [activeCourses, setActiveCourses] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [savedCourses, setSavedCourses] = useState([]);
  const [certificates, setCertificate] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState({
    showCourses: true,
    showActive: false,
    showCompleted: false,
    showCertificates: false,
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchDashboardData = useCallback(async () => {
    const response = await axios.get(`/users/dashboard/${user?._id}`);
    const { courses, activeCourses, completedCourses, certificates } =
      response.data;
    // console.log(response.data);
    setCourses(courses);
    setActiveCourses(activeCourses);
    setCompletedCourses(completedCourses);
    setCertificate(certificates);
    fetchAllCourses(courses);
  }, [user]);

  useEffect(() => {
    try {
      setIsFetching(true);
      fetchSavedCourses();
      fetchDashboardData();
      setTimeout(() => {
        setIsFetching(false);
      }, 1500);
    } catch (error) {
      console.log(error?.response);
    }
  }, [fetchDashboardData]);

  const fetchSavedCourses = async () => {
    const response = await axios.get(`/users/courses/saved`);
    // console.log(response.data?.courses);
    setSavedCourses(response.data?.courses);
  };

  const fetchAllCourses = async (coursesArray) => {
    try {
      const res = await axios.get(`/courses`);
      // console.log(res.data.data);
      const docs = res.data.data;
      const newData = docs.filter((course) => {
        return (
          course?.courseMetadata?.isApprovedByAdmin === 1 &&
          !coursesArray.some((mycourse) => mycourse._id === course._id)
        );
      });
      setData(newData?.reverse());
    } catch (error) {
      console.log(error?.response);
    }
  };

  const handleShow = (name) => {
    const newShow = { ...show };
    const updatedObj = Object.keys(newShow).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    updatedObj[name] = true;
    setShow(updatedObj);
  };

  const viewCourse = (id) => {
    window.location.href = `/courses/${id}`;
  };

  const addCourse = () => {
    window.location.href = `/courses/create`;
  };

  const deleteCourse = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/courses/${id}`).then((res) => {
        setSuccess(true);
        setSuccessMessage("Course deleted successfully");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        fetchDashboardData();
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const updateCourse = (id) => {
    window.location.href = `/courses/update/${id}`;
  };

  const saveCourse = async (id, action) => {
    try {
      setIsLoading(true);
      if (action === "save") {
        await axios.post(`/users/courses/save`, {
          courseId: id,
        });
        // console.log(res.data);
        setSuccess(true);
        setSuccessMessage("Course saved successfully");
        fetchSavedCourses();
      }
      if (action === "unsave") {
        await axios.post(`/users/courses/unsave`, {
          courseId: id,
        });
        setSuccess(true);
        setSuccessMessage("Course removed from saved successfully");
        fetchSavedCourses();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      {/* {user && (
        <div className="user">
          <Link to={"/dashboard/myprofile"}>
            <p>My Profile</p>
          </Link>
          <p onClick={handleLogout}>Logout</p>
        </div>
      )} */}
      <Drawer />
      <div className="dashboard">
        <h2 className="courses_dashboard_heading job_alert_tips">Dashboard</h2>
        {/* <p className="line">
          {" "}
          Get access to all 700+ courses (and MORE) for only £12.00 per month.
          Find out more.
        </p> */}
        <div className="stats-items">
          <div
            onClick={() => handleShow("showCourses")}
            className="stats-item"
            id={show.showCourses ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Created Courses</h3>
            <p>{courses?.length || 0}</p>
          </div>

          <div
            onClick={() => handleShow("showActive")}
            className="stats-item"
            id={show.showActive ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Active Courses</h3>
            <p>{activeCourses?.length || 0}</p>
          </div>

          <div
            onClick={() => handleShow("showCompleted")}
            className="stats-item"
            id={show.showCompleted ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>Completed Courses</h3>
            <p>{completedCourses?.length || 0}</p>
          </div>

          <div
            onClick={() => handleShow("showCertificates")}
            className="stats-item"
            id={show.showCertificates ? "active-stats-item" : ""}
            style={{ cursor: "pointer" }}
          >
            <h3>My Certificates</h3>
            <p>{certificates?.length || 0}</p>
          </div>
        </div>

        {/* <div className="student-leaderboard">
          <h3>Student leaderboard This Month</h3>
          <ul>
            <li>award 1 - Ching-Han W - 4 pts</li>
            <li>award 2 - Kamalpreet S - 2 pts</li>
            <li>award 3 - 1 pts</li>
            <li>award 4 - 1 pts</li>
            <li>award 5 - Gayke C - 1 pts</li>
            <li>award 6 - Ashly S - 1 pts</li>
            <li>award 7 - Tsz Ying L - 1 pts</li>
            <li>award 8 - D S - 1 pts</li>
            <li>award 9 - Muhammad J(you) - 1 pts</li>
            <li>award 10 - Christy T - 1 pts</li>
          </ul>
        </div> */}

        {show.showCourses && (
          <div className="messages">
            <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
              Created Courses
            </h3>
            <button
              className="btn btn-primary profile-button"
              type="button"
              onClick={addCourse}
            >
              Add New Course
            </button>
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {courses?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Category & Type</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => viewCourse(item?._id)}
                          >
                            {item?.courseMetadata?.title}
                          </td>
                          <td>{item?.courseMetadata?.price + " $"}</td>
                          <td>
                            {item?.courseMetadata?.category +
                              " & " +
                              item?.courseMetadata?.type}
                          </td>
                          <td>{item?.courseMetadata?.duration + " Hours"}</td>
                          <td>
                            {item?.courseMetadata?.isApprovedByAdmin === 0 &&
                              "Pending"}
                            {item?.courseMetadata?.isApprovedByAdmin === 1 &&
                              "Approved"}
                            {item?.courseMetadata?.isApprovedByAdmin === 2 &&
                              "Rejected"}
                          </td>
                          <td>
                            <button onClick={() => updateCourse(item?._id)}>
                              Update
                            </button>
                            <button onClick={() => deleteCourse(item?._id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data exists!!
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {show.showActive && (
          <div className="messages">
            <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
              Active Courses
            </h3>
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {activeCourses?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Category & Type</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeCourses?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => viewCourse(item?._id)}
                          >
                            {item?.courseMetadata?.title}
                          </td>
                          <td>{item?.courseMetadata?.price + " $"}</td>
                          <td>
                            {item?.courseMetadata?.category +
                              " & " +
                              item?.courseMetadata?.type}
                          </td>
                          <td>{item?.courseMetadata?.duration + " Hours"}</td>
                          <td>
                            {item?.courseMetadata?.isApprovedByAdmin === 0 &&
                              "Pending"}
                            {item?.courseMetadata?.isApprovedByAdmin === 1 &&
                              "Approved"}
                            {item?.courseMetadata?.isApprovedByAdmin === 2 &&
                              "Rejected"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data exists!!
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {show.showCompleted && (
          <div className="messages">
            <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
              Completed Courses
            </h3>
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {completedCourses?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Category & Type</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completedCourses?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => viewCourse(item?._id)}
                          >
                            {item?.courseMetadata?.title}
                          </td>
                          <td>{item?.courseMetadata?.price + " $"}</td>
                          <td>
                            {item?.courseMetadata?.category +
                              " & " +
                              item?.courseMetadata?.type}
                          </td>
                          <td>{item?.courseMetadata?.duration + " Hours"}</td>
                          <td>
                            {item?.courseMetadata?.isApprovedByAdmin === 0 &&
                              "Pending"}
                            {item?.courseMetadata?.isApprovedByAdmin === 1 &&
                              "Approved"}
                            {item?.courseMetadata?.isApprovedByAdmin === 2 &&
                              "Rejected"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data exists!!
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {show.showCertificates && (
          <div className="messages">
            <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
              Certificates
            </h3>
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {certificates?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Course Name</th>
                        <th>Certificate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {certificates?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.courseId?.courseMetadata?.title}</td>
                          <td>
                            <a
                              href={item?.certificate}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Certificate
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data exists!!
                  </div>
                )}
              </>
            )}
          </div>
        )}

        <div className="recommended-courses">
          <h3>Other courses you might like...</h3>
          <div className="messages">
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            ) : (
              <>
                {data?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Category & Type</th>
                        <th>Duration</th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.slice(0, 6)?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => viewCourse(item?._id)}
                          >
                            {item?.courseMetadata?.title}
                          </td>
                          <td>{item?.courseMetadata?.price + " $"}</td>
                          <td>
                            {item?.courseMetadata?.category +
                              " & " +
                              item?.courseMetadata?.type}
                          </td>
                          <td>{item?.courseMetadata?.duration + " Hours"}</td>
                          <td>
                            {item?.courseMetadata?.description?.slice(0, 50)}...
                          </td>
                          <td>
                            <button style={{ backgroundColor: "none" }}>
                              {savedCourses.some(
                                (course) => course?._id === item?._id
                              ) ? (
                                <FaHeart
                                  color="var(--blue)"
                                  onClick={() =>
                                    saveCourse(item?._id, "unsave")
                                  }
                                />
                              ) : (
                                <FaHeart
                                  onClick={() => saveCourse(item?._id, "save")}
                                />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No data exists!!
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
