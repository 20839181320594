import React, { useCallback, useEffect, useState } from "react";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Drawer from "./drawer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import ReactPlayer from "react-player";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

const ActiveCourses = () => {
  const { user } = useAuthContext();
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedModule, setSelectedModules] = useState({});
  const [completed, setCompleted] = useState([]);
  const [show, setShow] = useState({
    showModules: true,
    showVideos: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchSavedCourses = useCallback(async () => {
    const response = await axios.get(`/users/courses/active`);
    // console.log(response.data?.courses);
    const arr = response.data?.courses;
    setCourses(arr);
    setPagePosts(arr?.slice(0, postsPerPage));
    const pages = Math.ceil(arr?.length / postsPerPage);
    setTotalPages(pages);
  }, [postsPerPage]);

  useEffect(() => {
    try {
      if (user && user?.role === "user") {
        setIsFetching(true);
        fetchSavedCourses();
        fetchCompletedCourses();
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  }, [user, fetchSavedCourses]);

  const fetchCompletedCourses = async () => {
    const response = await axios.get(`/users/courses/completed`);
    // console.log(response.data.courses);
    setCompleted(response.data?.courses);
  };

  const handleMarkCompleted = async (id) => {
    try {
      setIsLoading(true);
      await axios.patch(`/courses/mark-complete/${id}`).then((res) => {
        // console.log(res.data);
        setSuccess(true);
        setSuccessMessage(res.data?.message);
        fetchCompletedCourses();
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleShow = (name) => {
    const newShow = { ...show };
    const updatedObj = Object.keys(newShow).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    updatedObj[name] = true;
    setShow(updatedObj);
  };

  const handleSelectModule = (id) => {
    const mod = selectedCourse?.modules.find((obj) => obj?._id === id);
    setSelectedModules(mod);
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = courses?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <>
      <Drawer />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="dashboard">
        <h2 className="courses_dashboard_heading job_alert_tips">
          Active Courses
        </h2>
        {/* <p className="line">
          Get access to all 700+ courses (and MORE) for only £12.00 per month.
          Find out more.
        </p> */}

        <div className="messages">
          <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
            Active Courses
          </h3>
          {isFetching ? (
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <Loader />
            </div>
          ) : (
            <>
              {pagePosts?.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th>Category & Type</th>
                      <th>Duration</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagePosts?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                        <td>{item?.courseMetadata?.title}</td>
                        <td>{item?.courseMetadata?.price + " $"}</td>
                        <td>
                          {item?.courseMetadata?.category +
                            " & " +
                            item?.courseMetadata?.type}
                        </td>
                        <td>{item?.courseMetadata?.duration + " Hours"}</td>
                        <td>
                          {completed?.find((obj) => obj?._id === item?._id) ? (
                            <button>Completed</button>
                          ) : (
                            <button
                              onClick={() => handleMarkCompleted(item?._id)}
                            >
                              Mark Completed
                            </button>
                          )}

                          {selectedCourse?._id === item?._id ? (
                            <button onClick={() => setSelectedCourse("")}>
                              Close
                            </button>
                          ) : (
                            <button onClick={() => setSelectedCourse(item)}>
                              Open
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="container d-flex justify-content-center align-items-center">
                  No data Exists!!
                </div>
              )}
            </>
          )}
        </div>
        {pagePosts?.length >= 1 && (
          <div className="pagination mt-4">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary" onClick={() => paginate()}>
                  ...
                </div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
            </div>
          </div>
        )}

        {selectedCourse && (
          <>
            <div className="text-center">
              <div className="active_courses_module-div">
                <div className="stats-items">
                  <div
                    onClick={() => handleShow("showModules")}
                    className="stats-item"
                    id={show.showModules ? "active-stats-item" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <h3>Modules</h3>
                    <p>{selectedCourse?.modules?.length || 0}</p>
                  </div>
                </div>
              </div>
              {selectedCourse?.modules?.length > 0 ? (
                <>
                  <label>Select Module</label>
                  <br />
                  <select
                    className="courses_module_select"
                    name=""
                    id=""
                    onChange={(e) => handleSelectModule(e.target.value)}
                    required
                  >
                    <option value="Please Select ">Please Select</option>
                    {selectedCourse?.modules?.map((item) => {
                      return (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </>
              ) : (
                <div className="container d-flex justify-content-center align-items-center">
                  Modules are not Added yet!!
                </div>
              )}
            </div>

            {selectedModule && (
              <div className="course_videos_row">
                {selectedModule?.videos?.length > 0 ? (
                  <>
                    {selectedModule?.videos?.map((item) => {
                      return (
                        <div className="course_video_block" key={item?._id}>
                          <ReactPlayer
                            className="react-player"
                            url={item?.diskVideos}
                            light="true"
                            controls="true"
                            playing="false"
                            width="100%"
                            style={{ borderRadius: "10px" }}
                          />
                          <p>{item?.title}</p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="container d-flex justify-content-center align-items-center">
                    No Video Added yet!!
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ActiveCourses;
