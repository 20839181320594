export const ACTIONS = {
  fetch_start: "fetch_start",
  fetch_jobs: "fetch_jobs",
  fetch_applications: "fetch_applications",
  fetch_posts: "fetch_posts",
  fetch_saved_jobs: "fetch_saved_jobs",
  fetch_team: "fetch_team",
  fetch_devices: "fetch_devices",
  fetch_job_alerts: "fetch_job_alerts",
  fetch_success: "fetch_success",
  fetch_failure: "fetch_failure",
  fetch_end: "fetch_end",
};

export const INITIAL_STATE = {
  jobs: [],
  applications: [],
  posts: [],
  savedJobs: [],
  team: [],
  devices: [],
  jobAlerts: [],
  isFetching: false,
  error: false,
};

export const USER_ACTIONS = {
  login_start: "login_start",
  login_redirect: "login_redirect",
  login_success: "login_success",
  login_failure: "login_failure",
  update_start: "update_start",
  update_success: "update_success",
  update_failure: "update_failure",
  fetch_user_start: "fetch_user_start",
  fetch_user_success: "fetch_user_success",
  fetch_user_failure: "fetch_user_failure",
};

export const INITIAL_USER = {
  user: null,
  isFetching: false,
  error: false,
};

export const INITIAL_ADMIN_PANEL_STATE = {
  candidates: [],
  employers: [],
  companies: [],
  courseUsers: [],
  managementUsers: [],
  posts: [],
  checkoutPlans: [],
  premiumPlans: [],
  hiringOrders: [],
  courses: [],
  enquiries: [],
  contactUsQueries: [],
  userLogs: [],
  configurations: null,
  isFetching: false,
  error: false,
};

export const ADMIN_PANEL_ACTIONS = {
  fetch_start: "fetch_start",
  fetch_candidates: "fetch_candidates",
  fetch_employers: "fetch_employers",
  fetch_companies: "fetch_companies",
  fetch_course_users: "fetch_course_users",
  fetch_management_users: "fetch_management_users",
  fetch_posts: "fetch_posts",
  fetch_checkout_plans: "fetch_checkout_plans",
  fetch_premium_plans: "fetch_premium_plans",
  fetch_hiring_orders: "fetch_hiring_orders",
  fetch_courses: "fetch_courses",
  fetch_enquiries: "fetch_enquiries",
  fetch_contact_us_queries: "fetch_contact_us_queries",
  fetch_user_logs: "fetch_user_logs",
  fetch_configurations: "fetch_configurations",
  fetch_success: "fetch_success",
  fetch_failure: "fetch_failure",
};
