import React from "react";
import "./working.css";
import {
  BsAirplane,
  BsGift,
  BsCalendar4Week,
  BsBuildings,
  BsBalloonHeart,
} from "react-icons/bs";
import {
  MdModelTraining,
  MdOutlineDiscount,
  MdThumbUpOffAlt,
} from "react-icons/md";
import { RiMoneyCnyCircleLine, RiMoneyDollarCircleLine } from "react-icons/ri";

function Workingcv() {
  return (
    <div className="working-main-container">
      <div className="working-container">
        <div className="working-container-top">
          <h2>Working at CV-GlobalHub</h2>
          <p>
            Our business thrives for one reason only – our team. As a growing
            digital company, our teams include experts in technology, sales,
            marketing, customer service and more.
          </p>
          <p>
            To show our appreciation for all their hard work, our staff can
            enjoy...
          </p>
        </div>
        <ul className="working-ul working-container-list">
          <li>
            <BsAirplane className="ul-img" />
            Up to 27 days annual leave
          </li>
          <li>
            <BsGift className="ul-img" />
            An extra day off for your birthday
          </li>
          <li>
            <BsCalendar4Week className="ul-img" />
            Christmas bonus
          </li>
          <li>
            <BsBuildings className="ul-img" />
            Hybrid working model
          </li>
          <li>
            <MdThumbUpOffAlt className="ul-img" />
            Regular social events
          </li>
        </ul>
        <ul className="working-ul working-container-list">
          <li>
            <BsBalloonHeart className="ul-img" />
            Mental health & well-being support
          </li>
          <li>
            <RiMoneyDollarCircleLine className="ul-img" />
            Generous pension scheme
          </li>
          <li>
            <RiMoneyCnyCircleLine className="ul-img" />
            Life insurance
          </li>
          <li>
            <MdModelTraining className="ul-img" />
            Training and development
          </li>
          <li>
            <MdOutlineDiscount className="ul-img" />
            Discounts with retailers, cinemas and more
          </li>
        </ul>
        {/* <p className="working-container-p">
          Want to join an award-winning team? We are hiring! You can view all
          our current vacancies
        </p> */}
      </div>
    </div>
  );
}

export default Workingcv;
