import React, { useEffect, useState } from "react";
import CvNavbar from "../../cvdatabase/cvdatabase_navbar/Cv_navbar";
import { TiTick } from "react-icons/ti";
import { FaLock } from "react-icons/fa";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import "./checkout.css";
import { useLocation, useParams } from "react-router-dom";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";
import Loader from "../../components/Loader";

const Checkout = () => {
  const [plan, setPlan] = useState();
  const [config, setConfig] = useState();
  const [quantity, setQuantity] = useState(1);
  const [description, setDescription] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkoutType = queryParams.get("type");
  const { user } = useAuthContext();
  const { id } = useParams();
  const courseId = id;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let taxPrice = 0.0;

  useEffect(() => {
    setError(false);
  }, [error]);

  useEffect(() => {
    try {
      setIsFetching(true);
      const fetchPlan = async () => {
        await axios.get(`/adminPanel/plans/registration/${id}`).then((res) => {
          setPlan(res.data?.doc);
        });
      };

      const fetchConfig = () => {
        axios.get(`/adminPanel/configurations`).then((res) => {
          setConfig(res.data?.doc?.configurations?.tax);
        });
      };
      fetchConfig();

      const fetchOtherPlan = async () => {
        const response = await axios.get(`/adminPanel/plans/others/${id}`);
        const data = response.data?.doc;
        // console.log(data);
        setPlan(data);
      };

      const fetchCourse = async () => {
        const response = await axios.get(`/courses/${courseId}`);
        // console.log(response.data.data.courseMetadata);
        setPlan(response.data.data.courseMetadata);
      };

      if (
        checkoutType === "upgrade-jobseeker" ||
        checkoutType === "background-check"
      ) {
        if (user?.role !== "jobseeker") {
          setError(true);
          setErrorMessage("Please login from candidate account to checkout");
          setTimeout(() => {
            window.location.href = "/candidate/login";
          }, 4000);
          return;
        }

        fetchOtherPlan();
      } else if (checkoutType === "hire-recruiter") {
        if (user?.role !== "company") {
          setError(true);
          setErrorMessage("Please login from company account to checkout");
          setTimeout(() => {
            window.location.href = "/login";
          }, 4000);
          return;
        }

        fetchOtherPlan();
      } else if (checkoutType === "buy-course") {
        if (user?.role !== "user") {
          setError(true);
          setErrorMessage("Please login from courses account to checkout");
          setTimeout(() => {
            window.location.href = "/courses/login";
          }, 4000);
          return;
        }

        fetchCourse();
      } else fetchPlan();
      setIsFetching(false);
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, [checkoutType, courseId, id, user]);

  const checkout = async (id) => {
    if (!plan) {
      setError(true);
      setErrorMessage("Invalid Checkout!!!");
      return;
    }
    try {
      setIsLoading(true);
      switch (checkoutType) {
        case "upgrade-jobseeker":
          await axios.post(`/candidates/premium-checkout/${id}`).then((res) => {
            window.location.href = `${res?.data?.session?.url}`;
          });
          break;
        case "background-check":
          await axios
            .post(`/candidates/background-check-checkout/${id}`)
            .then((res) => {
              window.location.href = `${res?.data?.session?.url}`;
            });
          break;
        case "hire-recruiter":
          await axios
            .post(`/companies/hirerecruiter-checkout/${id}`, {
              numberOfRecruiters: quantity,
              recruitersDescription: description,
            })
            .then((res) => {
              console.log(res.data);
              window.location.href = `${res?.data?.session?.url}`;
            });
          break;
        case "buy-course":
          await axios.post(`/courses/buy/${courseId}`).then((res) => {
            console.log(res.data);
            window.location.href = `${res?.data?.session?.url}`;
          });
          break;
        default:
          window.location.href = `/details/${id}`;
          break;
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handlePlus = () => {
    if (quantity === 30) {
      setError(true);
      setErrorMessage("Maximum 30 recruiters can be Hired at a time");
      return;
    } else setQuantity(quantity + 1);
  };

  const handleMinus = () => {
    if (quantity === 1) {
      setError(true);
      setErrorMessage("Minimum 1 recruiter can be Hired!!");
      return;
    } else setQuantity(quantity - 1);
  };

  if (config?.taxType === "percent") {
    taxPrice = plan?.price * quantity * (parseFloat(config?.value) / 100);
  } else if (config?.taxType === "price") {
    taxPrice = config?.value;
  }

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div>
        <CvNavbar />
        {isFetching ? (
          <div className="checkout_div">
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <Loader />
            </div>
          </div>
        ) : (
          <div className="checkout_div">
            {checkoutType === "upgrade-jobseeker" ||
            checkoutType === "buy-course" ||
            checkoutType === "background-check" ||
            checkoutType === "hire-recruiter" ? (
              <div className="left_div">
                <h3>Your Basket</h3>
                <hr />
                <div className="plan_div">
                  <div className="plan_header">
                    <p>
                      {checkoutType === "buy-course" ? plan?.title : plan?.name}
                    </p>
                    <p className="job_alert_tips">${plan?.price}</p>
                  </div>

                  {checkoutType === "upgrade-jobseeker" && (
                    <>
                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Applications straight to employer Inbox.</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Application status instant Information</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>80% more chances of being reviewed.</p>
                      </div>
                    </>
                  )}
                  {checkoutType === "background-check" && (
                    <>
                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Applications straight to employer Inbox.</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Application status instant Information</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>80% more chances of being reviewed.</p>
                      </div>
                    </>
                  )}
                  {checkoutType === "hire-recruiter" && (
                    <>
                      <div className="recruiter_count_div">
                        <p>Number of Recruiters: </p>
                        &emsp;
                        <p>
                          <span onClick={handleMinus}>
                            <FaMinusCircle color="var(--btn-color)" size={27} />
                          </span>
                          &emsp;
                          <b>{quantity}</b>
                          &emsp;
                          <span onClick={handlePlus}>
                            <FaPlusCircle color="var(--btn-color)" size={27} />
                          </span>
                        </p>
                      </div>

                      <div className="align-items-center py-3">
                        <div className="col-lg-12 input_field_label">
                          <h6 className="mb-3">
                            Description for Required Recruiters:{" "}
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <textarea
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Alphy Johnson's recruiters to serve you.</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Reduced workload and cost for Hiring.</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>80% boost in the hiring process.</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="left_div">
                <h3>Your Basket</h3>
                <hr />
                <div className="plan_div">
                  <div className="plan_header">
                    <p>{plan?.name}</p>
                    <p className="job_alert_tips">${plan?.price}</p>
                  </div>
                  {plan?.category === "advertise jobs" && (
                    <>
                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Applications straight to your inbox</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Free company profile</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>Use over three months</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>
                          Adverts live for {plan?.advertiseJobs?.daysAllowed}{" "}
                          days
                        </p>
                      </div>
                    </>
                  )}{" "}
                  {plan?.category === "search cvs" && (
                    <>
                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>View Unlimited CVs</p>
                      </div>

                      <div className="plan_line">
                        <TiTick color="var(--btn-color)" size={27} />
                        <p>{`${plan?.searchCvs?.download_cvs} CV downloads`}</p>
                      </div>
                    </>
                  )}
                </div>
                {plan?.category === "advertise jobs" && (
                  <p>
                    *Jobs will be posted immediately however applications may be
                    held while we validate your account.
                  </p>
                )}{" "}
                {plan?.category === "search cvs" && (
                  <p>
                    Your database access will begin following verification of
                    your details and status as a genuine recruiter. This can
                    take between 1 hour and 1 working day (Monday-Friday
                    09:00-17:00).{" "}
                  </p>
                )}
              </div>
            )}
            <div className="right_div">
              <h3>Your Order Summary</h3>
              <hr />
              <div className="checkout_line">
                <p>
                  {checkoutType === "buy-course" ? plan?.title : plan?.name}
                </p>
                <p>${parseInt(plan?.price) * quantity}</p>
              </div>
              <hr />

              <div className="checkout_line">
                <p>SubTotal</p>
                <p>${(plan?.price * quantity * 1.0).toFixed(3)}</p>
              </div>

              <div className="checkout_line">
                <p>VAT</p>
                <p>${taxPrice.toFixed(3)}</p>
              </div>

              <div className="checkout_line">
                <h2>
                  <b>Order Total</b>
                </h2>
                <h2>
                  <b>${(plan?.price * quantity * 1.0 + taxPrice).toFixed(3)}</b>
                </h2>
              </div>

              <button
                className="checkout_btn"
                onClick={() => checkout(plan?._id)}
              >
                <FaLock size={24} color="white" />
                &ensp;&ensp; Checkout Securely
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
