import { ACTIONS } from "./ActionTypes";

const ProfileReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.fetch_start:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case ACTIONS.fetch_jobs:
      return {
        ...state,
        jobs: action.payload,
      };
    case ACTIONS.fetch_applications:
      return {
        ...state,
        applications: action.payload,
      };
    case ACTIONS.fetch_posts:
      return {
        ...state,
        posts: action.payload,
      };
    case ACTIONS.fetch_saved_jobs:
      return {
        ...state,
        savedJobs: action.payload,
      };
    case ACTIONS.fetch_team:
      return {
        ...state,
        team: action.payload,
      };
    case ACTIONS.fetch_devices:
      return {
        ...state,
        devices: action.payload,
      };
    case ACTIONS.fetch_job_alerts:
      return {
        ...state,
        jobAlerts: action.payload,
      };
    case ACTIONS.fetch_end:
      return {
        ...state,
        isFetching: false,
        error: false,
      };
    case ACTIONS.fetch_success:
      return {
        ...state,
        isFetching: false,
        error: false,
      };
    case ACTIONS.fetch_failure:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
