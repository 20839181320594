import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = (props) => {
  return (
    <LazyLoadImage
      src={props?.src || ""}
      alt={props?.alt || ""}
      effect="blur"
      className={props?.className || ""}
      style={props?.style || {}}
      {...props}
    />
  );
};

export default LazyImage;
