import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { Industries } from "../../constants/GlobalConstants";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const AddJob = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState({
    jobTitle: "",
    jobType: "",
    description: "",
    location: "",
    salary: "",
    category: "",
    requirements: "",
    benifits: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/";
    }
  }, [user]);

  const handleSubmit = async () => {
    // console.log(jobDetails);
    if (
      user?.role === "jobseeker" ||
      user?.role === "user" ||
      user?.role === "admin"
    ) {
      setError(true);
      setErrorMessage("You cannot post Job");
      setTimeout(() => {
        navigate(-1);
      }, 1500);
      return;
    }
    try {
      setIsLoading(true);
      let route;
      switch (user?.role) {
        case "employer":
          route = "/jobs/employers/create-job";
          break;
        case "company":
          route = "/jobs/companies/create-job";
          break;
        default:
          break;
      }
      await axios.post(route, jobDetails).then((res) => {
        setSuccess(true);
        setSuccessMessage("Job created successfully");
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleInputChange = (name, value) => {
    setJobDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="">
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details to advertise your job</p>
          <div className="register_form">
            <h3 className="register_form_h3_start">Job Details</h3>

            <hr />
            <form>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Job Title</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.jobTitle}
                    onChange={(e) =>
                      handleInputChange("jobTitle", e.target.value)
                    }
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Job Type</label>
                  <br />
                  <select
                    name=""
                    id=""
                    value={jobDetails?.jobType}
                    onChange={(e) =>
                      handleInputChange("jobType", e.target.value)
                    }
                    required
                  >
                    <option value="">Please Select</option>
                    <option value="permanent">Permanent</option>
                    <option value="contract">Contract</option>
                    <option value="temporary">Temporary</option>
                    <option value="part time">Part Time</option>
                    <option value="full time">Full Time</option>
                    <option value="internship">Internship</option>
                  </select>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Location</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.location}
                    onChange={(e) =>
                      handleInputChange("location", e.target.value)
                    }
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Salary</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.salary}
                    onChange={(e) =>
                      handleInputChange("salary", e.target.value)
                    }
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Category</label>
                  <br />
                  <select
                    name=""
                    id=""
                    value={jobDetails?.category}
                    onChange={(e) =>
                      handleInputChange("category", e.target.value)
                    }
                    required
                  >
                    <option value="">Please Select</option>
                    {Industries.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Description</label>
                  <br />
                  <textarea
                    rows={3}
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    required
                  ></textarea>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>
                    Requirements<small>(Optional)</small>
                  </label>
                  <br />
                  <small>Write on separate lines for better view..</small>
                  <br />
                  <textarea
                    rows={3}
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.requirements}
                    onChange={(e) =>
                      handleInputChange("requirements", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>
                    Perks/Benifits<small>(Optional)</small>
                  </label>
                  <br />
                  <small>Write on separate lines for better view..</small>
                  <br />
                  <textarea
                    rows={3}
                    id="keyword"
                    className="text"
                    type="text"
                    value={jobDetails?.benifits}
                    onChange={(e) =>
                      handleInputChange("benifits", e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </form>
          </div>

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Create Job</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddJob;
