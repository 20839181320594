import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Navbar3 from "../navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { useLocation } from "react-router-dom";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleResetPassword = async () => {
    if (!email || !role) {
      setError(true);
      setErrorMessage("Please fill the fields to continue...");
      return;
    }
    try {
      setIsLoading(true);
      let route;
      switch (role) {
        case "jobseeker":
          route = "/candidates/reset";
          break;
        case "employer":
          route = "/employers/reset";
          break;
        case "company":
          route = "/companies/reset";
          break;
        case "user":
          route = "/users/reset";
          break;
        default:
          break;
      }
      await axios.post(route, { email });
      setSuccess(true);
      setSuccessMessage("Email sent successfully");
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <section>
        <div className="container update_password_container">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-9">
              <h3 className="mb-4 text-center">Reset Password</h3>

              <div
                className="card text-white"
                style={{ borderRadius: "15px", backgroundColor: "var(--blue)" }}
              >
                <div className="card-body">
                  <div className="row align-items-center py-3 input-field">
                    <div className="col-md-3 input-field-label">
                      <h6 className="mb-3">Email Address</h6>
                    </div>
                    <div className="col-md-9 pe-5">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <hr className="mx-n3" />

                  <div className="row align-items-center py-3 input-field">
                    <div className="col-md-3 input-field-label">
                      <h6 className="mb-3">Account Type</h6>
                    </div>
                    <div className="col-md-9 pe-5">
                      <select
                        className="form-control form-control-lg text-grey"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <option value={"jobseeker"}>Candidate</option>
                        <option value={"employer"}>Employer</option>
                        <option value={"company"}>Company</option>
                        <option value={"user"}>Course User</option>
                      </select>
                    </div>
                  </div>

                  <hr className="mx-n3" />

                  <div className="px-5 py-4 text-center">
                    <button
                      type="submit"
                      className="btn-lg"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const Verify2FA = () => {
  const [twoFACode, setTwoFACode] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("userRole");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify2FA = async (e) => {
    e.preventDefault();
    if (!twoFACode) {
      setError(true);
      setErrorMessage("Please enter the code to continue...");
      return;
    }
    try {
      setIsLoading(true);
      let route;
      switch (role) {
        case "jobseeker":
          route = "/candidates/2fa/verify";
          break;
        case "employer":
          route = "/employers/2fa/verify";
          break;
        case "company":
          route = "/companies/2fa/verify";
          break;
        case "user":
          route = "/users/2fa/verify";
          break;
        case "additional":
          route = "/additionalUsers/2fa/verify";
          break;
        default:
          break;
      }
      const response = await axios.patch(route, { twoFACode });
      const { user } = response?.data;
      if (user) localStorage.setItem("user", JSON.stringify(user));
      setIsLoading(false);
      if (role === "additional") window.location.href = "/adminboard";
      if (role === "user") window.location.href = "/dashboard";
      if (role === "jobseeker" || role === "employer" || role === "company")
        window.location.href = "/profile";
    } catch (error) {
      setError(true);
      setErrorMessage(error?.response?.data?.message);
      setIsLoading(false);
      return;
    }
  };

  return (
    <div>
      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>

        <div className="register_form">
          <h3 className="register_form_h3_start">Enter Verification Code</h3>
          <hr />

          <form onSubmit={handleVerify2FA}>
            <div className="register_form_div">
              <div className="register_options">
                <label>2FA Code</label>
                <br />
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  value={twoFACode}
                  onChange={(e) => setTwoFACode(e.target.value)}
                  required
                ></input>
                <small>
                  This code will expire in 3 minutes.Please Hurry Up..
                </small>
              </div>
            </div>
          </form>
        </div>
        <div className="button_maindiv">
          <button type="submit" onClick={handleVerify2FA}>
            Verify 2FA
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { ResetPassword, Verify2FA };
