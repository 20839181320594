import React, { useEffect, useState } from "react";
import "./careerAdvice.css";
import { FaPlusCircle } from "react-icons/fa";
import Blogposts from "../../components/blogPosts/blogPosts";
import Navbar3 from "../../components/navbar3/navbar3";
import { motion } from "framer-motion";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import SearchBar from "../../components/careerAdvice/SearchBar";
import RightOptions from "../../components/careerAdvice/RightOptions";
import BottomOptions from "../../components/careerAdvice/BottomOptions";

const CareerPost = () => {
  const { user } = useAuthContext();
  const { postId } = useParams();
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    try {
      const fetchPost = async () => {
        await axios.get(`/career-advices/${postId}`).then((res) => {
          setCurrentPost(res?.data?.doc);
        });
      };

      const fetchPopular = async () => {
        await axios.get("/career-advices/popular").then((res) => {
          setFeaturedPosts(res?.data?.posts);
        });
      };

      const fetchData = async () => {
        setIsFetching(true);
        await fetchPost();
        await fetchPopular();
        setIsFetching(false);
      };

      fetchData();
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      // alert(error?.response?.data?.message);
    }
  }, [postId]);

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const visitPost = (id) => {
    window.location.replace(`/career-advice/${id}`);
  };

  const animationVariants = {
    initial: {
      opacity: 0,
      y: -20,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  const animationTransition = {
    duration: 5,
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 4,
  };

  return (
    <div>
      <Navbar3 />
      <SearchBar />
      <hr />
      <div className="career_advice">
        <motion.h1
          variants={animationVariants}
          initial="initial"
          animate="animate"
          transition={animationTransition}
          style={{ textTransform: "capitalize" }}
        >
          Unlocking Your Professional Potential
        </motion.h1>
        {user && user?.role !== "user" && (
          <a
            className="create_post_item"
            style={{
              textDecoration: "none",
            }}
            href="/addpost"
          >
            <FaPlusCircle /> Create Post
          </a>
        )}
        <p style={{ textTransform: "capitalize" }}>
          In our comprehensive advice center, you'll find a wealth of resources designed to support
          your career journey. Packed with insightful articles, practical tips, step-by-step guides,
          and professionally crafted CV templates, our platform is dedicated to equipping you with
          the knowledge and tools to excel in your job search and make the most of your career.
        </p>
      </div>

      <div className="section-padding">
        <hr />
        <ul id="list-nav">
          <li>
            <a href="/career-advice" style={{ cursor: "pointer" }}>
              Home
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/getting started`}>
              getting started
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/career guides`}>
              career guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/location guides`}>
              location guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cvs`}>
              cvs
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cv tips and templates`}>
              cv tips and templates
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cover letter guides`}>
              cover letter guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/interview tips`}>
              interview tips
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/work life balance`}>
              work life balance
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/career development`}>
              career development
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/graduates`}>
              graduates
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/apprenticeships`}>
              apprenticeships
            </a>
          </li>
        </ul>
        <hr />
      </div>
      {isFetching ? (
        <div className="blogs_div section-padding">
          <div className="container d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          {featuredPosts?.length <= 0 ? (
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <h2>Coming Soon...</h2>
            </div>
          ) : (
            <div className="blogs_div section-padding">
              {featuredPosts?.slice(0, 5).map((post) => {
                return (
                  <Blogposts
                    key={post?._id}
                    onclick={() => visitPost(post._id)}
                    img={post?.thumbnail}
                    heading={
                      post?.headline?.length > 28
                        ? `${post?.headline.slice(0, 25)}...`
                        : post?.headline
                    }
                    author={post?.author}
                    date={formatDate(post?.date)}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
      <div className="careeradvice_blog_posts_div section-padding">
        <div className="blog_posts_flex_div">
          {isFetching ? (
            <div className="blog_posts_flex_div_left">
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="blog_posts_flex_div_left">
              <div>
                <h2 className="current_post_heading job_alert_tips">{currentPost?.headline}</h2>
                <div className="blog_post_body">
                  <img
                    alt={currentPost?.thumbnail}
                    src={currentPost?.thumbnail}
                    crossOrigin="anonymous"
                    loading="lazy"
                  />
                  <h3>
                    Author:{" "}
                    <u>
                      {currentPost?.author?.employerId
                        ? currentPost?.author?.employerId?.name
                        : currentPost?.author?.companyId
                        ? currentPost?.author?.companyId?.name
                        : currentPost?.author?.jobSeekerId
                        ? `${currentPost?.author?.jobSeekerId?.firstName} ${currentPost?.author?.jobSeekerId?.lastName}`
                        : currentPost?.author?.userId
                        ? `${currentPost?.author?.userId?.firstName} ${currentPost?.author?.userId?.lastName}`
                        : "Content Team"}
                    </u>
                    {currentPost?.author?.id === user?._id && (
                      <>
                        &emsp;
                        <a className="btn btn-primary" href={`/editpost/${currentPost?._id}`}>
                          Update
                        </a>
                      </>
                    )}
                  </h3>
                  <p>{formatDate(currentPost?.date)}</p>
                  <p className="single_post_body">{currentPost?.postBody}</p>
                  {currentPost?.sections?.map((item) => {
                    return (
                      <div key={item?._id}>
                        <p className="single_post_body fw-bold mt-4">{item?.heading}</p>
                        <p className="single_post_body">{item?.content}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <RightOptions />
        </div>

        <h2 className="latest_job_head">
          The most recent job opportunities on{" "}
          <a href="https://resumeglobalhub.thealphyjohnson.com">ResumeGlobalHub</a> and{" "}
          <a href="https://cvglobalhub.thealphyjohnson.com">CvGlobalHub</a>.
        </h2>

        <BottomOptions />
      </div>
      <Footer />
    </div>
  );
};

export default CareerPost;
