import { ACTIONS } from "./actionTypes";

const InitialState = {
  show: {
    showCandidates: true,
    showEmployers: false,
    showCompanies: false,
    showUsers: false,
    showPosts: false,
    showPlans: false,
    showCourses: false,
    showPremiumPlans: false,
    showHiringOrders: false,
    showAddRecruiter: false,
    showEnquiries: false,
    showContactQueries: false,
    showLogs: false,
    showProfile: false,
    showAdditionalUsers: false,
    showSettings: false,
  },

  lightMode: true,
  isModalOpen: false,
  isUpdateModalOpen: false,
  isUpdateProfileModalOpen: false,
};

const handleShow = (name) => {
  const newShow = InitialState.show;
  const updatedObj = Object.keys(newShow).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});
  updatedObj[name] = true;
  return updatedObj;
};

export const NavReducer = (state = InitialState, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_SHOW:
      const newState = handleShow(action.payload.name);
      return {
        ...state,
        show: newState,
      };
    case ACTIONS.CHANGE_MODE:
      return {
        ...state,
        lightMode: !state.lightMode,
      };
    case ACTIONS.change_modal:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case ACTIONS.change_update_modal:
      return {
        ...state,
        isUpdateModalOpen: !state.isUpdateModalOpen,
      };
    case ACTIONS.change_update_profile_modal:
      return {
        ...state,
        isUpdateProfileModalOpen: !state.isUpdateProfileModalOpen,
      };
    default:
      return state;
  }
};
