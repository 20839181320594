import "./Privacy.css";
import React from "react";
import ReactSearchBox from "../ReactSearchBox/ReactSearchBox";
import Footer from "../../components/footer/footer";
import Navbar3 from "../../components/navbar3/navbar3";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/privacy-policy" />

        <title>Privacy Policy - How We Protect Your Data | Alphy Johnson</title>

        <meta
          name="description"
          content=" Review our privacy policy to understand how we protect your personal information. Learn about data usage, protection measures, & privacy on our site."
        />
      </Helmet>

      <Navbar3 />
      <div>
        <br></br>
        <br></br>
        <ReactSearchBox />
      </div>
      <div className="border terms_border_div">
        <div className="border-1">
          <div className="heading__style">
            <h1>
              <b>PRIVACY POLICY OF Alphy Johnson's Employment Center</b>
            </h1>
            <div className="line"></div>
          </div>
          <div></div>

          <div className="priv_text">
            <p>
              This privacy policy pertains specifically to this website, which is operated by Alphy
              Johnson's Employment Center Ltd.  The terms <b>'Alphy Johnson's Employment Center'</b>
              ,<b>'we'</b>, <b>'us'</b> and <b>'our'</b> refer to{" "}
              <b>Alphy Johnson's Employment Center LLC</b>, whose addresses are:
              <br></br>
              <strong>HEAD QUARTERS: </strong>
              <b>6731 Frontier Dr. #1095 Springfield, VA 22150 Unites States.</b>
              <br></br>
              <strong>Digital Address: </strong>{" "}
              <b>WS - 292 - 8129 Airport road Takoradi, Western</b>
              <br></br>
              <strong>Digital Address: </strong>
              <b>
                CC - 008 - 2200 p 1366 Light House Avenu Cape Coast, Central NIGERIA ADDRESS: TBA
              </b>
            </p>
          </div>

          <div>
            <p className="priv_text_1">
              <a href="/">Alphy Johnson's Employement Center</a> is committed to keeping the
              information you submit to us private and using it solely for the purposes outlined
              below. We strive to provide a safe and secure experience for all users and comply with
              our obligations under the EU General Data Protection Regulation (GDPR) and The CCPA
              California Consumer Privacy Act. We also recognize the significance of preserving the
              confidentiality of your CV or RESUMES and other stored information while using our
              website.
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">PRIVACY MANAGEMENT </h2>
            <p className="priv_text">
              To enhance your user experience, we and our partners utilize technologies like cookies
              to store and/or access information on your device. By agreeing to these technologies,
              we and our partners may process personal data, such as browsing behavior or unique
              IDs, on this site. Choosing not to consent or withdrawing your consent may impact
              certain features and functionalities of the site.
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">
              INFORMATION RELATED TO JOBSEEKER ACCOUNTS AND CVS/RESUMES. 
            </h2>
            <p className="priv_text">
              When you create an account on <a href="/">Alphy Johnson's Employement Center</a>, your
              information, including your CV or RESUMES, may be accessible to direct recruiters,
              employment consultants, or other individuals or organizations who request CV or
              RESUMES matching your profile. They can view, search, and download your details over
              the internet. While <a href="/">Alphy Johnson's Employement Center</a> tries to verify
              the identity and legitimacy of these entities, we do not provide any warranties
              regarding their authenticity. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">Your personal information</h2>
            <p className="priv_text">
              When you register with us as a jobseeker we will process your personal data on the
              basis set out in this policy, as it is in our legitimate interests to do so following
              your registration. We will collect personal information from you, such as your name,
              contact details, details of the kind of work you are looking for, the location where
              you want to work and your CV. We will use your personal data to manage your account
              and to email you about our services which includes job alerts and emails about courses
              that are relevant to your fields of interest. We may contact you regarding site
              changes or changes to the Alphy Johnson's Employment Center products or services that
              you use.
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">YOUR PERSONAL INFORMATION AND PRIVACY </h2>
            <p className="priv_text">
              As a jobseeker registering with us, we process your personal data based on the grounds
              outlined in this policy, as it is our legitimate interest to do so following your
              registration. We will gather personal information from you, such as your name, contact
              details, preferred job type, desired work location, and your CV or RESUMES. This
              information will be used to manage your account and to send you emails about our
              services, including job alerts and relevant courses. We may also contact you about
              changes to the
              <a href="/">Alphy Johnson's Employement Center</a> products or services that you use. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">REVEALING PERSONAL INFORMATION </h2>
            <p className="priv_text">
              Upon registration, certain information about you is stored in your account, which is
              safeguarded by your password and can only be accessed by you. This information is not
              a part of our candidate database and is not searchable or accessible by Alphy
              Johnson's Employment Center clients. You have the option to delete your account
              whenever you wish. Unless you choose to hide it, your CV or RESUMES will be visible to
              all Alphy Johnson's Employment Center clients. If you opt to hide your CV or RESUMES,
              it will not be visible or accessible to any Alphy Johnson clients.  If you do not want
              us to collect your personal information, please refrain from submitting it to us.  We
              request job seekers to exclude any sensitive personal data, such as their racial or
              ethnic origin, political opinions, religious or philosophical beliefs, trade union
              membership, genetic data, biometric data, health information, sex life, or sexual
              orientation, from their CV or RESUMES.  If you opt to generate a profile and upload
              your CV or RESUMES to our database, it is your responsibility to ensure that your
              information is current. It is recommended that you update your CV or RESUMES every six
              months, especially if your contact details have been modified.  Alphy Johnson reserves
              the right to delete any CV or RESUMES from our database that contain any material
              deemed illegal or offensive. We may compile and share aggregated information with our
              clients. Furthermore, if you provide your information on a co-branded registration or
              job application page, such as a <a href="/">Alphy Johnson's Employement Center</a>
              client-company registration page, the data you provide is the property of both{" "}
              <a href="/">Alphy Johnson's Employement Center</a> and the client.
              <a href="/">Alphy Johnson's Employement Center</a> is not accountable for the
              information that the client receives. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">ACCOUNT INFORMATION AND CV or RESUMES ACCESS </h2>
            <p className="priv_text">
              When you register with us as a jobseeker, we store some information about you in your
              account. This information is protected by your password and can only be accessed by
              you. It does not form part of our candidate database and cannot be searched for or
              accessed by <a href="/">Alphy Johnson's Employement Center</a>
              clients.  You have the option to delete your account at any time. However, please note
              that your CV or RESUMES, unless you choose to 'hide' it, will be accessible to any
              Alphy Johnson's Employment Center client. If you choose to hide your CV or RESUMES, it
              will not be viewable by any clients. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">SENSITIVE PERSONAL DATA</h2>
            <p className="priv_text">
              Please refrain from submitting sensitive personal data on CV or RESUMES, such as your
              racial or ethnic origin, political opinions, religious or philosophical beliefs, trade
              union membership, genetic data, biometric data, health, sex life, or sexual
              orientation. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">RESUME/CV or RESUMES UPDATES</h2>
            <p className="priv_text">
              If you choose to create a profile and upload a CV or RESUME to our database, it is
              your responsibility to ensure that your details are kept up to date. You should update
              your CV or RESUMES at least every six months, particularly if your contact details
              have changed. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">OFFENSIVE CONTENT AND DATA SHARING </h2>
            <p className="priv_text">
              <a href="/">Alphy Johnson's Employement Center</a> reserves the right to remove any CV
              or RESUMES that include illegal or offensive content from our database. We may also
              record and share aggregated information with our clients.  If you input your
              information on a co-branded registration or job application page, such as a Alphy
              Johnson's Employment Center client-company registration page, the information you
              provide becomes the property of both Alphy Johnson's Employment Center and the client.
              Alphy Johnson's Employment Center is not responsible for the information that the
              client receives.  <a href="/">Alphy Johnson's Employement Center</a> will analyze the
              aggregate data to identify employment trends across various industries, such as
              average salaries, without linking it to any personally identifying information about
              users. Please note that some of our customers may be located all over the world. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">PLEASE BE ADVISED:</h2>
            <p className="priv_text">
              Your current employer may have access to Alphy Johnson's Employment Center's database
              as a customer or through a client of our customer.{" "}
              <a href="/">Alphy Johnson's Employement Center</a> cannot limit which customers have
              access to profiles on our database and cannot be held liable for any consequences
              arising from such access. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">COLLECTION AND USE OF INFORMATION </h2>
            <h2 className="heading__2">IP Logging: </h2>
            <p className="priv_text">
              As with many websites, we gather your IP address information to determine your general
              location. This data is anonymous and is never linked to your Jobseeker Account or CV
              or RESUMES Information. Collecting your IP address helps us determine if you are
              visiting the site from within the US, EU or the rest of the world. This enables us to
              show you location-specific content and ask the right questions about your eligibility
              to work in the US or EU, should you decide to apply for a job in the Alphy Johnson's
              Employment Center. Your IP address is automatically received and logged by{" "}
              <a href="/">Alphy Johnson's Employement Center</a>'s web servers. We record IP
              addresses for anonymous statistical purposes, administration of our website and
              servers, and to improve our services. An IP address is a unique identifier assigned to
              every device connected to the internet, like a car license plate. 
            </p>

            <h2 className="heading__2">GOOGLE MAPS: </h2>
            <p className="priv_text">
              <a href="/">Alphy Johnson's Employement Center</a> uses the Google Maps API in
              accordance with
              <a className="links" href="https://policies.google.com/privacy?hl=en-US">
                {" "}
                Google's Privacy Policy.
              </a>
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">COOKIES: </h2>
            <p className="priv_text">
              <a href="/">Alphy Johnson's Employement Center</a> may collect information through
              "cookies" from your registration form and use of our website. This information may be
              used internally for purposes relating to your use of the website and for marketing
              activities. It may also be shared with selected third parties for marketing
              activities. If you do not want to receive marketing material from Alphy Johnson's
              Employment Center or from third parties, you can indicate your preference on the
              submission form or notify us through the Contact page of our website or delete your CV
              or RESUMES.  Alphy Johnson's Employment Center may set and access cookies on your
              computer. You have the option to choose whether to accept cookies but declining them
              may limit your access to some of our services. For more information about{" "}
              <a href="/">Alphy Johnson's Employement Center</a> cookies, please refer to our Cookie
              Policy. This policy does not apply to companies that{" "}
              <a href="/">Alphy Johnson's Employement Center</a> does not own or control or to
              individuals that Alphy Johnson does not employ.  LiveRamp: When you log in to our
              website, we may share information that we collect from you, such as your email (in
              hashed, pseudonymous form), IP address, or information about your browser or operating
              system, with our partner LiveRamp. LiveRamp uses this information to create an online
              identification code for the purpose of recognizing you on your device. This code does
              not contain any identifiable personal data and cannot be used to re-identify you. We
              place this code in our cookie and allow it to be used for online and cross-channel
              advertising. It may be shared with our advertising partners and other third-party
              advertising companies globally to enable interest-based content or targeted
              advertising throughout your online experience (e.g., web, email, connected devices,
              and in-app, etc.). These third parties may use this code to link demographic or
              interest-based information you have provided in your interactions with them. You have
              the right to choose whether we share this data with LiveRamp for the above purposes,
              the creation of this code, or the sharing of the code with our advertising partners. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">Third Party Cookies</h2>
            <p className="priv_text">
              <a href="/">Alphy Johnson's Employement Center</a> authorizes Criteo to place and read
              cookies on their website, including network websites and emails, to serve personalized
              advertisements when users visit other Criteo ad network sites. Criteo are authorized
              by CV-GlobalHub to place and read cookies on our websites (including network websites
              and emails). When you use our website, we share information that we may collect from
              you, such as your email (in hashed, de-identified form), IP address or information
              about your browser or operating system, with our partner, LiveRamp Inc and its group
              companies (‘LiveRamp’). LiveRamp may drop a cookie on your browser or directly in our
              emails and match your shared information to their on- and offline marketing databases
              and those of its advertising partners to create a link between your browser and
              information in those other databases. Similarly, when you use our mobile apps, we may
              also share hashed and de-identified email addresses, mobile device ID, location data,
              and advertising identifiers with LiveRamp, which uses that information to create a
              link between your mobile device and its databases. This link may be shared by our
              partners globally for the purpose of enabling interest-based content or advertising
              throughout your online experience (e.g. cross device, web, email and in app etc.) by
              third parties unaffiliated with our website. These third parties may in turn link
              further demographic or interest-based information to your browser. You have the right
              to exercise your rights under GDPR including opting out of LiveRamp’s Cookie or Mobile
              Identifiers and can easily do so by
              <a className="links" href="#Click">
                {" "}
                clicking on this link.{" "}
              </a>
              Other choice options for display: Users can also express their choices, through the
              following platforms: IAB opt-out platform, Network Advertising Initiative opt-out
              platform, Digital Advertising Alliance platform that provide options to express their
              choices for all companies that are registered in this platform. CV-GlobalHub uses a
              specific cookie in order to facilitate the use of Google Universal Analytics for
              logged in Candidates or Recruiters (“Logged-In User”). If you are a Logged-In User,
              CV-GlobalHub may use your Candidate or Recruiter user ID in combination with Google
              Universal Analytics and Google Analytics to track and analyse the pages of
              CV-GlobalHub that you visit. We do this to better understand how you use CV-GlobalHub,
              with the aim of improving CV-GlobalHub for all users. We do not pass any Logged-In
              User personal identification information to Google Analytics.
              <br></br>
              <br></br>
              <a className="links" href="https://support.google.com/analytics/answer/6004245">
                Learn more about privacy at Google{" "}
              </a>
              or opt-out of this feature by installing the
              <a className="links" href="https://tools.google.com/dlpage/gaoptout/">
                {" "}
                Google Analytics Opt-out Bser Add-on.
              </a>
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">EMAIL TRACKING PIXELS </h2>
            <p className="priv_text">
              <a href="/">Alphy Johnson's Employement Center</a> employs tracking pixels in some of
              the emails it sends, as is the case with approximately 40% of emails worldwide. These
              pixels are small, invisible images inserted into Alphy Johnson emails that allow us to
              gauge the performance and effectiveness of our email campaigns.  We collect this data
              anonymously for statistical reporting purposes only and do not use it to personalize
              our marketing or sales activities towards any individual. We utilize a pixel provided
              by Google Analytics to assess the effectiveness of our messages in driving traffic to
              our website.  We also use a pixel provided by ReturnPath to analyze the time
              recipients spend reading our emails, which enables us to improve the content we send
              to you. The ReturnPath pixel identifies the platform on which the email is opened,
              such as the email client or device type, and we use this information to ensure that
              our emails are designed effectively for the different ways in which our users view
              them.  Both pixels provide anonymous details on the location and time when the email
              was opened, which we may use to ensure our services are being used effectively and to
              measure the speed at which our messages are reviewed by their recipients. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">EMAILS FROM ALPHY JOHNSON </h2>
            <p className="priv_text">
              By joining <a href="/">Alphy Johnson's Employement Center</a>, you will receive email
              communications from us. You will receive essential communications, but upon
              registration, you will also have the option to sign up for 'Job Alerts' and
              'Updates.' 
              <br></br>
              <a className="links" href="https://thealphyjohnson.com/register">
                {" "}
                Register with <a href="/">Alphy Johnson's Employement Center</a>
              </a>
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">ESSENTIAL COMMUNICATIONS </h2>
            <p className="priv_text">
              We will send you messages that we believe are necessary to fulfill our legal
              obligations and meet your expectations, including messages about your account,
              application confirmations, and documentation related to purchases of optional premium
              products.  In accordance with our Terms & Conditions, if you do not wish to receive
              emails of this nature, you should refrain from using Alphy Johnson (see Closing Your{" "}
              <a href="/">Alphy Johnson's Employement Center</a>
              Account below). 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">Job Alerts</h2>
            <p className="priv_text">
              Customized Job Alerts comprise notifications of the most recent job postings on our
              website that meet your search preferences. These notifications may contain: 
              <ul className="list">
                <li>
                  Daily Job Alerts
                  <p>
                    These alerts will be sent to you daily, except when there are no new job
                    postings that align with your preferences since your last alert. If you have
                    established multiple Job Alerts, you will receive them separately daily. 
                  </p>
                </li>
                <li>
                  Instant Job Alerts
                  <p>
                    If a new job that closely matches your Job Alert preferences is posted, an
                    Instant Job Alert will be sent to you. The frequency of these alerts will vary
                    based on the type of job you desire, your preferred location/commute, and salary
                    expectations.
                  </p>
                </li>
                <li>
                  Company Alerts
                  <p>
                    If you choose to set-up a Company Alert (a Job Alert for roles at a specific
                    company), you will receive an email notification when that company posts new
                    vacancies matching your search criteria. These will also vary in frequency
                    depending on your search criteria and the volume of roles the Company makes
                    available.
                  </p>
                </li>
              </ul>
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">Updates</h2>
            <p className="priv_text">
              Updates are email communications from{" "}
              <a href="/">Alphy Johnson's Employment Center</a> aimed to assist you in your job
              search, offering tips, motivational advice, and other suggestions to help you
              progress. The updates may cover the following topics: 
              <ul className="list">
                <li>
                  Notifications:  
                  <p>
                    These will provide you with summaries of your job applications and any messages
                    or requests from recruiters. We'll also send reminders to check in and stay
                    active in your job search if you haven't done so for a while. 
                  </p>
                </li>
                <li>
                  Featured Job Opportunities: 
                  <p>
                    Recruiters or employers sometimes ask us to promote certain job opportunities
                    that they believe are a good fit for candidates. If the location and salary
                    match your preferences, you may receive a featured job opportunity
                    notification. 
                  </p>
                </li>
                <li>
                  Career Advice 
                  <p>
                    We partner with experts to provide helpful guidance on improving your CV or
                    RESUMES and career prospects. You will receive occasional emails about these
                    services. 
                  </p>
                </li>
                <li>
                  The <a href="/">Alphy Johnson's Employement Center</a> Community: 
                  <p>
                    It is an opportunity for you to share your thoughts and opinions with us to help
                    us improve our services. We may reach out to you once a year or so to ask for
                    your feedback on topics such as the job market, new or existing services, and
                    more. Additionally, we may hold prize draws for members of the community. 
                  </p>
                </li>
              </ul>
              Alternatively, you can stop receiving Updates altogether if you so choose - this can
              also be done via My Account, or you can click the unsubscribe link in any Updates
              email.
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">Security</h2>
            <p className="priv_text">
              The latest security devices and firewalls are employed to safeguard this site from
              unauthorized access. 
            </p>
          </div>

          <div className="text__Container">
            <h2 className="heading__2">See Also:</h2>
            <p className="priv_text">
              <ul className="list">
                <li>
                  <a className="links" href="https://thealphyjohnson.com/terms">
                    Alphy Johnson's Employment Center Terms & Condition 
                  </a>
                </li>
                <li>
                  <a className="links" href="https://thealphyjohnson.com/privacy">
                    Alphy Johnson's Employment Center Cookie Policy
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
