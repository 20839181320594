import React from "react";
import Blog from "./Blog";
import Cvbulider from "./Cvbulider";
import CvCreate from "./Cv_create";
import Header from "./Header";
import Slder from "./Slder";
import "./cvbuilder.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { Helmet } from "react-helmet";

function CvbuilderPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Expert Resume Fixer | Polish Your Resume to Perfection</title>

        <link rel="canonical" href="https://thealphyjohnson.com/resume-fixer" />
        <meta
          name="description"
          content="Transform your resume with our expert resume fixer services. Ensure the resume stands out & gets noticed by employers - Enhance your job prospects today!"
        />
      </Helmet>
      <Navbar3 />
      <Header />
      <Cvbulider />
      <Slder />
      <Blog />
      <CvCreate />
      <Footer />
    </>
  );
}

export default CvbuilderPage;
