import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import "./jobsdetail.css";
import Navbar3 from "../../components/navbar3/navbar3";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { FaMapMarkerAlt, FaClock, FaRegListAlt } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

function JobDetails() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [job, setJob] = useState(null);
  const [isJobSaved, setIsJobSaved] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [createdJobs, setJobs] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    try {
      setIsFetching(true);
      const fetchJobDetails = async () => {
        const response = await axios.get(`/jobs/${id}`);
        setJob(response.data);
        // console.log(response.data);
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      };

      const fetchSavedJobs = async () => {
        const response = await axios.get(`/jobs/saved`);
        if (response.data.find((obj) => obj?.job?._id === id)) {
          setIsJobSaved(true);
        }
        // console.log(response.data);
      };

      const fetchApplications = async () => {
        await axios.get(`/candidates/get/applied-jobs`).then((res) => {
          setApplications(res.data?.appliedJobs);
        });
      };

      const fetchJobs = async () => {
        let route;
        switch (user?.role) {
          case "employer":
            route = `/employers/get/jobs`;
            break;
          case "company":
            route = `/companies/get/jobs`;
            break;
          default:
            return;
        }
        await axios.get(route).then((res) => {
          // console.log(res?.data?.data);
          setJobs(res.data?.data);
        });
      };

      fetchJobDetails();
      if (user?.role === "jobseeker") {
        fetchSavedJobs();
        fetchApplications();
      }
      if (user?.role === "employer" || user?.role === "company") fetchJobs();
    } catch (error) {
      console.log(error.response);
      // alert(error?.response?.data?.message);
    }
  }, [user, id]);

  const handleSaveClick = async () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login first to continue");
      return;
    }
    try {
      setIsLoading(true);
      if (!isJobSaved) {
        // Save the job
        await axios.patch(`/jobs/save/${job._id}`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res.data?.message);
          setIsJobSaved(true);
        });
      } else {
        // Remove the saved job
        await axios.delete(`/jobs/${job._id}/unsave`).then((res) => {
          setSuccess(true);
          setSuccessMessage(res.data?.message);
          setIsJobSaved(false);
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleApplyNowClick = () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login first to continue");
      return;
    }
    window.location.href = `/applynow/${id}`;
  };

  const editJob = () => {
    window.location.href = `/editjob/${id}`;
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div>
        <Navbar3 />
        <div className="job-details-container profile_details_main_div">
          {isFetching ? (
            <div
              className="container d-flex justify-content-center align-items-center"
              style={{ minHeight: "100px", margin: "2rem auto" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <div className="container-lg">
                <div className="job_title_div">
                  <h2 className="job-details-title job_alert_tips">
                    {job?.jobTitle}
                  </h2>
                  <div className="save_job">
                    {isJobSaved ? (
                      <button className="unsave-btn" onClick={handleSaveClick}>
                        UnSave
                      </button>
                    ) : (
                      <button className="save-btn" onClick={handleSaveClick}>
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="job_details_area section-bg2">
                <div className="container-lg">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="job_details_header">
                        <div className="links_locat">
                          <div className="job-location">
                            <p>
                              <FaMapMarkerAlt className="job_detail_icon" />
                            </p>
                            <p>{job?.location}</p>
                          </div>
                          <div className="job-location">
                            <p>
                              <FaClock className="job_detail_icon" />
                            </p>
                            <p>{job?.jobType}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="job_sumary">
                        <div className="summery_header">
                          <h4 className="job_detail_icon">
                            <b>Summary</b>
                          </h4>
                        </div>
                        <div className="job_content">
                          <ul style={{ listStyleType: "none" }}>
                            <li>
                              Published on:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {formatDate(job?.postedDate)}
                              </span>
                            </li>
                            <li>
                              Salary:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                {job?.salary}
                              </span>
                            </li>
                            <li>
                              Location:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {job?.location}
                              </span>
                            </li>
                            <li>
                              Job Nature:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {job?.jobType}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="jobs_left">
                        <div className="descript_wrap white-bg">
                          <div className="single_wrap">
                            <h4
                              style={{ fontWeight: "bold" }}
                              className="job_detail_icon"
                            >
                              Description
                            </h4>
                            <p>{job?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {job?.requirements && (
                      <div className={job?.perks ? "col-lg-6" : "col-lg-12"}>
                        <div className="jobs_left">
                          <div className="descript_wrap white-bg">
                            <div className="single_wrap">
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="job_detail_icon d-flex align-items-center"
                              >
                                <FaRegListAlt />
                                &nbsp;&nbsp; Requirements
                              </h4>
                              {job?.requirements
                                ?.split("\n")
                                ?.map((item, index) => {
                                  return (
                                    <div key={index} className="desc-item-div">
                                      <p>
                                        <BsCheckCircleFill color="var(--btn-color)" />
                                      </p>
                                      <p>{item}</p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {job?.perks && (
                      <div
                        className={job?.requirements ? "col-lg-6" : "col-lg-12"}
                      >
                        <div className="jobs_left">
                          <div className="descript_wrap white-bg">
                            <div className="single_wrap">
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="job_detail_icon d-flex align-items-center"
                              >
                                <FaRegListAlt />
                                &nbsp;&nbsp; Perks/Benifits
                              </h4>
                              {job?.perks?.split("\n")?.map((item, index) => {
                                return (
                                  <div key={index} className="desc-item-div">
                                    <p>
                                      <BsCheckCircleFill color="var(--btn-color)" />
                                    </p>
                                    <p>{item}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="job_btns">
                {applications?.find((obj) => obj?.job?._id === job?._id) ? (
                  <p style={{ color: "var(--btn-color)" }}>
                    Application Submitted..
                  </p>
                ) : (
                  <button
                    className="apply-now-btn"
                    onClick={handleApplyNowClick}
                  >
                    Apply Now
                  </button>
                )}
                {(user?.role === "employer" || user?.role === "company") &&
                  createdJobs?.find((obj) => obj?._id === id) && (
                    <button className="apply-now-btn" onClick={editJob}>
                      Edit
                    </button>
                  )}
                {user?.role === "admin" && (
                  <button className="apply-now-btn" onClick={editJob}>
                    Edit
                  </button>
                )}
              </div>
            </>
          )}

          <span className="top_border profile_items_border"></span>
          <span className="right_border profile_items_border"></span>
          <span className="bottom_border profile_items_border"></span>
          <span className="left_border profile_items_border"></span>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JobDetails;
