import React, { useEffect, useState } from "react";
import "./viewcv.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import {
  FaLandmark,
  FaPhone,
  FaInbox,
  FaLocationArrow,
  FaStar,
} from "react-icons/fa";
import Footer from "../../components/footer/footer";
import Loader from "../../components/Loader";

const ViewCV = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [cv, setCV] = useState("");
  const [show, setShow] = useState({
    showExperience: true,
    showEducation: false,
    showCertificates: false,
  });
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);

  const navOptions = ["Work Experience", "Education", "Certificates"];

  useEffect(() => {
    if (!user || user?.role === "user") {
      // alert("You are not authorized to view this page!!!");
      navigate(-1);
    }
    try {
      setIsFetching(true);
      const fetchCV = async () => {
        await axios.get(`/cv/${id}`).then((res) => {
          const { cv } = res.data;
          console.log(cv);
          setCV(cv);
          setTimeout(() => {
            setIsFetching(false);
          }, 1000);
        });
      };

      fetchCV();
    } catch (error) {
      console.log(error);
      // alert(error?.response?.data?.message);
      return;
    }
  }, [id, user, navigate]);

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const handleShow = (name) => {
    switch (name) {
      case "Work Experience":
        setShow({
          showExperience: true,
          showCertificates: false,
          showEducation: false,
        });
        break;
      case "Education":
        setShow({
          showExperience: false,
          showCertificates: false,
          showEducation: true,
        });
        break;
      case "Certificates":
        setShow({
          showExperience: false,
          showCertificates: true,
          showEducation: false,
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div>
        <Navbar3 />
        <div className="container cv_container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      View CV
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          {isFetching ? (
            <div className="row">
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-3 ">
                <div className="card left-profile-card">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        src={cv?.user?.profilePhoto}
                        alt={cv?.user?.profilePhoto}
                        crossOrigin="anonymous"
                        className="user-profile"
                        loading="lazy"
                      />
                      <h3>{cv?.firstName + " " + cv?.lastName}</h3>
                      {user?.role === "jobseeker" && (
                        <p>
                          <b>
                            <a href="/updatecv">Edit CV</a>
                          </b>
                        </p>
                      )}
                      <p>{cv?.professionSummary}</p>
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        <i
                          className="fas fa-star"
                          style={{ color: "var(--blue)" }}
                        >
                          <FaStar />
                        </i>
                        <i
                          className="fas fa-star"
                          style={{ color: "var(--blue)" }}
                        >
                          <FaStar />
                        </i>
                        <i
                          className="fas fa-star"
                          style={{ color: "var(--blue)" }}
                        >
                          <FaStar />
                        </i>
                        <i
                          className="fas fa-star"
                          style={{ color: "var(--blue)" }}
                        >
                          <FaStar />
                        </i>
                        <i
                          className="fas fa-star"
                          style={{ color: "var(--blue)" }}
                        >
                          <FaStar />
                        </i>
                      </div>
                    </div>
                    <div className="personal-info">
                      <h3>Personal Information</h3>
                      <ul className="personal-list">
                        <li>
                          <FaLocationArrow color="var(--blue)" size={20} />
                          &nbsp;&nbsp;
                          <span> {cv?.address} </span>
                        </li>
                        <li>
                          <FaLandmark color="var(--blue)" size={20} />
                          &nbsp;&nbsp;
                          <span> {cv?.city} </span>
                        </li>
                        <li>
                          <FaInbox color="var(--blue)" size={20} />
                          &nbsp;&nbsp;
                          <span> {cv?.email} </span>
                        </li>
                        <li>
                          <FaPhone color="var(--blue)" size={20} />
                          &nbsp;&nbsp;
                          <span> {cv?.user?.details?.contactNo} </span>
                        </li>
                      </ul>
                    </div>
                    <div className="skill">
                      <h3>Skills</h3>
                      {cv?.skills?.map((skill, index) => {
                        return (
                          <>
                            <p key={skill}>{skill}</p>
                            <div key={index} className="progress mb-3">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${
                                    Math.floor(Math.random() * 51) + 50
                                  }%`,
                                  backgroundColor: "var(--blue)",
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="languages">
                      <h3>Languages</h3>
                      {cv?.languages?.map((skill, index) => {
                        return (
                          <>
                            <p key={skill}>{skill}</p>
                            <div key={index} className="progress mb-3">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${
                                    Math.floor(Math.random() * 51) + 50
                                  }%`,
                                  backgroundColor: "var(--blue)",
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="languages">
                      <h3>Hobbies</h3>
                      {cv?.hobbies?.map((skill, index) => {
                        return (
                          <>
                            <p key={skill}>{skill}</p>
                            <div key={index} className="progress mb-3">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${
                                    Math.floor(Math.random() * 51) + 50
                                  }%`,
                                  backgroundColor: "var(--blue)",
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="card right-profile-card">
                  <div className="view_cv_header_nav">
                    <ul className="nav nav-pills">
                      {navOptions.map((item) => {
                        return (
                          <li
                            key={item}
                            className="nav-item"
                            onClick={() => handleShow(item)}
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <p className="active-item">
                    {show.showCertificates
                      ? "/Certificates"
                      : show.showEducation
                      ? "/Education"
                      : "/Work Experience"}
                  </p>
                  <div className="card-body">
                    <div className="tab-content" id="pills-tabContent">
                      {show.showExperience && (
                        <div>
                          {cv?.workExp?.map((exp, index) => {
                            return (
                              <div key={index} className="work-container">
                                <h3>{exp?.jobTitle}</h3>
                                <h4>
                                  <b>{exp?.jobEmployementType}</b> @{" "}
                                  <b>{exp?.jobCompany}</b>
                                </h4>
                                <h4>
                                  <b>
                                    {formatDate(
                                      exp?.jobStartDate ||
                                        "2023-06-10T00:00:00.000Z"
                                    )}
                                  </b>{" "}
                                  to{" "}
                                  <b>
                                    {formatDate(
                                      exp?.jobEndDate ||
                                        "2023-06-10T00:00:00.000Z"
                                    )}
                                  </b>
                                </h4>
                                <p>{exp?.jobResponsibilities}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {show.showEducation && (
                        <div>
                          {cv?.education?.map((item, index) => {
                            return (
                              <div key={index} className="work-container">
                                <h3>{item?.eduInstitute}</h3>
                                <h4>
                                  <b>
                                    {item?.eduQualification +
                                      " " +
                                      item?.eduCourse}
                                  </b>
                                </h4>
                                <h4>
                                  Completes on{" "}
                                  <b>
                                    {formatDate(
                                      item?.eduCompletionYear ||
                                        "2023-06-10T00:00:00.000Z"
                                    )}
                                  </b>
                                </h4>
                                <p>{item?.eduDescription}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {show.showCertificates && (
                        <div>
                          {cv?.certificates?.map((item, index) => {
                            return (
                              <div key={index} className="work-container">
                                <h3>{item?.certificateName}</h3>
                                <h4>
                                  <b>{item?.certificateOrganization}</b>
                                </h4>
                                <p>
                                  {
                                    <b>
                                      {formatDate(
                                        item?.certificateCompletionDate ||
                                          "2023-06-10T00:00:00.000Z"
                                      )}
                                    </b>
                                  }
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewCV;
