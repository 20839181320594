import React, { useEffect, useState } from "react";
import "./Profile.css";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { MdLanguage, MdOutlinePersonPin, MdPostAdd, MdWork } from "react-icons/md";
import Loader from "../../components/Loader";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const role = params.get("role");
  const [user, setUser] = useState("");
  const { id } = useParams();
  const userId = id;
  const [jobs, setJobs] = useState([]);
  const [cv, setCV] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    try {
      setIsFetching(true);
      const fetchCV = async (userId) => {
        await axios.get(`/cv/user/${userId}`).then((res) => {
          const { cv } = res.data;
          console.log(cv);
          setCV(cv);
        });
      };

      const fetchJobs = async () => {
        await axios.get(`/jobs/creator/${userId}?role=${role}`).then((res) => {
          // console.log(res?.data?.data);
          setJobs(res.data?.data);
        });
      };

      const fetchUser = async () => {
        let route;
        if (role === "jobseeker") route = `/candidates/${userId}`;
        if (role === "employer") route = `/employers/${userId}`;
        if (role === "company") route = `/companies/${userId}`;
        await axios.get(route).then((res) => {
          // console.log(res.data?.doc);
          setUser(res.data?.doc);
          if (role === "jobseeker") fetchCV(res.data?.doc.id);
        });
      };

      fetchUser();
      if (role === "employer" || role === "company") fetchJobs();
      setTimeout(() => {
        setIsFetching(false);
      }, 1500);
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, [role, userId]);

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      <div className="pge">
        <Navbar3 />
        <div className="searching_companies">
          <a className="link" href="/job-recruiting-websites">
            Search Unlimited Jobs <p>from</p> Numerous companies{" "}
          </a>
        </div>
        <ReactSearchBox />
        {isFetching ? (
          <div
            className="container d-flex justify-content-center align-items-center"
            style={{ minHeight: "100px", margin: "2rem auto" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {user ? (
              <div className="profile">
                <h1 className="job-details-title job_alert_tips">
                  {user?.name ? user?.name : user?.firstName + " " + user?.lastName}
                  's Profile
                </h1>
                <div className="main_box">
                  <div className="profile_box" id="c1">
                    <div className="profile_icon">
                      <Link>
                        <img
                          src={user?.profilePhoto}
                          alt={user?.profilePhoto}
                          crossOrigin="anonymous"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className="credentials_1">
                      <h3>{user?.name ? user?.name : user?.firstName + " " + user?.lastName}</h3>
                      {user?.jobTitle}
                      {user?.role === "jobseeker" && user?.cv && (
                        <p>
                          <a href={`/viewcv/${cv?._id}`}>View CV</a>
                        </p>
                      )}
                    </div>

                    {(user?.role === "employer" || user?.role === "company") && (
                      <>
                        <div className="btn-group">
                          <div className="profile_links_btn">
                            <p className="btn_link">
                              <MdPostAdd />
                              <strong>Posted Jobs:</strong>
                            </p>
                            <p className="btn_link">
                              <strong>{user?.postedJobs?.length}</strong>
                            </p>
                          </div>
                        </div>
                        {user?.postedJobs?.length === 0 && <div>No job Posted Yet</div>}
                        {user?.postedJobs?.length > 0 &&
                          jobs?.map((item) => {
                            return (
                              <div
                                key={item?._id}
                                className="job-block"
                                style={{ width: "100%", margin: "10px auto" }}
                              >
                                <h3
                                  className="job-title job_alert_tips"
                                  style={{ marginLeft: "0", padding: "0" }}
                                >
                                  {item?.jobTitle}
                                </h3>
                                <div className="job-details">
                                  <p>
                                    <b>Posted At: </b>
                                    {formatDate(item?.postedDate)}
                                  </p>
                                  <p>
                                    <b>Salary: </b>
                                    {item?.salary}
                                  </p>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => navigate(`/jobs/${item?._id}`)}
                                >
                                  View Job
                                </button>
                              </div>
                            );
                          })}
                      </>
                    )}
                    <Outlet />
                  </div>

                  <div className="recommend" id="c2">
                    {user?.role === "jobseeker" && (
                      <div className="details">
                        <div className="profile_details_header">
                          <p className="profile_details_heading">
                            <MdOutlinePersonPin />
                            Personal Details
                          </p>
                        </div>
                        <div className="profile_details_main_div">
                          <div className="details_item">
                            <p>First name: </p>
                            <p>{user?.firstName}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Last Name: </p>
                            <p>{user?.lastName}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Country: </p>
                            <p>{user?.country}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Desired Job Title: </p>
                            <p>{user?.jobTitle}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <div className="details_item">
                            <p>Post Code: </p>
                            <p>{user?.postcode}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Phone: </p>
                            <p>{user?.details?.contactNo}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Email Address: </p>
                            <p>{user?.email}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <span className="top_border profile_items_border"></span>
                          <span className="right_border profile_items_border"></span>
                          <span className="bottom_border profile_items_border"></span>
                          <span className="left_border profile_items_border"></span>
                        </div>
                      </div>
                    )}

                    {(user?.role === "employer" || user?.role === "company") && (
                      <div className="details">
                        <div className="profile_details_header">
                          <p className="profile_details_heading">
                            <MdOutlinePersonPin />
                            Personal Details
                          </p>
                        </div>

                        <div className="profile_details_main_div">
                          {user?.role === "employer" && (
                            <>
                              <div className="details_item">
                                <p>Company Name: </p>
                                <p>{user?.companyName}</p>
                              </div>
                              <div className="details_item_row"></div>
                            </>
                          )}
                          <div className="details_item">
                            <p>Name: </p>
                            <p>{user?.name}</p>
                          </div>
                          <div className="details_item_row"></div>
                          {user?.role === "company" && (
                            <>
                              <div className="details_item">
                                <p>Website Url: </p>
                                <p>
                                  <a target="_blank" href={user?.website} rel="noreferrer">
                                    {user?.website}
                                  </a>
                                </p>
                              </div>
                              <div className="details_item_row"></div>
                            </>
                          )}
                          <div className="details_item">
                            <p>Phone: </p>
                            <p>{user?.phone}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Email Address: </p>
                            <p>{user?.email}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <div className="details_item">
                            <p>Address: </p>
                            <p>{user?.address}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Post Code: </p>
                            <p>{user?.postcode}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Town: </p>
                            <p>{user?.town}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Country: </p>
                            <p>{user?.country}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <span className="top_border profile_items_border"></span>
                          <span className="right_border profile_items_border"></span>
                          <span className="bottom_border profile_items_border"></span>
                          <span className="left_border profile_items_border"></span>
                        </div>
                      </div>
                    )}

                    {(user?.role === "employer" || user?.role === "company") && (
                      <div className="details">
                        <div className="profile_details_header">
                          <p className="profile_details_heading">
                            <MdWork /> Buisness Details
                          </p>
                        </div>
                        <div className="profile_details_main_div">
                          <div className="details_item">
                            <p>Buisness Type: </p>
                            <p>{user?.buisnessType}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <div className="details_item">
                            <p>Buisness Size: </p>
                            <p>{user?.buisnessSize}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <span className="top_border profile_items_border"></span>
                          <span className="right_border profile_items_border"></span>
                          <span className="bottom_border profile_items_border"></span>
                          <span className="left_border profile_items_border"></span>
                        </div>
                      </div>
                    )}

                    {user?.role === "jobseeker" && (
                      <div className="details">
                        <div className="profile_details_header">
                          <p className="profile_details_heading">
                            <MdWork /> Job Details
                          </p>
                        </div>
                        <div className="profile_details_main_div">
                          <div className="details_item">
                            <p>Job Types: </p>
                            <p>
                              {user?.details?.jobType?.map((job) => {
                                return `${job}  `;
                              })}
                            </p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Expected Salary: </p>
                            <p>{user?.details?.expectedSalary}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Current/Previous Job: </p>
                            <p>{user?.jobTitle}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Willing to Relocate: </p>
                            <p>{user?.preferences?.relocate ? "Yes" : "No"}</p>
                          </div>
                          <div className="details_item_row"></div>

                          <div className="details_item">
                            <p>Travel: </p>
                            <p>{user?.preferences?.travelDistance}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Notice Period: </p>
                            <p>{user?.preferences?.Availability}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Driving Liscence: </p>
                            <p>{user?.preferences?.drivingLiscence ? "Yes" : "No"}</p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Industries: </p>
                            <p>
                              {user?.preferences?.industries?.map((job) => {
                                return `${job}  `;
                              })}
                            </p>
                          </div>
                          <div className="details_item_row"></div>

                          <span className="top_border profile_items_border"></span>
                          <span className="right_border profile_items_border"></span>
                          <span className="bottom_border profile_items_border"></span>
                          <span className="left_border profile_items_border"></span>
                        </div>
                      </div>
                    )}

                    {user?.role === "jobseeker" && (
                      <div className="details">
                        <div className="profile_details_header">
                          <p className="profile_details_heading">
                            <MdLanguage /> Skills and Languages
                          </p>
                        </div>
                        <div className="profile_details_main_div">
                          <div className="details_item">
                            <p>Skills: </p>
                            <p>
                              {user?.skills?.map((skill) => {
                                return `${skill}  `;
                              })}
                            </p>
                          </div>
                          <div className="details_item_row"></div>
                          <div className="details_item">
                            <p>Languages: </p>
                            <p>
                              {user?.languages?.map((skill) => {
                                return `${skill}  `;
                              })}
                            </p>
                          </div>
                          <div className="details_item_row"></div>

                          <span className="top_border profile_items_border"></span>
                          <span className="right_border profile_items_border"></span>
                          <span className="bottom_border profile_items_border"></span>
                          <span className="left_border profile_items_border"></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container d-flex justify-content-center align-items-center">
                Network Error!!
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Profile;
