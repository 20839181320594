import React, { useEffect, useState } from "react";
import "./contact.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import OverlayLoader from "../../components/loader/OverlayLoader";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { useAuthContext } from "../../context/AuthContext";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const { user } = useAuthContext();
  const [formData, setForm] = useState({
    name: "",
    email: user ? user?.email : "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post("/contact", formData);
      if (res.status === 201) {
        setSuccess(true);
        setSuccessMessage("Query Submitted Successfully!");
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/contact-us" />

        <title>Contact Us | Get in Touch for Career Support and Services</title>

        <meta
          name="description"
          content="Have questions or need assistance? Contact us today for support with our services. We're here to help you with career advice, job searches, & more."
        />
      </Helmet>

      <Navbar3 />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="form-body">
        <div className="row">
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <h3 className="fw-bold">Contact Us</h3>
                <p>Please fill in the form below.</p>
                <form className="requires-validation" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      value={formData.name}
                      onChange={(e) => setForm({ ...formData, name: e.target.value })}
                      required
                    />
                    <div className="valid-feedback">Username field is valid!</div>
                    <div className="invalid-feedback">Username field cannot be blank!</div>
                  </div>

                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="E-mail Address"
                      value={formData.email}
                      onChange={(e) => setForm({ ...formData, email: e.target.value })}
                      required
                    />
                    <div className="valid-feedback">Email field is valid!</div>
                    <div className="invalid-feedback">Email field cannot be blank!</div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <textarea
                      rows={3}
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Write your query here..."
                      value={formData.description}
                      onChange={(e) => setForm({ ...formData, description: e.target.value })}
                      required
                    />
                    <div className="valid-feedback">Email field is valid!</div>
                    <div className="invalid-feedback">Email field cannot be blank!</div>
                  </div>

                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label className="form-check-label">
                      I confirm that all data are correct
                    </label>
                    <div className="invalid-feedback">
                      Please confirm that the entered data are all correct!
                    </div>
                  </div> */}

                  <div className="form-button d-flex justify-content-center">
                    <button id="submit" type="submit" className="btn btn-primary fw-bold">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
