import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../../components/alert/toastAlert";
import { store } from "../../../redux/store";
import * as actions from "../../../redux/actions";
import UpdateUserModal from "../../../components/modals/UpdateUserModal";

const ManagementUsers = () => {
  const { state, fetchAdditionalUsers } = useAdminPanelContext();
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    setPagePosts(state?.managementUsers?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.managementUsers?.length / postsPerPage));
  }, [state?.managementUsers, postsPerPage]);

  const deleteUser = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/adminPanel/additional/user/${id}`);
      setSuccess(true);
      setSuccessMessage("User deleted successfully!");
      await fetchAdditionalUsers();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.managementUsers?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setPagePosts(current);
    }
  };

  const handleUpdateUser = (id) => {
    setCurrentUserId(id);
    store.dispatch(actions.change_update_modal());
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <UpdateUserModal userId={currentUserId} />
      <div className="messages">
        <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
          Management Users
        </h3>
        {pagePosts?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Allowed Permissions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pagePosts?.map((user, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                  <td>{user?.firstName + " " + user?.lastName}</td>
                  <td>{user?.email}</td>
                  <td style={{ textTransform: "capitalize" }}>{user?.role}</td>
                  <td
                    // style={{
                    //   overflowX: "scroll",
                    //   wordWrap: "break-word",
                    //   maxWidth: "100px",
                    // }}
                  >
                    <div>
                      {Object.keys(user?.permissions).map((item) => {
                        return (
                          <p key={item} style={{ textTransform: "capitalize" }}>
                            {user?.permissions[item] ? (
                              <>
                                {item.split("_").join(" ")}:{" "}
                                <b>
                                  {user?.permissions[item] ? "true" : "false"}
                                </b>
                              </>
                            ) : null}
                          </p>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    <button onClick={() => handleUpdateUser(user?._id)}>
                      Update
                    </button>
                    <button onClick={() => deleteUser(user?._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No Data yet to show Here!!</p>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManagementUsers;
