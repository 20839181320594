import React, { useEffect, useRef, useState } from "react";
import CvNavbar from "./cvdatabase_navbar/Cv_navbar";
import Footer from "../components/footer/footer";
import HeaderCvDatabase from "./header_cvdatabase/Header_cvdatabase";
// import cv1 from "./cvdatabase_images/cvdatabase1.png";
// import cv2 from "./cvdatabase_images/cvdatabase2.png";
// import cv3 from "./cvdatabase_images/cvdatabase3.png";
// import cv4 from "./cvdatabase_images/cvdatabase4.png";
// import cv5 from "./cvdatabase_images/cvdatabase5.png";
// import cv6 from "./cvdatabase_images/cvdatabase6.png";
import { ToastSuccess, ToastError } from "../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import axios from "../axios";
import OverlayLoader from "../components/loader/OverlayLoader";

const Enquiry = () => {
  const companyName = useRef();
  const name = useRef();
  const phone = useRef();
  const email = useRef();
  const buisnessType = useRef();
  const enquiry = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      name: name.current.value,
      email: email.current.value,
      companyName: companyName?.current?.value,
      phone: phone.current.value,
      buisnessType: buisnessType.current.value,
      enquiry: enquiry.current.value,
    };
    // console.log(data);
    Object.entries(data).forEach(([key, value]) => {
      if (value === "" || value === "Please Select ") {
        setError(true);
        setErrorMessage("Please fill all the fields to  continue");
      }
      if (error) return;
    });
    try {
      await axios.post("/submit/enquiry", data).then((res) => {
        console.log(res.data);
        setSuccess(true);
        setSuccessMessage(res.data?.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <CvNavbar />
      <HeaderCvDatabase />

      <div className="container-fluid mian_section_cvdatabase pb-4">
        <h1 className="text-center text-white fw-bold p-5 animate__backInLeft ">
          Join Alphy Johnson's Employment Resource Center now!
        </h1>
        <div className="container d-flex justify-content-center gap-4 main_database_cards ">
          <div className="card main_database_cards1 animate__backInLeft hover-effect">
            <div className="card-body">
              <h5 className="card-title fw-bold fs-3">Enquire Now</h5>
              <p className="card-text w-90">
                Just fill out the form provided, and we'll promptly reach out to
                address any inquiries you may have. <br></br>Our team is
                available from Monday to Friday, 08:30 to 17:30. <br></br>Toll
                Free: +1 (855) 580-0685 <br></br>{" "}
                <a href="mailto: info@resumeglobalhub.com">
                  info@resumeglobalhub.com
                </a>{" "}
                |<br></br>
                <a href="mailto: info@cvglobalhub.com.gh">
                  info@cvglobalhub.com.gh
                </a>
                <br></br> WhatsApp: 1+8 (486) 671-288
              </p>
              <div className="register_form">
                <form>
                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Company Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        ref={companyName}
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Name</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        ref={name}
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Phone</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="text"
                        ref={phone}
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Email</label>
                      <br />
                      <input
                        id="keyword"
                        className="text"
                        type="email"
                        ref={email}
                        required
                      ></input>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Buisness Type</label>
                      <br />
                      <select name="" id="" ref={buisnessType} required>
                        <option value="Please Select ">Please Select</option>
                        <option value="employer agency">Employer Agency</option>
                        <option value="direct employer">Direct Employer</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="register_form_div">
                    <div className="register_options">
                      <label>Enquiry</label>
                      <br />
                      <textarea
                        id="keyword"
                        className="text"
                        type="text"
                        ref={enquiry}
                        required
                        maxLength={500}
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div className="text-center mt-4">
                <button
                  className="btn text-white"
                  style={{ background: "var(--btn-color)" }}
                  onClick={handleSubmit}
                >
                  Submit Enquiry
                </button>
                <br></br>
                <small>
                  This form is exclusively for recruiters!<br></br>Candidates
                  kindly proceed to <a href="/register">Register your CV</a>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-flex justify-content-center mt-4">
          <div className="card hover-effect" style={{ width: "48rem" }}>
            <div className="card-body d-flex justify-content-between align-items-center main_database_cards2">
              <div>
                <p className="card-text fs-5 fw-bold">
                  For Hiring Packages, call +1 (855) 580-0685
                </p>
                <p className="card-text fs-6">
                  We'll build a customized hiring solution, tailored to your
                  needs
                </p>
              </div>
              <div>
                <a
                  href="/enquirenow"
                  className="btn text-white fw-bold"
                  style={{ background: "var(--btn-color)" }}
                >
                  Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Enquiry;
