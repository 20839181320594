import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar3 from "../../components/navbar3/navbar3";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

const UpdatePassword = () => {
  const { user } = useAuthContext();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  const { token } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      let route;
      switch (role) {
        case "jobseeker":
          route = `/candidates/reset-password/${token}`;
          break;
        case "employer":
          route = `/employers/reset-password/${token}`;
          break;
        case "company":
          route = `/companies/reset-password/${token}`;
          break;
        case "user":
          route = `/users/reset-password/${token}`;
          break;
        default:
          break;
      }
      const response = await axios.patch(route, {
        password: newPassword,
        confirmPassword,
      });

      if (response) {
        setSuccess(true);
        setSuccessMessage("Password Updated successfully");
        setTimeout(() => {
          setIsLoading(false);
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleUpdatePassword = async () => {
    let route;
    switch (user?.role) {
      case "jobseeker":
        route = "/candidates/update/password";
        break;
      case "employer":
        route = "/employers/update/password";
        break;
      case "company":
        route = "/companies/update/password";
        break;
      default:
        break;
    }
    try {
      setIsLoading(true);
      await axios
        .patch(
          route,
          {
            oldPassword: confirmPassword,
            newPassword: newPassword,
          },
          { withCredentials: true }
        )
        .then((res) => {
          // console.log(res.data?.user);
          setSuccess(true);
          setSuccessMessage(res.data?.message);
          setTimeout(() => {
            setIsLoading(false);
            navigate(-1);
          }, 2000);
        });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const toggleConfirmVisibility = () => {
    setConfirmVisible(!confirmVisible);
  };

  const toggleNewVisibility = () => {
    setNewVisible(!newVisible);
  };

  return (
    <div>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <section>
        <div className="container update_password_container">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-9">
              <h1 className="mb-4">Update Password</h1>

              <div
                className="card"
                style={{
                  backgroundColor: "var(--blue)",
                  color: "#fff",
                  borderRadius: "15px",
                }}
              >
                <div className="card-body">
                  <div className="update_password_div row align-items-center py-3">
                    <div className="col-lg-3 text-center">
                      <h3 className="mb-0">New Password:</h3>
                    </div>
                    <div className="col-lg-9 form-group">
                      <input
                        className="form-control form-control-lg"
                        type={newVisible ? "text" : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <span
                        key="2"
                        className="view-password"
                        onClick={toggleNewVisibility}
                      >
                        {newVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </div>

                  <hr className="mx-n3" />

                  <div className="update_password_div row align-items-center py-3">
                    <div className="col-lg-3 text-center">
                      <h3 className="mb-0">
                        {user ? "Old Password:" : "Confirm Password:"}
                      </h3>
                    </div>
                    <div className="col-lg-9 form-group">
                      <input
                        className="form-control form-control-lg"
                        type={confirmVisible ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span
                        key="2"
                        className="view-password"
                        onClick={toggleConfirmVisibility}
                      >
                        {confirmVisible ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </div>

                  <hr className="mx-n3" />

                  <div className="d-flex justify-content-center align-items-center py-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg"
                      onClick={
                        user ? handleUpdatePassword : handleResetPassword
                      }
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UpdatePassword;
