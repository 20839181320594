import React, { useRef, useState } from "react";
import Navbar3 from "../../components/navbar3/navbar3";
import { useNavigate, Link } from "react-router-dom";
import "./courses.css";
import "animate.css";
import { motion } from "framer-motion";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaRegWindowClose } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { IMAGES } from "../../constants/GlobalConstants";
import LazyImage from "../../components/common/LazyImage";

function Courses() {
  const { user } = useAuthContext();
  const animatedHeadingRef = useRef(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isColapsed, setIsColapsed] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const element = animatedHeadingRef.current;
  //     const elementPosition = element.getBoundingClientRect().top;
  //     const windowHeight = window.innerHeight;

  //     if (elementPosition < windowHeight * 0.8) {
  //       element.classList.add("animate__backInLeft");
  //     } else {
  //       element.classList.remove("animate__backInLeft");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleClick = (category) => {
    const queryParams = {};
    if (typeof category === "string") {
      queryParams.category = category;
    }
    if (searchQuery) {
      queryParams.title = searchQuery;
    }
    navigate(`/allcourses?${new URLSearchParams(queryParams).toString()}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Counselling Courses | Professional Job Training In USA</title>

        <link rel="canonical" href="https://thealphyjohnson.com/career-counselling-courses" />
        <meta
          name="description"
          content="Join career counselling courses for personal growth and job training. Learn new skills with our online programs designed for the USA workforce. Enroll today!"
        />
      </Helmet>
      <Navbar3 />
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid courses_top_bar">
          <button
            className="navbar-toggler toggle_btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setIsColapsed(!isColapsed)}
          >
            <span className="navbar-toggler-icon">
              {isColapsed ? (
                <FaRegWindowClose size={35} color="var(--btn-color)" />
              ) : (
                <MdArrowDropDownCircle size={35} color="var(--btn-color)" />
              )}
            </span>
          </button>
          <Link className="navbar-brand courses_title" href="/courses">
            <h2
              style={{ fontWeight: "bold", marginBottom: "0" }}
              className="header-heading job_alert_tips"
            >
              Enhanced Skills Institute
            </h2>
          </Link>
          <div className="collapse navbar-collapse position-relative" id="navbarTogglerDemo03">
            <ul className="navbar-nav me-auto mb-2  mb-lg-0 end-0 pe-5 courses-drowp-down ">
              {user && user?.role === "user" && (
                <>
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      style={{ fontSize: "14px", color: "#000" }}
                      to={`/allcourses?category=${encodeURIComponent("Staff Training")}`}
                    >
                      Staff Training
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link  "
                      style={{ fontSize: "14px", color: "#000" }}
                      aria-current="page"
                      to="/dashboard"
                    >
                      Courses Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      style={{ fontSize: "14px", color: "#000" }}
                      to="/dashboard/active-courses"
                    >
                      My Courses
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      style={{ fontSize: "14px", color: "#000" }}
                      to="/courses/create"
                    >
                      Add Course
                    </Link>
                  </li>
                </>
              )}
              {/* {showLoginPopup && <CourseSignin />} */}
              {(!user || !(user?.role === "user")) && (
                <>
                  <Link to="/courses/register">
                    <button
                      className="btn btn-primary"
                      style={{ borderRadius: "30px", color: "#fff" }}
                    >
                      Create Account
                    </button>
                  </Link>
                  <Link to="/courses/login">
                    <button
                      className="btn btn-primary"
                      style={{ borderRadius: "30px", color: "#fff" }}
                    >
                      Sign In
                    </button>
                  </Link>
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      style={{ fontSize: "14px", color: "#000" }}
                      to={`/allcourses?category=${encodeURIComponent("Staff Training")}`}
                    >
                      Staff Training
                    </Link>
                  </li>
                </>
              )}
              <div
                className="d-flex justify-content-center align-items-center ms-3"
                style={{
                  border: "3px solid var(--btn-color)",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                }}
              >
                <Link to="/allcourses">
                  <i className="bx bx-search-alt-2 fs-4" style={{ color: "black" }}></i>
                </Link>
              </div>
              {user && user?.role === "user" && (
                <div
                  className="d-flex justify-content-center align-items-center ms-3"
                  style={{
                    border: "3px solid var(--btn-color)",
                    width: "3rem",
                    height: "3rem",
                    borderRadius: "50%",
                  }}
                >
                  <Link to="/dashboard/myorders">
                    <i className="bx bxs-cart-alt fs-4" style={{ color: "black" }}></i>
                  </Link>
                </div>
              )}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container-fluid courses-header pb-5">
        <div ref={animatedHeadingRef} className="container animate__backInLeft">
          <div className="row">
            <div className="courses_header_left">
              <h1
                ref={animatedHeadingRef}
                className="text-center text-white fs-1 fw-bolder pt-5 animate__backInLeft"
              >
                Advance Your Career with Our Career Counselling Courses
              </h1>
              <p className="text-center text-white fs-4 fw-bolder m-0 p-0 animate__backInLeft">
                Our career counselling courses are designed to help you build the skills you need to
                move forward in your professional life. Whether you’re looking to grow personally or
                advance in your career, these courses offer practical knowledge that can help you
                reach your goals. Join a community of learners and professionals, and take the next
                step toward new job opportunities.
              </p>
              <div className="input-group mb-3 course_search_input">
                <input
                  type="text"
                  className="form-control w-75"
                  placeholder="Find Your perfect course"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  style={{ height: "4rem" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <span
                  className="input-group-text"
                  style={{ backgroundColor: "var(--btn-color)" }}
                  id="basic-addon2"
                  onClick={handleClick}
                >
                  <i className="bx bx-search fs-1 text-white"></i>
                </span>
              </div>
              <div className="d-flex flex-wrap justify-content-center align-items-center gap-2 pb-5">
                <Link to={`/allcourses?title=${searchQuery}&category=`}>
                  <button
                    className="btn btn-outline-light fs-4"
                    style={{ border: "3px solid white", borderRadius: "5rem" }}
                  >
                    See All Courses
                  </button>
                </Link>
                <Link to={`/allcourses?category=${encodeURIComponent("Staff Training")}`}>
                  <button
                    className="btn btn-outline-light fs-4 "
                    style={{
                      border: "3px solid white",
                      borderRadius: "5rem",
                    }}
                  >
                    Staff Training
                  </button>
                </Link>
                <Link to={"/contact-us"}>
                  <button
                    className="btn btn-primary btn-outline-light fs-4 "
                    style={{
                      border: "3px solid white",
                      borderRadius: "5rem",
                    }}
                  >
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="conatiner-fluid">
        <div className="container courses-section1">
          <div className="container d-flex justify-content-around pt-3 pb-3 courses-rate">
            <div>
              <p className="text-white fw-bolder fs-4 text-center">Enhanced Skills</p>
              <span className="d-flex justify-content-center align-items-center">
                <i
                  className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                  style={{ background: "black" }}
                ></i>
                <i
                  className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                  style={{ background: "black" }}
                ></i>
                <i
                  className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                  style={{ background: "black" }}
                ></i>
                <i
                  className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                  style={{ background: "black" }}
                ></i>
                <i
                  className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                  style={{ background: "black" }}
                ></i>
              </span>{" "}
            </div>

            <div className="given-rate-courses">
              <div>
                <p className="text-white fw-bolder fs-4 text-center">Improved Efficiency</p>
                <span className="d-flex justify-content-center align-items-center">
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                </span>{" "}
              </div>
            </div>

            <div className="given-rate-courses">
              <div>
                <p className="text-white fw-bolder fs-4 text-center">Motivated Workforce</p>
                <span className="d-flex justify-content-center align-items-center">
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                </span>{" "}
              </div>
            </div>

            <div className="given-rate-courses">
              <div>
                <p className="text-white fw-bolder fs-4 text-center">Team Collaboration</p>
                <span className="d-flex justify-content-center align-items-center">
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                  <i
                    className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                    style={{ background: "black" }}
                  ></i>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5"
        style={{
          backgroundColor: "var(--blue)",
          color: "#fff",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        <h2 className="text-center fs-2">Professional Development Courses</h2>
        <p className="text-center home_content_main_para">
          We offer a broad selection of online courses that are designed to help professionals grow
          their skills in meaningful ways. Whether you're looking to improve your expertise in
          business, technology, or personal development, we have courses to suit various career
          goals. These courses are built around practical knowledge, ensuring that what you learn
          can be applied immediately in your job or business. Whether you want to progress in your
          current position or explore a new field entirely, our flexible training options are
          tailored to your schedule, so you can learn at your own pace. Whatever industry you’re in,
          you’ll find a course that helps you move forward and succeed in your career.
        </p>
      </div>

      <section id="popularindustries">
        <div className="animate__backInLeft">
          <div className="container">
            <h2 className="industries-head text-center fw-bold">COURSE CATEGORIES</h2>
          </div>
          <div className="popular-industriesrow">
            <div className="col-4-industries">
              <LazyImage src={IMAGES.img1} alt="Business" loading="lazy" />
              <h3 className="pic-head">
                <a
                  href={`/allcourses?category=${encodeURIComponent(
                    "Business and Entrepreneurship"
                  )}`}
                >
                  Business and Entrepreneurship
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img2} alt="Technology" loading="lazy" />
              <h3 className="pic-head">
                <a
                  href={`/allcourses?category=${encodeURIComponent("Technology and Programming")}`}
                >
                  Technology and Programming
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img3} alt="Arts and Creativity" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Arts and Creativity")}`}>
                  Arts and Creativity
                </a>
              </h3>
            </div>
          </div>
          <div className="popular-industriesrow">
            <div className="col-4-industries">
              <LazyImage src={IMAGES.img4} alt="Language Learning" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Language Learning")}`}>
                  Language Learning
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img5} alt="Staff Training" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Staff Training")}`}>
                  Staff Training
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img6} alt="Health and Wellness" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Health and Wellness")}`}>
                  Health and Wellness
                </a>
              </h3>
            </div>
          </div>
          <div className="popular-industriesrow">
            <div className="col-4-industries">
              <LazyImage src={IMAGES.img7} alt="Science and Engineering" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Science and Engineering")}`}>
                  Science and Engineering
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img8} alt="Social Sciences" loading="lazy" />
              <h3 className="pic-head">
                <a
                  href={`/allcourses?category=${encodeURIComponent(
                    "Social Sciences and Humanities"
                  )}`}
                >
                  Social Sciences and Humanities
                </a>
              </h3>
            </div>

            <div className="col-4-industries">
              <LazyImage src={IMAGES.img9} alt="Beauty Tips" loading="lazy" />
              <h3 className="pic-head">
                <a href={`/allcourses?category=${encodeURIComponent("Beauty Tips")}`}>
                  Beauty Tips
                </a>
              </h3>
            </div>
          </div>

          <div className="popular-industriesrow">
            <Link to={`/allcourses?title=${searchQuery}&category=`}>
              <button className="btn btn-primary">View All</button>
            </Link>
          </div>
        </div>
      </section>

      <div className="container mt-5 mb-5">
        <h2 className="text-center fw-bolder">Why Choose New Skills Academy for Training?</h2>
        <div
          className="row mt-5 animate__backInLeft justify-content-center align-items-center"
          ref={animatedHeadingRef}
        >
          <motion.div
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center icons-of-courses"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <LazyImage
              src={IMAGES.sh1}
              className="LazyImage-fluid course_badges_img"
              alt="Skill Development"
              loading="lazy"
            />

            <h3 className="fs-6 fw-bolder pt-2">Skill Development</h3>
            <p className="fs-6 text-center">
              We offer courses that help your team build the skills they need to thrive in today’s
              fast-paced job market. Whether it's mastering new software or improving leadership
              abilities, our training ensures your staff feels confident and ready to excel in their
              roles.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center icons-of-courses"
          >
            <LazyImage
              src={IMAGES.sh2}
              className="LazyImage-fluid course_badges_img"
              alt="Staying Current"
              loading="lazy"
            />

            <h3 className="fs-6 fw-bolder pt-2">Staying Current</h3>
            <p className="fs-6 text-center">
              The business world changes quickly, and it's important to keep up. Our programs are
              designed to keep your employees updated on the latest tools, methods, and industry
              trends, helping them face new challenges with confidence and the knowledge they need
              to stay competitive.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center icons-of-courses"
          >
            <LazyImage
              src={IMAGES.sh3}
              className="LazyImage-fluid course_badges_img"
              alt="Improved Work Efficiency"
              loading="lazy"
            />

            <h3 className="fs-6 fw-bolder pt-2">Improved Work Efficiency</h3>
            <p className="fs-6 text-center">
              Learning isn’t just about gaining knowledge—it's about using it effectively. Our
              training focuses on practical applications, helping your team become more efficient
              and reducing the number of mistakes, leading to smoother workflows and higher
              productivity.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center icons-of-courses"
          >
            <LazyImage
              src={IMAGES.sh4}
              className="LazyImage-fluid course_badges_img"
              alt="Boosted Employee Engagement"
              loading="lazy"
            />

            <h3 className="fs-6 fw-bolder pt-2">Boosted Employee Engagement</h3>
            <p className="fs-6 text-center">
              When employees receive the training they need, they feel valued and supported. This
              not only boosts their confidence but also keeps them motivated and engaged in their
              work. In turn, a motivated team performs better and contributes positively to the work
              environment.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center icons-of-courses"
          >
            <LazyImage
              src={IMAGES.sh5}
              className="LazyImage-fluid course_badges_img"
              alt="Adaptability"
              loading="lazy"
            />

            <h3 className="fs-6 fw-bolder pt-2">Adaptability and Team Collaboration</h3>
            <p className="fs-6 text-center">
              Our courses are designed to foster both individual and team growth. We help your staff
              improve their ability to work together, build resilience in the face of challenges,
              and collaborate effectively, ensuring they contribute to your company’s success in
              meaningful ways
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Courses;
