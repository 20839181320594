import React, { useEffect, useState } from "react";
import "./searchCV.css";
import Payplan from "../../components/payPlan/payplan";
import Button from "../../components/button/button";
import Cvdatabase from "../../components/cvDatabase/cvdatabase";
import axios from "../../axios";
import whyCv from "../../images/whyCv.svg";
import CvNavbar from "../../cvdatabase/cvdatabase_navbar/Cv_navbar";
import HeaderCvdatabase from "../../cvdatabase/header_cvdatabase/Header_cvdatabase";
import { useAuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { IMAGES, Industries } from "../../constants/GlobalConstants";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { Helmet } from "react-helmet";

const SearchCV = () => {
  const [searchTools, setSearchTools] = useState(true);
  const [watchdogs, setWatchdogs] = useState(false);
  const [interview, setInterview] = useState(false);
  const [industry, setIndustry] = useState("");
  const [location, setLocation] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const navigate = useNavigate();
  const [plan1, setPlan1] = useState({});
  const [plan2, setPlan2] = useState({});
  const { user } = useAuthContext();
  const [results, setResults] = useState([]);
  const [locationOptions, setNames] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const industryOptions = Industries;

  useEffect(() => {
    setError(false);
  }, [error]);

  useEffect(() => {
    // if (user && user?.checkout?.packageName !== "search cvs") {
    //   setError(true);
    //   setErrorMessage("Checkout for search cvs to continue");
    //   setTimeout(() => {
    //     navigate(-1);
    //   }, 3000);
    // }
    try {
      setIsFetching(true);
      const fetchPlans = async () => {
        await axios.get("/adminPanel/plans/registration").then((res) => {
          const plansArr = res?.data?.docs;
          const plans = plansArr?.filter((plan) => plan?.category === "search cvs");
          setPlan1(plans[0]);
          setPlan2(plans[1]);
        });
        setTimeout(() => {
          setIsFetching(false);
        }, 1500);
      };
      const fetchCountries = async () => {
        await axios.get("https://restcountries.com/v3.1/all").then((res) => {
          const array = res.data;
          const namesArray = [];
          array.forEach((country) => {
            namesArray.push(country?.name?.common);
          });
          namesArray.sort();
          setNames(namesArray);
        });
      };

      fetchCountries();
      fetchPlans();
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, []);

  const SearchToolsTrue = () => {
    setSearchTools(true);
    setWatchdogs(false);
    setInterview(false);
  };
  const WatchdogsTrue = () => {
    setSearchTools(false);
    setWatchdogs(true);
    setInterview(false);
  };
  const InterviewTrue = () => {
    setSearchTools(false);
    setWatchdogs(false);
    setInterview(true);
  };

  const createCheckout = (id) => {
    window.location.href = `/checkout/${id}`;
  };

  const handleSearch = async () => {
    if (
      (user?.role === "employer" || user?.role === "company") &&
      user?.checkout?.packageName != "search cvs"
    ) {
      setError(true);
      setErrorMessage("Please checkout for search cvs to continue");
      return;
    }
    setIsLoading(true);
    let route;
    const role = user?.role;
    const query = {};
    if (jobTitle) {
      query.jobTitle = jobTitle;
    }
    if (industry) {
      query.industry = industry;
    }
    if (location) {
      query.location = location;
    }
    const queryString = new URLSearchParams(query).toString();
    switch (role) {
      case "employer":
        route = `/cv/employer/search-cvs?${queryString}`;
        break;
      case "company":
        route = `/cv/company/search-cvs?${queryString}`;
        break;
      default:
        break;
    }
    try {
      await axios.get(route).then((res) => {
        setResults(res.data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/searchcv" />

        <title>Register & Search CVs Today | Find Perfect Candidate For You</title>

        <meta
          name="description"
          content=" Utilize our search CV tool to find ideal candidates for your job openings. Browse through a diverse range of CVs connect with top talent to enhance your team."
        />
      </Helmet>

      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="search_cv-maindiv">
        <CvNavbar />
        <HeaderCvdatabase />
        {!user && (
          <div className="searchcv_payplan section-padding">
            <p className="recruiter">Recruiter Home -- Search CV's</p>
            <div className="payplan-subdiv1">
              <div className="payplan1">
                <h1 className="animate__backInLeft" style={{ textTransform: "uppercase" }}>
                  Discover the Perfect Match for Your Vacancy
                </h1>
                <p className="animate__backInLeft">
                  Access the Africa’s Preferred Resume or CV Database for Unlimited Resume or CV
                  Views When searching for the ideal candidate to fill your vacancy, our platform
                  offers access to the global favorite Resume or CV Database. With unlimited Resume
                  or CV views, you can efficiently explore a vast pool of qualified professionals to
                  find the perfect match for your organization.
                </p>
              </div>
              {isFetching ? (
                <div className="payplan2">
                  <div className="container d-flex justify-content-center align-items-center">
                    <Loader />
                  </div>
                </div>
              ) : (
                <div className="payplan2">
                  <Payplan
                    week={plan1?.searchCvs?.weeksAllowed}
                    curPayment={plan1?.price}
                    prevPayment={parseInt(plan1?.price) + parseInt(plan1?.discount)}
                    downloads={plan1?.searchCvs?.download_cvs}
                    onclick={() => createCheckout(plan1?._id)}
                  />
                  <Payplan
                    week={plan2?.searchCvs?.weeksAllowed}
                    curPayment={plan2?.price}
                    prevPayment={parseInt(plan2?.price) + parseInt(plan2?.discount)}
                    downloads={plan2?.searchCvs?.download_cvs}
                    onclick={() => createCheckout(plan2?._id)}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="searchcv_candidates section-padding">
          <h2>Find candidates from every sector all over the world</h2>
          <div className="indus-location">
            <div className="industry_div">
              <p>Enter Job Title</p>
              <form>
                <input
                  className="industry_input"
                  type="text"
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                ></input>
              </form>
            </div>
            <div className="industry_div">
              <p>choose industry</p>
              <form>
                <select
                  name="industry"
                  id="location"
                  className="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target?.value)}
                >
                  <option value="">Please Select</option>
                  {industryOptions.map((ind, index) => {
                    return (
                      <option key={index} value={ind}>
                        {ind}
                      </option>
                    );
                  })}
                </select>
              </form>
            </div>
            <div className="location_div">
              <p>select country</p>
              <form>
                <select
                  name="industry"
                  id="location"
                  className="location"
                  value={location}
                  onChange={(e) => setLocation(e.target?.value)}
                >
                  <option value="">Please Select</option>
                  {locationOptions.map((name) => {
                    return (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </form>
            </div>
            <div className="search_btn">
              <button onClick={handleSearch}>Search</button>
            </div>
          </div>
          {results.length > 0 && (
            <p className="no_of_candidates">
              <span className="number">{results.length}</span> <span></span> candidates in{" "}
              <span>{jobTitle}</span> <span>{industry}</span> <span>{location}</span>
            </p>
          )}
        </div>

        {results?.length > 0 && (
          <section id="search_cv_results">
            <div className="browseIndustryDivFlex" style={{ marginBottom: "30px" }}>
              <div>
                <h3
                  className="BrowseIndustryjob-head text-center"
                  style={{
                    marginBottom: "30px",
                    fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
                    fontSize: "24px",
                  }}
                >
                  RESULTS
                </h3>
              </div>
              <div className="BrowseIndustryjob-row">
                {results.slice(0, 9).map((item, index) => {
                  return (
                    <div key={index} className="col-5-BrowseIndustryjob">
                      <img
                        src={item?.candidate?.profilePhoto}
                        alt={item?.candidate?.profilePhoto}
                        className="col-5-BrowseIndustryjobimg"
                        loading="lazy"
                      />
                      <Link to={`/view-profile/${item?.candidate?._id}?role=jobseeker`}>
                        <h3 style={{ marginBottom: "10px" }}>
                          {item?.candidate?.firstName + " " + item?.candidate?.lastName}
                        </h3>
                      </Link>
                      <p>{item?.candidate?.jobTitle}</p>
                      <a href={`/viewcv/${item?.cv?._id}`}> View CV </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}

        <h2 className="industries-head text-center fw-bold pt-4 animate__backInLeft ">
          Here's how our Resume or CV search service can benefit your recruitment process:
        </h2>
        <div className="container-fluid main_cv_database_content_div">
          <div className="d-flex gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Extensive Resume or CV Database: </h2>
              <p>
                Our platform hosts a comprehensive Resume or CV Database filled with a wide range of
                talented professionals across various industries and sectors. This database is
                continually updated, ensuring that you have access to the most relevant and
                up-to-date candidate profiles. Whether you're looking for entry-level candidates or
                experienced professionals, our database has the diversity to meet your recruitment
                needs.
              </p>
            </div>
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Advanced Search Filters:</h2>
              <p>
                Our platform provides advanced search filters that enable you to refine your search
                based on specific criteria. You can tailor your search based on skills, experience,
                qualifications, location, and more, allowing you to pinpoint candidates who closely
                match your requirements. This targeted approach saves you time and effort by
                focusing on the most suitable candidates.
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Resume</h2>
              <p>
                This means you can review candidate profiles without any restrictions, ensuring that
                you thoroughly assess each candidate's qualifications and suitability for your
                vacancy. You can take your time to evaluate multiple Resume or CVs and make informed
                decisions based on the candidates' profiles.
              </p>
            </div>
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Efficient Recruitment Process:</h2>
              <p>
                Our platform streamlines your recruitment process by providing easy access to
                relevant Resume or CVs. You can quickly navigate through candidate profiles,
                evaluate their skills and experience, and shortlist the most promising candidates.
                This efficient process saves you time and allows you to move swiftly in selecting
                and engaging with potential candidates.
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Tailored Match for Your Vacancy:</h2>
              <p>
                Our Resume or CV search service allows you to find candidates who are the best fit
                for your specific vacancy. By using our advanced search filters and reviewing
                candidate profiles, you can identify individuals whose skills, qualifications, and
                experience align with your requirements. This tailored approach increases the
                chances of finding candidates who are a perfect match for your organization.
              </p>
            </div>
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Efficiently Find Top Candidates:</h2>
              <p>
                Access Millions of Fresh Resume or CVs for Your Next Hire When it comes to making
                your next hire, our platform offers you the advantage of accessing a vast database
                of millions of fresh Resume or CVs. Searching our Resume or CV Database provides a
                cost-effective solution for finding the best candidates for your job openings.
              </p>
            </div>
          </div>

          <div className="container-fluid my-cv cv_database_below_div">
            <p>
              Access our preferred Resume or CV Database today and unlock a world of talented
              professionals. Benefit from unlimited Resume or CV views, advanced search filters, and
              an efficient recruitment process. Find the ideal candidate to fill your vacancy and
              drive your organization's success.
            </p>
          </div>
        </div>

        <div className="search_Cv_Database section-padding">
          <h1>How to search our CV Database</h1>
          <div className="search_Cv_Database_maindiv">
            <div className="search_Cv_Database_maindiv_subdiv">
              <p onClick={SearchToolsTrue}>Search tools</p>
              <p onClick={WatchdogsTrue}>Watchdogs</p>
              <p onClick={InterviewTrue}>Interview</p>
            </div>
            <hr />
            <div
              className="main_cv_database_content_div"
              style={{ width: "95%", padding: "1rem 0" }}
            >
              {searchTools ? (
                <Cvdatabase
                  heading={"Simple searching tools"}
                  para={
                    "Effortlessly locate CV matches within seconds using our user-friendly and widely acclaimed Search Designer tool."
                  }
                />
              ) : watchdogs ? (
                <Cvdatabase
                  heading={"Beat the competition with CV alerts"}
                  para={
                    "Stay ahead of the competition by utilizing CV alerts. Receive notifications directly in your inbox as soon as relevant candidates register, ensuring you don't miss out on valuable talent."
                  }
                />
              ) : interview ? (
                <Cvdatabase
                  heading={"Shortlist and interview"}
                  para={
                    "Efficiently manage your candidate selection process by shortlisting top applicants and conducting video interviews through . Contact and schedule interviews with the most promising candidates seamlessly."
                  }
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="search_Cv_Database_mobile section-padding">
          <div className="main_cv_database_content_div">
            <Cvdatabase
              heading={"Search Tools"}
              para={
                "Effortlessly locate CV matches within seconds using our user-friendly and widely acclaimed Search Designer tool."
              }
            />
            <Cvdatabase
              heading={"Watchdogs"}
              para={
                "Stay ahead of the competition by utilizing CV alerts. Receive notifications directly in your inbox as soon as relevant candidates register, ensuring you don't miss out on valuable talent."
              }
            />
            <Cvdatabase
              heading={"Screening"}
              para={
                "Efficiently manage your candidate selection process by shortlisting top applicants and conducting video interviews through . Contact and schedule interviews with the most promising candidates seamlessly."
              }
            />
          </div>
        </div>

        <div className="whycv_div">
          <h1 style={{ textTransform: "uppercase" }}>
            Why choose Alphy Johnson's Employment Center?
          </h1>
        </div>
        <div className="section-padding">
          <div className="whycv_div_flex ">
            <div className="whyvc_img_div">
              <img src={whyCv} alt="best customer support" loading="lazy" />
            </div>
            <div className="whycv_div1">
              <h2>The best customer support</h2>
              <p>
                Top-rated on Employment Center, your dedicated Client Response Coordinator is here
                to help <br />
                <br />
                "Excellent customer service and great team. Always helping us and great value for
                money. Highly recommend"
                <br />
                <br />- Alphy Johnson
              </p>
            </div>
          </div>
          <hr />
          <div className="whycv_div_flex whycv_div2 ">
            <div className="whyvc_img_div">
              <img src={whyCv} alt="Cost-effective solutions" loading="lazy" />
            </div>
            <div className="whycv_div1">
              <h2>Cost-effective solutions</h2>
              <p>
                Whether you need to hire two people or 2,000, we have a CV Search package to suit
                you
              </p>
            </div>
          </div>
          <hr />

          <div className="whycv_div_flex ">
            <div className="whyvc_img_div">
              <img src={whyCv} alt="A trusted brand" loading="lazy" />
            </div>

            <div className="whycv_div1">
              <h2>A trusted brand</h2>
              <p>We connect thousands of employers with millions of candidates every day</p>
              {/* <Button text={"client testimonials"} /> */}
            </div>
          </div>
        </div>
        <div className="searchcv_advertise section-padding mb-2">
          <div className="advertise-img">
            <img src={IMAGES.advertise} alt="Advertise jobs" loading="lazy" />
          </div>
          <div className="searchcv_advertise_subdiv2">
            <div>
              <h1>Advertise jobs</h1>
              <p>Post a job from just </p>
            </div>
            <div>
              <p>
                Was $<span className="prev_prce">149</span>
                <br />
                <span className="cur_price">$90+tax</span>
              </p>
            </div>
            <div>
              <Button onclick={() => navigate("/job-advertisement")} text={"advertise now"} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchCV;
