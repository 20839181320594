import React, { useEffect, useRef, useState } from "react";
import "./register.css";
import Button from "../../components/button/button";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import Footer from "../../components/footer/footer";
import { ToastError, ToastSuccess } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Navbar3 from "../../components/navbar3/navbar3";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Register3 = () => {
  const availability = useRef();
  const travelDistance = useRef();
  const relocate = useRef();
  const drivingLiscence = useRef();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      preferences: {
        availability: availability.current.value,
        travelDistance: travelDistance.current.value,
        relocate: relocate.current.value,
        drivingLicense: drivingLiscence.current.value,
      },
    };
    try {
      await axios.patch(`/candidates/register/3/${id}`, formData);
      setSuccess(true);
      setSuccessMessage("Registration Completed");
      setTimeout(() => {
        setIsLoading(false);
        window.location.href = "/profile";
      }, 1000);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Last step! Add your work preferences</h2>
        <p>
          You are 6 times more likely to be found by employers with all the
          details completed.
        </p>
        <div className="register_form">
          <h2>Work preferences</h2>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="register_form_div">
              <div className="register_options">
                <label>Availability / Notice Period</label>
                <br />
                <select name="" id="" ref={availability} required>
                  <option value="Please Select ">Please Select</option>
                  <option value="immediately ">Immediately</option>
                  <option value="1 week ">1 week</option>
                  <option value="2 weeks ">2 weeks</option>
                  <option value="3 weeks ">3 weeks</option>
                  <option value="1 month ">1 month</option>
                  <option value="2 months ">2 months</option>
                  <option value="3 months+ ">3 months+</option>
                </select>
              </div>
              <div className="register_options">
                <label>Willing to Travel...</label>
                <br />
                <select name="" id="" ref={travelDistance} required>
                  <option value="up to 10 miles">up to 10 miles</option>
                  <option value="up to 20 miles">up to 20 miles</option>
                  <option value="up to 35 miles">up to 35 miles</option>
                  <option value="up to 50 miles">up to 50 miles</option>
                  <option value="up to 100 miles">up to 100 miles</option>
                  <option value="up to 200 miles">up to 200 miles</option>
                  <option value="anywhere ">anywhere</option>
                </select>
              </div>
              <div className="register_options">
                <label>Willing to Relocate?</label>
                <br />
                <select name="" id="" ref={relocate} required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="register_options">
                <label>Driving Licence?</label>
                <br />
                <select name="" id="" ref={drivingLiscence} required>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <Button type="submit" text={"Complete Profile"} />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register3;
