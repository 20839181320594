import React, { useState } from "react";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Logout = () => {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      localStorage.clear();
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await axios.post("/logout").then((res) => {
        setSuccess(true);
        setSuccessMessage(res.data?.message);
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/";
        }, 1000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage("Error processing request!!");
      }
      setIsLoading(false);
      return;
    }
  };

  const updatePassword = () => {
    window.location.href = `/reset-password/${user?._id}`;
  };

  return (
    <div className="logout">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      {isLoading && <OverlayLoader />}
      <button className="btn_logout" onClick={updatePassword}>
        Update Password
      </button>
      <button onClick={handleLogout} className="btn_logout">
        Logout
      </button>
    </div>
  );
};

export default Logout;
