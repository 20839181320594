import React, { useEffect, useState } from "react";
import "./advertiseJobs.css";
import Jobs from "../../components/jobs/jobs";
import Button from "../../components/button/button";
import Perks from "../../components/perks/perks";
import Posts from "../../components/posts/posts";
import jobposting from "../../images/jobposting.svg";
import features from "../../images/features.svg";
import CvNavbar from "../../cvdatabase/cvdatabase_navbar/Cv_navbar";
import HeaderCvdatabase from "../../cvdatabase/header_cvdatabase/Header_cvdatabase";
import { CSSTransition } from "react-transition-group";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";

const Advertisejob = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [plan1, setPlan1] = useState({});
  const [plan2, setPlan2] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    try {
      setIsFetching(true);
      const fetchPlans = async () => {
        await axios.get("/adminPanel/plans/registration").then((res) => {
          const plansArr = res?.data?.docs;
          const plans = plansArr?.filter((plan) => plan?.category === "advertise jobs");
          setPlan1(plans[0]);
          setPlan2(plans[1]);
        });
        setTimeout(() => {
          setIsFetching(false);
        }, 1500);
      };

      fetchPlans();
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error.response);
    }
  }, []);

  const createCheckout = (id) => {
    window.location.href = `/checkout/${id}`;
  };

  const createJob = () => {
    window.location.href = `/createjob`;
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/job-advertisement" />

        <title>Job Advertisement | Promote Your Vacancies Effectively</title>

        <meta
          name="description"
          content="Advertise your job openings effectively with our job advertisement services. Reach the right candidates and fill your vacancies faster with targeted job ads."
        />
      </Helmet>

      <div>
        <CvNavbar />
        <HeaderCvdatabase />

        {(!user || user?.role === "user") && (
          <div className="advertise_jobs section-padding">
            <p className="p">Recruiter Home -- Advertise Jobs</p>
            <h2 className="header-heading job_alert_tips fw-bold text-center">
              Promote Your Job Openings
            </h2>
            <CSSTransition in={true} appear={true} timeout={1000} classNames="fade">
              <h1 className="animate__backInRight" style={{ textTransform: "uppercase" }}>
                Reach Unlimited Candidates by Advertising with Us!
              </h1>
            </CSSTransition>

            <h3 className="animate__backInRight">
              When you advertise your job openings with us, you gain access to a vast pool of
              talent, reaching unlimited potential candidates. With our platform, you can easily
              post your job advertisement, and within 28 days, receive applications directly to your
              inbox.
            </h3>
            {isFetching ? (
              <div className="job_selection">
                <div className="container d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              </div>
            ) : (
              <div className="job_selection">
                <Jobs
                  jobs={plan2?.name}
                  noOfDays={plan2?.advertiseJobs?.daysAllowed}
                  description={"Only $64.50 per job Use over 3 months"}
                  price={plan2?.price}
                  saving={plan2?.discount}
                  onclick={() => createCheckout(plan2?._id)}
                  additionalInfo={"This promotion Applies to new customers only.  T&Cs applies."}
                />
                <Jobs
                  className="job2"
                  jobs={plan1?.name}
                  noOfDays={plan1?.advertiseJobs?.daysAllowed}
                  descrip2={"Applications straight to your inbox "}
                  description={"Your job sent in daily alerts Free Company Profile"}
                  price={plan1?.price}
                  saving={plan1?.discount}
                  additionalInfo={"This promotion Applies to new customers only.  T&Cs applies."}
                  onclick={() => createCheckout(plan1?._id)}
                />
                <div className="jobs_maindiv animate__backInLeft">
                  <h1>5 Jobs+</h1>
                  <p className="jobs_descrip">
                    Take advantage of our limited time offer with highly discounted rates on our
                    larger job packages. Whether you have multiple positions to fill or want to
                    maximize your recruitment efforts.
                  </p>
                  <p>Call us now at</p>
                  <h2 className="jobs_number">+1 (855) 580-0685</h2>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="advertise_discounts">
          <hr />
          <div className="discounts_descrip section-padding">
            <p>
              <span className="discount1">
                For discounts on larger job packages, or for{" "}
                <span className="disc2">Pay for Performance</span> options, please contact our
                friendly sales team
              </span>
              <br />
              <br />
              <span className="disc2">Call Now </span>at +1 (855) 580-0685 - we'll be happy to help!
            </p>
            <Button onclick={() => navigate("/enquirenow")} text={"enquire now"} />
          </div>
        </div>

        <h2 className="industries-head text-center fw-bold pt-4 animate__backInLeft ">
          Here's how our job advertising service can benefit your recruitment efforts:
        </h2>
        <div className="container-fluid main_cv_database_content_div">
          <div className="d-flex justify-content-between gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Extensive Candidate Reach: </h2>
              <p>
                Our platform offers a wide reach, connecting you with a substantial candidate pool
                of unlimited individuals. By advertising your job openings with us, you have the
                opportunity to tap into this vast talent network, maximizing your chances of finding
                qualified candidates who match your requirements.
              </p>
            </div>
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Efficient Job Posting:</h2>
              <p>
                Our user-friendly platform enables you to post your job advertisement quickly and
                easily. With a straightforward posting process, you can provide a comprehensive job
                description, outline the necessary qualifications, and highlight the benefits of
                joining your organization. This ensures that your job opening is effectively
                communicated to potential candidates.
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Increased Visibility:</h2>
              <p>
                By advertising with us, your job opening gains increased visibility among job
                seekers. Our platform is designed to attract and engage a large audience actively
                searching for employment opportunities. This heightened visibility ensures that your
                job advertisement stands out and captures the attention of potential candidates.
              </p>
            </div>
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Targeted Job Matching:</h2>
              <p>
                Our platform employs advanced algorithms and filters to ensure that your job
                advertisement is shown to candidates with relevant skills and experience. This
                targeted approach enhances the likelihood of receiving applications from candidates
                who are well-suited for the position. You can specify criteria such as location,
                industry, and job level to precisely target your desired audience.
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 main_cvdatabase_subdiv">
            <div className="home_content_column job_board2 ">
              <h2 className="home_content_heading">Streamlined Application Process: </h2>
              <p>
                With our job advertising service, applications are sent directly to your inbox,
                making it convenient for you to review and manage candidate submissions. This
                streamlined process allows you to efficiently track applications, shortlist
                candidates, and proceed with the recruitment process smoothly.
              </p>
            </div>
          </div>

          <div className="container-fluid my-cv cv_database_below_div">
            <p>
              By advertising your job openings with us, you unlock the potential to reach a wide and
              diverse talent pool. Benefit from increased visibility, efficient job posting, and
              targeted candidate matching. Connect with qualified candidates who are eager to apply
              for your positions and receive applications directly to your inbox. Start advertising
              with us today and discover the ideal candidates to join your organization.
            </p>
          </div>
        </div>

        <div className="advertise_afterposting section-padding">
          <h1>What happens after you post a job?</h1>
          <p>
            Within seconds, you can advertise a job on Alphy Johnson's Employment Center and place
            your vacancy in front of over 18.5 million candidates to view quickly and easily.
          </p>
          <div className="advertise_perks">
            <Perks
              img={jobposting}
              heading={"Daily Job Alerts"}
              p={
                "Your job will automatically list in daily email alerts to highly relevant candidates"
              }
            />
            <Perks
              img={jobposting}
              heading={"Dedicated account management"}
              p={"You will know exactly who to turn to if you need a helping hand"}
            />
            <Perks
              img={jobposting}
              heading={"Exposure across Various job sites"}
              p={"All jobs are automatically shared across our network of specialist job sites"}
            />
            <Perks
              img={jobposting}
              heading={"Free Company Profile"}
              p={"A dedicated place to showcase your brand and jobs"}
            />
          </div>
        </div>
        <div className="advertise_trust">
          <div className="trust1">
            <p className="p11">Employment Center</p>
          </div>
          <div className="trust2">
            <p>Excellent</p>
          </div>
          <div className="trust1 border1">
            <p className="p21">Best rating in the industry</p>
          </div>
        </div>
        {(user?.role === "employer" || user?.role === "company") && (
          <div className="advertise_posting section-padding">
            <h1>Posting is quick and simple</h1>
            <div className="advertise_posts">
              <Perks
                img={jobposting}
                heading={"Select"}
                p={
                  "Add the job details, including salary and location, and choose your screening questions"
                }
              />
              <Perks
                img={jobposting}
                onclick={createJob}
                heading={"Advertise"}
                p={
                  "Post your job and it will automatically be sent to all relevant sites in our network"
                }
              />
              <Perks
                img={jobposting}
                heading={"Hire"}
                p={"Receive applications, shortlist candidates and make your next hire!"}
              />
            </div>
          </div>
        )}
        <div className="advertise_customer-service section-padding">
          <h2>Award-winning customer service</h2>
          <p>
            Your account manager will provide you with the industry's finest customer support
            throughout your recruitment process. They can offer tips and advice on how to find the
            perfect candidates quickly.
          </p>
        </div>

        <div className="searchcv_maindiv section-padding">
          <h1>Search CVs now</h1>
          <p>Explore active candidates on our CV Database from just $225+VAT</p>
          <Button onclick={() => navigate("/searchcv")} text={"Find candidates"} />
        </div>

        <div className="advertise_additional section-padding">
          <h1 style={{ textTransform: "uppercase" }}>Additional job advertising features</h1>
          <div className="additional_features">
            <div>
              <Posts
                icon={features}
                heading={"Screening questions"}
                para={
                  "Add up to five custom screening questions to quickly determine the most suitable applicants"
                }
              />
              <Posts
                icon={features}
                heading={"Social media job posting"}
                para={
                  "Share your jobs automatically on your LinkedIn, Twitter and Facebook profiles"
                }
              />
              <Posts
                icon={features}
                heading={"Monthly usage reports"}
                para={"Monthly reports ensure you're getting the most out of our services"}
              />
            </div>

            <div>
              <Posts
                icon={features}
                heading={"Video Interviews NEW"}
                para={
                  "Instantly set up video interviews with the best applicants, via our free tool"
                }
              />
              <Posts
                icon={features}
                heading={"Account-managed service"}
                para={
                  "Receive customer service support from a dedicated account manager throughout your campaign"
                }
              />
              <Posts
                icon={features}
                heading={"Free Company Profile"}
                para={
                  "Receive a free Company Profile to display your company's branding and improve SEO"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Advertisejob;
