import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

const ServicesDetails = () => {
  return (
    <div>
      <div className="services_section">
        <section className="services_content_section">
          <h3 className="services-heading heading-font">
            Screening and Reviewing Resumes or CV's
          </h3>
          <p className="services_section_para">
            Resume screening is our process to find the right person for a job
            by narrowing down the list of eligible candidates based on the
            information in their resumes. Employers can hire their own
            Recruiters to review candidates' resumes themselves or use our
            services.
            <br></br>
            <a href="/enquirenow" style={{ textDecoration: "none" }}>
              [Contact us for Details]
            </a>
          </p>
          <div className="contact_main_div">
            <span className="btn btn-primary contact_div">
              <FaPhoneAlt />
              +1 (855) 580-0685
            </span>
            <span className="btn btn-primary contact_div">
              <FaWhatsapp />
              (848-667-1288)
            </span>
          </div>
        </section>
      </div>

      <div className="services_second_section_main">
        <div className="services_second_section_header">
          <h3 className="services-heading heading-font">
            Conducting Background checks
          </h3>
          <p className="fs-4 text-center">
            Our national criminal background check shows criminal records,
            convictions, and sex offenders with a single search. Same-day
            results for most applicants and 90% within 1-4 business days. Our
            low fixed price includes the cost of court verification with no
            additional fees.<br></br>{" "}
            <a href="/enquirenow">[Contact us for Details]</a>
          </p>
          <div className="contact_main_div">
            <span className="btn btn-primary contact_div">
              <FaPhoneAlt />
              +1 (855) 580-0685
            </span>
            <span className="btn btn-primary contact_div">
              <FaWhatsapp />
              (848-667-1288)
            </span>
          </div>
        </div>
        <div className="services_second_section">
          <section className="services_sub_section job_board2">
            <h3 className="services-heading">
              National Criminal Background Check
            </h3>
            <p className="services_section_para text-center">
              Our most popular product, our national criminal background check
              allows you to check out potential employees, volunteers, or
              tenants with the click of a mouse. This report is FCRA compliant,
              suitable for pre-employment screening and tenant background
              checks. Most reports are on the same day, with 90% finishing
              within 1-4 business days. $39:99. Report prices are per person.
            </p>
          </section>
          <section className="services_sub_section job_board2">
            <h3 className="services-heading">Driving Records</h3>
            <p className="services_section_para text-center">
              Make sure that your employees drive responsibly with our motor
              vehicle record reports. All report results are instant and come
              straight from the Department of Motor Vehicles.<br></br> *For most
              states. To see state prices click here. Report prices are per
              person and include the state DMV fee.
            </p>
          </section>
          <section className="services_sub_section job_board2">
            <h3 className="services-heading">SSN Trace and Validation</h3>
            <p className="services_section_para text-center">
              Our Social Security Number/ National Identity Number Trace and
              Validation will examine a NIN or SSN to see if it is valid and
              whether it is listed on the master death index. This report also
              provides address history, which can be used to perform deeper
              criminal checks and confirm reported records. Call for details
              +1 (855) 580-0685
            </p>
            <small>
              NOTE: everyone must physically show up at a facility to submit
              fingerprints, ID, SSN & other recognized government IDs to
              properly prevent any fraud info being submitted to us. Likewise,
              with drug screening everyone must show up at a recommended
              facilities for example Quest Diagnostics, to show their ID to
              submit urine, blood, or hair for drug test.
            </small>
          </section>
        </div>
      </div>

      <div className="services_section interview_div">
        <section className="services_content_section">
          <h3 className="services-heading heading-font">
            Conducting Interviews
          </h3>
          <p className="services_section_para">
            Alphy Johnson’s Resume Global can assist employers with screening
            interviews. A screening interview is a great opportunity to set c
            candidates apart from other candidates and select the most
            impressive candidate for your interviewer. This could be a brief
            phone interview, with the potential candidate to determine their
            genuine interest in your position as well as their enthusiasm for
            your organization and the value they could bring to that position.
            Saving your company time and money.
            <br></br>
            <a href="/enquirenow">[Contact us for Details]</a>
          </p>
          <div className="contact_main_div">
            <span className="btn btn-primary contact_div">
              <FaPhoneAlt />
              +1 (855) 580-0685
            </span>
            <span className="btn btn-primary contact_div">
              <FaWhatsapp />
              (848-667-1288)
            </span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServicesDetails;
