import Header from "../header/Header";
import Form from "../form/Form";
import Footer2 from "../footer/footer";
import Navbar3 from "../navbar3/navbar3";
function Login() {
  return (
    <>
      <Navbar3 />
      <Header />
      <Form />
      {/* <Footer /> */}
      <Footer2 />
    </>
  );
}

export default Login;
