import React from "react";
import "./board.css";
import Button from "../button/button";
import LazyImage from "../common/LazyImage";
const board = ({ img, heading, para, buttonText, onclick }) => {
  return (
    <div className="board_maindiv">
      <LazyImage className="board-image" src={img} alt={heading} loading="lazy" />
      <div className="board_subdiv2">
        <h2>{heading}</h2>
        <hr />
        <p>{para}</p>
        <Button onclick={onclick} text={buttonText} />
      </div>
    </div>
  );
};

export default board;
