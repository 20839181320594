import React, { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { store } from "../../redux/store";
import * as actions from "../../redux/actions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Container,
  Badge,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  FilledInput,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Close,
  CameraAlt,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Colors } from "../../constants/GlobalConstants";
import { AdditionalRoles } from "../../constants/GlobalConstants";
import axios from "../../axios";
import { ToastError, ToastSuccess } from "../alert/toastAlert";
import { useAuthContext } from "../../context/AuthContext";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.blue,
    },
  },
});

const UpdateProfileModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuthContext();
  const profilePhoto = useRef();
  const [oldVisible, setOldVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    oldPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    try {
      let route;
      if (user?.role === "admin") route = `/adminPanel`;
      if (AdditionalRoles.includes(user?.role)) route = `/additionalUsers`;
      const fetchUser = async () => {
        const res = await axios.get(route);
        const data = res.data?.user;

        setUserDetails((prev) => ({
          ...prev,
          firstName: data?.firstName,
          lastName: data?.lastName,
        }));
      };

      fetchUser();
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  store.subscribe(() => {
    setIsOpen(store.getState().NavReducer.isUpdateProfileModalOpen);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let route;
      if (user?.role === "admin") route = `/adminPanel/update-profile`;
      if (AdditionalRoles.includes(user?.role))
        route = `/additionalUsers/update-profile`;

      await axios.patch(route, userDetails).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Profile updated successfully");
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      return;
    }
    store.dispatch(actions.change_update_profile_modal());
  };

  const editProfilePhoto = async (image) => {
    const formData = new FormData();
    formData.append("profilePhoto", image);
    try {
      let route;
      if (user?.role === "admin") route = `/adminPanel/update-profile`;
      if (AdditionalRoles.includes(user?.role))
        route = `/additionalUsers/update-profile`;

      await axios.patch(route, formData).then((res) => {
        console.log(res.data?.data);
        const user = res.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setSuccess(true);
        setSuccessMessage("Profile photo updated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      return;
    }
    store.dispatch(actions.change_update_profile_modal());
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      let route;
      if (user?.role === "admin") route = `/adminPanel/update-password`;
      if (AdditionalRoles.includes(user?.role))
        route = `/additionalUsers/update-password`;

      const res = await axios.patch(route, {
        oldPassword: userDetails.oldPassword,
        newPassword: userDetails.newPassword,
      });

      setSuccess(true);
      setSuccessMessage(res.data?.message);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      return;
    }
    store.dispatch(actions.change_update_profile_modal());
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: isSmallScreen ? "90%" : "60%",
    height: "80vh",
    overflowY: "scroll",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <div>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Container component="main" maxWidth="xs">
            <ThemeProvider theme={defaultTheme}>
              <Box sx={style}>
                <Close
                  style={closeIconStyle}
                  onClick={() =>
                    store.dispatch(actions.change_update_profile_modal())
                  }
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <CameraAlt sx={{ color: "var(--btn-color)" }} />
                  }
                  onClick={() => profilePhoto.current.click()}
                  sx={{ cursor: "pointer" }}
                >
                  <Avatar
                    alt="Profile Photo"
                    sx={{ width: 56, height: 56 }}
                    src={user?.profilePhoto}
                  />
                </Badge>
                <input
                  type="file"
                  ref={profilePhoto}
                  style={{ display: "none" }}
                  onChange={(e) => editProfilePhoto(e.target.files[0])}
                />
                <Typography
                  className="text-center"
                  component="h1"
                  variant="h4"
                  fontWeight={"bold"}
                  id="transition-modal-title"
                >
                  Update Info
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        value={userDetails?.firstName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            firstName: e.target.value,
                          })
                        }
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="lastName"
                        value={userDetails?.lastName}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            lastName: e.target.value,
                          })
                        }
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="email"
                        value={user?.email}
                        disabled
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="role"
                        value={user?.role}
                        disabled
                        label="Role"
                        name="role"
                        autoComplete="role"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    className="btn-primary"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontSize: "20px", fontWeight: "bold" }}
                  >
                    Submit
                  </Button>
                </Box>

                <Typography
                  className="text-center"
                  component="h1"
                  variant="h4"
                  fontWeight={"bold"}
                  id="transition-modal-title"
                  mt={4}
                  mb={2}
                >
                  Update Password
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1 }} fullWidth variant="filled">
                      <InputLabel htmlFor="filled-adornment-password">
                        Old Password
                      </InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={oldVisible ? "text" : "password"}
                        value={userDetails?.oldPassword}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            oldPassword: e.target.value,
                          })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setOldVisible(!oldVisible)}
                              edge="end"
                            >
                              {oldVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1 }} fullWidth variant="filled">
                      <InputLabel htmlFor="filled-adornment-password">
                        New Password
                      </InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type={newVisible ? "text" : "password"}
                        value={userDetails?.newPassword}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            newPassword: e.target.value,
                          })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setNewVisible(!newVisible)}
                              edge="end"
                            >
                              {newVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  onClick={handleUpdatePassword}
                  className="btn-primary"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontSize: "20px", fontWeight: "bold" }}
                >
                  Update Password
                </Button>
              </Box>
            </ThemeProvider>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
};

export default UpdateProfileModal;

const closeIconStyle = {
  cursor: "pointer",
  position: "absolute",
  right: "0",
  top: "0",
  fontSize: "35px",
};
