import React, { useEffect, useState } from "react";
import "./application.css";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "../profile/ProfileLeft";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import Logout from "../profile/Logout";
import { useProfileContext } from "../../context/ProfileContext";

function AppliedJobs() {
  const { user } = useAuthContext();
  const { state } = useProfileContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  if (!user) {
    window.location.href = "/register";
  }

  useEffect(() => {
    setPagePosts(state?.applications?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.applications?.length / postsPerPage));
  }, [state?.applications, postsPerPage]);

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state.applications?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setPagePosts(current);
    }
  };

  return (
    <>
      <Navbar3 />
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          {state.isFetching ? (
            <div className="recommend" id="c2">
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="recommend" id="c2">
              {user?.role === "jobseeker" && (
                <div className="applications_main_div">
                  <h3
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                    }}
                  >
                    Applied Jobs
                  </h3>
                  {state.applications.length === 0 ? (
                    <div className="application">
                      <i className="bx bxs-error"></i>
                      <p>You have not made any applications yet.</p>
                      <p>Search and Apply for Jobs Now.</p>
                    </div>
                  ) : (
                    <>
                      <div className="application_main_div">
                        {pagePosts?.map((application) => (
                          <div className="application" key={application?._id}>
                            <h3 className="job-title">
                              {application?.job?.jobTitle}
                            </h3>
                            <div className="application_para_1">
                              <p>
                                <b>Location: </b> {application?.job?.location}
                              </p>
                              <p>
                                <b>Phone: </b> {application?.phone}
                              </p>
                            </div>
                            <p>
                              <b>Cover Letter: </b>{" "}
                            </p>
                            <p>{application?.coverLetter}</p>
                            <div className="application_para_1">
                              <p>
                                <b>Viewed: </b>{" "}
                                {application?.isViewed ? "Yes" : "No"}
                              </p>
                              {user?.status === "featured" && (
                                <p>
                                  <b>Background Check: </b>{" "}
                                  {
                                    application?.backgroundCheckRecord
                                      ?.isRecordClear
                                  }
                                </p>
                              )}
                              <p>
                                <b>Status: </b> {application?.recruitmentStatus}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {pagePosts?.length >= 1 && (
                        <div>
                          <div className="pagination">
                            <div
                              className="btn btn-primary"
                              onClick={() => paginate("prev")}
                            >
                              <FaLessThan />
                              <FaLessThan />
                            </div>
                            <div
                              className={
                                currentPage === 1
                                  ? "btn btn-primary active_page_btn"
                                  : "btn btn-primary"
                              }
                              onClick={() => paginate(1)}
                            >
                              1
                            </div>
                            {currentPage !== 1 && (
                              <div
                                className="btn btn-primary active_page_btn"
                                onClick={() => paginate(currentPage)}
                              >
                                {currentPage}
                              </div>
                            )}
                            {totalPages >= 2 && (
                              <>
                                <div className="btn btn-primary">...</div>
                                <div
                                  className="btn btn-primary"
                                  onClick={() => paginate(totalPages)}
                                >
                                  {totalPages}
                                </div>
                              </>
                            )}
                            <div
                              className="btn btn-primary"
                              onClick={() => paginate("next")}
                            >
                              <FaGreaterThan />
                              <FaGreaterThan />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
}

export default AppliedJobs;
