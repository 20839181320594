import React from "react";
import "./header.css";

function Header() {
  return (
    <div className="about-header">
      <div className="header-container">
        <div className="header-center">
          <div className="header-left-section">
            <h1 className="header-heading">ABOUT THE ALPHY JOHNSON</h1>
            <p>
              The <u>Alphy Johnson</u> is a rising woman owned global employment
              company founded in 2019 right before the COVID 19. The COVID
              effect rendered the new company inactive, but with our fighting
              spirit the business status was restored in <b>Nov 2022</b> aiming
              at providing services to companies who are equally fighting to
              find affordable potential individual job seekers make up revenue
              lost. Our goal is to redirect US and European companies attention
              to the vast affordable resources awaiting in Africa. We provide
              operational support services, training and vetting including
              converging physical and digital infrastructural environments.
            </p>
            <div className="header-left-section_row">
              <div className="header-rating">
                <p>Various</p>
                <span>UNIQUE CV's</span>
              </div>
              <div className="header-rating">
                <p>Best Rating</p>
                <span>ON JOB SEARCHING</span>
              </div>
              <div className="header-rating">
                <p>Numerous</p>
                <span>BUISNESSES</span>
              </div>
              <div className="header-rating">
                <p>Unlimited</p>
                <span>JOBS</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
