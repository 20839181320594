import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import {
  MdDisplaySettings,
  MdEmail,
  MdNotificationsPaused,
  MdSearch,
  MdSettingsApplications,
  MdSupport,
} from "react-icons/md";

function Cvbulider() {
  const [showAnswer, setShowAnswer] = useState(true);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };
  const [answersVisibility, setAnswersVisibility] = useState({});

  const toggleAnswerr = (question) => {
    setAnswersVisibility((prevState) => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };
  return (
    <div>
      <h2
        className="text-center fw-bold header-heading animate__backInLeft mt-4 fs-4"
        style={{ textTransform: "uppercase", backgroundColor: "var(--blue)" }}
      >
        Stay Connected with New Job Opportunities!
      </h2>
      <div
        className="mt-4 mb-4 pt-4 pb-4"
        style={{ backgroundColor: "var(--blue)", color: "#fff" }}
      >
        <p className="fs-5 text-center" style={{ margin: "0 5%" }}>
          Never miss out on a potential career opportunities with our Job Alerts
          service. By signing up for Job Alerts, you'll receive timely
          notifications about new job openings that match your preferences and
          qualifications, & you can find and post a job online for free.
        </p>
      </div>

      <div className="container-fluid position-static">
        <div className="container-fluid    ">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper cv-designer-slider"
          >
            <SwiperSlide className=" cv-designer-slider-items">
              <div className="container-fluid  text-center faqs-slider">
                <h2 className="text-center fs-3">
                  Here's why Job Alerts are a valuable tool in your job search:
                  <br></br>
                  <small>(Click on the Question to view Answer)</small>
                </h2>
                <p
                  className="fw-bold p-0 m-0 fs-3 job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={toggleAnswer}
                >
                  <span className="job_alert_tips_div">
                    <MdEmail />
                    Time and Effort Savings
                  </span>
                </p>
                {showAnswer && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    Searching for job openings can be time-consuming and
                    overwhelming. Job Alerts streamline the process by
                    delivering relevant opportunities directly to your inbox or
                    mobile device. This saves you the hassle of manually
                    scouring job boards or company websites and allows you to
                    focus your energy on preparing applications and interviews.
                  </p>
                )}
                <p
                  className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAnswerr("firstQuestion")}
                >
                  <span className="job_alert_tips_div">
                    <MdSettingsApplications />
                    Early Access to Opportunities
                  </span>
                </p>
                {answersVisibility["firstQuestion"] && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    Job Alerts provide you with a competitive advantage by
                    delivering new job openings to you as soon as they become
                    available. This early access allows you to submit your
                    application ahead of other candidates, increasing your
                    chances of securing an interview and landing your desired
                    job.
                  </p>
                )}
                <p
                  className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAnswerr("secondQuestion")}
                >
                  <span className="job_alert_tips_div">
                    <MdDisplaySettings />
                    Increased Job Market Awareness
                  </span>
                </p>
                {answersVisibility["secondQuestion"] && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    By consistently receiving Job Alerts, you gain valuable
                    insights into the current job market. You can observe
                    trends, industry demands, and emerging opportunities. This
                    market awareness helps you make informed decisions regarding
                    your career path and stay ahead in your job search.
                  </p>
                )}
                {/* <p
                className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                style={{ cursor: "pointer" }}
                onClick={() => toggleAnswerr("thirdQuestion")}
              >
                <span className="job_alert_tips_div">
                  <MdAddAlert />
                  Create up to 20 alerts
                </span>
              </p>
              {answersVisibility["thirdQuestion"] && (
                <p className="fs-5">
                  Create multiple alerts to receive the jobs you want. Keep an
                  eye on different locations, job titles, and industries, so you
                  never miss a job again.
                </p>
              )} */}
                <p
                  className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAnswerr("fourth")}
                >
                  <span className="job_alert_tips_div">
                    <MdNotificationsPaused />
                    Customized Notifications
                  </span>
                </p>
                {answersVisibility["fourth"] && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    Job Alerts are tailored to your specific criteria and
                    preferences. You can set filters based on job titles,
                    industries, locations, salary ranges, or any other relevant
                    factors. This ensures that you receive notifications for
                    positions that align with your interests and career goals.
                  </p>
                )}
                <p
                  className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAnswerr("fifth")}
                >
                  <span className="job_alert_tips_div">
                    <MdSearch />
                    Targeted Job Search
                  </span>
                </p>
                {answersVisibility["fifth"] && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    Job Alerts allow you to focus your job search efforts on
                    positions that match your skills and preferences. Instead of
                    wasting time on irrelevant or unsuitable openings, you can
                    invest your energy in pursuing the right opportunities. This
                    targeted approach enhances your efficiency and maximizes
                    your chances of finding the perfect job.
                  </p>
                )}
                <p
                  className="fw-bold p-0 m-0 fs-3 nav-link job_alert_tips"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleAnswerr("sixth")}
                >
                  <span className="job_alert_tips_div">
                    <MdSupport />
                    Continuous Support
                  </span>
                </p>
                {answersVisibility["sixth"] && (
                  <p className="fs-5" style={{ margin: "0 5%" }}>
                    Job Alerts provide ongoing support throughout your job
                    search journey. You'll receive regular updates on new job
                    openings, ensuring that you never miss a potential
                    opportunity. This consistent support keeps you motivated and
                    engaged in your job search, even during challenging times.
                  </p>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Cvbulider;
