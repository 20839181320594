import axios from "./axios";

export const fetchAllCandidates = async () => {
  try {
    const res = await axios.get("/candidates");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllEmployers = async () => {
  try {
    const res = await axios.get("/employers");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllCompanies = async () => {
  try {
    const res = await axios.get("/companies");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllUsers = async () => {
  try {
    const res = await axios.get("/users");
    const userArray = res.data?.docs;
    const newArray = userArray?.filter((user) => user?.role === "user");
    return newArray;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllAdditionalUsers = async () => {
  try {
    const res = await axios.get("/adminPanel/additional/user");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllPosts = async () => {
  try {
    const res = await axios.get("/career-advices");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllPlans = async () => {
  try {
    const res = await axios.get("/adminPanel/plans/registration");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllCourses = async () => {
  try {
    const res = await axios.get("/courses");
    return res?.data?.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchPremiumPlans = async () => {
  try {
    const res = await axios.get("/adminPanel/plans/others");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchHiringOrders = async () => {
  try {
    const res = await axios.get("/adminPanel/hiring/orders");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchEnquiries = async () => {
  try {
    const res = await axios.get("/adminPanel/enquiries");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchContactQueries = async () => {
  try {
    const res = await axios.get("/adminPanel/contact/queries");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchConfigurations = async () => {
  try {
    const res = await axios.get("/adminPanel/configurations");
    return res?.data?.doc;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchContactQuery = async (id) => {
  try {
    const res = await axios.get(`/adminPanel/contact/queries/${id}`);
    return res?.data?.doc;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const deleteQuery = async (id) => {
  try {
    const res = await axios.delete(`/adminPanel/contact/queries/${id}`);
    return res;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const addDevice = async (role, data) => {
  try {
    let route;
    switch (role) {
      case "user":
        route = "/devices/users";
        break;
      case "jobseeker":
        route = "/devices/candidates";
        break;
      case "employer":
        route = "/devices/employers";
        break;
      case "company":
        route = "/devices/companies";
        break;
      default:
        break;
    }
    const res = await axios.post(route, data);
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchDevices = async (role) => {
  try {
    let route;
    switch (role) {
      case "user":
        route = "/devices/users";
        break;
      case "jobseeker":
        route = "/devices/candidates";
        break;
      case "employer":
        route = "/devices/employers";
        break;
      case "company":
        route = "/devices/companies";
        break;
      default:
        break;
    }
    const res = await axios.get(route);
    return res.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const deleteDevices = async (role) => {
  try {
    let route;
    switch (role) {
      case "user":
        route = "/devices/users";
        break;
      case "jobseeker":
        route = "/devices/candidates";
        break;
      case "employer":
        route = "/devices/employers";
        break;
      case "company":
        route = "/devices/companies";
        break;
      default:
        break;
    }
    const res = await axios.delete(route);
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchJobs = async (role) => {
  try {
    let route;
    switch (role) {
      case "employer":
        route = "/employers/get/jobs";
        break;
      case "company":
        route = "/companies/get/jobs";
        break;
      default:
        break;
    }
    const res = await axios.get(route);
    return res.data?.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchApplications = async () => {
  try {
    const res = await axios.get("/candidates/get/applied-jobs");
    return res.data?.appliedJobs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchSavedJobs = async () => {
  try {
    const res = await axios.get("/jobs/saved");
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchTeam = async () => {
  try {
    const res = await axios.get("/companies/get/team");
    return res.data?.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchJobAlerts = async () => {
  try {
    const res = await axios.get("/jobs/job-alerts");
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchPosts = async (role) => {
  try {
    let route;
    switch (role) {
      case "jobseeker":
        route = "/career-advices/candidates";
        break;
      case "employer":
        route = "/career-advices/employers";
        break;
      case "company":
        route = "/career-advices/companies";
        break;
      default:
        break;
    }
    const res = await axios.get(route);
    return res.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const deletePost = async (id, role) => {
  try {
    let route;
    switch (role) {
      case "jobseeker":
        route = `/career-advices/candidates/${id}`;
        break;
      case "employer":
        route = `/career-advices/employers/${id}`;
        break;
      case "company":
        route = `/career-advices/companies/${id}`;
        break;
      default:
        break;
    }
    const res = await axios.delete(route);
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchCurrentUser = async (role) => {
  try {
    let route;
    switch (role) {
      case "user":
        route = "/users/me";
        break;
      case "jobseeker":
        route = "/candidates/me";
        break;
      case "employer":
        route = "/employers/me";
        break;
      case "company":
        route = "/companies/me";
        break;
      default:
        break;
    }

    const res = await axios.get(route);
    return res.data?.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const fetchAllLogs = async () => {
  try {
    const res = await axios.get("/adminPanel/logs");
    return res?.data?.docs;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};

export const logout = async () => {
  try {
    localStorage.clear();
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    const res = await axios.post("/logout");
    return res.data;
  } catch (error) {
    console.log(error?.response);
    throw error;
  }
};
