import React, { useEffect, useState } from "react";
import cvbuild from "../../images/cvbuild.jpeg";
import { ToastError } from "../../components/alert/toastAlert";
import { useAuthContext } from "../../context/AuthContext";
import LazyImage from "../../components/common/LazyImage";

function Slder() {
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setError(false);
  }, [error]);

  const hanldeJobseekerClicks = () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      window.location.href = "/assistcv";
    }
  };

  return (
    <>
      <div className="container-fluid mt-5 my-cv ">
        {error && <ToastError message={errorMessage} />}
        <div className="container flex-wrap buildmycv_div">
          <h2 className="text-center text-white pt-5" style={{ textTransform: "uppercase" }}>
            Get Free Resume Assistance and Create Your Resume Online Today!
          </h2>
          <h2 className="text-center  text-white">
            Need help creating the perfect resume? At{" "}
            <a href="/" target="_blank">
              Alphy Johnson
            </a>
            , we offer free resume assistance through our expert designers. If you prefer hands-on
            help, our team can guide you through every step or even craft your entire resume for
            you. Get professional help with our resume fixer, or use our CV builder tool to create a
            standout resume in minutes. Ready to get started? Click below to begin your resume
            transformation.
          </h2>

          <button className=" fw-bold cv-designer-btn" onClick={() => hanldeJobseekerClicks()}>
            DESIGN FOR ME
          </button>
        </div>
      </div>
      <div className="container-fluid  mt-5">
        <h3 className="text-center">Why Job Seekers Love Our Jobs Resume Builder Tool</h3>

        <p style={{ marginInline: "2rem" }}>
          Our users trust us because our resume builder is not just fast – it’s also customizable
          and intuitive. Whether you need a <b>resume builder for jobs</b> for quick updates or want
          to build a new CV from scratch, we’ve got you covered. With templates and expert
          suggestions, you’ll have a professional resume ready in minutes. Plus, you can access our{" "}
          <b>free resume assistance</b> anytime, ensuring you get the job you deserve.
        </p>
        <div className="container cvbuilder_slder_div mt-5 d-flex justify-content-center ">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade "
            data-bs-ride="carousel"
          >
            <div className="carousel-inner  slder_img_div ">
              <div className="carousel-item active">
                <LazyImage
                  className="slder_img"
                  src={cvbuild}
                  alt="resume builder online"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slder;
