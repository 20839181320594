import React, { useEffect, useState } from "react";
// import "./register.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";

const UpdateCV = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [cv, setCV] = useState();
  const [countryNames, setNames] = useState([]);
  const [cvDetails, setCvDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    country: "",
    postCode: "",
    languages: [""],
    professionSummary: "",
    education: [
      {
        eduInstitute: "",
        eduQualification: "",
        eduCourse: "",
        eduGrade: "",
        eduCompletionYear: "",
        eduDescription: "",
      },
    ],
    certificates: [
      {
        certificateName: "",
        certificateOrganization: "",
        certificateCompletionDate: "",
      },
    ],
    workExp: [
      {
        jobTitle: "",
        jobEmployementType: "",
        jobCompany: "",
        jobCity: "",
        jobStartDate: "",
        jobEndDate: "",
        jobResponsibilities: "",
      },
    ],
    skills: [""],
    hobbies: [""],
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      setTimeout(() => {
        navigate(-1);
      }, 2000);

      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      setTimeout(() => {
        navigate(-1);
      }, 2000);
      return;
    }
    try {
      const fetchCV = async () => {
        await axios.get("/cv/").then((res) => {
          setIsFetching(true);
          const { cv } = res.data;
          const { _id, user, ...newCv } = cv;
          if (cv?.user._id === user._id) {
            setCV(cv);
            setCvDetails(newCv);
            setIsFetching(false);
          } else {
            setError(true);
            setErrorMessage("No CV found!!");
            setTimeout(() => {
              window.location.href = "/resume-fixer";
            }, 2000);
          }
        });
      };

      const fetchCountries = async () => {
        await axios.get("https://restcountries.com/v3.1/all").then((res) => {
          const array = res.data;
          const namesArray = [];
          array.forEach((country) => {
            namesArray.push(country?.name?.common);
          });
          namesArray.sort();
          setNames(namesArray);
        });
      };

      fetchCV();
      fetchCountries();
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error);
    }
  }, [user, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await axios.patch(`/cv/${cv._id}`, cvDetails).then((res) => {
        setSuccess(true);
        setSuccessMessage("CV updated Successfully");
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 1500);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleInputChange = (name, value) => {
    setCvDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (index, event, arrayName) => {
    const newValues = { ...cvDetails };
    const { value } = event.target;
    newValues[arrayName][index] = value;
    setCvDetails(newValues);
  };

  const handleAddInput = (arrayName) => {
    const newValues = { ...cvDetails };
    newValues[arrayName].push("");
    console.log(newValues);
    setCvDetails(newValues);
  };

  const handleRemoveInput = (index, arrayName) => {
    const newValues = { ...cvDetails };
    newValues[arrayName].splice(index, 1);
    setCvDetails(newValues);
  };

  const handleSectionChange = (event, section, index, field) => {
    const newCvDetails = { ...cvDetails };
    if (
      section === "education" ||
      section === "certificates" ||
      section === "workExp"
    ) {
      newCvDetails[section][index][field] = event.target.value;
    }
    setCvDetails(newCvDetails);
  };

  const handleAddSection = (section) => {
    const newCvDetails = { ...cvDetails };
    newCvDetails[section].push({});
    setCvDetails(newCvDetails);
  };

  const handleRemoveSection = (section, index) => {
    const newCvDetails = { ...cvDetails };
    newCvDetails[section].splice(index, 1);
    setCvDetails(newCvDetails);
  };

  const formatDate = (string) => {
    const date = new Date(string).toISOString().split("T")[0];
    return date;
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="">
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>We just need a few details about you to build your cv.</p>

          <div className="register_form">
            <h3 className="register_form_h3_start">Personal Details</h3>
            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>First Name</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={cvDetails?.firstName}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Last Name</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={cvDetails?.lastName}
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Email</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="email"
                      value={cvDetails?.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  {cvDetails?.languages?.map((item, index) => {
                    return (
                      <div key={index} className="register_options">
                        <label>Language</label>
                        <input
                          id="keyword"
                          className="text"
                          type="email"
                          value={item}
                          required
                          onChange={(e) => handleChange(index, e, "languages")}
                        />
                        <button
                          className="cv_build_btn"
                          onClick={() => handleRemoveInput(index, "languages")}
                        >
                          Remove Language
                        </button>
                      </div>
                    );
                  })}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddInput("languages")}
                  >
                    Add Language
                  </button>
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Billing Details</h3>
            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Address</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={cvDetails?.address}
                      onChange={(e) =>
                        handleInputChange("address", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>City</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={cvDetails?.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Country</label>
                    <br />
                    <select
                      id="keyword"
                      type="text"
                      value={cvDetails?.country}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      required
                    >
                      <option value="Please Select ">Please Select</option>
                      {countryNames.map((name) => {
                        return (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="register_form_div">
                  <div className="register_options">
                    <label>Postcode</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={cvDetails?.postCode}
                      onChange={(e) =>
                        handleInputChange("postCode", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Professional Details</h3>
            <hr />

            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Summary</label>
                    <br />
                    <textarea
                      id="keyword"
                      className="form-control"
                      type="text"
                      value={cvDetails?.professionSummary}
                      onChange={(e) =>
                        handleInputChange("professionSummary", e.target.value)
                      }
                      required
                      maxLength={500}
                    ></textarea>
                  </div>
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Work Experience</h3>

            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <br />
                  {cvDetails?.workExp.map((eduItem, eduIndex) => (
                    <div key={eduIndex} className="register_options">
                      <label>Job Title</label>
                      <input
                        type="text"
                        value={eduItem?.jobTitle}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobTitle"
                          )
                        }
                      />
                      <label>Employment Type</label>
                      <input
                        type="text"
                        value={eduItem?.jobEmployementType}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobEmployementType"
                          )
                        }
                      />
                      <label>Company Name</label>
                      <input
                        type="text"
                        value={eduItem?.jobCompany}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobCompany"
                          )
                        }
                      />
                      <label>City</label>
                      <input
                        type="text"
                        value={eduItem?.jobCity}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobCity"
                          )
                        }
                      />
                      <label>Start Date</label>
                      <input
                        type="date"
                        value={formatDate(
                          eduItem?.jobStartDate || "2023-06-10T00:00:00.000Z"
                        )}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobStartDate"
                          )
                        }
                      />
                      <label>End Date</label>
                      <input
                        type="date"
                        value={formatDate(
                          eduItem?.jobEndDate || "2023-06-10T00:00:00.000Z"
                        )}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobEndDate"
                          )
                        }
                      />
                      <label>Responsibilities</label>
                      <input
                        type="text"
                        value={eduItem?.jobResponsibilities}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "workExp",
                            eduIndex,
                            "jobResponsibilities"
                          )
                        }
                      />
                      <button
                        className="cv_build_btn"
                        onClick={() => handleRemoveSection("workExp", eduIndex)}
                      >
                        Remove Experience
                      </button>
                    </div>
                  ))}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddSection("workExp")}
                  >
                    Add Experience
                  </button>
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Certificates</h3>

            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <br />
                  {cvDetails?.certificates.map((eduItem, eduIndex) => (
                    <div key={eduIndex} className="register_options">
                      <label>Certificate Name</label>
                      <input
                        type="text"
                        value={eduItem?.certificateName}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "certificates",
                            eduIndex,
                            "certificateName"
                          )
                        }
                      />
                      <label>Organization/Institute</label>
                      <input
                        type="text"
                        value={eduItem?.certificateOrganization}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "certificates",
                            eduIndex,
                            "certificateOrganization"
                          )
                        }
                      />
                      <label>Completion Date</label>
                      <input
                        type="date"
                        value={formatDate(
                          eduItem?.certificateCompletionDate ||
                            "2023-06-10T00:00:00.000Z"
                        )}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "certificates",
                            eduIndex,
                            "certificateCompletionDate"
                          )
                        }
                      />

                      <button
                        className="cv_build_btn"
                        onClick={() =>
                          handleRemoveSection("certificates", eduIndex)
                        }
                      >
                        Remove Certificate
                      </button>
                    </div>
                  ))}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddSection("certificates")}
                  >
                    Add Certificate
                  </button>
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Education Details</h3>

            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  <br />
                  {cvDetails.education.map((eduItem, eduIndex) => (
                    <div key={eduIndex} className="register_options">
                      <label>Institute Name</label>
                      <input
                        type="text"
                        value={eduItem.eduInstitute}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduInstitute"
                          )
                        }
                      />
                      <label>Degree Type</label>
                      <input
                        type="text"
                        value={eduItem?.eduQualification}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduQualification"
                          )
                        }
                      />
                      <label>Department</label>
                      <input
                        type="text"
                        value={eduItem?.eduCourse}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduCourse"
                          )
                        }
                      />
                      <label>Grade</label>
                      <input
                        type="text"
                        value={eduItem?.eduGrade}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduGrade"
                          )
                        }
                      />
                      <label>Completion Year</label>
                      <input
                        type="date"
                        value={formatDate(
                          eduItem?.eduCompletionYear ||
                            "2023-06-10T00:00:00.000Z"
                        )}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduCompletionYear"
                          )
                        }
                      />
                      <label>Any other Details</label>
                      <input
                        type="text"
                        value={eduItem?.eduDescription}
                        required
                        onChange={(event) =>
                          handleSectionChange(
                            event,
                            "education",
                            eduIndex,
                            "eduDescription"
                          )
                        }
                      />
                      <button
                        className="cv_build_btn"
                        onClick={() =>
                          handleRemoveSection("education", eduIndex)
                        }
                      >
                        Remove Education
                      </button>
                    </div>
                  ))}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddSection("education")}
                  >
                    Add Education
                  </button>
                  {/* </div> */}
                </div>
              </form>
            )}
          </div>

          <div className="register_form">
            <h3 className="register_form_h3_start">Skills and Hobbies</h3>

            <hr />
            {isFetching ? (
              <div className="container d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <form>
                <div className="register_form_div">
                  {cvDetails?.skills?.map((item, index) => {
                    return (
                      <div key={index} className="register_options">
                        <label>Skill</label>
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={item}
                          required
                          onChange={(e) => handleChange(index, e, "skills")}
                        />
                        <button
                          className="cv_build_btn"
                          onClick={() => handleRemoveInput(index, "skills")}
                        >
                          Remove Skill
                        </button>
                      </div>
                    );
                  })}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddInput("skills")}
                  >
                    Add Skill
                  </button>
                </div>

                <div className="register_form_div">
                  {cvDetails?.hobbies?.map((item, index) => {
                    return (
                      <div key={index} className="register_options">
                        <label>Hobby</label>
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={item}
                          required
                          onChange={(e) => handleChange(index, e, "hobbies")}
                        />
                        <button
                          className="cv_build_btn"
                          onClick={() => handleRemoveInput(index, "hobbies")}
                        >
                          Remove Hobby
                        </button>
                      </div>
                    );
                  })}
                  <button
                    className="cv_build_btn"
                    onClick={() => handleAddInput("hobbies")}
                  >
                    Add Hobby
                  </button>
                </div>
              </form>
            )}
          </div>

          <div className="button_maindiv">
            <button onClick={handleSubmit}>Update CV</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpdateCV;
