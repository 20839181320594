import React, { useEffect, useState } from "react";
import "./adminPanel.css";
import {
  BsBrightnessHighFill,
  BsCaretDownFill,
  BsCaretUpFill,
  BsMoonStarsFill,
} from "react-icons/bs";
import { useAuthContext } from "../../context/AuthContext";
import {
  FaBars,
  FaChalkboardTeacher,
  FaRegWindowClose,
  FaRegistered,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import { TbBrandBinance } from "react-icons/tb";
import { GiMultiDirections } from "react-icons/gi";
import { SiConcourse, SiTheregister } from "react-icons/si";
import { RiLuggageCartFill, RiUserSettingsFill } from "react-icons/ri";
import { MdLocalPolice, MdOutlineContactMail } from "react-icons/md";
import { ImUserPlus } from "react-icons/im";
import { HiUserPlus } from "react-icons/hi2";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
// import { BsPersonVcardFill } from "react-icons/bs";
import {
  change_show,
  change_mode,
  change_modal,
  change_update_profile_modal,
} from "../../redux/actions";
import { store } from "../../redux/store";
import * as api from "../../ApiCalls";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import AddUserModal from "../../components/modals/AddUserModal";
import UpdateProfileModal from "../../components/modals/UpdateProfileModal";
import LazyImage from "../../components/common/LazyImage";

const AdminTopBar = () => {
  const [lightModeOn, setLightModeOn] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const [show, setShow] = useState(store.getState().NavReducer.show);
  store.subscribe(() => {
    setShow(store.getState().NavReducer.show);
    setLightModeOn(store.getState().NavReducer.lightMode);
  });

  const handleShow = (value) => {
    store.dispatch(change_show(value));
    setIsCollapsed(!isCollapsed);
  };

  const goToHomePage = () => {
    window.location.replace("/");
  };

  const handleLogout = async () => {
    try {
      const res = await api.logout();
      if (res) {
        setSuccess(true);
        setSuccessMessage(res.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      return;
    }
  };

  return (
    <div className={lightModeOn ? "admin_navbar_top light_mode" : "admin_navbar_top dark_mode"}>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="admin_navbar_top_left">
        <div className="collapsed_navbar">
          {isCollapsed ? (
            <FaBars onClick={() => setIsCollapsed(false)} />
          ) : (
            <FaRegWindowClose onClick={() => setIsCollapsed(true)} />
          )}

          {!isCollapsed && (
            <div
              className={
                lightModeOn
                  ? "admin_navbar_collapsed dark-mode"
                  : "admin_navbar_collapsed light-mode"
              }
            >
              <section className="navbar_options">
                <ul>
                  {(user?.role === "admin" || user?.permissions.view_candidates) && (
                    <li
                      className={
                        show?.showCandidates
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showCandidates")}
                    >
                      <FaUsers />
                      Candidates
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_employers) && (
                    <li
                      className={
                        show?.showEmployers
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showEmployers")}
                    >
                      <FaUserPlus />
                      Employers
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_companies) && (
                    <li
                      className={
                        show?.showCompanies
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showCompanies")}
                    >
                      <TbBrandBinance />
                      Companies
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_course_users) && (
                    <li
                      className={
                        show?.showUsers
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showUsers")}
                    >
                      <FaChalkboardTeacher />
                      Course Users
                    </li>
                  )}
                  {user?.role === "admin" && (
                    <li
                      className={
                        show?.showAdditionalUsers
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showAdditionalUsers")}
                    >
                      <RiUserSettingsFill />
                      Management
                    </li>
                  )}
                  <li
                    className={
                      show?.showPosts
                        ? "admin_navbar_option admin_navbar_option_active"
                        : "admin_navbar_option"
                    }
                    onClick={() => handleShow("showPosts")}
                  >
                    <GiMultiDirections />
                    Career Guidance
                  </li>
                  {(user?.role === "admin" || user?.permissions.view_checkout_plans) && (
                    <li
                      className={
                        show?.showPlans
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showPlans")}
                    >
                      <FaRegistered />
                      Recruiter Plans
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_premium_plans) && (
                    <li
                      className={
                        show?.showPremiumPlans
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showPremiumPlans")}
                    >
                      <SiTheregister />
                      Premium Plans
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_hiring_orders) && (
                    <li
                      className={
                        show?.showHiringOrders
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showHiringOrders")}
                    >
                      <RiLuggageCartFill />
                      Hiring Orders
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_courses) && (
                    <li
                      className={
                        show?.showCourses
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showCourses")}
                    >
                      <SiConcourse />
                      Courses
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_enquiries) && (
                    <li
                      className={
                        show?.showEnquiries
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showEnquiries")}
                    >
                      <MdLocalPolice />
                      Enquiries
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.view_contact_us_queries) && (
                    <li
                      className={
                        show?.showContactQueries
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showContactQueries")}
                    >
                      <MdOutlineContactMail />
                      Contact Queries
                    </li>
                  )}
                  {(user?.role === "admin" || user?.permissions.add_recruiters) && (
                    <li
                      className={
                        show?.showAddRecruiter
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showAddRecruiter")}
                    >
                      <ImUserPlus />
                      Add Recruiters
                    </li>
                  )}
                  {user?.role === "admin" && (
                    <li
                      className={
                        show?.showLogs
                          ? "admin_navbar_option admin_navbar_option_active"
                          : "admin_navbar_option"
                      }
                      onClick={() => handleShow("showLogs")}
                    >
                      <DeveloperBoardIcon />
                      User Logs
                    </li>
                  )}
                  {/* <li
                    className={
                      show?.showProfile
                        ? "admin_navbar_option admin_navbar_option_active"
                        : "admin_navbar_option"
                    }
                    onClick={() => handleShow("showProfile")}
                  >
                    <BsPersonVcardFill />
                    My Profile
                  </li> */}
                </ul>
              </section>
            </div>
          )}
        </div>
        <p className="site_logo" onClick={goToHomePage}>
          Alphy Johnson's Employment Center
        </p>
      </div>
      {user?.role === "admin" && <AddUserModal />}
      <UpdateProfileModal />
      <div className="top_bar_right_div">
        <section className={lightModeOn ? "light_mode_on" : "dark_mode_on"}>
          <p
            className={lightModeOn ? "toggle_mode" : "default_mode"}
            onClick={() => store.dispatch(change_mode())}
          >
            <BsMoonStarsFill />
          </p>
          <p
            className={lightModeOn ? "default_mode" : "toggle_mode"}
            onClick={() => store.dispatch(change_mode())}
          >
            <BsBrightnessHighFill />
          </p>
        </section>
        {user?.role === "admin" && (
          <p
            style={{
              cursor: "pointer",
              border: "2px solid grey",
              borderRadius: "50%",
              padding: "5px",
            }}
            onClick={() => store.dispatch(change_modal())}
          >
            <HiUserPlus size={26} />
          </p>
        )}
        <p className="navbar_top_profile" onClick={() => setIsOpen(!isOpen)}>
          <LazyImage
            className="navbar_top_profile_img"
            src={user?.profilePhoto}
            alt="user profile"
            crossOrigin="anonymous"
          />

          {isOpen ? <BsCaretUpFill /> : <BsCaretDownFill />}
        </p>
      </div>
      {isOpen && (
        <section className="profile_dropdown">
          <button
            className="admin_navbar_option"
            style={{ width: "100%" }}
            onClick={() => store.dispatch(change_update_profile_modal())}
          >
            Update Profile
          </button>
          <button className="admin_navbar_option" style={{ width: "100%" }} onClick={handleLogout}>
            Sign Out
          </button>
        </section>
      )}
    </div>
  );
};

export default AdminTopBar;
