import React, { useEffect, useRef, useState } from "react";
import "./form.css";
import Formright from "../formright/Formright";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import axios from "../../axios";
import { ToastSuccess, ToastError } from "../alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../loader/OverlayLoader";

function Form() {
  const emailInput = useRef();
  const passwordInput = useRef();
  const formRef = useRef(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleId, setRoleId] = useState(1);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let route;
    if (roleId === 1) {
      route = "/candidates/login";
    }
    if (roleId === 2) {
      route = "/employers/login";
    }
    if (roleId === 3) {
      route = "/companies/login";
    }
    try {
      const response = await axios.post(
        route,
        {
          email: emailInput.current.value,
          password: passwordInput.current.value,
        },
        { withCredentials: true }
      );

      if (response.data.status === 200) {
        const { user } = response?.data;
        localStorage.setItem("user", JSON.stringify(user));
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/profile";
        }, 1000);
      } else if (response.data.status === 302) {
        setSuccess(true);
        setSuccessMessage(response.data?.message);
        // console.log(response.data?.url);
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = `${response.data?.url}`;
        }, 1000);
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <div className="rec_session animate__backInLeft">
        <div className="rec_session-left">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineLeft
              size={30}
              onClick={() => {
                if (roleId > 1) {
                  setRoleId(roleId - 1);
                } else if (roleId === 1) {
                  setRoleId(3);
                }
              }}
              style={{ marginInline: "5px", cursor: "pointer" }}
            />

            <h1 className="text-center">
              {roleId === 1
                ? "Candidate Login"
                : roleId === 2
                ? "Recruiter/Employer Login"
                : "Company Login"}
            </h1>

            <AiOutlineRight
              size={30}
              onClick={() => {
                if (roleId < 3) {
                  setRoleId(roleId + 1);
                } else if (roleId === 3) {
                  setRoleId(1);
                }
              }}
              style={{ marginInline: "5px", cursor: "pointer" }}
            />
          </div>
          <p className="text-center">Please enter your email address to get started.</p>
          <div className="rec_session-form">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="rec_email_input">
                <label>Email Address</label>
                <br />
                <input type="email" name="rec-email" required="required" ref={emailInput} />
              </div>
              <div className="rec-passowrd_input">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  name="rec-password"
                  required="required"
                  ref={passwordInput}
                />
              </div>
              <div className="rec-login text-center">
                <button className="btn-primary" type="submit">
                  Login Now
                </button>
              </div>
            </form>
          </div>
          <p className="rec-paraghraph text-center">
            <a href="/reset-password" className="rec-paraghraph-ancher">
              Forgotten Password?
            </a>
          </p>
        </div>
        <Formright />
      </div>
    </>
  );
}

export default Form;
