import React, { useEffect, useRef, useState } from "react";
import "./addBlog.css";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Navbar3 from "../navbar3/navbar3";
import Loader from "../Loader";
import OverlayLoader from "../loader/OverlayLoader";
import { AdditionalRoles } from "../../constants/GlobalConstants";

const EditPost = () => {
  const { user } = useAuthContext();
  const [post, setPost] = useState();
  const [values, setValues] = useState({
    headline: "",
    postBody: "",
  });
  const thumbnail = useRef();
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState([
    {
      heading: "",
      content: "",
    },
  ]);
  // const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      window.location.href = "/";
      return;
    }
    if (user && user?.role === "user") {
      setError(true);
      setErrorMessage("Courses user cannot add post. Login as Candidate");
      window.location.href = "/candidate/login";
      return;
    }
    try {
      setIsFetching(true);
      const fetchPost = async () => {
        await axios.get(`/career-advices/${id}`).then((res) => {
          const postData = res.data?.doc;
          setPost(postData);
          setValues(postData);
          setSections(postData?.sections);
          setCategories(postData?.category);
          setIsFetching(false);
        });
      };

      fetchPost();
    } catch (error) {
      // alert(error.response?.data?.message);
      console.log(error);
      setIsFetching(false);
    }
  }, [id, user]);

  const handleSubmit = async () => {
    if (
      (user?.role === "jobseeker" ||
        user?.role === "employer" ||
        user?.role === "company") &&
      post?.author?.id !== user?._id
    ) {
      setError(true);
      setErrorMessage("You cannot edit this post");
      return;
    } else if (
      user?.role !== "admin" &&
      !AdditionalRoles.includes(user?.role)
    ) {
      setError(true);
      setErrorMessage("You cannot edit this post");
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("headline", values.headline);
      categories?.forEach((value) => {
        formData.append("category", value);
      });
      if (thumbnail.current.files[0])
        formData.append("thumbnail", thumbnail.current.files[0]);
      // if (selectedImages?.length > 0) {
      //   selectedImages.forEach((file) => {
      //     formData.append("images", file);
      //   });
      // }
      formData.append("postBody", values.postBody);
      if (sections.length > 0) {
        const sectionsData = sections.filter(
          (item) => item.heading.trim() !== "" && item.content.trim() !== ""
        );
        if (sectionsData.length > 0) {
          sectionsData.forEach((section, index) => {
            formData.append(`sections[${index}][heading]`, section.heading);
            formData.append(`sections[${index}][content]`, section.content);
          });
        }
      }
      let route;
      if (user?.role === "admin") route = `/career-advices/${id}`;
      if (user?.role === "jobseeker")
        route = `/career-advices/candidates/${id}`;
      if (user?.role === "employer") route = `/career-advices/employers/${id}`;
      if (user?.role === "company") route = `/career-advices/companies/${id}`;
      if (AdditionalRoles.includes(user?.role))
        route = `/additionalUsers/posts/${id}`;

      await axios.patch(route, formData).then((res) => {
        setSuccess(true);
        setSuccessMessage("Post Updated successfully!");
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 2000);
      });
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleInputChange = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setCategories((prev) => ({
      ...prev,
      value,
    }));
  };

  // const handleImagesUpload = async (event) => {
  //   const files = event.target.files;
  //   if (files.length > 5) {
  //     setError(true);
  //     setErrorMessage("Only 5 images are allowed");
  //   }
  //   const updatedImages = [];

  //   // Limit the number of selected images to 5
  //   for (let i = 0; i < files.length && i < 5; i++) {
  //     updatedImages.push(files[i]);
  //   }

  //   setSelectedImages(updatedImages);
  // };

  const categoryOptions = [
    "getting started",
    "career guides",
    "location guides",
    "cvs",
    "cv tips and templates",
    "cover letter guides",
    "interview tips",
    "how to guides",
    "work life balance",
    "career development",
    "graduates",
    "apprenticeships",
  ];

  const addSection = () => {
    if (sections.length > 0) {
      const lastSection = sections[sections.length - 1];
      if (
        lastSection.heading.trim() === "" ||
        lastSection.content.trim() === ""
      ) {
        setError(true);
        setErrorMessage("Last section must not have empty values.");
        return;
      }
      setSections((prev) => [...prev, { heading: "", content: "" }]);
    } else setSections((prev) => [...prev, { heading: "", content: "" }]);
  };

  const removeSection = (index) => {
    const newData = [...sections];
    newData.splice(index, 1);
    setSections(newData);
  };

  const handleSectionChange = (value, index, name) => {
    if (index >= 0 && index < sections.length) {
      setSections((prevSections) => {
        const updatedSections = [...prevSections]; // Create a copy of the array
        updatedSections[index][name] = value;
        return updatedSections;
      });
    }
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h2>Welcome to Alphy Johnson's Employment Center</h2>
        <p>We just need a few details to Update your post</p>
        {isFetching ? (
          <div className="container d-flex justify-content-center align-items-center section-padding">
            <Loader />
          </div>
        ) : (
          <>
            <div className="register_form">
              <h3 className="register_form_h3_start">Post Headline</h3>

              <hr />
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Headline</label>
                    <br />
                    <input
                      id="keyword"
                      className="text"
                      type="text"
                      value={values?.headline}
                      onChange={(e) =>
                        handleInputChange("headline", e.target.value)
                      }
                      required
                    ></input>
                  </div>
                </div>
              </form>
            </div>
            <div className="register_form">
              <h3 className="register_form_h3_start">Categories</h3>
              <hr />
              <div className="register_div_jobtype">
                {categoryOptions?.map((option, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="radio"
                        id={`${option}`}
                        name=""
                        value={`${option}`}
                        onChange={handleCategoryChange}
                        checked={categories.find((obj) => obj === option)}
                      />
                      &nbsp;&nbsp;
                      <label
                        style={{ textTransform: "capitalize" }}
                        htmlFor={`${option}`}
                      >{`${option}`}</label>
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="register_form">
              <h3 className="register_form_h3_start">Post Body</h3>

              <hr />
              <form>
                <div className="register_form_div">
                  <div className="register_options">
                    <label>Body</label>
                    <br />
                    <textarea
                      id="keyword"
                      className="text"
                      type="text"
                      value={values?.postBody}
                      onChange={(e) =>
                        handleInputChange("postBody", e.target.value)
                      }
                      required
                      maxLength={"2000"}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>

            <div className="register_form">
              <h3 className="register_form_h3_start">
                Upload Thumbnail for your Post
              </h3>

              <hr />
              <form>
                <div className="register_form_div">
                  <div className="register_options register_options_label">
                    <input
                      id="cv"
                      type="file"
                      accept="image/*"
                      required
                      ref={thumbnail}
                    />
                    <label>Upload thumbnail</label>
                    <br />
                  </div>
                </div>
                {/* <div className="register_form_div">
                  <div className="register_options register_options_label">
                    <input
                      id="cv"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImagesUpload}
                    />
                    <label>Upload Additional Images</label>
                    <br />
                  </div>
                </div> */}
              </form>
            </div>

            <div className="register_form">
              <h3 className="register_form_h3_start">Additional Sections</h3>

              <hr />
              <form>
                {sections?.map((item, index) => {
                  return (
                    <div className="register_form_div" key={index}>
                      <div className="register_options">
                        <label>Heading</label>
                        <br />
                        <input
                          id="keyword"
                          className="text"
                          type="text"
                          value={item?.heading}
                          onChange={(e) =>
                            handleSectionChange(
                              e.target.value,
                              index,
                              "heading"
                            )
                          }
                          required
                          maxLength={"300"}
                        ></input>
                      </div>
                      <div className="register_options">
                        <label>Content</label>
                        <br />
                        <textarea
                          id="keyword"
                          className="text"
                          type="text"
                          value={item?.content}
                          onChange={(e) =>
                            handleSectionChange(
                              e.target.value,
                              index,
                              "content"
                            )
                          }
                          required
                          maxLength={"2000"}
                        ></textarea>
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={() => removeSection(index)}
                      >
                        Remove Section
                      </button>
                    </div>
                  );
                })}
              </form>
              <hr />
              <button className="btn btn-primary mt-4" onClick={addSection}>
                Add Section
              </button>
            </div>
          </>
        )}
        <div className="button_maindiv">
          <button onClick={handleSubmit}>Update Post</button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditPost;
