import React, { useEffect, useRef, useState } from "react";
import "./register.css";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { ToastError, ToastSuccess } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import Navbar3 from "../../components/navbar3/navbar3";
import OverlayLoader from "../../components/loader/OverlayLoader";

const Register = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const formRef = useRef(null);
  const emailInput = useRef();
  const firstNameInput = useRef();
  const lastNameInput = useRef();
  const passwordInput = useRef();
  const postcodeInput = useRef();
  const jobInput = useRef();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (user && user?.role !== "user") {
      setError(true);
      setErrorMessage("Already logged in");
      setTimeout(() => {
        window.location.href = "/profile";
      }, 4000);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      emailInput.current.value?.trim() === "" ||
      firstNameInput.current.value?.trim() === "" ||
      lastNameInput.current.value?.trim() === "" ||
      passwordInput.current.value?.trim() === "" ||
      postcodeInput.current.value?.trim() === "" ||
      jobInput.current.value?.trim() === ""
    ) {
      setError(true);
      setErrorMessage("Please fill all the fields to continue!!");
      return;
    }
    setIsLoading(true);
    let response;
    try {
      response = await axios.post("/candidates/register", {
        email: emailInput.current.value,
        firstName: firstNameInput.current.value,
        lastName: lastNameInput.current.value,
        password: passwordInput.current.value,
        postcode: postcodeInput.current.value,
        jobTitle: jobInput.current.value,
      });
      const { token, user } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      setSuccess(true);
      setSuccessMessage("Success!");
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/register/2/${user?._id}`);
      }, 500);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
    formRef.current.reset();
  };

  const viewPolicy = () => {
    window.location.href = "/privacy-policy";
  };

  const viewTerms = () => {
    window.location.href = "/terms-and-conditions";
  };

  return (
    <div className="">
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div className="register_maindiv section-padding">
        <h1>Candidate Registration</h1>
        <h2>Create an Alphy Johnson's Employment Center account</h2>
        <p>Be found by numerous companies who are hiring today</p>
        <div className="register_form">
          <h2>Your details</h2>
          <hr />
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="register_form_div">
              <div>
                <input
                  id="keyword"
                  className="text"
                  type="email"
                  placeholder="Email Address"
                  ref={emailInput}
                  required
                ></input>
              </div>
              <div className="register_form_name">
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="First Name"
                  ref={firstNameInput}
                  required
                ></input>
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="Last Name"
                  ref={lastNameInput}
                  required
                ></input>
              </div>
              <div>
                <input
                  id="keyword"
                  className="text"
                  type="password"
                  placeholder="Password"
                  ref={passwordInput}
                  required
                ></input>
              </div>
              <div>
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="Postcode"
                  ref={postcodeInput}
                  required
                ></input>
              </div>
              <div>
                <input
                  id="keyword"
                  className="text"
                  type="text"
                  placeholder="Desired Job Title"
                  ref={jobInput}
                  required
                ></input>
              </div>
              <div>
                <input
                  id="keyword"
                  className="text"
                  type="checkbox"
                  required
                ></input>
                <p className="register_terms_conditions">
                  By registering with Alphy Johnson's Employment Center you
                  agree to our
                  <span onClick={viewPolicy}> privacy policy </span> and
                  <span onClick={viewTerms}> Term & Conditions</span>{" "}
                </p>
              </div>
            </div>

            <Button type="submit" text={"Next"} />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
