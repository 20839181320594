import React from "react";
import Footer from "../components/footer/footer";
// import Header2 from "./Header2";
import Mainheader from "./Mainheader";
// import Nav2 from "./Nav2";
import Searchjob from "./Searchjob";
import "./cvlibrecruiter.css";
import Navbar3 from "./navbar3/navbar3";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find Best Job Recruiting Websites | Connect with Top Employers</title>

        <link rel="canonical" href="https://thealphyjohnson.com/job-recruiting-websites" />
        <meta
          name="description"
          content=" Find the leading job recruiting websites to connect with top companies. Browse jobs, submit your resume, & get hired faster. Explore your opportunities now!"
        />
      </Helmet>
      <Navbar3 />
      <Searchjob />
      <Mainheader />
      <Footer />
    </div>
  );
}

export default Home;
