import React from 'react'
import './header.css'
// import logo from '../../images/johan.png'
function Header() {
  return (
    <div className='login-logo'>
        <a href='/cv-data-base' className='login-logo__ancher'>
            
        </a>
    </div>
  )
}

export default Header