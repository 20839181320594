import React, { useState } from "react";
import "./ReactSearchBox.css";
import { FiSearch } from "react-icons/fi";

function ReactSearchBox() {
  const [location, setLocation] = useState("");
  const [keyword, setKeyword] = useState("");

  const handleSearch = async () => {
    window.location.href = `/job-recruiting-websites?location=${location}&keyword=${keyword}`;
  };

  return (
    <div className="react_search_box_div">
      <div className="row__1">
        <div className="column__1">
          <div className="form-group">
            <span>Keywords</span>
            <input
              className="form-field"
              type="text"
              placeholder="e.g. Customer Service"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
        </div>
        <div className="column__1">
          <div className="form-group-1">
            <span>Location</span>
            <input
              className="form-field"
              type="text"
              placeholder="e.g. postcode or town"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>
        <button className="btn_job" onClick={handleSearch}>
          Find jobs
          <FiSearch />
        </button>
      </div>
    </div>
  );
}

export default ReactSearchBox;
