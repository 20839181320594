import advertise from "../images/advertise.svg";
import nature8 from "../images/jobAlert.jpg";
import nature9 from "../images/companyhome.jpg";
import nature10 from "../images/careerhome.jpg";
import cv_logo from "../images/johan.png";
import screening from "../images/screening.jpg";
import homepage_img2 from "../images/homepage_img2.jpg";
import interviews from "../images/interviews.jpg";
import background_check from "../images/background_check.jpg";
import assistance from "../images/assistance.jpg";
import employment from "../images/employment.jpg";
import img1 from "../images/9.jpg";
import img2 from "../images/se.jpg";
import img3 from "../images/designer.jpg";
import img4 from "../images/language.jpg";
import img5 from "../images/personal.jpg";
import img6 from "../images/health.jpg";
import img7 from "../images/engineering.jpg";
import img8 from "../images/socialism.jpg";
import img9 from "../images/beauty.jpg";
import sh1 from "../images/sh.png";
import sh2 from "../images/sh1.png";
import sh3 from "../images/sh2.png";
import sh4 from "../images/sh6.png";
import sh5 from "../images/sh4.png";
import img10 from "../images/manufacturer.jpg";
import img11 from "../images/sales.jpg";
import civil from "../images/civil.jpg";
import manager from "../images/manager.jpg";
import media from "../images/media.jpg";
import prof from "../images/prof.jpg";
import doc from "../images/doc.jpg";

const Industries = [
  "Administrative & Clerical",
  "Banking & Finance",
  "Construction",
  "Customer Service",
  "Education",
  "Engineering",
  "Hospitality & Tourism",
  "Healthcare",
  "Marketing & Advertising",
  "Manufacturing & Production",
  "Sales",
  "Technology",
];

const Locations = [
  "Africa",
  "Nigeria",
  "Sierra Leone",
  "Washington DC",
  "Maryland",
  "Delaware",
  "Virginia",
  "New Jersey",
  "New York",
  "North Carolina",
  "South Carolina",
  "California",
  "Ohio",
  "Chicago",
  "Arizona",
  "Texas",
  "Philadelphia",
  "Utah",
  "Accra",
  "Kumasi",
  "Takoradi",
  "Cape Coast",
  "Winneba",
  "Saltpond",
  "Koforidua",
  "Bolgatanga",
];

export const Colors = {
  blue: "#033e3e",
};

export const AdditionalRoles = [
  "chief executive officer",
  "director",
  "manager",
  "supervisor",
  "corporate super agent",
  "corporate agent",
  "customer concierge",
  "learning administrator",
];

export const PermissionsList = {
  view_candidates: false,
  delete_candidate: false,
  view_employers: false,
  delete_employer: false,
  view_companies: false,
  delete_company: false,
  view_course_users: false,
  delete_course_user: false,
  add_post: false,
  update_post: false,
  delete_post: false,
  view_checkout_plans: false,
  add_checkout_plan: false,
  update_checkout_plan: false,
  delete_checkout_plan: false,
  view_premium_plans: false,
  add_premium_plan: false,
  update_premium_plan: false,
  delete_premium_plan: false,
  view_hiring_orders: false,
  delete_hiring_orders: false,
  view_courses: false,
  approve_course: false,
  reject_course: false,
  delete_course: false,
  view_enquiries: false,
  delete_enquiry: false,
  view_contact_us_queries: false,
  delete_contact_us_queries: false,
  add_recruiters: false,
};

const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Alphy Johnson",
  alternateName: "Alphy Johnson – Job Platform USA",
  url: "https://thealphyjohnson.com/",
  logo: "https://thealphyjohnson.com/static/media/johan.0bebdc494126c4f7da5f.png",
};

const websiteSchema = {
  "@context": "https://schema.org/",
  "@type": "WebSite",
  name: "Alphy Johnson – Job Platform",
  url: "https://thealphyjohnson.com/",
  potentialAction: {
    "@type": "SearchAction",
    target:
      "https://thealphyjohnson.com/job-recruiting-websites?keyword={search_term_string}&location={location}&miles={miles}&minSalary={min_salary}&maxSalary={max_salary}&jobType={job_type}&industry={industry}{search_term_string}",
    "query-input": "required name=search_term_string",
  },
};

const localBusinessSchema = {
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: "Alphy Johnson – Your Job Partner",
  image: "https://thealphyjohnson.com/static/media/media.6bcea13cec3b98c7c4aa.jpg",
  url: "https://thealphyjohnson.com/",
  telephone: "+1 (855) 580-0685",
  address: {
    "@type": "PostalAddress",
    streetAddress: "",
    addressLocality: "",
    postalCode: "",
    addressCountry: "",
  },
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    opens: "00:00",
    closes: "23:59",
  },
};

export { Industries, Locations, websiteSchema, organizationSchema, localBusinessSchema };

export const IMAGES = {
  advertise,
  nature8,
  nature9,
  nature10,
  cv_logo,
  screening,
  homepage_img2,
  interviews,
  background_check,
  assistance,
  employment,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  sh1,
  sh2,
  sh3,
  sh4,
  sh5,
  img10,
  img11,
  civil,
  manager,
  media,
  prof,
  doc,
};
