import React, { useEffect, useState } from "react";
import header_pic from "../../images/header_cv.jpeg";
// import { motion } from "framer-motion";
import { ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { useAuthContext } from "../../context/AuthContext";
import LazyImage from "../../components/common/LazyImage";

function Header() {
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setError(false);
    setErrorMessage("");
  }, [error]);

  const hanldeJobseekerClicks = () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      window.location.href = "/buildcv";
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      <ToastContainer />
      <div
        className="container-fluid cv-designer"
        // initial={{ x: -100 }}
        // animate={{ x: 0 }}
        // transition={{ duration: 2 }}
      >
        <div className="cvbuilder_header_div container d-flex justify-content-between align-items-center header1 pt-4">
          <div
            className="cvbuilder_left_div"
            // initial={{ opacity: 0 }} // Initial scale value for zooming in
            // animate={{ opacity: 1 }} // Target scale value for zooming out
            // transition={{
            //   duration: 8,
            //   repeat: Infinity,
            //   repeatType: "reverse",
            //   ease: "easeInOut",
            // }}
          >
            <h1 className="text-white fs-3 header-heading animate__backInLeft">
              The Ultimate Resume Fixer – Build Your Professional Resume in Minutes
            </h1>
            <p className="text-white fs-4">
              Need to fix up your resume fast? Our free resume fixer helps you craft a polished
              resume in just a few clicks. Whether you're starting from scratch or need to update
              your current CV, our USA jobs resume builder is here to make the process easy. Get
              your resume job-ready in minutes and start applying for your dream job today.
            </p>
            <div className="design-my-resume">
              <button
                className="btn btn-primary design-my-resume-btn"
                onClick={hanldeJobseekerClicks}
              >
                Design My Resume/CV
              </button>
            </div>
          </div>
          <div className="right">
            <LazyImage
              className="img-fluid"
              src={header_pic}
              alt="cv templates with cv maker"
              style={{ width: "868px", marginTop: "33px" }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
