import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./job.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "../profile/ProfileLeft";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";
import Logout from "../profile/Logout";
import { useProfileContext } from "../../context/ProfileContext";
import { ACTIONS } from "../../context/ActionTypes";
import * as api from "../../ApiCalls";

function JobAlert() {
  const { user } = useAuthContext();
  const { state, dispatch } = useProfileContext();
  const [formData, setFormData] = useState({
    keywords: "",
    location: "",
    radius: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchJobAlerts = async () => {
    try {
      const res = await api.fetchJobAlerts();
      dispatch({ type: ACTIONS.fetch_start });
      dispatch({ type: ACTIONS.fetch_job_alerts, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const handleSubmit = async () => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    try {
      setIsLoading(true);
      await axios.post("/jobs/job-alerts", formData);
      setSuccess(true);
      setSuccessMessage("Job Alert created");
      await fetchJobAlerts();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleDeleteAlert = async (id) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    try {
      setIsLoading(true);
      await axios.delete(`/jobs/job-alerts/${id}`);
      setSuccess(true);
      setSuccessMessage("Alert deleted successfully");
      await fetchJobAlerts();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const handleSearch = (id) => {
    const Alert = state.jobAlerts.find((obj) => obj?._id === id);
    window.location.href = `/job-recruiting-websites?keyword=${Alert?.keywords}&location=${Alert?.location}&miles=${Alert?.radius}`;
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          <div className="recommend" id="c2">
            <h3 className="details_heading">Jobs Alerts</h3>
            <div className="container-job">
              <div className="form_jobalert">
                <p>Create new Job Alert</p>
                <div className="top-from">
                  <div className="form-input">
                    <label htmlFor="">keywords</label>
                    <input
                      type="text"
                      className="form-control"
                      id="keywords"
                      name="keywords"
                      placeholder="Enter Keywords"
                      value={formData.keywords}
                      onChange={(e) =>
                        setFormData({ ...formData, keywords: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-input">
                    <label htmlFor="">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      placeholder="Enter Location"
                      value={formData.location}
                      onChange={(e) =>
                        setFormData({ ...formData, location: e.target.value })
                      }
                    />
                  </div>
                  <div className="selection">
                    <label htmlFor="pet-select">Within</label>

                    <select
                      id="pet-select"
                      className="top"
                      value={formData.radius}
                      onChange={(e) =>
                        setFormData({ ...formData, radius: e.target.value })
                      }
                    >
                      <option value="">Select Miles</option>
                      <option value="4">4 Miles</option>
                      <option value="6">6 Miles</option>
                      <option value="8">8 Miles</option>
                      <option value="11">11 Miles</option>
                      <option value="12">12 Miles</option>
                      <option value="23">23 Miles</option>
                    </select>
                  </div>
                </div>

                <div className="hide-show">
                  <button
                    className="btn btn-primary btn-btn-create-job"
                    onClick={handleSubmit}
                  >
                    Create New alert
                  </button>
                </div>
              </div>

              <div className="current_job text-dark">
                <p>
                  Your current job alerts{" "}
                  <span>({state.jobAlerts?.length} active)</span>
                </p>
              </div>

              {user?.role === "jobseeker" && state.jobAlerts.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
                  <p className="text-dark">No job alerts created yet!!</p>
                </div>
              ) : (
                <div className="web-developer">
                  <h3 className="details_heading">Current Job Alerts</h3>
                  {state.jobAlerts.map((item) => (
                    <div className="dec-icons" key={item._id}>
                      <div className="leftdec">
                        <p>
                          keywords: <span>{item.keywords.join(", ")}</span>
                        </p>
                        <p>
                          Location: <span>{item.location}</span>
                        </p>
                        <p>
                          Radius: <span>Within {item?.radius || 0} miles</span>
                        </p>
                      </div>
                      <div className="righticons">
                        <div className="righticon">
                          <li>
                            {" "}
                            <i className="bx bx-search-alt-2"></i>{" "}
                            <span
                              onClick={() => handleSearch(item?._id)}
                              style={{ cursor: "pointer" }}
                            >
                              Search
                            </span>
                          </li>
                          <li>
                            <i className="bx bxs-trash-alt"></i>
                            <span
                              onClick={() => handleDeleteAlert(item?._id)}
                              style={{ cursor: "pointer" }}
                            >
                              Delete Alert
                            </span>
                          </li>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
}

export default JobAlert;
