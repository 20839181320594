import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import { useAuthContext } from "../../context/AuthContext";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import OverlayLoader from "../../components/loader/OverlayLoader";

function Interview() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { appId } = useParams();
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login first to continue");
      setTimeout(() => {
        window.location.href = "/candidate/login";
      }, 2000);
      return;
    }
  }, [user]);

  const submitApplication = async () => {
    if (!time || !date || !link) {
      setError(true);
      setErrorMessage("Please fill all the fields to schedule interview");
      return;
    }
    setIsLoading(true);
    let route;
    switch (user?.role) {
      case "employer":
        route = `/employers/interview/${appId}`;
        break;
      case "company":
        route = `/companies/interview/${appId}`;
        break;
      default:
        break;
    }
    const newTime = formatTimeTo12Hours(time);
    try {
      const res = await axios.patch(route, {
        time: newTime,
        date,
        link,
      });
      if (res.status === 200) {
        setSuccess(true);
        setSuccessMessage(res?.data?.message);
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 1000);
      } else {
        setError(true);
        setErrorMessage("Error Scheduling Interview!!");
      }
    } catch (error) {
      console.log(error?.response);
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      }
      setIsLoading(false);
      return;
    }
  };

  const formatTimeTo12Hours = (time) => {
    const [hours, minutes] = time.split(":");
    let meridian = "AM";
    let formattedHours = parseInt(hours, 10);
    if (formattedHours >= 12) {
      meridian = "PM";
      formattedHours %= 12;
    }
    formattedHours = formattedHours || 12;
    return `${formattedHours}:${minutes} ${meridian}`;
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <div>
        <section>
          <div className="container update_password_container">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-9">
                <h3 className="mb-4 text-center">Schedule Interview</h3>

                <div
                  className="card text-white"
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "var(--blue)",
                  }}
                >
                  <div className="card-body">
                    <div className="row align-items-center py-3 input-field">
                      <div className="col-md-3 input_field_label">
                        <h6 className="mb-3">Time:</h6>
                      </div>
                      <div className="col-md-9 pe-5">
                        <input
                          className="form-control form-control-lg"
                          type="time"
                          name="time"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <hr className="mx-n3" />

                    <div className="row align-items-center py-3 input-field">
                      <div className="col-md-3 input_field_label">
                        <h6 className="mb-3">Date:</h6>
                      </div>
                      <div className="col-md-9 pe-5">
                        <input
                          className="form-control form-control-lg"
                          type="date"
                          name="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <hr className="mx-n3" />

                    <div className="row align-items-center py-3 input-field">
                      <div className="col-md-3 input_field_label">
                        <h6 className="mb-3">Link: </h6>
                      </div>
                      <div className="col-md-9 pe-5">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="link"
                          placeholder="Interview Link"
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <hr className="mx-n3" />

                    <div className="px-5 py-4 text-center">
                      <button
                        type="submit"
                        className="btn-lg"
                        onClick={submitApplication}
                      >
                        Schedule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Interview;
