import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { ToastError } from "../../components/alert/toastAlert";
import { useAuthContext } from "../../context/AuthContext";
import axios from "../../axios";

function Cvbulider() {
  const { user } = useAuthContext();
  const [cv, setCV] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setError(false);
  }, [error]);

  useEffect(() => {
    try {
      const fetchCV = async () => {
        await axios.get("/cv/").then((res) => {
          const { cv } = res.data;
          if (cv?.user._id === user?._id) {
            setCV(cv);
          }
        });
      };

      if (user?.role === "jobseeker") {
        fetchCV();
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const hanldeJobseekerClicks = (action) => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      return;
    }
    if (user && user?.role === "jobseeker") {
      switch (action) {
        case "create":
          window.location.href = "/buildcv";
          break;
        case "edit":
          window.location.href = "/updatecv";
          break;
        case "view":
          window.location.href = `/viewcv/${cv?._id}`;
          break;
        case "download":
          window.location.href = `/viewcv/${cv?._id}`;
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      <div
        className="container-fluid pb-5 cv-desinger-icons-main-section"
        style={{ background: "#f3f6f8" }}
      >
        <div className="container d-flex  flex-wrap justify-content-between align-items-center">
          <div className="mt-5">
            <h2 className="fs-6 fw-bold" style={{ textTransform: "uppercase" }}>
              HOW DOES THE JOBS RESUME BUILDER WORKS?
            </h2>

            <div className="left1 justify-content-center gap-2 d-flex flex-wrap mt-5">
              <div className=" pb-4">
                <li className="d-flex flex-col" style={{ listStyle: "none" }}>
                  <i className="bx bx-devices"></i>
                  <span
                    onClick={() => hanldeJobseekerClicks("create")}
                    style={{ cursor: "pointer" }}
                    className="fw-bold"
                  >
                    Build Your Resume Anywhere
                  </span>
                </li>
                <p
                  className="cvbuilder_div2_para"
                  style={{
                    width: "335px",
                    marginLeft: "60px",
                    marginTop: "-41px",
                  }}
                >
                  Whether you're using a phone, tablet, or laptop, our resume builder is designed to
                  work smoothly on all devices.
                </p>
              </div>
              <div className="pb-4">
                <li className="d-flex flex-col" style={{ listStyle: "none" }}>
                  <i className="bx bx-file"></i>
                  <span
                    className="fw-bold"
                    onClick={() => hanldeJobseekerClicks("edit")}
                    style={{ cursor: "pointer" }}
                  >
                    Personalize Your Resume
                  </span>
                </li>
                <p
                  className="cvbuilder_div2_para"
                  style={{
                    width: "335px",
                    marginLeft: "60px",
                    marginTop: "-41px",
                  }}
                >
                  Choose from our pre-written job descriptions and summaries to make your resume
                  stand out.
                </p>
              </div>
            </div>
            <div className="right2  justify-content-center mt-3  d-flex flex-wrap">
              <div>
                <li className="d-flex flex-col" style={{ listStyle: "none" }}>
                  <i className="bx bx-file-find"></i>
                  <span
                    className="fw-bold"
                    onClick={() => hanldeJobseekerClicks("view")}
                    style={{ cursor: "pointer" }}
                  >
                    Make Changes As You Go
                  </span>
                </li>
                <p
                  className="cvbuilder_div2_para"
                  style={{
                    width: "335px",
                    marginLeft: "60px",
                    marginTop: "-41px",
                  }}
                >
                  Review and tweak your resume at any point in the process to ensure it’s perfect.
                </p>
              </div>
              <div>
                <li className="d-flex flex-col" style={{ listStyle: "none" }}>
                  <i className="bx bx-download"></i>
                  <span
                    className="fw-bold"
                    onClick={() => hanldeJobseekerClicks("download")}
                    style={{ cursor: "pointer" }}
                  >
                    Save and Share
                  </span>
                </li>
                <p
                  className="cvbuilder_div2_para"
                  style={{
                    width: "335px",
                    marginLeft: "60px",
                    marginTop: "-41px",
                  }}
                >
                  Download your resume and start applying to jobs immediately – anywhere, anytime.
                </p>
              </div>
            </div>
          </div>
          <div className="cvbuilder_cv_build_div right3">
            <div className="cvbuilder_video">
              <YouTube
                videoId={"8OsgPPSdAoc"}
                // opts={{
                //   width: "400",
                //   height: "250",
                // }}
                autoplay={1}
                controls={1}
              />
            </div>
            {/* <video controls width="400" className="cvbuilder_video videos">
              <source
                src="https://www.youtube.com/watch?v=9P-AE6sH5E8&t=4s"
                type="video/webm"
              />
              <source
                src="https://www.youtube.com/watch?v=9P-AE6sH5E8&t=4s"
                type="video/mp4"
              />
              <a href="https://www.youtube.com/watch?v=9P-AE6sH5E8&t=4s">
                WEBM
              </a>
              <a href="https://www.youtube.com/watch?v=9P-AE6sH5E8&t=4s">MP4</a>
            </video> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Cvbulider;
