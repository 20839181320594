import React, { useEffect, useState } from "react";
// import "./register.css";
import Navbar3 from "../../components/navbar3/navbar3";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import OverlayLoader from "../../components/loader/OverlayLoader";

const AssistCV = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [cvDetails, setCvDetails] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    if (!user) {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      // window.location.href = "/register";
      return;
    }
    if (user && user?.role !== "jobseeker") {
      setError(true);
      setErrorMessage("Please login as a candidate to continue");
      // navigate(-1);
      return;
    }
  }, [user]);

  const handleSubmit = async () => {
    if (
      cvDetails.name === "" ||
      cvDetails.email === "" ||
      cvDetails.phone === "" ||
      cvDetails.description === ""
    ) {
      setError(true);
      setErrorMessage("Please fill all the fields to  continue");
      return;
    }
    try {
      setIsLoading(true);
      const res = await axios.post("/candidates/cv/assist", cvDetails);
      if (res.status === 201) {
        setSuccess(true);
        setSuccessMessage("Request submitted Successfully");
        setTimeout(() => {
          setIsLoading(false);
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage("Error processing request!!");
      }
      setIsLoading(false);
      return;
    }
  };

  const handleInputChange = (name, value) => {
    setCvDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      {isLoading && <OverlayLoader />}
      <div className="">
        <Navbar3 />
        <div className="register_maindiv section-padding">
          <h2>Welcome to Alphy Johnson's Employment Center</h2>
          <p>
            We just need a few details about you to Assist your CV or Resume
            Creation.
          </p>

          <div className="register_form">
            <h3 className="register_form_h3_start">Details</h3>
            <hr />
            <form>
              <div className="register_form_div">
                <div className="register_options">
                  <label>Full Name</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    placeholder="Enter Name"
                    type="text"
                    value={cvDetails?.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Email</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    placeholder="Enter Email"
                    type="email"
                    value={cvDetails?.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Phone</label>
                  <br />
                  <input
                    id="keyword"
                    className="text"
                    placeholder="Enter Phone"
                    type="text"
                    value={cvDetails?.phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                    required
                  ></input>
                </div>
              </div>

              <div className="register_form_div">
                <div className="register_options">
                  <label>Brief Description</label>
                  <br />
                  <textarea
                    rows={3}
                    id="keyword"
                    className="text"
                    placeholder="Enter detailed description"
                    type="text"
                    value={cvDetails?.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    required
                  ></textarea>
                </div>
              </div>
            </form>
          </div>

          <div className="button_maindiv">
            <button onClick={() => handleSubmit()}>Submit</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AssistCV;
