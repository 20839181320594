import React from "react";
import "./services.css";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import TopHeader from "./TopHeader";
import ServicesDetails from "./ServicesDetails";
import { Helmet } from "react-helmet";
import Services from "../homepage/Services";

const ServicesPage = () => {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://thealphyjohnson.com/our-services" />

        <title>Our Services | Explore What We Offer for Your Career Needs</title>

        <meta
          name="description"
          content="Explore the range of services we offer to support your career development. From resume fixes to job recruitment, find out how we can assist you."
        />
      </Helmet>

      <Navbar3 />
      <TopHeader />
      <div className="mt-4">
        <Services IsHome={false} />
      </div>
      <ServicesDetails />
      <Footer />
    </div>
  );
};

export default ServicesPage;
