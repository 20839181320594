import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../../components/alert/toastAlert";
import { useAuthContext } from "../../../context/AuthContext";
import { AdditionalRoles } from "../../../constants/GlobalConstants";

const Candidates = () => {
  const { user } = useAuthContext();
  const { state, fetchAllCandidates } = useAdminPanelContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    setPagePosts(state?.candidates?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.candidates?.length / postsPerPage));
  }, [state?.candidates, postsPerPage]);

  const deleteUser = async (id) => {
    try {
      setIsLoading(true);

      if (user?.role === "admin") await axios.delete(`/candidates/${id}`);
      if (AdditionalRoles.includes(user?.role))
        await axios.delete(`/additionalUsers/candidates/${id}`);

      setSuccess(true);
      setSuccessMessage("User deleted successfully!");
      await fetchAllCandidates();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const downloadCv = (candidate) => {
    const fileUrl = `${candidate?.cv}`;
    console.log(fileUrl);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.click();
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.candidates?.slice(
        indexOfFirstPost,
        indexOfLastPost,
      );
      setPagePosts(current);
    }
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="messages">
        <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
          Candidates
        </h3>
        {pagePosts?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pagePosts?.map((item, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                  <td>{item?.firstName + " " + item?.lastName}</td>
                  <td>{item?.email}</td>
                  <td>
                    {(user?.role === "admin" ||
                      user?.permissions?.delete_candidate) && (
                      <button onClick={() => deleteUser(item?._id)}>
                        Delete
                      </button>
                    )}

                    {item?.cv ? (
                      <button onClick={() => downloadCv(item)}>
                        View Resume
                      </button>
                    ) : user?.role !== "admin" &&
                      !user?.permissions?.delete_candidate ? (
                      <p>No Action Allowed.</p>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No Data yet to show Here!!</p>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Candidates;
