import { useState, useEffect } from "react";
// import { FaHeart } from "react-icons/fa";
import axios from "../../axios";
import Drawer from "./drawer";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchSavedCourses = async () => {
      try {
        setIsFetching(true);
        const res = await axios.get(`/users/courses/certificates`);
        setCertificates(res.data?.courses);
        const newPosts = res?.data?.courses;
        setPagePosts(newPosts?.slice(0, postsPerPage));
        const pages = Math.ceil(newPosts?.length / postsPerPage);
        setTotalPages(pages);
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching saved courses:", error);
      }
    };

    fetchSavedCourses();
  }, [postsPerPage]);

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = certificates?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  return (
    <>
      <Drawer />
      <div className="dashboard">
        <h2 className="courses_dashboard_heading job_alert_tips">
          Certificates
        </h2>
        {/* <p className="line">
          Get access to all 700+ courses (and MORE) for only £12.00 per month.
          Find out more.
        </p> */}

        <div className="messages">
          <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
            Certificates
          </h3>
          {isFetching ? (
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <Loader />
            </div>
          ) : (
            <>
              {pagePosts?.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Course Name</th>
                      <th>Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagePosts?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                        <td>{item?.courseId?.courseMetadata?.title}</td>
                        <td>
                          <a
                            href={item?.certificate}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Certificate
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="container d-flex justify-content-center align-items-center">
                  No data Exists!!
                </div>
              )}
            </>
          )}
        </div>
        {pagePosts?.length >= 1 && (
          <div className="pagination mt-4">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary" onClick={() => paginate()}>
                  ...
                </div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Certificates;
