import React, { useEffect, useState } from "react";
import axios from "../../axios";
import "./application.css";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "../profile/ProfileLeft";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import Loader from "../../components/Loader";
import OverlayLoader from "../../components/loader/OverlayLoader";
import Logout from "../profile/Logout";
import { useProfileContext } from "../../context/ProfileContext";

function MyApplication() {
  const { user } = useAuthContext();
  const { state } = useProfileContext();
  const [selectedJob, setSelectedJob] = useState({
    jobId: "",
    jobTitle: "",
  });
  const [applications, setApplications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  if (!user || (user?.role !== "employer" && user?.role !== "company")) {
    window.location.href = "/register";
  }

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchApplicationsOnJobs = async (id) => {
    try {
      setIsFetching(true);
      const role = user.role;
      await axios.get(`/jobs/${role}/applications/${id}`).then((res) => {
        const app = res.data;
        console.log(app);
        setApplications(app);
        const pages = Math.ceil(app?.length / postsPerPage);
        setTotalPages(pages);
        setPagePosts(app?.slice(0, postsPerPage));
        setIsFetching(false);
      });
    } catch (error) {
      // alert(error?.response?.data?.message);
      console.log(error);
      setIsFetching(false);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = applications?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  const handleJobChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const value = selectedOption.value;
    const jobID = selectedOption.getAttribute("data-custom-attribute");
    fetchApplicationsOnJobs(jobID);
    setSelectedJob({ jobId: jobID, jobTitle: value });
  };

  const viewApplication = async (id) => {
    let route;
    if (user?.role === "employer") route = `/employers/view/${id}`;
    if (user?.role === "company") route = `/companies/view/${id}`;
    try {
      setIsLoading(true);
      const res = await axios.patch(route);
      setSuccess(true);
      setSuccessMessage(res?.data?.message);
      await fetchApplicationsOnJobs(selectedJob.jobId);
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const updateBackgroundCheck = async (id, record) => {
    let route;
    if (user?.role === "employer") route = `/employers/background-status/${id}`;
    if (user?.role === "company") route = `/companies/background-status/${id}`;
    try {
      setIsLoading(true);
      const res = await axios.patch(route, record);
      console.log(res.data);
      setSuccess(true);
      setSuccessMessage(res?.data?.message);
      await fetchApplicationsOnJobs(selectedJob.jobId);
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const conductInterview = (id) => {
    window.location.replace(`/interview/${id}`);
  };

  return (
    <>
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading && <OverlayLoader />}
      <Navbar3 />
      <ReactSearchBox />
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          {isFetching ? (
            <div className="recommend" id="c2">
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="recommend" id="c2">
              <h3 className="details_heading">Posted Jobs</h3>
              {(user?.role === "employer" || user?.role === "company") &&
                user?.checkout?.packageName === "advertise jobs" && (
                  <>
                    {state?.jobs?.length === 0 ? (
                      <div className="application">
                        <p>You have not posted any jobs yet!!</p>
                      </div>
                    ) : (
                      <>
                        <div id="posted_jobs_header">
                          <label className="select_label">
                            <b>Select Job</b>
                          </label>
                          &emsp;
                          <select
                            className="job_select_input"
                            value={selectedJob.jobTitle}
                            onChange={(e) => handleJobChange(e)}
                          >
                            <option>Please Select</option>
                            {state?.jobs?.map((job) => {
                              return (
                                <option
                                  key={job?._id}
                                  data-custom-attribute={job?._id}
                                  value={job?.jobTitle}
                                >
                                  {job?.jobTitle}
                                </option>
                              );
                            })}
                          </select>
                          &emsp;
                          <h3>{pagePosts?.length} Applications</h3>
                        </div>
                        {applications?.length === 0 ? (
                          <div className="application_main_div">
                            <div className="application d-flex justify-content-center">
                              <p className="text-center">
                                This job has not received any applications yet!!
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="application_main_div">
                            {pagePosts?.map((application) => (
                              <div
                                className="application"
                                key={application?._id}
                              >
                                <h3 className="job-title">
                                  {application?.jobTitle}
                                </h3>
                                <div className="application_para_1">
                                  <p>
                                    <b>Applicant: </b>
                                    <a
                                      href={`/view-profile/${application?.user?._id}?role=jobseeker`}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {application?.user?.firstName +
                                        " " +
                                        application?.user?.lastName}
                                    </a>
                                  </p>
                                  <p>
                                    <b>Applicant Status: </b>
                                    {application?.user?.status}
                                  </p>
                                </div>
                                <div className="application_para_1">
                                  <p>
                                    <b>Location: </b> {application?.location}
                                  </p>
                                  <p>
                                    <b>Phone: </b> {application?.phone}
                                  </p>
                                </div>
                                <div className="application_para_1">
                                  <p>
                                    <b>View CV: </b>
                                    <a
                                      href={`${application?.cv}`}
                                      target="_blank"
                                      style={{ cursor: "pointer" }}
                                      rel="noreferrer"
                                    >
                                      {"cv.pdf"}
                                    </a>
                                  </p>
                                  {application?.user?.status === "featured" && (
                                    <>
                                      <p>
                                        <b>Viewed: </b>{" "}
                                        {application?.isViewed ? "Yes" : "No"}
                                      </p>
                                      {!application?.isViewed && (
                                        <button
                                          className="cv_build_btn"
                                          onClick={() =>
                                            viewApplication(application?._id)
                                          }
                                        >
                                          Mark As Viewed
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="application_para_1">
                                  <p>
                                    <b>Application Status: </b>{" "}
                                    {application?.recruitmentStatus}
                                  </p>

                                  {application?.user?.status === "featured" && (
                                    <div className="application-block-2">
                                      {application?.backgroundCheckRecord
                                        ?.isCheckedOut && (
                                        <p>
                                          <b>Background Check: </b>{" "}
                                          {
                                            application?.backgroundCheckRecord
                                              ?.isRecordClear
                                          }
                                        </p>
                                      )}
                                      {application?.backgroundCheckRecord
                                        ?.isCheckedOut && (
                                        <>
                                          <button
                                            className="btn btn-danger"
                                            onClick={() =>
                                              updateBackgroundCheck(
                                                application?._id,
                                                "clear"
                                              )
                                            }
                                          >
                                            Clear
                                          </button>
                                          <button
                                            className="btn btn-danger"
                                            onClick={() =>
                                              updateBackgroundCheck(
                                                application?._id,
                                                "not clear"
                                              )
                                            }
                                          >
                                            Not Clear
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <p>
                                  <b>Cover Letter: </b>{" "}
                                  {application?.coverLetter}
                                </p>
                                {application?.recruitmentStatus !==
                                  "interview" && (
                                  <p>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        conductInterview(application?._id)
                                      }
                                    >
                                      Call for Interview
                                    </button>
                                  </p>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                        {pagePosts?.length >= 1 && (
                          <div>
                            <div className="pagination">
                              <div
                                className="btn btn-primary"
                                onClick={() => paginate("prev")}
                              >
                                <FaLessThan />
                                <FaLessThan />
                              </div>
                              <div
                                className={
                                  currentPage === 1
                                    ? "btn btn-primary active_page_btn"
                                    : "btn btn-primary"
                                }
                                onClick={() => paginate(1)}
                              >
                                1
                              </div>
                              {currentPage !== 1 && (
                                <div
                                  className="btn btn-primary active_page_btn"
                                  onClick={() => paginate(currentPage)}
                                >
                                  {currentPage}
                                </div>
                              )}
                              {totalPages >= 2 && (
                                <>
                                  <div className="btn btn-primary">...</div>
                                  <div
                                    className="btn btn-primary"
                                    onClick={() => paginate(totalPages)}
                                  >
                                    {totalPages}
                                  </div>
                                </>
                              )}
                              <div
                                className="btn btn-primary"
                                onClick={() => paginate("next")}
                              >
                                <FaGreaterThan />
                                <FaGreaterThan />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
            </div>
          )}
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
}

export default MyApplication;
