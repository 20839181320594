import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useAdminPanelContext } from "../../../context/AdminPanelContext";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import OverlayLoader from "../../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../../components/alert/toastAlert";
import { useAuthContext } from "../../../context/AuthContext";
import { AdditionalRoles } from "../../../constants/GlobalConstants";

const Posts = () => {
  const { state, fetchAllPosts } = useAdminPanelContext();
  const { user } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pagePosts, setPagePosts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  useEffect(() => {
    setPagePosts(state?.posts?.slice(0, postsPerPage));
    setTotalPages(Math.ceil(state.posts?.length / postsPerPage));
  }, [state?.posts, postsPerPage]);

  const deletePost = async (id) => {
    try {
      setIsLoading(true);

      let route;
      if (user?.role === "admin") route = `/career-advices/${id}`;
      if (AdditionalRoles.includes(user?.role))
        route = `/additionalUsers/posts/${id}`;

      await axios.delete(route);

      setSuccess(true);
      setSuccessMessage("Post deleted successfully!");
      await fetchAllPosts();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber === "prev") {
      if (currentPage === 1) return;
      if (currentPage > 1) {
        paginate(currentPage - 1);
      }
    } else if (pageNumber === "next") {
      if (currentPage === totalPages) return;
      if (currentPage < totalPages) {
        paginate(currentPage + 1);
      }
    } else {
      setCurrentPage(pageNumber);
      const indexOfLastPost = pageNumber * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const current = state?.posts?.slice(indexOfFirstPost, indexOfLastPost);
      setPagePosts(current);
    }
  };

  const addPost = () => {
    window.location.href = "/addpost";
  };

  const editPost = (id) => {
    window.location.href = `/editpost/${id}`;
  };

  return (
    <>
      {isLoading ? <OverlayLoader /> : null}
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <div className="messages">
        <h3 style={{ textAlign: "center", fontWeight: "bolder" }}>
          Posts of Career Advice
        </h3>
        {user?.role === "admin" || user?.permissions?.add_post ? (
          <button onClick={addPost}>Add Post</button>
        ) : null}

        {pagePosts?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Headline</th>
                <th>Categories</th>
                <th>Author</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pagePosts?.map((post, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * postsPerPage + index + 1}</td>
                  <td>{post?.headline}</td>
                  <td>
                    {post?.category?.map((cat) => {
                      return `${cat} ,`;
                    })}
                  </td>
                  <td>
                    {post?.author?.employerId
                      ? post?.author?.employerId?.name
                      : post?.author?.companyId
                      ? post?.author?.companyId?.name
                      : post?.author?.jobSeekerId
                      ? `${post?.author?.jobSeekerId?.firstName} ${post?.author?.jobSeekerId?.lastName}`
                      : post?.author?.userId
                      ? `${post?.author?.userId?.firstName} ${post?.author?.userId?.lastName}`
                      : "Content Team"}
                  </td>
                  <td>
                    {user?.role === "admin" ||
                    user?.permissions?.update_post ? (
                      <button onClick={() => editPost(post?._id)}>Edit</button>
                    ) : null}

                    {user?.role === "admin" ||
                    user?.permissions?.delete_post ? (
                      <button onClick={() => deletePost(post?._id)}>
                        Delete
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "center" }}>No Data yet to show Here!!</p>
        )}
      </div>

      {pagePosts?.length >= 1 && (
        <div className="container-fluid mt-4">
          <div className="pagination">
            <div className="btn btn-primary" onClick={() => paginate("prev")}>
              <FaLessThan />
              <FaLessThan />
            </div>
            <div
              className={
                currentPage === 1
                  ? "btn btn-primary active_page_btn"
                  : "btn btn-primary"
              }
              onClick={() => paginate(1)}
            >
              1
            </div>
            {currentPage !== 1 && (
              <div
                className="btn btn-primary active_page_btn"
                onClick={() => paginate(currentPage)}
              >
                {currentPage}
              </div>
            )}
            {totalPages >= 2 && (
              <>
                <div className="btn btn-primary">...</div>
                <div
                  className="btn btn-primary"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <div className="btn btn-primary" onClick={() => paginate("next")}>
              <FaGreaterThan />
              <FaGreaterThan />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Posts;
