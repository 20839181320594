import React from "react";
import "./main_cvdatabase.css";
import search from "../cvdatabase_images/search.png";
import hands from "../cvdatabase_images/hands.png";
import box from "../cvdatabase_images/box.png";
import speaker from "../cvdatabase_images/speaker.png";
import apply from "../cvdatabase_images/apply.png";
import cup from "../cvdatabase_images/cup.png";
import handphone from "../cvdatabase_images/handphone.png";
import phone from "../cvdatabase_images/phone.png";
import LazyImage from "../../components/common/LazyImage";

function Main_cvdatabase() {
  return (
    <>
      <div className="container-fluid mian_section_cvdatabase pt-4 pb-4">
        <h2 className="header-heading job_alert_tips">Recruitment Made Easy</h2>
        <h1 className="text-center text-white fw-bold pt-5 animate__backInLeft ">
          Access Our Resume or CV Database and Maximize Your Job Reach Today!
        </h1>
        <p className="text-center text-white fw-bold animate__backInLeft home_content_main_para">
          Are you in the process of recruiting and looking for top talent? Our platform offers
          convenient solutions to streamline your hiring process. With our CV database and job
          advertising services, you can effectively reach qualified candidates and find the perfect
          fit for your organization.
        </p>
        <br></br>
        <div className="container d-flex justify-content-center gap-4 main_database_cards ">
          <div className="card main_database_cards1 animate__backInLeft hover-effect">
            <div className="card-body">
              <h5 className="card-title fw-bold fs-3">Maximize Your Job Reach</h5>
              <p className="card-text w-75">
                When you advertise your job openings with us, you gain access to the global largest
                independent Resume or CV Database.
              </p>
              <p className="card-text pt-3 fw-bold pb-2">
                Advertise to numerous Candidates<br></br> Receive Applications Directly to Your
                Inbox
              </p>
              <a
                href="/job-advertisement"
                className="btn text-white "
                style={{ background: "var(--btn-color)" }}
              >
                Advertise now
              </a>
            </div>
          </div>
          <div className="card main_database_cards1 animate__backInRight hover-effect">
            <div className="card-body">
              <h5 className="card-title fw-bold fs-3">Search Resume or CVs</h5>
              <p className="card-text w-75">
                Access the Africa’s Preferred Resume or CV Database for Unlimited Resume or CV Views
                to find the ideal candidate.
              </p>
              <p className="card-text pt-3 fw-bold pb-2">
                Unlimited CV views<br></br>Extensive Resume or CV Database
              </p>
              <a
                href="/searchcv"
                className="btn text-white "
                style={{ background: "var(--btn-color)" }}
              >
                Search CVs now
              </a>
            </div>
          </div>
        </div>

        <div className="container d-flex justify-content-center mt-4">
          <div className="card hover-effect" style={{ width: "48rem" }}>
            <div className="card-body d-flex justify-content-between align-items-center main_database_cards2">
              <div>
                <p className="card-text fs-5 fw-bold">
                  For Hiring Packages, call +1 (855) 580-0685
                </p>
                <p className="card-text fs-6">
                  We'll build a customised hiring solution, tailored to your needs
                </p>
              </div>
              <div>
                <a
                  href="/enquirenow"
                  className="btn text-white fw-bold"
                  style={{ background: "var(--btn-color)" }}
                >
                  Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="industries-head text-center fw-bold pt-4 animate__backInLeft ">
        Here's how our platform can assist you:
      </h2>
      <div className="container-fluid main_cv_database_content_div">
        <div className="d-flex justify-content-between gap-4 main_cvdatabase_subdiv">
          <div className="home_content_column job_board2 ">
            <h2 className="home_content_heading">Resume or CV Database Access:</h2>
            <p>
              Gain access to our extensive CV database, which is filled with resumes from skilled
              professionals across various industries. You can search the database using specific
              criteria such as skills, experience, location, or job titles to find candidates who
              meet your requirements. This saves you time and effort by directly connecting you with
              potential candidates who have already expressed their interest in job opportunities.
            </p>
          </div>
          <div className="home_content_column job_board2 ">
            <h2 className="home_content_heading">Targeted Candidate Search:</h2>
            <p>
              Our platform allows you to refine your candidate search based on your specific hiring
              needs. Utilize our advanced search filters to narrow down the pool of candidates and
              identify individuals with the right qualifications and experience for your vacant
              positions. This targeted approach ensures that you connect with candidates who align
              with your requirements.
            </p>
          </div>
        </div>
        <div className="d-flex gap-4 main_cvdatabase_subdiv">
          <div className="home_content_column job_board2 ">
            <h2 className="home_content_heading">Advertise Jobs:</h2>
            <p>
              Our job advertising service provides you with a platform to promote your job openings
              to a wide audience. You can create compelling job listings that showcase your company
              and the specific role, attracting the attention of potential candidates. By utilizing
              our platform's reach and visibility, you can maximize the exposure of your job
              opportunities and attract a diverse range of qualified candidates.
            </p>
          </div>
          <div className="home_content_column job_board2 ">
            <h2 className="home_content_heading">Streamlined Hiring Process:</h2>
            <p>
              Our recruitment services are designed to simplify and expedite your hiring process. By
              accessing our Resume or CV database and advertising jobs on our platform, you can
              efficiently source candidates, review resumes, and communicate with applicants. This
              streamlined approach enables you to identify and engage with top talent promptly,
              reducing time-to-hire and ensuring a smooth recruitment experience.
            </p>
          </div>
        </div>
        <div className="d-flex gap-4 main_cvdatabase_subdiv">
          <div className="home_content_column job_board2 ">
            <h2 className="home_content_heading">Targeted Reach:</h2>
            <p>
              Our platform caters to a wide range of industries and job seekers, allowing you to
              connect with candidates from diverse backgrounds and skill sets. Whether you're
              recruiting for entry-level positions, specialized roles, or executive positions, our
              platform offers a targeted reach that ensures your job opportunities reach the right
              audience.
            </p>
          </div>
        </div>
        <div className="container-fluid my-cv cv_database_below_div">
          <p>
            By utilizing our Resume or CV database and job advertising services, you can effectively
            streamline your recruitment efforts and connect with qualified candidates. Start
            leveraging our platform today to access top talent and find the ideal candidates for
            your organization. Simplify your hiring process and discover the best fit for your
            vacant positions.
          </p>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container d-flex justify-content-around mt-5 mb-5 main_section_second_database">
          <div className="right">
            <h2 className="fw-bold fs-5  text-center " style={{ color: "#000" }}>
              Best rating in the industry
            </h2>
            <div className="ps-2 mt-4 mb-2 text-center  ">
              <i className="bx bxs-star" style={{ color: "#00b67a", fontSize: "2rem" }}></i>{" "}
              <span style={{ fontSize: "1.6em", fontFamily: "serif" }}>Employment Center</span>
            </div>{" "}
            <span className="d-flex justify-content-center align-items-center">
              <i
                className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                style={{ background: "#00b67a" }}
              ></i>
              <i
                className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                style={{ background: "#00b67a" }}
              ></i>
              <i
                className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                style={{ background: "#00b67a" }}
              ></i>
              <i
                className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                style={{ background: "#00b67a" }}
              ></i>
              <i
                className="bx bxs-star-half ms-1 text-white cvdatabase-start_icons "
                style={{ background: "#00b67a" }}
              ></i>
            </span>{" "}
            <p className="text-center pt-2" style={{ fontSize: "13px" }}>
              TrustScore <span className="fw-bold">4.9 | 3,678</span> reviews
            </p>
            <p className="pt-5 text-center">
              Become part of our esteemed roster of companies that are actively recruiting with
              Alphy Johnson Employment Center. Join us today!
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ background: "#f3f6f8" }}>
        <div className="container pt-5">
          <h2 className="fw-bold text-center fs-3" style={{ color: "#000" }}>
            Simple and effective recruitment solutions for everyone
          </h2>
          <p className="text-center fs-6" style={{ fontFamily: "sans-serif" }}>
            Alphy Johnson's Employment Center's versatile hiring products and straightforward
            recruitment solutions are designed to benefit businesses of all sizes.
          </p>
          <p className="text-center fs-6" style={{ fontFamily: "sans-serif" }}>
            We focus on delivering quality job applications and growing our CV Database
          </p>
          <p className="text-center fs-6" style={{ fontFamily: "sans-serif" }}>
            Alphy Johnson's Employment Center is ideal for…
          </p>
        </div>
        <div className="container d-flex flex-wrap justify-content-around align-items-center pb-5">
          <div className="d-flex flex-column justify-content-center align-items-center hover-effect">
            {" "}
            <LazyImage
              style={{ width: "5rem" }}
              src={search}
              alt="Recruitment agencies"
              className="img-fluid"
            />{" "}
            <p className="fw-bold fs-5 ">Recruitment agencies</p>{" "}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center hover-effect">
            {" "}
            <LazyImage
              style={{ width: "5rem" }}
              src={hands}
              alt="Direct employers"
              className="img-fluid"
            />{" "}
            <p className="fw-bold fs-5 ">Direct employers</p>{" "}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center hover-effect">
            {" "}
            <LazyImage
              style={{ width: "5rem" }}
              src={speaker}
              alt="Advertising agencies"
              className="img-fluid"
            />{" "}
            <p className="fw-bold fs-5 ">Advertising agencies</p>{" "}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center hover-effect">
            {" "}
            <LazyImage
              style={{ width: "5rem" }}
              src={box}
              alt="Corporates"
              className="img-fluid"
            />{" "}
            <p className="fw-bold fs-5 ">Corporates</p>{" "}
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ background: "#f3f6f8" }}>
        <div className="container pt-5">
          <h1
            className="text-center  fw-bold"
            style={{ color: "#000", textTransform: "uppercase" }}
          >
            Benefits of recruiting with Alphy Johnson's Employment Center
          </h1>
        </div>
        <div className="container d-flex database_all_cards_sections  gap-4">
          <div className="card d-flex  cvdatabase-section5_main_cards">
            <div className="card-body d-flex flex-wrap justify-content-around align-items-center hover-effect">
              <div>
                {" "}
                <LazyImage
                  src={apply}
                  className="img-fluid"
                  alt="Database Access"
                  style={{ width: "5rem" }}
                />
              </div>
              <div>
                <h5 className="card-title fw-bold database_cards_title">
                  Resume or CV Database Access
                </h5>
                <p className="card-text database_cards_des">
                  Gain access to our extensive CV database, which is filled with resumes from
                  skilled professionals across various industries.
                </p>
              </div>
            </div>
          </div>
          <div className="card d-flex  cvdatabase-section5_main_cards">
            <div className="card-body d-flex flex-wrap justify-content-around align-items-center hover-effect">
              <div>
                {" "}
                <LazyImage
                  src={phone}
                  className="img-fluid"
                  alt="Targeted Candidate Search"
                  style={{ width: "5rem" }}
                />
              </div>
              <div>
                <h5 className="card-title fw-bold database_cards_title">
                  Targeted Candidate Search
                </h5>
                <p className="card-text database_cards_des">
                  Our platform allows you to refine your candidate search based on your specific
                  hiring needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex database_all_cards_sections gap-4 pt-4 pb-5">
          <div className="card d-flex  cvdatabase-section5_main_cards">
            <div className="card-body d-flex flex-wrap justify-content-around align-items-center hover-effect">
              <div>
                {" "}
                <LazyImage
                  src={handphone}
                  className="img-fluid"
                  alt="Advertise Jobs"
                  style={{ width: "5rem" }}
                />
              </div>
              <div>
                <h5 className="card-title fw-bold database_cards_title">Advertise Jobs</h5>
                <p className="card-text database_cards_des">
                  Our job advertising service provides you with a platform to promote your job
                  openings to a wide audience.
                </p>
              </div>
            </div>
          </div>
          <div className="card d-flex cvdatabase-section5_main_cards">
            <div className="card-body d-flex flex-wrap justify-content-around align-items-center hover-effect ">
              <div>
                {" "}
                <LazyImage
                  src={cup}
                  className="img-fluid"
                  alt="Streamlined Hiring Process"
                  style={{ width: "5rem" }}
                />
              </div>
              <div>
                <h5 className="card-title fw-bold database_cards_title">
                  Streamlined Hiring Process
                </h5>
                <p className="card-text database_cards_des">
                  Our recruitment services are designed to simplify and expedite your hiring
                  process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-5 mb-4 reac-the-best-candidates-animation">
          <h1 className="text-center" style={{ color: "#000", textTransform: "uppercase" }}>
            Reach the best candidates
          </h1>
          <p className="text-center fs-6" style={{ fontFamily: "systems-ui" }}>
            Our unique network is home to Numerous specially selected Global job sites.
          </p>
          <p className="text-center fs-6" style={{ fontFamily: "systems-ui" }}>
            All jobs posted on Alphy Johnson's Employment Center are advertised on all relevant
            Group and partner sites, at no extra cost!
          </p>
          <p className="text-center pt-3 fs-6 pb-5" style={{ fontFamily: "systems-ui" }}>
            Reach more candidates and receive more job applications!
          </p>
        </div>
      </div>
    </>
  );
}

export default Main_cvdatabase;
