import React, { useEffect, useState } from "react";
import { Industries, Locations } from "../../constants/GlobalConstants";
// import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "../../axios";

const Dropdown = () => {
  const [industries, setIsIndustry] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const [company, setCompany] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const res = await axios.get("/companies/all");
      // console.log(res?.data.data);
      setCompanies(res.data?.data);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (isLocation) {
      setIsIndustry(false);
      setCompany(false);
      setIsPopular(false);
    }
  }, [isLocation]);

  useEffect(() => {
    if (industries) {
      setIsLocation(false);
      setCompany(false);
      setIsPopular(false);
    }
  }, [industries]);

  useEffect(() => {
    if (isPopular) {
      setIsIndustry(false);
      setCompany(false);
      setIsLocation(false);
    }
  }, [isPopular]);

  useEffect(() => {
    if (company) {
      setIsLocation(false);
      setIsIndustry(false);
      setIsPopular(false);
    }
  }, [company]);

  const handleLinkClick = async (paramName, paramValue) => {
    try {
      const encoded = encodeURIComponent(paramValue);
      const queryParams = new URLSearchParams({
        [paramName]: encoded,
      });
      window.location.href = `/job-recruiting-websites?${queryParams}`;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="container mt-5 d-flex justify-content-around   gap-2 drop_down_hide_show_links--homepage">
        <Link
          to=""
          className="drowp_down_links_company rounded"
          onClick={() => setIsIndustry(!industries)}
          style={{ border: "2px solid white" }}
        >
          Jobs By Industry{" "}
          <i className="bx bxs-down-arrow  company_a-z_icons"></i>
        </Link>
        <Link
          to=""
          className="drowp_down_links_company rounded"
          onClick={() => setIsLocation(!isLocation)}
          style={{ border: "2px solid white" }}
        >
          Jobs By Location{" "}
          <i className="bx bxs-down-arrow company_a-z_icons"></i>
        </Link>
        <Link
          to=""
          className="drowp_down_links_company rounded"
          onClick={() => setIsPopular(!isPopular)}
          style={{ border: "2px solid white" }}
        >
          Popular Jobs <i className="bx bxs-down-arrow company_a-z_icons"></i>
        </Link>
        <Link
          to=""
          className="drowp_down_links_company rounded"
          onClick={() => setCompany(!company)}
          style={{ border: "2px solid white" }}
        >
          Jobs By Company{" "}
          <i className="bx bxs-down-arrow company_a-z_icons"></i>
        </Link>
      </div>
      {industries && (
        // <motion.div
        //   className="container-fluid links_div"
        //   initial={{ y: -10 }}
        //   animate={{ y: 0 }}
        //   transition={{ duration: 2 }}
        // >
        <div className="container-fluid links_div">
          <div
            className="container-fluid pb-3 drop-down-homePage-Links"
            style={{ border: "1px solid white", borderRadius: "5px" }}
          >
            <ul>
              {Industries?.sort()
                ?.slice(0, 3)
                ?.map((item) => {
                  return (
                    <li
                      key={item}
                      style={{
                        listStyle: "none",
                        fontFamily: "sans-serif",
                        paddingTop: "0.8rem",
                      }}
                    >
                      <Link
                        style={{ color: "white" }}
                        onClick={() => handleLinkClick("industry", item)}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul>
              {Industries?.sort()
                ?.slice(3, 6)
                ?.map((item) => {
                  return (
                    <li
                      key={item}
                      style={{
                        listStyle: "none",
                        fontFamily: "sans-serif",
                        paddingTop: "0.8rem",
                      }}
                    >
                      <Link
                        style={{ color: "white" }}
                        onClick={() => handleLinkClick("industry", item)}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul>
              {Industries?.sort()
                ?.slice(6, 9)
                ?.map((item) => {
                  return (
                    <li
                      key={item}
                      style={{
                        listStyle: "none",
                        fontFamily: "sans-serif",
                        paddingTop: "0.8rem",
                      }}
                    >
                      <Link
                        style={{ color: "white" }}
                        onClick={() => handleLinkClick("industry", item)}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul>
              {Industries?.sort()
                ?.slice(9, 12)
                ?.map((item) => {
                  return (
                    <li
                      key={item}
                      style={{
                        listStyle: "none",
                        fontFamily: "sans-serif",
                        paddingTop: "0.8rem",
                      }}
                    >
                      <Link
                        style={{ color: "white" }}
                        onClick={() => handleLinkClick("industry", item)}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
      {isLocation && (
        // <motion.div
        //   className="container-fluid"
        //   initial={{ y: -10 }}
        //   animate={{ y: 0 }}
        //   transition={{ duration: 2 }}
        // >
        <div className="container-fluid links_div">
          <div
            className="container-fluid pb-3 drop-down-homePage-Links"
            style={{ border: "1px solid white", borderRadius: "5px" }}
          >
            <ul>
              {Locations?.slice(0, 6)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      style={{ color: "white" }}
                      to="#"
                      onClick={() => handleLinkClick("location", obj)}
                    >
                      {obj}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {Locations?.slice(6, 12)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      style={{ color: "white" }}
                      to="#"
                      onClick={() => handleLinkClick("location", obj)}
                    >
                      {obj}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {Locations?.slice(12, 18)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      style={{ color: "white" }}
                      to="#"
                      onClick={() => handleLinkClick("location", obj)}
                    >
                      {obj}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {Locations?.slice(18, 24)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      style={{ color: "white" }}
                      to="#"
                      onClick={() => handleLinkClick("location", obj)}
                    >
                      {obj}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {isPopular && (
        // <motion.div
        //   className="container-fluid pt-3"
        //   initial={{ y: -10 }}
        //   animate={{ y: 0 }}
        //   transition={{ duration: 2 }}
        // >
        <div className="container-fluid links_div">
          <div
            className="container-fluid pb-3 drop-down-homePage-Links"
            style={{ border: "1px solid white", borderRadius: "5px" }}
          >
            <ul>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    handleLinkClick("keyword", "Adminstrator Jobs")
                  }
                >
                  Adminstrator Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Bus driver Jobs")}
                >
                  Bus driver Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Carer Jobs")}
                >
                  Carer Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Data Entry Jobs")}
                >
                  Data Entry Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    handleLinkClick("keyword", "Estate agent Jobs")
                  }
                >
                  Estate agent Jobs
                </Link>
              </li>
            </ul>
            <ul>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Airport Jobs")}
                >
                  Airport Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Call Center Jobs")}
                >
                  Call Center Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Carpenter Jobs")}
                >
                  Carpenter Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    handleLinkClick("keyword", "Delivery driver Jobs")
                  }
                >
                  Delivery driver Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    handleLinkClick("keyword", "Events Management Jobs")
                  }
                >
                  Events Management Jobs
                </Link>
              </li>
            </ul>
            <ul>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Bookkeeper Jobs")}
                >
                  Bookkeeper Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("Bookkeeper Jobs")}
                >
                  Bookkeeper Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Clearing Jobs")}
                >
                  Clearing Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Driver Jobs")}
                >
                  Driver Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    handleLinkClick("keyword", "Finance Manager Jobs")
                  }
                >
                  Finance Manager Jobs
                </Link>
              </li>
            </ul>
            <ul>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Bricklayer Jobs")}
                >
                  Bricklayer Jobs
                </Link>{" "}
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Care Home Jobs")}
                >
                  Care Home Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Council Jobs")}
                >
                  Council Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Electrician Jobs")}
                >
                  Electrician Jobs
                </Link>
              </li>
              <li
                style={{
                  listStyle: "none",
                  fontFamily: "sans-serif",
                  paddingTop: "0.8rem",
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() => handleLinkClick("keyword", "Football Jobs")}
                >
                  Football Jobs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      {company && (
        // <motion.div
        //   className="container-fluid pt-3"
        //   initial={{ y: -10 }}
        //   animate={{ y: 0 }}
        //   transition={{ duration: 2 }}
        // >
        <div className="container-fluid links_div">
          <div
            className="container-fluid pb-3 drop-down-homePage-Links"
            style={{ border: "1px solid white", borderRadius: "5px" }}
          >
            <ul>
              {companies?.slice(0, 5)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      to={`/view-profile/${obj?._id}?role=company`}
                      style={{ color: "white" }}
                    >
                      {obj?.name?.length > 20
                        ? obj?.name?.slice(0, 20) + "..."
                        : obj?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {companies?.slice(6, 10)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      to={`/view-profile/${obj?._id}?role=company`}
                      style={{ color: "white" }}
                    >
                      {obj?.name?.length > 20
                        ? obj?.name?.slice(0, 20) + "..."
                        : obj?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {companies?.slice(10, 15)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      to={`/view-profile/${obj?._id}?role=company`}
                      style={{ color: "white" }}
                    >
                      {obj?.name?.length > 20
                        ? obj?.name?.slice(0, 20) + "..."
                        : obj?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {companies?.slice(15, 20)?.map((obj, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      fontFamily: "sans-serif",
                      paddingTop: "0.8rem",
                    }}
                  >
                    <Link
                      to={`/view-profile/${obj?._id}?role=company`}
                      style={{ color: "white" }}
                    >
                      {obj?.name?.length > 20
                        ? obj?.name?.slice(0, 20) + "..."
                        : obj?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
