import React from "react";
import "./jobs.css";
import Button from "../button/button";

const jobs = ({
  jobs,
  description,
  descrip2,
  noOfDays,
  price,
  saving,
  additionalInfo,
  onclick
}) => {
  return (
    <div className="jobs_maindiv animate__backInRight">
      <h1>{jobs}</h1>
      <p className="days28">{noOfDays} day job postings</p>
      <p className="descrip2">{descrip2}</p>
      <p>{description}</p>
      <h1>{`$${price}+VAT`}</h1>
      {/* <p className="saving">{`$${saving}+VAT`}</p> */}
      <Button onclick={onclick} text={"buy now"} />
      <p className="additional_info">{additionalInfo}</p>
    </div>
  );
};

export default jobs;
