import React, { useEffect, useState } from "react";
import "./careerAdvice.css";
import { FaPlusCircle } from "react-icons/fa";
import Blogposts from "../../components/blogPosts/blogPosts";
import Navbar3 from "../../components/navbar3/navbar3";
import { motion } from "framer-motion";
import axios from "../../axios";
import { useAuthContext } from "../../context/AuthContext";
import Footer from "../../components/footer/footer";
import Loader from "../../components/Loader";
import RightOptions from "../../components/careerAdvice/RightOptions";
import BottomOptions from "../../components/careerAdvice/BottomOptions";
import SearchBar from "../../components/careerAdvice/SearchBar";
import { Helmet } from "react-helmet";

const CareerAdvice = () => {
  const { user } = useAuthContext();
  const [popular, setPopular] = useState([]);
  const [cvPosts, setcvPosts] = useState([]);
  const [coverLetterPosts, setCoverLetterPosts] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    try {
      const fetchAllPosts = async () => {
        await axios.get("/career-advices").then((res) => {
          setFeaturedPosts(res?.data?.docs);
          const post1 = filterPosts(res?.data?.docs, "cv tips and templates");
          setcvPosts(post1);
          const post2 = filterPosts(res?.data?.docs, "cover letter guides");
          setCoverLetterPosts(post2);
        });
      };

      const fetchPopular = async () => {
        await axios.get("/career-advices/popular").then((res) => {
          const post2 = filterPosts(res?.data?.posts, "career guides");
          setPopular(post2);
        });
      };

      const fetchData = async () => {
        setIsFetching(true);
        await fetchAllPosts();
        await fetchPopular();
        setIsFetching(false);
      };

      fetchData();
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      // alert(error?.response?.data?.message);
    }
  }, []);

  const formatDate = (string) => {
    const date = new Date(string);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const filterPosts = (postsArray, category) => {
    const filtered = postsArray.filter((post) => {
      return post?.category?.some((cat) => cat?.includes(category));
    });
    return filtered;
  };

  const visitPost = (id) => {
    window.location.replace(`/career-advice/${id}`);
  };

  const animationVariants = {
    initial: {
      opacity: 0,
      y: -20,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  const animationTransition = {
    duration: 5,
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 4,
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Advice | Expert Tips for Professional Success</title>

        <link rel="canonical" href="https://thealphyjohnson.com/career-advice" />
        <meta
          name="description"
          content="Get valuable career advice to navigate your professional journey. Discover tips & strategies from experts to enhance your job search & career development."
        />
        <meta
          name="keywords"
          content="job boards online,resume maker online for free,job search in the usa,career counselling courses,employment opportunity"
        />
      </Helmet>
      <Navbar3 />
      <SearchBar />
      <hr />
      <div className="career_advice">
        <motion.h1
          variants={animationVariants}
          initial="initial"
          animate="animate"
          transition={animationTransition}
          style={{ textTransform: "capitalize", marginInline: "5%" }}
        >
          Navigate Your Career Journey with Expert Career Advice: Unlocking Your Professional
          Potential
        </motion.h1>
        {user && user?.role !== "user" && (
          <a
            className="create_post_item"
            style={{
              textDecoration: "none",
            }}
            href="/addpost"
          >
            <FaPlusCircle /> Create Post
          </a>
        )}
        <p style={{ textTransform: "capitalize" }}>
          In our comprehensive advice center, you'll find a wealth of resources designed to support
          your career journey. Packed with insightful articles, practical tips, step-by-step guides,
          and professionally crafted CV templates, our platform is dedicated to equipping you with
          the knowledge and tools to excel in your job search and make the most of your career.
        </p>
      </div>

      <div className="section-padding">
        <hr />
        <ul id="list-nav">
          <li>
            <a href="/career-advice" style={{ cursor: "pointer" }}>
              Home
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/getting started`}>
              getting started
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/career guides`}>
              career guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/location guides`}>
              location guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cvs`}>
              cvs
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cv tips and templates`}>
              cv tips and templates
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/cover letter guides`}>
              cover letter guides
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/interview tips`}>
              interview tips
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/work life balance`}>
              work life balance
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/career development`}>
              career development
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/graduates`}>
              graduates
            </a>
          </li>
          <li>
            <a style={{ cursor: "pointer" }} href={`/career-advice/category/apprenticeships`}>
              apprenticeships
            </a>
          </li>
        </ul>
        <hr />
      </div>
      {isFetching ? (
        <div className="container d-flex justify-content-center align-items-center section-padding">
          <Loader />
        </div>
      ) : (
        <>
          {featuredPosts?.length <= 0 ? (
            <div className="container d-flex justify-content-center align-items-center section-padding">
              <h2>Coming Soon...</h2>
            </div>
          ) : (
            <div className="blogs_div section-padding">
              {featuredPosts?.slice(0, 5).map((post) => {
                return (
                  <Blogposts
                    key={post?._id}
                    onclick={() => visitPost(post._id)}
                    img={post?.thumbnail}
                    heading={
                      post?.headline?.length > 28
                        ? `${post?.headline.slice(0, 25)}...`
                        : post?.headline
                    }
                    author={post?.author}
                    date={formatDate(post?.date)}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
      <div className="careeradvice_blog_posts_div section-padding">
        <div className="blog_posts_flex_div">
          <div className="blog_posts_flex_div_subdiv1">
            <div>
              <h2 className="blog_posts_heading">6 Common Career Advices</h2>
              {isFetching ? (
                <div className="careeradvice_separate_blogposts">
                  <div className="container d-flex justify-content-center align-items-center section-padding">
                    <Loader />
                  </div>
                </div>
              ) : (
                <>
                  {popular?.length <= 0 ? (
                    <div className="container d-flex justify-content-center align-items-center section-padding">
                      <h2>Coming Soon...</h2>
                    </div>
                  ) : (
                    <>
                      <div className="careeradvice_separate_blogposts">
                        {popular?.slice(0, 3).map((post) => {
                          return (
                            <Blogposts
                              key={post?._id}
                              onclick={() => visitPost(post._id)}
                              img={post?.thumbnail}
                              heading={
                                post?.headline?.length > 28
                                  ? `${post?.headline.slice(0, 25)}...`
                                  : post?.headline
                              }
                              author={post?.author}
                              date={formatDate(post?.date)}
                            />
                          );
                        })}
                      </div>
                      <div className="careeradvice_separate_blogposts">
                        {popular?.slice(3, 6).map((post) => {
                          return (
                            <Blogposts
                              key={post?._id}
                              onclick={() => visitPost(post._id)}
                              img={post?.thumbnail}
                              heading={
                                post?.headline?.length > 28
                                  ? `${post?.headline.slice(0, 25)}...`
                                  : post?.headline
                              }
                              author={post?.author}
                              date={formatDate(post?.date)}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div>
              <h2 className="blog_posts_heading">Tips and Templates for Resumes or CVs</h2>
              {isFetching ? (
                <div className="careeradvice_separate_blogposts">
                  <div className="container d-flex justify-content-center align-items-center section-padding">
                    <Loader />
                  </div>
                </div>
              ) : (
                <>
                  {cvPosts?.length <= 0 ? (
                    <div className="container d-flex justify-content-center align-items-center section-padding">
                      <h2>Coming Soon...</h2>
                    </div>
                  ) : (
                    <div className="careeradvice_separate_blogposts">
                      {cvPosts?.slice(0, 3).map((post) => {
                        return (
                          <Blogposts
                            key={post?._id}
                            onclick={() => visitPost(post._id)}
                            img={post?.thumbnail}
                            heading={
                              post?.headline?.length > 28
                                ? `${post?.headline.slice(0, 25)}...`
                                : post?.headline
                            }
                            author={post?.author}
                            date={formatDate(post?.date)}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>

            <div>
              <h2 className="blog_posts_heading networkng">Guides for Writing Cover Letters</h2>
              {isFetching ? (
                <div className="careeradvice_separate_blogposts">
                  <div className="container d-flex justify-content-center align-items-center section-padding">
                    <Loader />
                  </div>
                </div>
              ) : (
                <>
                  {coverLetterPosts.length <= 0 ? (
                    <div className="container d-flex justify-content-center align-items-center section-padding">
                      <h2>Coming Soon...</h2>
                    </div>
                  ) : (
                    <div className="careeradvice_separate_blogposts">
                      {coverLetterPosts?.slice(0, 3).map((post) => {
                        return (
                          <Blogposts
                            key={post?._id}
                            onclick={() => visitPost(post._id)}
                            img={post?.thumbnail}
                            heading={
                              post?.headline?.length > 28
                                ? `${post?.headline.slice(0, 25)}...`
                                : post?.headline
                            }
                            author={post?.author}
                            date={formatDate(post?.date)}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <RightOptions />
        </div>

        <h2 className="latest_job_head">
          The most recent job opportunities on{" "}
          <a href="https://resumeglobalhub.thealphyjohnson.com">ResumeGlobalHub</a> and{" "}
          <a href="https://cvglobalhub.thealphyjohnson.com">CvGlobalHub</a>.
        </h2>

        <BottomOptions />
      </div>
      <Footer />
    </div>
  );
};

export default CareerAdvice;
