import React, { useEffect, useState } from "react";
import * as api from "../../ApiCalls";
import { useAuthContext } from "../../context/AuthContext";
import Navbar3 from "../../components/navbar3/navbar3";
import Footer from "../../components/footer/footer";
import ReactSearchBox from "../../components/ReactSearchBox/ReactSearchBox";
import ProfileLeft from "./ProfileLeft";
import Logout from "./Logout";
import Loader from "../../components/Loader";
import { useProfileContext } from "../../context/ProfileContext";
import { ACTIONS } from "../../context/ActionTypes";
import OverlayLoader from "../../components/loader/OverlayLoader";
import { ToastSuccess, ToastError } from "../../components/alert/toastAlert";
import { ToastContainer } from "react-toastify";

const Devices = () => {
  const { user } = useAuthContext();
  const { state, dispatch } = useProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setError(false);
    setSuccess(false);
  }, [error, success]);

  const fetchDevices = async () => {
    try {
      dispatch({ type: ACTIONS.fetch_start });
      const res = await api.fetchDevices(user?.role);
      dispatch({ type: ACTIONS.fetch_devices, payload: res });
      dispatch({ type: ACTIONS.fetch_success });
    } catch (error) {
      dispatch({ type: ACTIONS.fetch_failure });
    }
  };

  const handleDeleteDevices = async () => {
    try {
      setIsLoading(true);
      await api.deleteDevices(user?.role);
      setSuccess(true);
      setSuccessMessage("Devices deleted Successfully.");
      await fetchDevices();
      setIsLoading(false);
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        setError(true);
        setErrorMessage("Network Error! Please check your network");
      } else {
        setError(true);
        setErrorMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <Navbar3 />
      <ReactSearchBox />
      {error && <ToastError message={errorMessage} />}
      {success && <ToastSuccess message={successMessage} />}
      <ToastContainer />
      {isLoading ? <OverlayLoader /> : null}
      <div className="profile">
        <h1 className="job-details-title job_alert_tips">My Dashboard</h1>
        <div className="main_box">
          <ProfileLeft />
          {state?.isFetching ? (
            <div className="recommend " id="c2">
              <div className="container d-flex justify-content-center align-items-center section-padding">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="recommend " id="c2">
              <h3 className="details_heading">Connected Devices</h3>
              {state?.devices?.length > 0 ? (
                <button onClick={handleDeleteDevices}>Delete All</button>
              ) : null}
              <div className="application_main_div">
                {state?.devices.length > 0 ? (
                  state?.devices.map((obj) => {
                    return (
                      <section key={obj?._id} className="application">
                        <div>
                          <h3>Device Details</h3>
                          <p
                            style={{
                              margin: "0",
                              paddingBottom: "0",
                              lineHeight: "unset",
                            }}
                          >
                            <b>IP Address:</b> {obj?.device?.id}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              paddingBottom: "0",
                              lineHeight: "unset",
                            }}
                          >
                            <b>Other Info:</b> {obj?.device?.details}
                          </p>
                        </div>
                        <div className="mt-2">
                          <h3>Location Details</h3>
                          <p
                            style={{
                              margin: "0",
                              paddingBottom: "0",
                              lineHeight: "unset",
                            }}
                          >
                            <b>Latitude:</b> {obj?.location?.lat}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              paddingBottom: "0",
                              lineHeight: "unset",
                            }}
                          >
                            <b>Longitude:</b> {obj?.location?.long}
                          </p>
                          {obj?.location?.address && (
                            <p
                              style={{
                                margin: "0",
                                paddingBottom: "0",
                                lineHeight: "unset",
                              }}
                            >
                              <b>Address:</b> {obj?.location?.address}
                            </p>
                          )}
                        </div>
                      </section>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <h2>No connected devices yet!!</h2>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Logout />
      </div>
      <Footer />
    </>
  );
};

export default Devices;
